export default {
    mounted() {
        window.addEventListener("resize", this.resizeHandler);
        document
            .getElementsByClassName("fold_img")[0]
            .addEventListener("click", this.resizeHandler);
    },
    methods: {
        resizeHandler() {
            // console.log('resize', 'click');
            this.chart.resize();
        },
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.resizeHandler);

        document
        .getElementsByClassName("fold_img")[0]
        .removeEventListener("click", this.resizeHandler);
    },
}