<template>
  <div class="indusInfo">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/infoManage' }"
          >基础信息管理</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/infoManage/hotwords' }"
          >热词管理</el-breadcrumb-item
        >
        <el-breadcrumb-item v-if="$route.query.hotWordId"
          >编辑</el-breadcrumb-item
        >
        <el-breadcrumb-item v-else>新增</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main">
      <div class="title">
        <div class="left">
          <i class="sep"></i>
          <h4 v-if="$route.query.hotWordId">编辑热词</h4>
          <h4 v-else>新增热词</h4>
        </div>
      </div>
      <div class="content">
        <el-form
          :model="formData"
          label-width="auto"
          class="basic_form"
          :rules="rules"
          ref="ruleForm"
        >
          <el-form-item label="选择客户：" prop="customerId">
            <el-select v-model="formData.customerId" filterable>
              <el-option
                v-for="item in cusMenu"
                :key="item.customerId"
                :label="item.customerName"
                :value="item.customerId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="客户编码：">
            <el-input v-model="formData.customerNum"></el-input>
          </el-form-item> -->
          <el-form-item label="热词：" prop="content">
            <el-input
              type="textarea"
              v-model="formData.content"
              resize="none"
            ></el-input>
          </el-form-item>
          <el-form-item label="热词状态：" prop="status">
            <el-switch
              v-model="formData.status"
              active-color="#4e57aa"
              inactive-color="#cfcfcf"
              active-value="0"
              inactive-value="1"
            ></el-switch>
          </el-form-item>
        </el-form>
        <el-button
          type="primary"
          icon="el-icon-refresh-right"
          class="subBtn"
          @click="onSubmitInfo"
          >提交</el-button
        >
        <el-button @click="onBack">返回列表</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  hotWord2ListPage,
  hotWordDetail,
  hotWordSave,
} from "@/api/infoManage/hotword";

export default {
  data() {
    return {
      cusMenu: [],
      formData: {
        customerId: "",
        status: "0",
        content: "",
      },
      rules: {
        customerId: [
          { required: true, message: "请选择客户名称", trigger: "change" },
        ],
        content: [{ required: true, message: "请填写热词", trigger: "blur" }],
        status: [
          { required: true, message: "请选择热词状态", trigger: "change" },
        ],
      },
    };
  },
  created() {
    hotWord2ListPage().then((res) => {
      this.cusMenu = res.data.customerInfos;
    });
    if (this.$route.query.hotWordId) {
      hotWordDetail(this.$route.query.hotWordId).then((res) => {
        this.formData = res.data;
      });
    }
  },
  methods: {
    onSubmitInfo() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.$route.query.hotWordId) {
            let updateData = { ...this.formData };
            updateData.hotWordId = this.$route.query.hotWordId;
            hotWordSave(updateData).then((res) => {
              if (res.code === 200) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
                this.$router.push("/infoManage/hotwords");
              }
            });
          } else {
            hotWordSave(this.formData).then((res) => {
              if (res.code === 200) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                });
                this.$router.push("/infoManage/hotwords");
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    onBack() {
      this.$router.push("/infoManage/hotwords");
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === "hotwords") {
      to.meta.keepAlive = true;
    }
    next();
  },
};
</script>

<style lang="scss" scoped>
.indusInfo {
  margin: 2vh 1.5vw;
  .marginTop {
    margin-top: 2vh;
  }
  .main {
    background: #fff;
    padding: 2vh 1vw;
    margin-top: 2vh;
    border-radius: 2vh;
    height: 100%;
    min-height: 75vh;
    .title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4.6vh;
      .left {
        display: flex;
        .sep {
          background: #4e57aa;
          width: 5px;
          height: 1.5rem;
          display: inline-block;
          margin: 0 5px;
        }
        h4 {
          padding: 0;
          margin: 0;
        }
      }
    }
    .content {
      margin-left: 2vw;
      .subBtn {
        background: #4e57aa;
        border-color: #4e57aa;
        margin-right: 1.09375vw;
      }
      .basic_form {
        margin-bottom: 10.3vh;
        /deep/.el-form-item {
          margin-bottom: 1.85vh;
          .el-form-item__label {
            color: #333;
          }
          .el-form-item__content {
            > .el-input {
              width: 20%;
            }
            .el-select {
              width: 20%;
            }
            .el-input__inner {
              font-size: 14px;
              color: #333;
            }
            .el-textarea {
              width: 30%;
              .el-textarea__inner {
                height: 20.6vh;
                font-size: 14px;
                color: #333333;
              }
            }
          }
        }
      }
    }
  }
}
</style>
