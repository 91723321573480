<template>
  <div class="speechAnalysis">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/systemManage' }"
          >系统管理</el-breadcrumb-item
        >
        <el-breadcrumb-item>行业管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main">
      <div class="title">
        <div class="left">
          <i class="sep"></i>
          <h4>行业管理</h4>
        </div>
      </div>
      <el-row :gutter="20" class="marginTop">
        <el-col :span="5">
          <span class="selectName">行业：</span>
          <el-input
            class="input"
            v-model="formData.industryName"
            placeholder="请输入"
          ></el-input>
        </el-col>
        <!-- <el-col :span="5" class="parent">
          <el-input placeholder="请输入" v-model="searchInput"> </el-input>
          <i class="el-icon-search searchInput"></i>
        </el-col> -->
        <el-col :span="5">
          <el-button class="searchBtn" @click="onInquire">查询</el-button>
          <el-button @click="onReset">重置</el-button>
        </el-col>
      </el-row>
      <div style="display: flex; justify-content: flex-end">
        <el-button
          type="primary"
          icon="el-icon-plus"
          style="background: #4e57aa; border-color: #4e57aa"
          @click="add"
          >新建</el-button
        >
        <!-- <el-button>批量开启</el-button>
        <el-button>批量关闭</el-button> -->
      </div>

      <el-row :gutter="10">
        <TableChart
          :tableDatas="{
            id: 'industry',
            data: industryTableData,
          }"
          :pageData="pageData"
          @changePage="currentChange"
          @changeSize="sizeChange"
        ></TableChart>
      </el-row>
    </div>
  </div>
</template>
<script>
import TableChart from "../table";
import { industryList } from "@/api/sysManage/industry";
export default {
  components: {
    TableChart,
  },
  data() {
    return {
      formData: {
        industryName: "",
      },
      industryTableData: {
        size: "",
        loading: false,
        columns: [
          {
            col: "行业名称",
            prop: "industryName",
            width: "",
          },
          {
            col: "行业编码",
            prop: "industryId",
            width: "",
          },
          {
            col: "备注",
            prop: "remark",
            width: "",
          },
          {
            col: "最后登录时间",
            prop: "updateDate",
            width: "",
          },
        ],
        data: [],
      },
      pageData: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      date: "",
      searchInput: "",
    };
  },
  created() {
    // this.getTableData();
  },
  methods: {
    getTableData() {
      this.industryTableData.loading = true;
      industryList(
        this.formData,
        this.pageData.pageNum,
        this.pageData.pageSize
      ).then((res) => {
        this.industryTableData.loading = false;
        this.industryTableData.data = res.rows;
        this.pageData.total = res.total;
      });
    },
    onInquire() {
      this.pageData.pageNum = 1;
      this.getTableData();
    },
    onReset() {
      this.formData.industryName = "";
    },
    currentChange(val) {
      this.pageData.pageNum = val;
      this.getTableData();
    },
    sizeChange(val) {
      this.pageData.pageNum = 1;
      this.pageData.pageSize = val;
      this.getTableData();
    },
    add() {
      this.$router.push("/systemManage/industry/indusInfo");
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.getTableData();
    });
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== "indusInfo") {
      from.meta.keepAlive = false;
      next();
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
$baseColor: #4e57aa;
$white: #fff;
$fontColor: #333;
$fontSize: 1.2em;
.speechAnalysis {
  margin: 2vh 1.5vw;
  .marginTop {
    margin-top: 2vh;
    margin-bottom: 2vh;
  }

  .main {
    background: #fff;
    padding: 2vh 1vw;
    margin-top: 2vh;
    border-radius: 2vh;
    height: 100%;
    min-height: 75vh;
    /deep/.input {
      width: 74%;
    }
    /deep/.el-select {
      width: 74%;
    }
    .title {
      display: flex;
      justify-content: space-between;
      .left {
        display: flex;
        .sep {
          background: $baseColor;
          width: 5px;
          height: 1.5rem;
          display: inline-block;
          margin: 0 5px;
        }
        h4 {
          padding: 0;
          margin: 0;
        }
      }
    }
    .status {
      // .selectName {
      //   width: 35%;
      // }
      /deep/.el-select {
        width: 65%;
      }
    }
    .fr {
      float: right;
      width: auto;
      margin-left: 0;
    }
    .selectName {
      //   width: 10vh;
      // width: 26%;
      font-size: 14px;
      display: inline-block;
      color: $fontColor;
      // text-align: justify;
      //   text-align-last: justify;
    }
    .parent {
      position: relative;
      .searchInput {
        position: absolute;
        right: 0;
        height: 100%;
        text-align: center;
        background: $baseColor;
        width: 2vw;
        right: 0px;
        top: 0;
        border-radius: 0 5px 5px 0;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .searchBtn {
      color: $white;
      background-color: $baseColor;
      border-color: $baseColor;
    }
  }
}
</style>