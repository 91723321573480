<template>
  <div class="store_data_analysis_detail_content">
    <div class="project_overview_header">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/dataAnalysis' }"
          >数据分析</el-breadcrumb-item
        >
        <el-breadcrumb-item>项目分析列表</el-breadcrumb-item>
        <el-breadcrumb-item>项目概况</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="topContent">
      <p class="pageTitle">项目基本信息</p>
      <div class="projectheader">
        <img class="proBack" src="@/assets/img/Background.png" alt="" />
        <div class="headerLeft">
          <p class="projectTitle" style="margin-bottom: 15px">
            {{ projectInfo.projectName }}
          </p>
          <p class="littlePro" style="margin-bottom: 20px">
            项目编号：<span style="color: #ffc531">{{
              projectInfo.projectNo
            }}</span>
          </p>
          <p class="littlePro" style="margin-bottom: 13px">
            项目开始时间：{{ projectInfo.startDate }}
          </p>
          <p class="littlePro">项目结束时间：{{ projectInfo.endDate }}</p>
        </div>
        <div class="proInformation">
          <div class="infoItem">
            <img src="@/assets/img/mendian.png" alt="" />
            <div class="item_info">
              <p style="line-height: 1">门店数量</p>
              <p style="font-size: 20px; font-weight: bold; margin-top: 5px">
                {{ projectInfo.spaceCount }}
              </p>
            </div>
          </div>
          <div class="infoItem">
            <img src="@/assets/img/dudao.png" alt="" />
            <div class="item_info">
              <p style="line-height: 1">督导员人数</p>
              <p style="font-size: 20px; font-weight: bold; margin-top: 5px">
                {{ projectInfo.supervisorCount }}
              </p>
            </div>
          </div>
          <div class="infoItem">
            <img src="@/assets/img/cuxiao.png" alt="" />
            <div class="item_info">
              <p style="line-height: 1">促销员人数</p>
              <p style="font-size: 20px; font-weight: bold; margin-top: 5px">
                {{ projectInfo.userCount }}
              </p>
            </div>
          </div>
          <div class="infoLine"></div>
        </div>
        <div class="amountBox">
          <div class="amountItem" style="margin-bottom: 30px">
            <span>胸牌使用量：{{ projectInfo.deviceCount }}</span>
            <div style="background: #23b899">高</div>
          </div>
          <div class="amountItem" style="margin-bottom: 20px">
            <span>蓝牙信标使用量：{{ projectInfo.beaconCount }}</span>
            <div style="background: #f84a22">低</div>
          </div>
          <!-- <div class="amountItem">
            <span>蓝牙信标使用量：9999</span>
          </div> -->
        </div>
      </div>
    </div>
    <div class="topTitle">
      <div class="indicatorBox">
        <div class="store_data_analysis_detail_area_proportion_content_header">
          <div
            class="project_overview_periphery_left_header_left"
            style="margin-left: 3vh"
          >
            <div
              class="project_overview_periphery_left_header_left_color"
            ></div>
            <div class="project_overview_periphery_left_header_left_text">
              指标总览
            </div>
          </div>
        </div>
        <!-- <div>
                        <span>达成率</span>
                        <img src="" alt="">
                    </div> -->
        <div class="indicator_item_box">
          <div class="">
            <!-- <el-progress
              type="circle"
              color="#6160DC"
              :percentage="75"
              :stroke-width="15"
            ></el-progress> -->
            <span class="font_style color1">{{ allData.audioCount }}</span>
            <p>音频文件个数</p>
          </div>
          <div class="">
            <!-- <el-progress
              type="circle"
              color="#FFC531"
              :percentage="50"
              :stroke-width="15"
            ></el-progress> -->
            <span class="font_style color2">{{
              allData.audioDurationAvg
            }}</span>

            <p>平均录音时长</p>
          </div>
          <div class="">
            <!-- <el-progress
              type="circle"
              color="#58CFFF"
              :percentage="scriptLibraryGetRate"
              :stroke-width="15"
              >></el-progress
            > -->
            <span class="font_style color3">{{ allData.allVerbalCount }}</span>
            <p>关键词命中次数</p>
            <!-- <div>
                        <span>命中率</span>
                        <img src="" alt="">
                    </div> -->
          </div>
        </div>
      </div>
      <div class="tradeTranstrfom">
        <div class="tradeTranstrfom_header">
          <div class="project_overview_periphery_left_header_left">
            <div
              class="project_overview_periphery_left_header_left_color"
            ></div>
            <div class="project_overview_periphery_left_header_left_text">
              <!-- 交易转化率 -->
              话术完成度
            </div>
          </div>
        </div>
        <div class="wrapper">
          <div class="inner">
            <div class="chart_shui" id="chart_shui"></div>
          </div>
        </div>
      </div>
      <div class="tradeTranstrfom">
        <div class="tradeTranstrfom_header">
          <div class="project_overview_periphery_left_header_left">
            <div
              class="project_overview_periphery_left_header_left_color"
            ></div>
            <div class="project_overview_periphery_left_header_left_text">
              热门关键词
            </div>
          </div>
        </div>
        <div
          id="word_cloud_view_id"
          style="width: 200px; height: 200px; margin: 0 auto"
        ></div>
      </div>
    </div>
    <!-- 地图撒点 -->
    <div class="detailMap">
      <div class="indicatortitle">门店概览</div>
      <div class="mapBox">
        <div id="yzMap1" style="width: 100%; height: 100%"></div>
      </div>
    </div>
    <div class="indicatorDetail" style="margin-bottom: 40px">
      <div class="indicatorSelect">
        <div class="indicatortitle">指标详情</div>
        <div class="selectBox">
          <div class="selectItem">
            <!-- <el-button class="searchBtn" @click="onYesterday">昨天</el-button> -->
          </div>
          <div class="selectItem">
            <el-date-picker
              v-model="date"
              type="daterange"
              @change="handlDate"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
      <div class="indicatorContent">
        <div
          class="contentItem"
          v-for="(item, index) in detailInfo"
          :key="index"
          :style="{ marginTop: index > 1 ? '40px' : '' }"
        >
          <p
            v-show="item.name"
            class="indexInfo"
            :style="{ opacity: (index + 1) % 2 == 0 ? '0.0' : '1' }"
          >
            {{ item.name }}
          </p>
          <div class="contentDetail">
            <div class="contentLeft">
              <div style="margin-left: 44px">每日人均</div>
              <div>日均</div>
            </div>
            <div
              class="detailItem"
              v-for="(val, i) in item.data"
              :key="i"
              :style="{ marginTop: i > 0 ? '38px' : '' }"
            >
              <div class="detailcon">
                <div class="titleIcon">
                  <img :src="val.src" alt="" />
                </div>
                <span
                  stlye="font-size: 14px;color:#333;white-space: nowrap;display:inline-block;"
                  >{{ val.title }}</span
                >
                <el-popover
                  placement="bottom"
                  width="200"
                  trigger="click"
                  :content="cautionTitle"
                >
                  <img
                    slot="reference"
                    class="cautionIcon"
                    @click="getContent(val.title)"
                    src="@/assets/img/Frame.png"
                    alt=""
                  />
                </el-popover>
              </div>
              <div class="detailInfo">
                <span style="font-size: 20px; color: #23b899">{{
                  val.allCon
                }}</span>
                <div
                  class="InfoBtnF"
                  :title="val.dayCon"
                  style="margin-left: 51px"
                >
                  {{ val.dayCon }}
                </div>
                <div
                  class="InfoBtnS"
                  :title="val.peoCon"
                  style="margin-left: 39px"
                >
                  {{ val.peoCon }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="indicatorDetail">
      <div class="indicatorSelect">
        <div class="indicatortitle">单项表现</div>
        <!-- <div class="selectBox">
            <div class="selectItem">
              <span>渠道：</span>
              <el-select
                multiple
                filterable
                allow-create
                default-first-option
                placeholder="全部">
                <el-option>
                </el-option>
              </el-select>
            </div>
            <div class="selectItem">
              <span>大区域：</span>
              <el-select
                multiple
                filterable
                allow-create
                default-first-option
                placeholder="全部">
                <el-option>
                </el-option>
              </el-select>
            </div>
            <div class="selectItem">
              <span>区域：</span>
              <el-select
                multiple
                filterable
                allow-create
                default-first-option
                placeholder="全部">
                <el-option>
                </el-option>
              </el-select>
            </div>
            <div class="selectItem">
              <span>省份：</span>
              <el-select
                multiple
                filterable
                allow-create
                default-first-option
                placeholder="全部">
                <el-option>
                </el-option>
              </el-select>
            </div>
          </div> -->
      </div>
    </div>
    <div class="store_data_analysis_detail_area_proportion_content">
      <div class="store_data_analysis_detail_area_proportion_content_header">
        <!-- <div class="project_overview_storeRanking_header_left"> -->
        <div class="project_overview_periphery_left_header_left">
          <div class="project_overview_periphery_left_header_left_color"></div>
          <div class="project_overview_periphery_left_header_left_text">
            促销员在岗
          </div>
        </div>
        <!-- </div> -->
      </div>
      <div class="echart_line">
        <div class="echart_item">
          <div class="charts_title">
            <div class="charts_title_left">
              <div class="charts_title_left_color"></div>
              <div class="charts_title_left_text">在岗情况</div>
            </div>
          </div>
          <div id="chart13"></div>
        </div>
        <div class="echart_item">
          <div class="charts_title">
            <div class="charts_title_left">
              <div class="charts_title_left_color"></div>
              <div class="charts_title_left_text">在岗时长区间占比</div>
            </div>
          </div>
          <div id="chart14"></div>
        </div>
      </div>
      <div class="echart_box">
        <div class="echart_Boxitem">
          <div class="charts_title">
            <div class="charts_title_left">
              <div class="charts_title_left_color"></div>
              <div class="charts_title_left_text">离岗情况</div>
            </div>
          </div>
          <div id="chart15"></div>
        </div>
        <div class="echart_Boxitem">
          <div class="charts_title">
            <div class="charts_title_left">
              <div class="charts_title_left_color"></div>
              <div class="charts_title_left_text">脱岗预警</div>
            </div>
          </div>
          <div id="chart16"></div>
        </div>
      </div>
    </div>
    <div class="indicatorDetail">
      <div class="indicatorSelect" style="margin-top: 40px">
        <div class="indicatortitle">促销员效能</div>
      </div>
    </div>
    <div class="store_data_analysis_detail_area_proportion_content">
      <div class="echart_box">
        <div class="echart_Boxitem">
          <div class="charts_title">
            <div class="charts_title_left">
              <div class="charts_title_left_color"></div>
              <div class="charts_title_left_text">主动性</div>
            </div>
          </div>
          <div id="chart17"></div>
        </div>
        <div class="echart_Boxitem">
          <div class="charts_title">
            <div class="charts_title_left">
              <div class="charts_title_left_color"></div>
              <div class="charts_title_left_text">静止预警</div>
            </div>
          </div>
          <div id="chart18"></div>
        </div>
      </div>
    </div>
    <div class="indicatorDetail">
      <div class="indicatorSelect" style="margin-top: 40px">
        <div class="indicatortitle">促销员话术</div>
      </div>
    </div>
    <div class="store_data_analysis_detail_area_proportion_content">
      <div class="echart_box">
        <div class="echart_Boxitem">
          <div class="charts_title">
            <div class="charts_title_left">
              <div class="charts_title_left_color"></div>
              <div class="charts_title_left_text">关键词命中次数人员top20</div>
            </div>
          </div>
          <div id="chart19"></div>
        </div>
      </div>
    </div>
    <div class="project_overview_analysisTrend">
      <div class="project_overview_storeRanking_header">
        <div class="project_overview_storeRanking_header_left">
          <div class="project_overview_periphery_left_header_left">
            <div
              class="project_overview_periphery_left_header_left_color"
            ></div>
            <div class="project_overview_periphery_left_header_left_text">
              概况分析趋势
            </div>
          </div>
        </div>
      </div>
      <div class="project_overview_analysisTrend_content">
        <div class="project_overview_analysisTrend_content_top">
          <div class="project_overview_analysisTrend_content_item">
            <div class="project_overview_analysisTrend_content_item_header">
              <div
                class="project_overview_analysisTrend_content_item_header_color"
              ></div>
              <div
                class="project_overview_analysisTrend_content_item_header_text"
              >
                行动步数趋势
              </div>
            </div>
            <div class="echarts-box">
              <Cu-Line-Xs
                :data="echartsTwoData"
                :animation="true"
                :dataMap="{
                  x: 'time',
                  y: 'count',
                }"
                :tooltip="{
                  show: false,
                  y: '',
                }"
                :lableShow="true"
                :yAxisShow="{
                  show: true,
                  color: '#EFF4FF',
                }"
                :gradient="{
                  top: '#bfbdef',
                  bottom: '#FFF',
                  linecolor: '#4E57AA',
                }"
                height="100%"
                width="100%"
              />
            </div>
          </div>
          <div class="project_overview_analysisTrend_content_item">
            <div class="project_overview_analysisTrend_content_item_header">
              <div
                class="project_overview_analysisTrend_content_item_header_color"
              ></div>
              <div
                class="project_overview_analysisTrend_content_item_header_text"
              >
                通话时长趋势
              </div>
            </div>
            <div class="echarts-box">
              <Cu-Line-Xs
                :data="echartSixData"
                :animation="true"
                :dataMap="{
                  x: 'time',
                  y: 'count',
                }"
                :tooltip="{
                  show: false,
                  y: '',
                }"
                :lableShow="true"
                :yAxisShow="{
                  show: true,
                  color: '#EFF4FF',
                }"
                :gradient="{
                  top: '#ffe3af',
                  bottom: '#FFF',
                  linecolor: '#FFC531',
                }"
                height="100%"
                width="100%"
              />
            </div>
          </div>
          <div class="project_overview_analysisTrend_content_item">
            <div class="project_overview_analysisTrend_content_item_header">
              <div
                class="project_overview_analysisTrend_content_item_header_color"
              ></div>
              <div
                class="project_overview_analysisTrend_content_item_header_text"
              >
                主动沟通占比趋势
              </div>
            </div>
            <div class="echarts-box">
              <Cu-Line-Xs
                :data="echartsFiveData"
                :animation="true"
                :dataMap="{
                  x: 'time',
                  y: 'count',
                }"
                :tooltip="{
                  show: false,
                  y: '',
                }"
                :lableShow="true"
                :yAxisShow="{
                  show: true,
                  color: '#EFF4FF',
                }"
                :gradient="{
                  top: '#b4ceff',
                  bottom: '#FFF',
                  linecolor: '#5885FF',
                }"
                height="100%"
                width="100%"
              />
            </div>
          </div>
        </div>
        <div class="project_overview_analysisTrend_content_top">
          <div class="project_overview_analysisTrend_content_item">
            <div class="project_overview_analysisTrend_content_item_header">
              <div
                class="project_overview_analysisTrend_content_item_header_color"
              ></div>
              <div
                class="project_overview_analysisTrend_content_item_header_text"
              >
                脱岗时长趋势
              </div>
            </div>
            <div class="echarts-box">
              <Cu-Line-Xs
                :data="echartsOneData"
                :animation="true"
                :dataMap="{
                  x: 'time',
                  y: 'count',
                }"
                :tooltip="{
                  show: false,
                  y: '',
                }"
                :lableShow="true"
                :yAxisShow="{
                  show: true,
                  color: '#EFF4FF',
                }"
                :gradient="{
                  top: '#a3f2d0',
                  bottom: '#FFF',
                  linecolor: '#23B899',
                }"
                height="100%"
                width="100%"
              />
            </div>
          </div>
          <div class="project_overview_analysisTrend_content_item">
            <div class="project_overview_analysisTrend_content_item_header">
              <div
                class="project_overview_analysisTrend_content_item_header_color"
              ></div>
              <div
                class="project_overview_analysisTrend_content_item_header_text"
              >
                设备静止时长趋势
              </div>
            </div>
            <div class="echarts-box">
              <Cu-Line-Xs
                :data="echartsThreeData"
                :animation="true"
                :dataMap="{
                  x: 'time',
                  y: 'count',
                }"
                :tooltip="{
                  show: false,
                  y: '',
                }"
                :lableShow="true"
                :yAxisShow="{
                  show: true,
                  color: '#EFF4FF',
                }"
                :gradient="{
                  top: '#cbadff',
                  bottom: '#FFF',
                  linecolor: '#6B48FF',
                }"
                height="100%"
                width="100%"
              />
            </div>
          </div>
          <div class="project_overview_analysisTrend_content_item">
            <div class="project_overview_analysisTrend_content_item_header">
              <div
                class="project_overview_analysisTrend_content_item_header_color"
              ></div>
              <div
                class="project_overview_analysisTrend_content_item_header_text"
              >
                话术匹配度
              </div>
            </div>
            <div class="echarts-box">
              <Cu-Line-Xs
                :data="echartsForeData"
                :animation="true"
                :dataMap="{
                  x: 'time',
                  y: 'count',
                }"
                :tooltip="{
                  show: false,
                  y: '',
                }"
                :lableShow="true"
                :yAxisShow="{
                  show: true,
                  color: '#EFF4FF',
                }"
                :gradient="{
                  top: '#b4edff',
                  bottom: '#FFF',
                  linecolor: '#58CFFF',
                }"
                height="100%"
                width="100%"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
function bgps_gps(bd_lng, bd_lat) {
  var X_PI = (Math.PI * 3000.0) / 180.0;
  var x = bd_lng - 0.0065;
  var y = bd_lat - 0.006;
  var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * X_PI);
  var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * X_PI);
  var gg_lng = z * Math.cos(theta);
  var gg_lat = z * Math.sin(theta);
  return { lng: gg_lng, lat: gg_lat };
}
import MapLoader from "@/plugin/aMap";
import CuLineXs from "@/views/components/Linexs";
import {
  getIndexAll,
  getProjectAnaldetail,
  getProjectAnalindexWork,
  getUserOnLine,
  getGeneral,
  getProjectCount,
  getProjectAnalIndexBehavior,
  getProjectAnalIndexVerbal,
  getProjectAnalUserEfficacy,
  getProjectAnalUserVerbal,
  getDicRemark,
  getSpaceMapList,
  getCompleteness,
  getProIndexAll,
} from "@/api/dataAnalysis/project";
import { setTimeout } from "timers";
export default {
  name: "storedataanalysisdetail",
  components: {
    CuLineXs,
  },
  data() {
    return {
      date: [],
      center: [121.499718, 31.239703],
      map: null,
      mapSpaceList: [],
      indicatorStart: "",
      indicatorEnd: "",
      word_cloud_view: null,
      word_cloud_options: {},
      word_list: [],
      projectInfo: {},
      detailInfo: [
        {
          name: "工作指标",
          data: [
            {
              title: "总在岗时长（h）",
              src: require("@/assets/img/zaigang.png"),
              aallCon: "867945869",
              dayCon: "23",
              peoCon: "5786",
            },
            {
              title: "总再排时长（h）",
              src: require("@/assets/img/zzpsc.png"),
              allCon: "867945869",
              dayCon: "23",
              peoCon: "5786",
            },
          ],
        },
        {
          name: "工作指标",
          data: [
            {
              title: "预警总次数",
              src: require("@/assets/img/yjzcs.png"),
              allCon: "867945869",
              dayCon: "23",
              peoCon: "5786",
            },
            {
              title: "预警总时长（h）",
              src: require("@/assets/img/yjzsc.png"),
              allCon: "867945869",
              dayCon: "23",
              peoCon: "5786",
            },
          ],
        },
        {
          name: "行为指标",
          data: [
            {
              title: "总脱岗次数",
              src: require("@/assets/img/ztgcs.png"),
              allCon: "867945869",
              dayCon: "23",
              peoCon: "5786",
            },
            {
              title: "总离岗次数",
              src: require("@/assets/img/zlgcs.png"),
              allCon: "867945869",
              dayCon: "23",
              peoCon: "5786",
            },
            {
              title: "总静止次数",
              src: require("@/assets/img/zjzcs.png"),
              allCon: "867945869",
              dayCon: "23",
              peoCon: "5786",
            },
            {
              title: "总行动步数",
              src: require("@/assets/img/zxdbs.png"),
              allCon: "867945869",
              dayCon: "23",
              peoCon: "5786",
            },
          ],
        },
        {
          name: "行为指标",
          data: [
            {
              title: "总脱岗时长（h）",
              src: require("@/assets/img/ztgsc.png"),
              allCon: "867945869",
              dayCon: "23",
              peoCon: "5786",
            },
            {
              title: "总离岗时长（h）",
              src: require("@/assets/img/zlgsc.png"),
              allCon: "867945869",
              dayCon: "23",
              peoCon: "5786",
            },
            {
              title: "总静止时长（h）",
              src: require("@/assets/img/zjzsc.png"),
              allCon: "867945869",
              dayCon: "23",
              peoCon: "5786",
            },
          ],
        },
        {
          name: "话术指标",
          data: [
            {
              title: "总沟通次数",
              src: require("@/assets/img/zgtcs.png"),
              allCon: "867945869",
              dayCon: "23",
              peoCon: "5786",
            },
            {
              title: "员工关键词命中次数",
              src: require("@/assets/img/yggjc.png"),
              allCon: "867945869",
              dayCon: "23",
              peoCon: "5786",
            },
            {
              title: "客户关键词命中次数",
              src: require("@/assets/img/khgjc.png"),
              allCon: "867945869",
              dayCon: "23",
              peoCon: "5786",
            },
          ],
        },
        {
          name: "话术指标",
          data: [
            {
              title: "日均主动沟通次数",
              src: require("@/assets/img/rjzd.png"),
              allCon: "867945869",
              dayCon: "23",
              peoCon: "5786",
            },
            {
              title: "员工沟通时长（h）",
              src: require("@/assets/img/yggtsc.png"),
              allCon: "867945869",
              dayCon: "23",
              peoCon: "5786",
            },
            {
              title: "客户沟通时长（h）",
              src: require("@/assets/img/yggtsc.png"),
              allCon: "867945869",
              dayCon: "23",
              peoCon: "5786",
            },
          ],
        },
      ],
      ficacyAvg: "",
      ficacyMax: "",
      ficacylist: [],
      VerbalList: [],
      formData: {
        warningType: "",
        projectId: "",
      },
      cautionTitle: "",
      areas: [],
      spaceName: "",
      echartsOneData: {
        name: "脱岗时长",
        max: 24,
        min: 0,
        data: [],
      },
      echartsTwoData: {
        name: "行动步数",
        max: 50000,
        min: 0,
        data: [],
      },
      echartsThreeData: {
        name: "设备静止时长",
        max: 24,
        min: 0,
        data: [],
      },
      echartsForeData: {
        name: "话术匹配",
        max: 100,
        min: 0,
        data: [
          {
            time: "08-20",
            count: 0,
          },
          {
            time: "08-21",
            count: 0,
          },
          {
            time: "08-22",
            count: 0,
          },
          {
            time: "08-23",
            count: 0,
          },
          {
            time: "08-24",
            count: 0,
          },
          {
            time: "08-25",
            count: 0,
          },
          {
            time: "08-20",
            count: 0,
          },
        ],
      },
      echartsFiveData: {
        name: "主动沟通占比",
        max: 100,
        min: 0,
        data: [],
      },
      echartSixData: {
        name: "通话时长",
        max: 180,
        min: 0,
        data: [],
      },
      storeList: [
        {
          spaceId: "",
          projectId: "",
          spaceName: "",
          userCount: "",
          saleCount: "",
          accordTalkCount: "",
          wordsMapping: "",
          talkTimeAvg: "",
          odc: "",
          sscAvg: "",
          ssdAvg: "",
          odd: "",
        },
      ],
      Mesh: [],
      value: "",
      value1: "",
      timeSet: [],
      userSscasList: [],
      dateSscaCountMax: "",
      sscaUserCountMax: "",
      dayIndex: 0,
      scriptLibraryGetRate: "",
      options: JSON.parse(localStorage.getItem("storeDetailsRow")),
      zlsj_l: [
        { text: "销售数量", num: "", src: require("@/assets/img/xs.png") },
        { text: "门店数量", num: "", src: require("@/assets/img/tgcs.png") },
        {
          text: "在岗异常人数",
          num: "",
          src: require("@/assets/img/zgyc.png"),
        },
        {
          text: "门店平均主动沟通占比",
          num: "",
          src: require("@/assets/img/jzcs.png"),
        },
        {
          text: "门店平均被动沟通次数",
          num: "",
          src: require("@/assets/img/mdgtzb.png"),
        },
        {
          text: "门店平均主动沟通次数",
          num: "",
          src: require("@/assets/img/hspp.png"),
        },
      ],
      zlsj_r: [
        {
          text: "门店平均话术匹配度",
          num: "",
          src: require("@/assets/img/md.png"),
        },
        {
          text: "门店平均沟通时长",
          num: "",
          src: require("@/assets/img/thsc.png"),
        },
        {
          text: "门店平均脱岗次数",
          num: "",
          src: require("@/assets/img/bs.png"),
        },
        {
          text: "门店平均行动步数",
          num: "",
          src: require("@/assets/img/jjgt.png"),
        },
        {
          text: "门店平均设备静止次数",
          num: "",
          src: require("@/assets/img/jzsc.png"),
        },
        {
          text: "门店平均设备静止时长",
          num: "",
          src: require("@/assets/img/gtzb.png"),
        },
      ],
      dataList: [],
      tendencyData: [],
      pickerOptions: {
        // 限制时间
        // 限制日期
        disabledDate: this.disabledDate,
      },
      // 指标总览
      allData: {
        audioCount: "0",
        audioDurationAvg: "0",
        allVerbalCount: "0",
      },
    };
  },
  created() {
    this.getListAll();
    this.getTableList();
    this.getProjectDetails();
  },
  methods: {
    // 话术完成率
    getCompleteness() {
      let option = {
        projectId: this.options.storeProjectId,
      };
      getCompleteness(option).then((res) => {
        if (res && res.data.length > 0) {
          let data = res.data;
          this.drawChart(data);
        } else {
          this.$message("暂无话术完成率");
          this.mapSpaceList = [];
          return false;
        }
      });
    },
    // 指标总览
    getProIndexAlls() {
      let option = {
        projectId: this.options.storeProjectId,
      };
      getProIndexAll(option).then((res) => {
        if (res && res.code == 200) {
          this.allData.audioCount = res.data.audioCount;
          this.allData.audioDurationAvg = res.data.audioDurationAvg;
          this.allData.allVerbalCount = res.data.allVerbalCount;
        } else {
          this.allData = {
            audioCount: "0",
            audioDurationAvg: "0",
            allVerbalCount: "0",
          };
          this.$message(res.msg);
        }
      });
    },
    getSpaceMap() {
      let option = {
        projectId: this.options.storeProjectId,
      };
      getSpaceMapList(option).then((res) => {
        if (res && res.code == 200) {
          this.mapSpaceList = res.data;
        } else {
          this.$message("暂无门店信息");
          this.mapSpaceList = [];
          return false;
        }
      });
    },
    initMap() {
      let self = this;
      let aMapScript = document.createElement("script");
      aMapScript.setAttribute(
        "src",
        "https://webapi.amap.com/maps?v=1.4.13&key=ec85d3648154874552835438ac6a02b2&plugin=AMap.PlaceSearch,AMap.AdvancedInfoWindow"
      );
      document.head.appendChild(aMapScript);
      let aMapScript1 = document.createElement("script");
      aMapScript1.setAttribute(
        "src",
        "https://webapi.amap.com/ui/1.1/main.js?v=1.1.1"
      );
      document.head.appendChild(aMapScript1);
      aMapScript.onload = function () {
        let center = bgps_gps(
          self.mapSpaceList[0].longitude,
          self.mapSpaceList[0].latitude
        );
        var icon = new AMap.Icon({
          size: new AMap.Size(80, 76), // 图标尺寸
          image: require("@/assets/img/placeIcon.png"),
          imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
          imageSize: new AMap.Size(50, 50), // 根据所设置的大小拉伸或压缩图片
        });
        let map = new AMap.Map("yzMap1", {
          center: [center.lng, center.lat], //地区位置
          resizeEnable: true,
          zoom: 15,
          isHotspot: false,
        });
        self.map = map;
        var infoWindow;
        map.clearMap(); // 清除地图覆盖物
        let newMark = [];
        self.mapSpaceList.forEach((item, index) => {
          newMark.push(
            Object.assign({}, item, {
              position: [item.longitude, item.latitude],
            })
          );
        });
        // 添加一些分布不均的点到地图上,地图上添加三个点标记，作为参照
        var viaMarker;
        newMark.forEach(function (marker) {
          viaMarker = new AMap.Marker({
            map: map,
            icon: icon,
            size: [80, 76],
            position: [marker.position[0], marker.position[1]],
          });
          var infoWindow = new AMap.InfoWindow({
            offset: new AMap.Pixel(0, -30),
          });
          viaMarker.content =
            "<div class='textBox'><ul><li><p>门店编号：</p><span>" +
            marker.spaceNo +
            "</span></li><li><p>门店名称：</p><span>" +
            marker.spaceName +
            "</span></li><li><p>门店地址：</p><span>" +
            marker.spacePath +
            "</span></li></ul></div>";
          viaMarker.on("click", markerClick);
          infoWindow.close();
          function markerClick(e) {
            infoWindow.setContent(e.target.content);
            infoWindow.open(self.map, e.target.getPosition());
          }
        });
        self.map.remove([viaMarker]);
        self.map.add([viaMarker]);
        AMap.service("AMap.AdvancedInfoWindow", function () {
          infoWindow = new AMap.AdvancedInfoWindow({});
        });
        AMap.service("AMap.PlaceSearch", function () {
          var placeSearch = new AMap.PlaceSearch({
            city: "010",
          });
        });
        //回调函数
        function placeSearch_CallBack() {
          var location = [121.740318, 31.054126];
          infoWindow.setContent(createContent());
          infoWindow.open(self.map, location);
        }
      };
    },
    disabledDate(time) {
      const startTime = this.projectInfo.startDate;
      const endTime = this.projectInfo.endDate;
      return (
        time.getTime() < new Date(startTime).getTime() ||
        time.getTime() > new Date(endTime).getTime()
      );
    },
    handlDate(val) {
      this.getProjectindexwork(val[0], val[1]);
      this.getProjectBehavior(val[0], val[1]);
      this.getProjectIndexVerbal(val[0], val[1]);
    },
    onYesterday() {},
    getProjectIndexVerbal(start, end) {
      getProjectAnalIndexVerbal({
        projectId: this.options.storeProjectId,
        startDate: start,
        endDate: end,
      }).then((res) => {
        this.detailInfo[4].data[0].allCon = res.data.allTalk;
        this.detailInfo[4].data[0].dayCon = res.data.dateTalk;
        this.detailInfo[4].data[0].peoCon = res.data.dateAvgTalk;

        this.detailInfo[4].data[1].allCon = res.data.allLeftVerbal;
        this.detailInfo[4].data[1].dayCon = res.data.dateLeftVerbal;
        this.detailInfo[4].data[1].peoCon = res.data.dateAvgLeftVerbal;

        this.detailInfo[4].data[2].allCon = res.data.allRightVerbal;
        this.detailInfo[4].data[2].dayCon = res.data.dateRightVerbal;
        this.detailInfo[4].data[2].peoCon = res.data.dateAvgRightVerbal;

        this.detailInfo[5].data[0].allCon = res.data.allActiveTalk;
        this.detailInfo[5].data[0].dayCon = res.data.dateActiveTalk;
        this.detailInfo[5].data[0].peoCon = res.data.dateAvgActiveTalk;

        this.detailInfo[5].data[1].allCon = res.data.allLeftVerbal;
        this.detailInfo[5].data[1].dayCon = res.data.dateLeftVerbal;
        this.detailInfo[5].data[1].peoCon = res.data.dateAvgLeftVerbal;

        this.detailInfo[5].data[2].allCon = res.data.allLeftStaff;
        this.detailInfo[5].data[2].dayCon = res.data.dateLeftStaff;
        this.detailInfo[5].data[2].peoCon = res.data.dateAvgLeftStaff;
        if (this.detailInfo[5].data.length >= 4) {
          this.detailInfo[5].data[3].allCon = res.data.allRightStaff;
          this.detailInfo[5].data[3].dayCon = res.data.dateRightStaff;
          this.detailInfo[5].data[3].peoCon = res.data.dateAvgRightStaff;
        }
      });
    },
    getProjectindexwork(start, end) {
      getProjectAnalindexWork({
        projectId: this.options.storeProjectId,
        startDate: start,
        endDate: end,
      }).then((res) => {
        this.detailInfo[0].data[0].allCon = res.data.allWorkDate;
        this.detailInfo[0].data[0].dayCon = res.data.dateWorkDate;
        this.detailInfo[0].data[0].peoCon = res.data.dateAvgWorkDate;
        this.detailInfo[0].data[1].allCon = res.data.allFenceDate;
        this.detailInfo[0].data[1].dayCon = res.data.dateFenceDate;
        this.detailInfo[0].data[1].peoCon = res.data.dateAvgFenceDate;
        this.detailInfo[1].data[0].allCon = res.data.allWarningCount;
        this.detailInfo[1].data[0].dayCon = res.data.dateWarningCount;
        this.detailInfo[1].data[0].peoCon = res.data.dateAvgWarningCount;
        this.detailInfo[1].data[1].allCon = res.data.allWarningTime;
        this.detailInfo[1].data[1].dayCon = res.data.dateWarningTime;
        this.detailInfo[1].data[1].peoCon = res.data.dateAvgWarningTime;
      });
    },
    getProjectBehavior(start, end) {
      getProjectAnalIndexBehavior({
        projectId: this.options.storeProjectId,
        startDate: start,
        endDate: end,
      }).then((res) => {
        this.detailInfo[2].data[0].allCon = res.data.allOscaCount;
        this.detailInfo[2].data[0].dayCon = res.data.dateOscaCount;
        this.detailInfo[2].data[0].peoCon = res.data.dateAvgOscaCount;
        this.detailInfo[2].data[1].allCon = res.data.allOdc;
        this.detailInfo[2].data[1].dayCon = res.data.dateOdc;
        this.detailInfo[2].data[1].peoCon = res.data.dateAvgOdc;
        this.detailInfo[2].data[2].allCon = res.data.allSsc;
        this.detailInfo[2].data[2].dayCon = res.data.dateSsc;
        this.detailInfo[2].data[2].peoCon = res.data.dateAvgSsc;
        this.detailInfo[2].data[3].allCon = res.data.allStep;
        this.detailInfo[2].data[3].dayCon = res.data.dateStep;
        this.detailInfo[2].data[3].peoCon = res.data.dateAvgStep;
        this.detailInfo[3].data[0].allCon = res.data.allOscaTime;
        this.detailInfo[3].data[0].dayCon = res.data.dateOscaTime;
        this.detailInfo[3].data[0].peoCon = res.data.dateAvgOscaTime;
        this.detailInfo[3].data[1].allCon = res.data.allOdd;
        this.detailInfo[3].data[1].dayCon = res.data.dateOdd;
        this.detailInfo[3].data[1].peoCon = res.data.dateAvgOdd;
        this.detailInfo[3].data[2].allCon = res.data.allSsd;
        this.detailInfo[3].data[2].dayCon = res.data.dateSsd;
        this.detailInfo[3].data[2].peoCon = res.data.dateAvgSsd;
      });
    },
    getContent(title) {
      getDicRemark({
        remarkTitle: title,
      }).then((res) => {
        this.cautionTitle = res.msg;
      });
    },
    getProjectDetails() {
      getProjectAnaldetail({
        projectId: this.options.storeProjectId,
      }).then((res) => {
        this.projectInfo = res.data;
        this.getProjectindexwork(res.data.startDate, res.data.endDate);
        this.getProjectBehavior(res.data.startDate, res.data.endDate);
        this.getProjectIndexVerbal(res.data.startDate, res.data.endDate);
      });
    },
    getTableList() {
      getUserOnLine({
        projectId: this.options.storeProjectId,
      }).then((res) => {
        this.dataList = res.data;
      });
    },
    getListAll() {
      getProjectCount({
        projectId: this.options.storeProjectId,
      }).then((res) => {
        let a = [];
        res.data.map((item) => {
          a.push({ name: item.content, value: item.getCount });
        });
        this.word_list = a;
        this.init_view_data();
      });
      getIndexAll({
        projectId: this.options.storeProjectId,
      }).then((res) => {
        if (res.code == 200) {
          this.scriptLibraryGetRate = res.data.scriptLibraryGetRate;
        }
      });
      getProjectAnalUserEfficacy({
        projectId: this.options.storeProjectId,
      }).then((res) => {
        this.ficacylist = res.data.userSteps;
        this.ficacyAvg = res.data.dateStepAvg;
        this.ficacyMax = res.data.dateStepMax;

        this.userSscasList = res.data.userSscas;
        this.dateSscaCountMax = res.data.dateSscaCountMax;
        this.sscaUserCountMax = res.data.sscaUserCountMax;
      });
      getProjectAnalUserVerbal({
        projectId: this.options.storeProjectId,
      }).then((res) => {
        this.VerbalList = res.data;
        this.initChart19();
      });
      getGeneral({
        projectId: this.options.storeProjectId,
      }).then((res) => {
        let a = [];
        let b = [];
        let c = [];
        let d = [];
        let e = [];
        res.data.postInfos.map((item) => {
          a.push({ time: item.sdate, count: item.oscaTime / 60 });
        });
        res.data.audioDays.map((item) => {
          b.push({ time: item.audioDate, count: item.staffDuration / 60 });
        });
        res.data.paceInfos.map((item) => {
          c.push({ time: item.sdate, count: item.step });
          d.push({ time: item.sdate, count: item.ssd / 60 });
        });
        res.data.userTalks.map((item) => {
          e.push({ time: item.sdate, count: item.activeTalkAvg || 0 });
        });
        this.echartsThreeData.data = d;
        this.echartsThreeData.max = Math.ceil(res.data.maxSsd / 60);
        this.echartsTwoData.data = c;
        this.echartsTwoData.max = res.data.maxStep;
        this.echartSixData.data = b;
        this.echartSixData.max = Math.ceil(res.data.maxStaffDuration / 60);
        this.echartsOneData.data = a;
        this.echartsOneData.max = Math.ceil(res.data.maxOscaTime / 60);
        this.echartsFiveData.data = e;
      });
    },
    draw_word_cloud_view() {
      if (this.word_cloud_view) {
        this.word_cloud_view.dispose();
      }
      this.word_cloud_view =
        document.getElementById("word_cloud_view_id") &&
        this.$echarts.init(
          document.getElementById("word_cloud_view_id"),
          "macarons"
        );
      this.word_cloud_view &&
        this.word_cloud_view.setOption(this.word_cloud_options);
    },
    init_view_data() {
      let word_cloud_series = [
        {
          type: "wordCloud",
          shape: "circle",
          //maskImage: maskImage,
          left: "center",
          top: "center",
          width: "100%",
          height: "100%",
          right: null,
          bottom: null,
          sizeRange: [16, 60],
          rotationRange: [-45, 45],
          autoSize: {
            enable: true,
            minSize: 16,
          },
          textPadding: 0,
          // rotationStep: 45,
          // gridSize: 8,
          drawOutOfBound: false,
          textStyle: {
            normal: {
              fontFamily: "sans-serif",
              fontWeight: "bold",
              // Color can be a callback function or a color string
              color: function () {
                // Random color
                return (
                  "rgb(" +
                  [
                    Math.round(Math.random() * 256),
                    Math.round(Math.random() * 256),
                    Math.round(Math.random() * 256),
                  ].join(",") +
                  ")"
                );
              },
            },
            emphasis: {
              shadowBlur: 10,
              shadowColor: "#333",
            },
          },
          data: this.word_list,
        },
      ];

      this.word_cloud_options = {
        series: word_cloud_series,
      };
      this.word_cloud_view.setOption(this.word_cloud_options);
    },
    initChart7() {
      var myChart = this.$echarts.init(document.getElementById("chart7"));
      var option = (option = {
        backgroundColor: "#FFF",
        title: {
          show: false,
          text: "数量",
          textStyle: {
            fontWeight: "normal",
            fontSize: 16,
            color: "#999999",
          },
          top: "0%",
          left: "0%",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          top: "30px",
          left: "30px",
          right: "20px",
          bottom: "15px",
        },
        legend: {
          x: "right", //可设定图例在左、右、居中
          padding: [0, 50, 0, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
          data: ["人均日步数", "人均接触步数"],
        },
        xAxis: [
          {
            type: "category",
            data: ["0316", "0317", "0318", "0319", "0320", "0321", "0322"],
            axisLine: {
              lineStyle: {
                color: "#EFF4FF",
              },
            },
            axisLabel: {
              margin: 6,
              color: "#999999",
              textStyle: {
                //textStyle里面写x轴下的字体的样式
                color: "#999999",
                fontSize: 8,
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "预警数",
            min: 0,
            max: 2.5,
            interval: 0.5,
            splitNumber: 3,
            axisLabel: {
              show: true,
              formatter: "{value} h",
              textStyle: {
                //textStyle里面写y轴下的字体的样式
                color: "#999999",
                fontSize: 8,
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#EFF4FF",
              },
            },
            //用于设置y轴的那一条线
            axisLine: {
              show: true, //这里的show用于设置是否显示y轴那一条线 默认为true
              lineStyle: {
                //lineStyle里面写y轴那一条线的样式
                color: "#EFF4FF",
                width: 1, //轴线的粗细 我写的是2 最小为0，值为0的时候线隐藏
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            name: "人均日步数",
            data: [1.5, 2.5, 1.5, 1.6, 1.5, 2.5, 0.5],
            barWidth: "12px",
            itemStyle: {
              normal: {
                color: "#5885FF", // 柱状图柱子颜色
                opacity: 1,
                barBorderRadius: [10, 10, 0, 0],
              },
            },
          },
          {
            type: "bar",
            name: "人均接触步数",
            data: [1.6, 0.8, 1.5, 0.7, 1.6, 0.8, 0.5],
            barWidth: "12px",
            itemStyle: {
              normal: {
                color: "#FFC531", // 柱状图柱子颜色
                opacity: 1,
                barBorderRadius: [10, 10, 0, 0],
              },
            },
          },
        ],
      });
      myChart.setOption(option);
    },
    initChart8() {
      var myChart = this.$echarts.init(document.getElementById("chart8"));
      var data1 = [20, 25, 24, 10, 20];
      var data2 = [20, 25, 24, 10, 20];
      var data3 = [20, 25, 24, 10, 20];
      var data4 = [];
      for (var i = 0; i <= data1.length; i++) {
        data4.push(100 - (data1[i] + data2[i] + data3[i]));
      }
      var option = {
        legend: {
          data: ["人均日步数", "人均接触步数", "有效在岗率%"],
        },
        xAxis: {
          type: "value",
          max: 100,
          axisLine: {
            //y轴
            show: false,
          },
          axisTick: {
            //y轴刻度线
            show: false,
          },
          splitLine: {
            //网格线
            show: false,
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#999",
            },
          },
        },
        grid: {
          top: "20px",
          left: "30px",
          right: "20px",
          bottom: "20px",
        },
        yAxis: {
          type: "category",
          data: ["0316", "0318", "0320", "0322", "0324"],
          axisLine: {
            //y轴
            show: false,
          },
          axisTick: {
            //y轴刻度线
            show: false,
          },
          splitLine: {
            //网格线
            show: false,
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#999",
            },
          },
        },
        color: ["#5885FF", "#7A79FF", "#58CFFF", "#F3F3FB"],
        series: [
          {
            name: "人均日步数",
            type: "bar",
            stack: "total",
            data: data1,
            barWidth: "15px",
          },
          {
            name: "人均接触步数",
            type: "bar",
            stack: "total",
            data: data2,
            barWidth: "15px",
          },
          {
            name: "有效在岗率%",
            type: "bar",
            stack: "total",
            data: data3,
            barWidth: "15px",
          },
        ],
      };
      myChart.setOption(option);
    },
    initChart9() {
      var myChart = this.$echarts.init(document.getElementById("chart9"));
      var option = (option = {
        backgroundColor: "#FFF",
        title: {
          show: false,
          text: "数量",
          textStyle: {
            fontWeight: "normal",
            fontSize: 16,
            color: "#999999",
          },
          top: "0%",
          left: "0%",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          top: "30px",
          left: "30px",
          right: "20px",
          bottom: "15px",
        },
        legend: {
          x: "right",
          padding: [0, 50, 0, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
          data: ["日均预警人数", "人均预警次数"],
        },
        xAxis: [
          {
            type: "category",
            data: ["0316", "0317", "0318", "0319", "0320", "0321", "0322"],
            axisLine: {
              lineStyle: {
                color: "#EFF4FF",
              },
            },
            axisLabel: {
              margin: 6,
              color: "#999999",
              textStyle: {
                //textStyle里面写x轴下的字体的样式
                color: "#999999",
                fontSize: 8,
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "预警数",
            min: 0,
            max: 80,
            interval: 20,
            splitNumber: 3,
            axisLabel: {
              show: true,
              formatter: "{value}",
              textStyle: {
                //textStyle里面写y轴下的字体的样式
                color: "#999999",
                fontSize: 8,
              },
            },
            splitNumber: 3,
            splitLine: {
              show: true,
              lineStyle: {
                color: "#EFF4FF",
              },
            },
            //用于设置y轴的那一条线
            axisLine: {
              show: true, //这里的show用于设置是否显示y轴那一条线 默认为true
              lineStyle: {
                //lineStyle里面写y轴那一条线的样式
                color: "#EFF4FF",
                width: 1, //轴线的粗细 我写的是2 最小为0，值为0的时候线隐藏
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            name: "日均预警人数",
            data: [22, 33, 55, 44, 11, 45, 68],
            barWidth: "12px",
            itemStyle: {
              normal: {
                color: "#5885FF", // 柱状图柱子颜色
                opacity: 1,
                barBorderRadius: [10, 10, 0, 0],
              },
            },
          },
          {
            type: "bar",
            name: "人均预警次数",
            data: [11, 45, 53, 64, 24, 23, 56],
            barWidth: "12px",
            itemStyle: {
              normal: {
                color: "#7A79FF", // 柱状图柱子颜色
                opacity: 1,
                barBorderRadius: [10, 10, 0, 0],
              },
            },
          },
        ],
      });
      myChart.setOption(option);
    },
    initChart10() {
      var myChart = this.$echarts.init(document.getElementById("chart10"));
      var option = (option = {
        backgroundColor: "#FFF",
        title: {
          show: false,
          text: "数量",
          textStyle: {
            fontWeight: "normal",
            fontSize: 16,
            color: "#999999",
          },
          top: "0%",
          left: "0%",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          top: "5%",
          right: "5%",
          left: "8%",
          bottom: "8%",
        },
        xAxis: [
          {
            type: "category",
            data: [
              "盘博刚",
              "仁纨",
              "陈玉蕊",
              "红维政",
              "郄晨琬",
              "待锦环",
              "里薇",
            ],
            axisLine: {
              lineStyle: {
                color: "#EFF4FF",
              },
            },
            axisLabel: {
              margin: 6,
              color: "#999999",
              textStyle: {
                //textStyle里面写x轴下的字体的样式
                color: "#999999",
                fontSize: 8,
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "预警数",
            min: 0,
            max: 100,
            interval: 25,
            splitNumber: 3,
            axisLabel: {
              show: true,
              formatter: "{value}",
              textStyle: {
                //textStyle里面写y轴下的字体的样式
                color: "#999999",
                fontSize: 8,
              },
            },
            splitNumber: 3,
            splitLine: {
              show: true,
              lineStyle: {
                color: "#EFF4FF",
              },
            },
            //用于设置y轴的那一条线
            axisLine: {
              show: true, //这里的show用于设置是否显示y轴那一条线 默认为true
              lineStyle: {
                //lineStyle里面写y轴那一条线的样式
                color: "#EFF4FF",
                width: 1, //轴线的粗细 我写的是2 最小为0，值为0的时候线隐藏
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            data: [24, 45, 77, 20, 25, 18, 15],
            barWidth: "14px",
            itemStyle: {
              normal: {
                color: "#7A79FF", // 柱状图柱子颜色
                opacity: 1,
              },
            },
          },
          {
            barGap: "120%",
            type: "line",
            data: [50, 50, 50, 50, 50, 50, 50],
            itemStyle: {
              normal: {
                color: "#FFC531", // 柱状图柱子颜色
                opacity: 1,
              },
            },
          },
        ],
      });
      myChart.setOption(option);
    },
    initChart11() {
      var pass_rate = 70,
        average = 60,
        total = 100;
      var myChart = this.$echarts.init(document.getElementById("chart11"));
      var option = {
        tooltip: {
          show: false,
          trigger: "item",
          formatter: "{a} : {c} ({d}%)",
        },
        color: ["#333FFF", "#7A79FF", "#58CFFF", "#23B899", "#5885FF"],
        legend: {
          orient: "vertical",
          x: "right",
          top: "27%",
          itemHeight: 10, //图例的高度
          itemGap: 8, //图例之间的间距
          data: [
            "基础知识" + pass_rate + "%",
            "新增知识" + average + "%",
            "分析话术",
            "考核话术",
            "补充内容",
          ],
          //图例的名字需要和饼图的name一致，才会显示图例
        },
        series: [
          {
            name: "通过率" + pass_rate + "%",
            type: "pie",
            radius: ["40%", "45%"],
            //环的位置
            label: {
              show: false,
              position: "center",
              // normal: { // 显示的位置
              //     position: 'inner'
              // }
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "14",
                fontWeight: "bold",
              },
            },
            data: [
              {
                value: pass_rate, //需要显示的数据
                name: "通过率" + pass_rate + "%",
                itemStyle: {
                  normal: {
                    color: "#333FFF",
                  },
                },
              },
              {
                value: total - pass_rate,
                //不需要显示的数据，颜色设置成和背景一样
                itemStyle: {
                  normal: {
                    color: "transparent",
                  },
                },
              },
            ],
          },
          {
            name: "平均值" + average + "%",
            type: "pie",
            radius: ["30%", "35%"],
            label: {
              show: false,
              position: "center",
              // normal: {
              //     position: 'inner'
              // }
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "14",
                fontWeight: "bold",
              },
            },
            data: [
              {
                name: "平均值" + average + "%",
                value: average,
                itemStyle: {
                  normal: {
                    color: "#7A79FF",
                  },
                },
              },
              {
                value: total - average,
                itemStyle: {
                  normal: {
                    color: "transparent",
                  },
                },
              },
            ],
          },
          {
            name: "分析话术",
            type: "pie",
            radius: ["30%", "35%"],
            label: {
              show: false,
              position: "center",
              // normal: {
              //     position: 'inner'
              // }
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "14",
                fontWeight: "bold",
              },
            },
            data: [
              {
                name: "分析话术",
                value: "70%",
                itemStyle: {
                  normal: {
                    color: "#58CFFF",
                  },
                },
              },
              {
                value: "70%",
                itemStyle: {
                  normal: {
                    color: "transparent",
                  },
                },
              },
            ],
          },
          {
            name: "考核话术",
            type: "pie",
            radius: ["30%", "35%"],
            label: {
              show: false,
              position: "center",
              // normal: {
              //     position: 'inner'
              // }
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "14",
                fontWeight: "bold",
              },
            },
            data: [
              {
                name: "考核话术",
                value: "70%",
                itemStyle: {
                  normal: {
                    color: "#23B899",
                  },
                },
              },
              {
                value: "70%",
                itemStyle: {
                  normal: {
                    color: "transparent",
                  },
                },
              },
            ],
          },
          {
            name: "补充内容",
            type: "pie",
            radius: ["30%", "35%"],
            label: {
              show: false,
              position: "center",
              // normal: {
              //     position: 'inner'
              // }
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "14",
                fontWeight: "bold",
              },
            },
            data: [
              {
                name: "补充内容",
                value: "70%",
                itemStyle: {
                  normal: {
                    color: "#5885FF",
                  },
                },
              },
              {
                value: "70%",
                itemStyle: {
                  normal: {
                    color: "transparent",
                  },
                },
              },
            ],
          },
        ],
      };
      option && myChart.setOption(option);
    },
    initChart12() {
      var myChart = this.$echarts.init(document.getElementById("chart12"));
      var option = (option = {
        backgroundColor: "#FFF",
        title: {
          show: false,
          text: "数量",
          textStyle: {
            fontWeight: "normal",
            fontSize: 16,
            color: "#999999",
          },
          top: "0%",
          left: "0%",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          top: "5%",
          right: "5%",
          left: "8%",
          bottom: "8%",
        },
        xAxis: [
          {
            type: "category",
            data: ["0316", "0317", "0318", "0319", "0320", "0321", "0322"],
            axisLine: {
              lineStyle: {
                color: "#EFF4FF",
              },
            },
            axisLabel: {
              margin: 6,
              color: "#999999",
              textStyle: {
                //textStyle里面写x轴下的字体的样式
                color: "#999999",
                fontSize: 8,
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "预警数",
            min: 0,
            max: 80,
            interval: 20,
            splitNumber: 3,
            axisLabel: {
              show: true,
              formatter: "{value}",
              textStyle: {
                //textStyle里面写y轴下的字体的样式
                color: "#999999",
                fontSize: 8,
              },
            },
            splitNumber: 3,
            splitLine: {
              show: true,
              lineStyle: {
                color: "#EFF4FF",
              },
            },
            //用于设置y轴的那一条线
            axisLine: {
              show: true, //这里的show用于设置是否显示y轴那一条线 默认为true
              lineStyle: {
                //lineStyle里面写y轴那一条线的样式
                color: "#EFF4FF",
                width: 1, //轴线的粗细 我写的是2 最小为0，值为0的时候线隐藏
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            data: [30, 45, 77, 20, 25, 18, 15],
            barWidth: "12px",
            itemStyle: {
              normal: {
                color: "#F9837D", // 柱状图柱子颜色
                opacity: 1,
                barBorderRadius: [10, 10, 0, 0],
              },
            },
          },
        ],
      });
      myChart.setOption(option);
    },
    initChart13() {
      var myChart = this.$echarts.init(document.getElementById("chart13"));
      var option = {
        textStyle: {
          color: "#999",
        },
        tooltip: {
          trigger: "axis",
          // axisPointer: {
          //     type: 'cross',
          //     crossStyle: {
          //         color: '#999'
          //     }
          // },
        },
        legend: {
          x: "right", //可设定图例在左、右、居中
          padding: [0, 80, 0, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
          data: ["在岗时长", "有效在岗率"],
        },
        xAxis: [
          {
            type: "category",
            data: this.dataList.infoResults.map((item) => item.sdate),
            // axisPointer: {
            //     type: 'line'
            // },
            axisLabel: {
              interval: 0,
              rotate: 360,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#EFF4FF",
              },
            },
            axisTick: {
              //y轴刻度线
              show: false,
            },
            // splitLine
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "在岗时长",
            min: 0,
            max: 10,
            interval: 1,
            axisLabel: {
              formatter: "{value} h",
            },
            axisLine: {
              lineStyle: {
                color: "#EFF4FF",
              },
            },
            axisTick: {
              //y轴刻度线
              show: false,
            },
          },
          {
            type: "value",
            name: "有效在岗率",
            nameTextStyle: {
              color: "#999",
            },
            min: 0,
            max: 100,
            interval: 10,
            axisLine: {
              lineStyle: {
                color: "#EFF4FF",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: "#EFF4FF",
              },
            },
            axisTick: {
              //y轴刻度线
              show: false,
            },
          },
        ],
        series: [
          {
            name: "在岗时长",
            type: "bar",
            data: this.dataList.infoResults.map((item) => item.workTime),
            barWidth: "12px",
            itemStyle: {
              normal: {
                color: "#23B899", // 柱状图柱子颜色
                opacity: 1,
                barBorderRadius: [10, 10, 0, 0],
              },
            },
          },
          {
            name: "有效在岗率",
            type: "line",
            yAxisIndex: 1,
            barWidth: "12px",
            data: this.dataList.infoResults.map((item) => item.workRate),
            itemStyle: {
              normal: {
                color: "#FFC531", // 柱状图柱子颜色
                opacity: 1,
                barBorderRadius: [10, 10, 0, 0],
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    initChart14() {
      var chartDom = document.getElementById("chart14");
      var myChart = this.$echarts.init(chartDom);
      var option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          //图例组件
          //right:100,  //图例组件离右边的距离
          orient: "horizontal", //布局  纵向布局 图例标记居文字的左边 vertical则反之
          width: 40, //图行例组件的宽度,默认自适应
          x: "right", //图例显示在右边
          y: "center", //图例在垂直方向上面显示居中
          itemWidth: 10, //图例标记的图形宽度
          itemHeight: 10, //图例标记的图形高度
          data: Object.keys(this.dataList.sectionMap),
          textStyle: {
            //图例文字的样式
            color: "#333", //文字颜色
            fontSize: 12, //文字大小
          },
          formatter: function (name) {
            // 获取legend显示内容
            let data = option.series[0].data;
            let total = 0;
            let tarValue = 0;
            for (let i = 0, l = data.length; i < l; i++) {
              total += data[i].value;
              if (data[i].name == name) {
                tarValue = data[i].value;
              }
            }
            let p = ((tarValue / total) * 100).toFixed(2);
            return name + " " + "     " + p + "%";
          },
        },
        color: ["#7A79FF", "#23B899", "#58CFFF", "#FFC531", "#F9837D"],
        series: [
          {
            name: "区间占比",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "inner",
            },
            labelLine: {
              show: false,
            },
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
              emphasis: {
                shadowBlur: 1,
                shadowOffsetX: 0,
                shadowColor: "#fff",
              },
              // normal: {
              //   label: {
              //     show: true,
              //     formatter: '{c}',
              //     position: 'inner'
              //   },
              //   labelLine: {
              //     show: true,
              //   }
              // },
            },
            emphasis: {
              label: {
                show: true,
              },
            },
            data: [
              {
                value:
                  this.dataList.sectionMap[
                    Object.keys(this.dataList.sectionMap)[1]
                  ],
                name: Object.keys(this.dataList.sectionMap)[1],
              },
              {
                value:
                  this.dataList.sectionMap[
                    Object.keys(this.dataList.sectionMap)[2]
                  ],
                name: Object.keys(this.dataList.sectionMap)[2],
              },
              {
                value:
                  this.dataList.sectionMap[
                    Object.keys(this.dataList.sectionMap)[3]
                  ],
                name: Object.keys(this.dataList.sectionMap)[3],
              },
              {
                value:
                  this.dataList.sectionMap[
                    Object.keys(this.dataList.sectionMap)[4]
                  ],
                name: Object.keys(this.dataList.sectionMap)[4],
              },
              {
                value:
                  this.dataList.sectionMap[
                    Object.keys(this.dataList.sectionMap)[0]
                  ],
                name: Object.keys(this.dataList.sectionMap)[0],
              },
            ],
          },
        ],
      };
      myChart.setOption(option);
    },
    initChart15() {
      var myChart = this.$echarts.init(document.getElementById("chart15"));
      var option = {
        textStyle: {
          color: "#999",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        legend: {
          x: "right", //可设定图例在左、右、居中
          padding: [0, 80, 0, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
          data: ["人均离岗时长", "人均离岗次数", "次数异常人数"],
        },
        xAxis: [
          {
            type: "category",
            data: this.dataList.infoResults.map((item) => item.sdate),
            axisPointer: {
              type: "shadow",
            },
            axisLine: {
              lineStyle: {
                color: "#EFF4FF",
              },
            },
            axisTick: {
              //y轴刻度线
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "人均离岗时长",
            min: 0,
            max: Math.ceil(this.dataList.maxWorkOdd),
            interval: Math.ceil(this.dataList.maxWorkOdd) / 10,
            axisLabel: {
              formatter: "{value} h",
            },
            axisLine: {
              lineStyle: {
                color: "#EFF4FF",
              },
            },
            axisTick: {
              //y轴刻度线
              show: false,
            },
          },
          {
            type: "value",
            name: "人均离岗次数",
            min: 0,
            max: Math.ceil(this.dataList.maxWorkOdc),
            interval: Math.ceil(this.dataList.maxWorkOdc) / 10,
            axisLine: {
              lineStyle: {
                color: "#EFF4FF",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: "#EFF4FF",
              },
            },
            axisTick: {
              //y轴刻度线
              show: false,
            },
          },
        ],
        series: [
          {
            name: "人均离岗时长",
            type: "bar",
            data: this.dataList.infoResults.map((item) => item.workOdd),
            barWidth: "12px",
            itemStyle: {
              normal: {
                color: "#4E57AA", // 柱状图柱子颜色
                opacity: 1,
                barBorderRadius: [10, 10, 0, 0],
              },
            },
          },
          {
            name: "人均离岗次数",
            type: "line",
            barWidth: "12px",
            yAxisIndex: 1,
            data: this.dataList.infoResults.map((item) => item.workOdc),
            itemStyle: {
              normal: {
                color: "#FFC531", // 柱状图柱子颜色
                opacity: 1,
                barBorderRadius: [10, 10, 0, 0],
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    initChart16() {
      var myChart = this.$echarts.init(document.getElementById("chart16"));
      var option = {
        textStyle: {
          color: "#999",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        legend: {
          x: "right", //可设定图例在左、右、居中
          padding: [0, 100, 0, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
          data: ["日均预警人数", "人均预警次数"],
        },
        xAxis: [
          {
            type: "category",
            data: this.dataList.infoResults.map((item) => item.sdate),
            axisPointer: {
              type: "shadow",
            },
            axisLabel: { interval: 0, rotate: 360 },
            axisLine: {
              lineStyle: {
                color: "#EFF4FF",
              },
            },
            axisTick: {
              //y轴刻度线
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "日均预警人数",
            min: 0,
            max: Math.ceil(this.dataList.maxOscaUserCount),
            interval: Math.ceil(this.dataList.maxOscaUserCount) / 5,
            axisLabel: {
              formatter: "{value}",
            },
            axisLine: {
              lineStyle: {
                color: "#EFF4FF",
              },
            },
            axisTick: {
              //y轴刻度线
              show: false,
            },
          },
          {
            type: "value",
            name: "人均预警次数",
            min: 0,
            max: Math.ceil(this.dataList.maxOscaCountAvg),
            interval: Math.ceil(this.dataList.maxOscaCountAvg) / 5,
            axisLine: {
              lineStyle: {
                color: "#EFF4FF",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: "#EFF4FF",
              },
            },
            axisTick: {
              //y轴刻度线
              show: false,
            },
          },
        ],
        series: [
          {
            name: "日均预警人数",
            type: "bar",
            data: this.dataList.infoResults.map((item) => item.oscaUserCount),
            barWidth: "22.45px",
            itemStyle: {
              normal: {
                color: "#7A79FF", // 柱状图柱子颜色
                opacity: 1,
                barBorderRadius: [10, 10, 0, 0],
              },
            },
          },
          {
            name: "人均预警次数",
            type: "bar",
            barWidth: "22.45px",
            data: this.dataList.infoResults.map((item) => item.oscaCountAvg),
            yAxisIndex: 1,
            itemStyle: {
              normal: {
                color: "#58CFFF", // 柱状图柱子颜色
                opacity: 1,
                barBorderRadius: [10, 10, 0, 0],
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    initChart17() {
      let arr = [];
      for (let index = 0; index < this.ficacylist.length; index++) {
        arr.push(this.ficacyAvg);
      }
      var myChart = this.$echarts.init(document.getElementById("chart17"));
      var option = {
        textStyle: {
          color: "#999",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        legend: {
          x: "right", //可设定图例在左、右、居中
          padding: [0, 100, 0, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
          data: ["人均日步数", "总人均日步数均值线"],
        },
        xAxis: [
          {
            type: "category",
            data: this.ficacylist.map((item) => item.sdate),
            axisPointer: {
              type: "shadow",
            },
            axisLabel: { interval: 0, rotate: 360 },
            axisLine: {
              lineStyle: {
                color: "#EFF4FF",
              },
            },
            axisTick: {
              //y轴刻度线
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "人均日步数",
            min: 0,
            max: Math.ceil(this.ficacyMax),
            interval: 500,
            axisLabel: {
              formatter: "{value}",
            },
            axisLine: {
              lineStyle: {
                color: "#EFF4FF",
              },
            },
            axisTick: {
              //y轴刻度线
              show: false,
            },
          },
          {
            type: "value",
            name: "总人均日步数均值线",
            min: 0,
            max: Math.ceil(this.ficacyMax),
            interval: 500,
            axisLine: {
              lineStyle: {
                color: "#EFF4FF",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: "#EFF4FF",
              },
            },
            axisTick: {
              //y轴刻度线
              show: false,
            },
          },
        ],
        series: [
          {
            name: "人均日步数",
            type: "bar",
            data: this.ficacylist.map((item) => item.dateStep),
            barWidth: "22.45px",
            itemStyle: {
              normal: {
                color: "#7A79FF", // 柱状图柱子颜色
                opacity: 1,
                barBorderRadius: [10, 10, 0, 0],
              },
            },
          },
          {
            name: "总人均日步数均值线",
            type: "line",
            barWidth: "22.45px",
            data: arr,
            yAxisIndex: 1,
            itemStyle: {
              normal: {
                color: "#FFC531", // 颜色
                opacity: 1,
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    initChart18() {
      var myChart = this.$echarts.init(document.getElementById("chart18"));
      var option = {
        textStyle: {
          color: "#999",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        legend: {
          x: "right", //可设定图例在左、右、居中
          padding: [0, 100, 0, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
          data: ["每日人均静止次数", "每日预警人数"],
        },
        xAxis: [
          {
            type: "category",
            data: this.userSscasList.map((item) => item.sdate),
            axisPointer: {
              type: "shadow",
            },
            axisLabel: { interval: 0, rotate: 360 },
            axisLine: {
              lineStyle: {
                color: "#EFF4FF",
              },
            },
            axisTick: {
              //y轴刻度线
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "每日人均静止次数",
            min: 0,
            max: Math.ceil(this.dateSscaCountMax),
            interval: 500,
            axisLabel: {
              formatter: "{value}",
            },
            axisLine: {
              lineStyle: {
                color: "#EFF4FF",
              },
            },
            axisTick: {
              //y轴刻度线
              show: false,
            },
          },
          {
            type: "value",
            name: "每日预警人数",
            min: 0,
            max: Math.ceil(this.sscaUserCountMax),
            interval: 500,
            axisLine: {
              lineStyle: {
                color: "#EFF4FF",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: "#EFF4FF",
              },
            },
            axisTick: {
              //y轴刻度线
              show: false,
            },
          },
        ],
        series: [
          {
            name: "每日人均静止次数",
            type: "bar",
            data: this.userSscasList.map((item) => item.dateSscaCount),
            barWidth: "22.45px",
            itemStyle: {
              normal: {
                color: "#6160DC", // 柱状图柱子颜色
                opacity: 1,
                barBorderRadius: [10, 10, 0, 0],
              },
            },
          },
          {
            name: "每日预警人数",
            type: "bar",
            barWidth: "22.45px",
            data: this.userSscasList.map((item) => item.sscaUserCount),
            yAxisIndex: 1,
            itemStyle: {
              normal: {
                color: "#F9837D", // 柱状图柱子颜色
                opacity: 1,
                barBorderRadius: [10, 10, 0, 0],
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    initChart19() {
      let arr = [];
      for (
        let index = 0;
        index < this.VerbalList.userVerbalGets.length;
        index++
      ) {
        arr.push(this.VerbalList.userVerbalGetAvg);
      }
      var myChart = this.$echarts.init(document.getElementById("chart19"));
      var option = {
        textStyle: {
          color: "#999",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        legend: {
          x: "right", //可设定图例在左、右、居中
          padding: [0, 100, 0, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
          data: ["关键词命中次数", "人均命中次数均值线"],
        },
        xAxis: [
          {
            type: "category",
            data: this.VerbalList.userVerbalGets.map((item) => item.nickName),
            axisPointer: {
              type: "shadow",
            },
            axisLabel: { interval: 0, rotate: 360 },
            axisLine: {
              lineStyle: {
                color: "#EFF4FF",
              },
            },
            axisTick: {
              //y轴刻度线
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "关键词命中次数",
            min: 0,
            // max: Math.ceil(this.VerbalList.userVerbalGets[0].verbalGetCount),
            interval: this.VerbalList.userVerbalGets[0].verbalGetCount / 10,
            axisLabel: {
              formatter: "{value}",
            },
            axisLine: {
              lineStyle: {
                color: "#EFF4FF",
              },
            },
            axisTick: {
              //y轴刻度线
              show: false,
            },
          },
          {
            type: "value",
            name: "人均命中次数均值线",
            min: 0,
            max: Math.ceil(this.VerbalList.userVerbalGets[0].verbalGetCount),
            interval: this.VerbalList.userVerbalGets[0].verbalGetCount / 10,
            axisLine: {
              lineStyle: {
                color: "#EFF4FF",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: "#EFF4FF",
              },
            },
            axisTick: {
              //y轴刻度线
              show: false,
            },
          },
        ],
        series: [
          {
            name: "关键词命中次数",
            type: "bar",
            data: this.VerbalList.userVerbalGets.map(
              (item) => item.verbalGetCount
            ),
            barWidth: "22.45px",
            itemStyle: {
              normal: {
                color: "#6160DC", // 柱状图柱子颜色
                opacity: 1,
                barBorderRadius: [10, 10, 0, 0],
              },
            },
          },
          {
            name: "人均命中次数均值线",
            type: "line",
            barWidth: "22.45px",
            data: arr,
            yAxisIndex: 1,
            itemStyle: {
              normal: {
                color: "#FFC531", // 柱状图柱子颜色
                opacity: 1,
                barBorderRadius: [10, 10, 0, 0],
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    drawChart(data) {
      let newData = Number(data);
      console.log(typeof newData);
      // 基于准备好的dom，初始化echarts实例
      let chart = this.$echarts.init(document.getElementById("chart_shui"));
      // 监听屏幕变化自动缩放图表
      window.addEventListener("resize", function () {
        chart.resize();
      });
      // 绘制图表
      chart.setOption({
        // 提示框组件
        tooltip: {
          trigger: "item", // 触发类型, 数据项图形触发，主要在散点图，饼图等无类目轴的图表中使用。
          textStyle: {
            color: "#FFF", // 文字颜色
          },
          // 提示框浮层内容格式器，支持字符串模板和回调函数两种形式
          // 水球图: {a}（系列名称），{b}（无），{c}（数值）
          // 使用函数模板   传入的数据值 -> value: number|Array,
          formatter: function (value) {
            return value.seriesName + ": " + value.data * 100 + "%";
          },
        },
        series: [
          {
            type: "liquidFill",
            name: "话术完成度", // 系列名称，用于tooltip的显示，legend 的图例筛选
            radius: "60%", // 水球图的半径
            center: ["50%", "60%"], // 水球图的中心（圆心）坐标，数组的第一项是横坐标，第二项是纵坐标
            // 水填充图的形状 circle 默认圆形  rect 圆角矩形  triangle 三角形
            // diamond 菱形  pin 水滴状 arrow 箭头状  还可以是svg的path
            shape: "circle",
            phase: 0, // 波的相位弧度 不设置  默认自动
            direction: "right", // 波浪移动的速度  两个参数  left 从右往左 right 从左往右
            outline: {
              show: true,
              borderDistance: 0, // 边框线与图表的距离 数字
              itemStyle: {
                opacity: 1, // 边框的透明度   默认为 1
                borderWidth: 1, // 边框的宽度
                shadowBlur: 1, // 边框的阴影范围 一旦设置了内外都有阴影
                shadowColor: "#23B899", // 边框的阴影颜色,
                borderColor: "#23B899", // 边框颜色
              },
            },
            // 图形样式
            itemStyle: {
              color: "#23B899", // 水球显示的背景颜色
              opacity: 0.5, // 波浪的透明度
              shadowBlur: 10, // 波浪的阴影范围
            },
            backgroundStyle: {
              color: "#fff", // 水球未到的背景颜色
              opacity: 0.5,
            },
            // 图形的高亮样式
            emphasis: {
              itemStyle: {
                opacity: 0.8, // 鼠标经过波浪颜色的透明度
              },
            },
            // 图形上的文本标签
            label: {
              fontSize: 18,
              fontWeight: 400,
              // fontFamily: "DigifaceWide",
              color: "#fff",
            },
            // data: [0.62], // 系列中的数据内容数组
            data: [newData], // 系列中的数据内容数组
          },
        ],
      });
    },
    selectTime(val) {
      if (val && val.length > 1) {
        this.dayIndex = 3;
        this.timeSet = val;
        this.getStatistics();
      }
    },
    getStatistics() {
      let nowTime = "";
      let weekDay = "";
      let monthDay = "";
      let date1 = new Date();
      nowTime =
        date1.getFullYear() +
        "-" +
        (date1.getMonth() + 1) +
        "-" +
        date1.getDate();
      let date2 = new Date();
      date2.setDate(date2.getDate() - 7);
      weekDay =
        date2.getFullYear() +
        "-" +
        (date2.getMonth() + 1) +
        "-" +
        date2.getDate();
      let date3 = new Date();
      date3.setDate(date3.getDate() - 30);
      monthDay =
        date3.getFullYear() +
        "-" +
        (date3.getMonth() + 1) +
        "-" +
        date3.getDate();
    },
    initChart() {
      let that = this;
      var myChart = this.$echarts.init(document.getElementById("chart"));
      var legendData = [""];
      var indicator = [
        {
          text: "行动步数",
          max: 1000,
        },
        {
          text: "离岗次数",
          max: 50,
        },
        {
          text: "主动沟通占比",
          max: 100,
        },
        {
          text: "话术匹配度",
          max: 100,
        },
        {
          text: "沟通时长占比",
          max: 100,
        },
        {
          text: "成交量订单",
          max: 100,
        },
      ];
      var dataArr = [
        {
          // that.Mesh
          value: that.Mesh,
          name: legendData[0],
          itemStyle: {
            normal: {
              lineStyle: {
                color: "#55d7f2",
              },
            },
          },
          areaStyle: {
            normal: {
              // 单项区域填充样式
              color: {
                type: "linear",
                x: 1, //右
                y: 0, //下
                x2: 1, //左
                y2: 1, //上
                colorStops: [
                  {
                    offset: 0,
                    color: "#c8ffff",
                  },
                  {
                    offset: 1,
                    color: "#c8ffff",
                  },
                ],
                globalCoord: false,
              },
              opacity: 0.5, // 区域透明度
            },
          },
        },
      ];
      var colorArr = ["#fea045", "#4BFFFC"]; //颜色
      var option = {
        backgroundColor: "#FFFFFF",
        color: colorArr,
        legend: {
          orient: "vertical",
          icon: "circle", //图例形状
          data: legendData,
          top: 15,
          left: 15,
          itemWidth: 8, // 图例标记的图形宽度。[ default: 25 ]
          itemHeight: 8, // 图例标记的图形高度。[ default: 14 ]
          itemGap: 22, // 图例每项之间的间隔。[ default: 10 ]横向布局时为水平间隔，纵向布局时为纵向间隔。
          textStyle: {
            fontSize: 12,
            fontWeight: "bold",
            color: "#00E4FF",
          },
        },
        radar: {
          // shape: 'circle',
          name: {
            textStyle: {
              color: "#333333",
              fontSize: 12,
            },
          },
          indicator: indicator,
          splitArea: {
            // 坐标轴在 grid 区域中的分隔区域，默认不显示。
            show: true,
            areaStyle: {
              // 分隔区域的样式设置。
              color: ["rgba(255,255,255,0)", "rgba(255,255,255,0)"], // 分隔区域颜色。分隔区域会按数组中颜色的顺序依次循环设置颜色。默认是一个深浅的间隔色。
            },
          },
          axisLine: {
            //指向外圈文本的分隔线样式
            lineStyle: {
              color: "#e3e4ea",
              type: "dashed",
            },
          },
          splitLine: {
            lineStyle: {
              color: "#e3e4ea", // 分隔线颜色
              width: 1, // 分隔线线宽
            },
          },
        },
        series: [
          {
            type: "radar",
            symbolSize: 8,
            symbol: "circle",
            data: dataArr,
          },
        ],
      };
      myChart.setOption(option);
    },
  },
  mounted() {
    MapLoader().then(
      (AMap) => {
        this.map = new AMap.Map("yzMap1", {
          zoom: 15,
          resizeEnable: true,
        });
        this.initMap();
        // ....你的操作
      },
      (e) => {}
    );
    let that = this;
    that.draw_word_cloud_view();
    that.getSpaceMap();
    that.getCompleteness();
    that.getProIndexAlls();
    // this.initChart7();
    // this.initChart8();
    // this.initChart9();
    // this.initChart10();
    // this.initChart11();
    // this.initChart12();
    setTimeout(function () {
      // that.init_view_data()
      that.initChart13();
      that.initChart15();
      that.initChart16();
      that.initChart17();
      that.initChart18();
      that.initChart19();
      that.initChart14();
    }, 800);
    let myChart1 = this.$echarts.init(document.getElementById("chart13"));
    let myChart2 = this.$echarts.init(document.getElementById("chart14"));
    let myChart3 = this.$echarts.init(document.getElementById("chart15"));
    let myChart4 = this.$echarts.init(document.getElementById("chart16"));
    let myChart5 = this.$echarts.init(document.getElementById("chart17"));
    let myChart6 = this.$echarts.init(document.getElementById("chart18"));
    let myChart7 = this.$echarts.init(document.getElementById("chart19"));
    window.addEventListener("resize", () => {
      myChart1.resize();
      myChart2.resize();
      myChart3.resize();
      myChart4.resize();
    });
  },
};
</script>


<style scoped>
.contentItem {
  width: 40%;
}
.searchBtn {
  color: #fff;
  background-color: #4e57aa;
  margin: 0 42px 0 30px;
}
.tradeTranstrfom .wrapper {
  width: 100%;
}
.tradeTranstrfom .wrapper .inner {
  width: 200px;
  height: 200px;
  margin: 0 auto;

  /* background: url(../../public/static/bg.png) no-repeat; */
}
.expressionBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
}
.expressionTitle {
  display: flex;
  align-items: center;
  margin: 32px 0 25px 0;
}
.tradeTranstrfom .wrapper .inner .chart_shui {
  width: 200px;
  height: 200px;
  position: relative;
  top: -30px;
  /* background: url(../../public/static/fill-border.gif) no-repeat center bottom; */
}
.tradeTranstrfom .btm {
  /* background: url(../../public/static/icon-bot.png) no-repeat; */
  text-align: center;
  position: absolute;
  left: 50%;
  bottom: 5px;
  font-size: 14px;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  white-space: nowrap;
}
.tradeTranstrfom .tradeTranstrfom_header {
  width: 100%;
  padding: 3vh 1.5vw 0 3vh;
}
.form-content {
  margin-bottom: 32px;
  flex-wrap: wrap;
}
.form-content,
.form-item {
  display: flex;
  align-items: center;
}
.form-content /deep/ .el-select {
  width: 180px;
}

.project_overview_storeRanking_item {
  width: 100%;
  height: 36vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 3vh;
}

.project_overview_storeRanking_header_left {
  width: 65%;
  height: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.project_overview_storeRanking_header_left_right {
  width: 70%;
  height: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.project_overview_storeRanking_header_right {
  width: 4vw;
  height: 3.8vh;
  background: #4e57aa;
  box-shadow: 0 6px 10px 0 rgba(78, 87, 170, 0.3);
  border-radius: 6px;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  line-height: 4vh;
}

.project_overview_analysisTrend {
  width: 100%;
  height: 90vh;
  margin-top: 0vh;
}

.project_overview_analysisTrend_content {
  width: 100%;
  height: 50vh;
}

.project_overview_analysisTrend_content_top {
  width: 100%;
  height: 40vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.project_overview_analysisTrend_content_item {
  background: #ffffff;
  border-radius: 12px;
  width: 32.6%;
  height: 38vh;
  position: relative;
}

.project_overview_analysisTrend_content_item_header {
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 4%;
  padding-bottom: 4%;
}
.project_overview_analysisTrend_content_item_header_color {
  width: 4px;
  height: 2vh;
  font-size: 14px;
  background: #4e57aa;
}
.project_overview_analysisTrend_content_item_header_text {
  margin-left: 0.4vw;
  font-size: 14px;
  line-height: 3vh;
}

.echartstwo_box {
  widows: 100%;
}

.chartstwo_box {
  width: 100%;
  height: 31.7vh;
  border-radius: 10px;
  background: #fff;
  padding: 1.5vh 0;
}

.charts_title {
  width: 100%;
  height: 5vh;
  display: flex;
  flex-direction: row;
}

.charts_title_left {
  width: 20vw;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.charts_title_left_color {
  width: 4px;
  height: 2vh;
  font-size: 18px;
  background: #4e57aa;
}

.charts_title_left_text {
  margin-left: 0.7vw;
  font-size: 14px;
  line-height: 3vh;
}

.echart_line {
  display: flex;
}

.echart_item {
  width: 50%;
}

.echart_Boxitem {
  width: 100%;
}

#chart7 {
  width: 100%;
  height: 25vh;
}

#chart8 {
  width: 100%;
  height: 25vh;
}

#chart9 {
  width: 100%;
  height: 25vh;
}

#chart10 {
  width: 100%;
  height: 23vh;
}

#chart11 {
  width: 100%;
  height: 25vh;
}

#chart12 {
  width: 100%;
  height: 23vh;
}
#chart13 {
  width: 100%;
  height: 400px;
}
#chart14 {
  width: 100%;
  height: 350px;
}
#chart15 {
  width: 100%;
  height: 400px;
}
#chart16 {
  width: 100%;
  height: 400px;
}
#chart17 {
  width: 100%;
  height: 400px;
}
#chart18 {
  width: 100%;
  height: 400px;
}
#chart19 {
  width: 100%;
  height: 400px;
}
</style>


<style scoped lang="less">
div {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.echarts-box {
  width: 100%;
  height: 73%;
  position: absolute;
  top: 20%;
  left: 0;
  overflow: hidden;
  .box {
    width: 100%;
    height: 100%;
  }
}
.tradeTranstrfom {
  width: 432px;
  background: #fff;
  margin-left: 20px;
  height: 280px;
  position: relative;
}

.tradeTranstrfom_text {
  text-align: center;
}

.tradeTranstrfom_text span {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

.topTitle {
  display: flex;
  // justify-content: space-between;
  margin-bottom: 21px;
}

.indicatorBox {
  width: 680px;
  background: #fff;
  height: 280px;
}

.indicator_item_box {
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  // margin-left: 50px;
}

.indicator_item_box p {
  font-size: 14px;
  font-weight: 400;
  color: #171717;
  margin: 20px 0 8px 0;
}

.indicator_item_box span {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
}

.indicator_item_box /deep/ .el-progress-circle {
  width: 100px !important;
  height: 100px !important;
}

.store_data_analysis_detail_content {
  width: 100%;
  height: auto;
  // overflow-y: auto;
  background: #f9f9fd;
  padding: 0vh 1.5vw;
}
.project_overview_header {
  width: 100%;
  height: 6vh;
  // margin: 4vh 0vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.project_overview_header_right {
  // width: 30%;
  height: 4vh;
  display: flex;
  // flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /deep/.el-breadcrumb {
    margin-right: 50px;
  }
}
.project_overview_header_right_times {
  font-size: 14px;
  color: #333333;
}

.store_data_analysis_detail_bigcontent {
  width: 100%;
  height: 55vh;
  border-radius: 10px;
  background: #fff;
}

.store_data_analysis_detail_bigcontent_button {
  width: 100%;
  height: 8vh;
  padding: 1vh 1.5vw 2vh 1.5vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.store_data_analysis_detail_bigcontent_button_left {
  width: 15vw;
  height: 5vh;
  background: #4e57aa;
  box-shadow: 0 6px 10px 0 rgba(78, 87, 170, 0.3);
  border-radius: 20px;
  text-align: center;
  line-height: 5vh;
  color: #fff;
  cursor: pointer;
  visibility: hidden;
}

.project_overview_periphery {
  width: 100%;
  height: 64vh;
  margin-top: 2.5vh;
  border-radius: 10px;
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.periphery_left_header_right_datetime {
  width: 32vw;
  display: flex;
  align-items: center;
  // height: 100%;
  // height: 40px;
}

.project_overview_overview {
  width: 100%;
  height: 50vh;
  // border-radius: 10px;
  // background: #FFF;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.project_overview_overview_left {
  width: 100%;
  height: 100%;
  margin: 0vh 1.5vw;
}
.overview_right_header_right /deep/ .el-range-editor--small.el-input__inner {
  height: 3vh;
}
.project_overview_overview_left_header {
  width: 95%;
  height: 3vh;
  padding: 3vh 0vw 5vh 0vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 3vh;
}
.project_overview_overview_left_header_color {
  width: 6px;
  height: 3vh;
  font-size: 18px;
  background: #4e57aa;
}
.project_overview_overview_left_header_text {
  margin-left: 0.7vw;
  font-size: 18px;
  line-height: 3vh;
}

.project_overview_overview_left_main {
  width: 100%;
  // padding-left: 8px;
  height: 39vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.project_overview_overview_left_main_left {
  width: 50%;
  height: 100%;
}
.project_overview_overview_left_main_left_item {
  width: 100%;
  height: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  background: #f9f9fd;
  border-radius: 8px;
}

// .project_overview_overview_left_main_left_item img {
//   height:23px;
// }
.overview_left_main_left_item_left {
  width: 58%;
  height: 5vh;
  font-size: 14px;
  line-height: 5vh;
  display: flex;
  align-items: center;
  padding-left: 22px;
  color: #333333;
  white-space: nowrap;
}
.overview_left_main_left_item_left img {
  margin-right: 12px;
}
.overview_left_main_left_item_right {
  width: 42%;
  height: 5vh;
  line-height: 5vh;
  text-align: right;
  font-size: 18px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #4e57aa;
  padding-right: 64px;
}

.project_overview_overview_right {
  width: 38%;
  height: 100%;
  margin: 0vh 1.5vw;
}

.store_data_analysis_detail_bigcontent_button_left_header {
  width: 100%;
  height: 5vh;
  display: flex;
  flex-direction: row;
}

.project_overview_overview_right_header {
  width: 100%;
  height: 3vh;
  display: flex;
  flex-direction: row;
}
.overview_right_header_left {
  width: 52%;
  height: 3vh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-right: 3%;
}
.overview_right_header_left_button {
  width: 4.3vw;
  height: 3vh;
  text-align: center;
  line-height: 3vh;
  border-radius: 6px;
  font-size: calc(14vh / 10.8);
  cursor: pointer;
}
.overview_right_header_left_button_checked {
  background: #4e57aa;
  box-shadow: 0 6px 10px 0 rgba(78, 87, 170, 0.3);
  color: #ffffff;
}
.overview_right_header_left_button_nochecked {
  color: #333333;
  border: 1px solid #cccccc;
  background: #ffffff;
}

.overview_right_header_right {
  width: 45%;
  height: 3vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.project_overview_overview_right_main {
  width: 100%;
  height: 38vh;
}
#chart {
  width: 100%;
  height: 34vh;
}

.store_data_analysis_detail_area_proportion_content {
  width: 100%;
  margin-top: 2.5vh;
  padding-left: 1.5vw;
  border-radius: 10px;
  background: #fff;
  // display: flex;
  // flex-direction: row;
  // justify-content: space-between;
}

.store_data_analysis_detail_area_proportion_content_header {
  width: 100%;
  height: 11vh;
  padding: 3vh 0;
}

.store_data_analysis_detail_area_proportion {
  width: 100%;
  height: 53vh;
  padding: 0 1.5vw;
}

.store_data_analysis_detail_area_proportion_main {
  background: rgba(78, 87, 170, 0.05);
}

.store_data_analysis_detail_area_proportion_main_item {
  height: 6.5vh;
  line-height: 6.5vh;
  text-align: center;
  font-size: 14px;
  color: #333333;
}

.store_data_analysis_detail_area_proportion_centent {
  background: rgba(255, 255, 255, 0.2);
}

.store_data_analysis_detail_area_proportion_main_centent_item {
  height: 6.5vh;
  line-height: 6.5vh;
  text-align: center;
  font-size: 14px;
  color: #333333;
}

.project_overview_periphery_left {
  width: 50%;
  height: 100%;
  margin: 0vh 1.5vw;
}
.project_overview_periphery_left_header {
  width: 100%;
  height: 5vh;
  margin: 3vh 0vw 3vh 0vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.project_overview_periphery_left_mapcontent {
  width: 100%;
  height: 50vh;
  position: relative;
}
.toMapBtn {
  position: absolute;
  top: 5px;
  right: 5px;
  background: rgba(64, 158, 255, 0.5);
  border-radius: 4px;
  z-index: 999;
  color: #fff;
  padding: 5px 10px;
  cursor: pointer;
}
.project_overview_periphery_left_header_left {
  height: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  // margin-left:3vh;
}
.project_overview_periphery_left_header_left_color {
  width: 6px;
  height: 3vh;
  font-size: 18px;
  background: #4e57aa;
}
.project_overview_periphery_left_header_left_text {
  margin-left: 0.7vw;
  font-size: 18px;
  line-height: 3vh;
}

.project_overview_periphery_left_header_right {
  width: 75%;
  height: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.periphery_left_header_right_select {
  width: 36vw;
  height: 5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 1.2vw;
}

.project_overview_periphery_right {
  width: 45%;
  height: 100%;
  padding-top: 1vh;
  margin: 0 1.5vw;
}
.project_overview_periphery_right_header {
  width: 100%;
  height: 9vh;
  // margin: 3vh 0vw 0vh 0vw;
  padding: 2vh 0vw 2vh 0vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.project_overview_periphery_right_header_left {
  width: 30%;
  height: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.project_overview_periphery_right_header_left_text {
  margin-left: 0.7vw;
  font-size: 18px;
  line-height: 3vh;
  white-space: nowrap;
}

.project_overview_periphery_right_main {
  background: rgba(78, 87, 170, 0.1);
  border-radius: 6px;
}

.project_overview_periphery_right_main_item {
  height: 5vh;
  line-height: 5vh;
  text-align: center;
  font-size: 14px;
  color: #333333;
}

.project_overview_periphery_right_main_centent {
  background: rgba(255, 255, 255, 0.2);
  font-size: 14px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #333333;
}

.project_overview_periphery_right_main_centent_item {
  height: 5vh;
  line-height: 5vh;
  text-align: center;
  font-size: 14px;
  color: #333333;
}

.project_overview_storeRanking {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.project_overview_storeRanking_header {
  width: 97%;
  height: 5vh;
  margin: 0vh 1.5% 3vh 1.5%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.project_overview_storeRanking_header_left {
  width: 65%;
  height: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.project_overview_storeRanking_header_left_right {
  width: 70%;
  height: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.project_overview_storeRanking_header_right {
  width: 4vw;
  height: 3.8vh;
  background: #4e57aa;
  box-shadow: 0 6px 10px 0 rgba(78, 87, 170, 0.3);
  border-radius: 6px;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  line-height: 4vh;
}

.project_overview_analysisTrend {
  width: 100%;
  height: auto;
  margin-top: 2.5vh;
  padding-bottom: 1.5vh;
}

.project_overview_analysisTrend_content {
  width: 100%;
  height: auto;
}

.project_overview_analysisTrend_content_top {
  width: 100%;
  height: 40vh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.project_overview_analysisTrend_content_item {
  background: #ffffff;
  border-radius: 12px;
  width: 32%;
  height: 38vh;
  position: relative;
}

.project_overview_analysisTrend_content_item_header {
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 4%;
  padding-bottom: 4%;
}
.project_overview_analysisTrend_content_item_header_color {
  width: 4px;
  height: 2vh;
  font-size: 14px;
  background: #4e57aa;
}
.project_overview_analysisTrend_content_item_header_text {
  margin-left: 0.4vw;
  font-size: 14px;
  line-height: 3vh;
}

.echarts-box {
  width: 100%;
  height: 73%;
  position: absolute;
  top: 20%;
  left: 0;
  overflow: hidden;
  .box {
    width: 100%;
    height: 100%;
  }
}

.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
  width: auto;
}
.el-range-editor--small.el-input__inner {
  // display: flex;
  // align-items: center;
  height: 4.8vh;
  padding-right: 0;
  // padding: calc(10vw / 19.2);
  /deep/.el-icon-date {
    width: 1.3vw;
    height: 4.8vh;
    line-height: 4.8vh;
    font-size: calc(14vw / 19.2);
  }
  /deep/.el-range-input {
    width: 43%;
    line-height: 4.8vh;
    vertical-align: middle;
    font-size: calc(14vw / 19.2);
  }
  /deep/.el-range-separator {
    // height: 4.8vh;
    // vertical-align: middle;
    // line-height: 40px;
    // line-height: none;
    width: 1vw;
    padding: 0 calc(2vw / 19.2);
    height: 4.8vh;
    line-height: 4.8vh;
    font-size: calc(13vw / 19.2);
  }
  /deep/.el-range__close-icon {
    width: 1.3vw;
    height: 4.8vh;
    line-height: 4.8vh;
    font-size: calc(13vw / 19.2);
  }
}

::-webkit-scrollbar {
  width: 3px;
}
::-webkit-scrollbar-track {
  border-radius: 8px;
  width: 8px;
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #bbb;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #eee;
}
.project_overview_periphery_right_header_right {
  display: flex;
  align-items: center;
}
.project_overview_periphery_right_header_right span {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
}

.pageTitle {
  font-family: PingFangSC;
  font-size: 22px;
  color: #000;
  margin: 20px 0;
}

.projectheader {
  width: 100%;
  height: 200px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.proBack {
  position: absolute;
  top: 0;
  left: 0;
  height: 200px;
  width: 100%;
}

.headerLeft {
  margin-left: 60px;
  z-index: 99;
}

.littlePro {
  font-family: PingFangSC;
  font-size: 14px;
  color: #fff;
}

.projectTitle {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: #fff;
}

.proInformation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40%;
  z-index: 99;
  border-right: 1px solid rgba(229, 229, 255, 0.3);
}
.infoItem {
  display: flex;
  align-items: center;
}

.item_info {
  height: 30px;
  font-size: 14px;
  color: #fff;
  margin-left: 9px;
}

.item_info p {
  margin: 0;
}

.infoItem img {
  width: 30px;
  height: 30px;
}

.amountBox {
  height: 148px;
  border-radius: 8px;
  padding: 25px 0 0 30px;
  z-index: 99;
  margin-right: 100px;
}

.amountItem {
  display: flex;
  align-items: center;
}
.amountItem span {
  font-size: 14px;
  color: #fff;
}

.amountItem div {
  width: 50px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #fff;
  border-radius: 12px;
  margin-left: 19px;
}

.topContent {
  margin-bottom: 20px;
}

.infoLine {
  height: 96px;
  width: 1px;
  background: rgba(229, 229, 255, 0.3);
}

.indicatorSelect {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.indicatortitle {
  font-family: PingFangSC;
  font-size: 20px;
  color: #333;
  border-left: 6px solid #4e57aa;
  padding-left: 0.7vw;
  margin-left: 3vh;
  white-space: nowrap;
}

.selectBox {
  display: flex;
  align-items: center;
}

.selectItem {
  margin-left: 16px;
}

.selectItem span {
  margin-right: 13px;
}

.indicatorContent {
  padding: 40px 59px 96px 60px;
  border-radius: 10px;
  background: #fff;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.contentDetail {
}

.contentLeft {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 21px;
}

.detailItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eff4ff;
  padding-bottom: 10px;
}

.titleIcon {
  width: 32px;
  height: 32px;
  margin-right: 6px;
}

.titleIcon img {
  width: 100%;
}

.cautionIcon {
  width: 14px;
  height: 14px;
  cursor: pointer;
  margin-left: 10px;
}

.detailInfo span {
  text-align: left;
  display: inline-block;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.detailInfo {
  justify-content: space-between;
}

.detailInfo,
.detailcon {
  display: flex;
  align-items: center;
}

.detailInfo div {
  padding: 7px;
  background: rgba(78, 87, 170, 0.1);
  border-radius: 7px;
  color: #4e57aa;
}
.InfoBtnF {
  width: 80px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.InfoBtnS {
  width: 50px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.indexInfo {
  font-size: 14px;
  color: #333;
  margin: 0 0 20px 0;
}
// 地图撒点
.detailMap {
  .indicatortitle {
    margin: 20px 0;
  }
  .mapBox {
    width: 100%;
    height: 700px;
    margin-bottom: 40px;
    background: #fff;
  }
}
// 撒点弹框
/deep/.amap-info-content {
  padding: 0;
}
/deep/.textBox {
  min-width: 400px;
  border: 2px solid #ffffff;
  box-shadow: 0px 15px 20px rgba(118, 139, 175, 0.4),
    inset 0px 0px 30px 10px rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(9px);
  border-radius: 16px;
  padding: 20px;
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  li {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: flex-start;
    line-height: 30px;
    p {
      margin: 0;
    }
    span {
      display: inline-block;
    }
  }
}
.font_style {
  font-size: 40px !important;
  font-family: "electronicFont";
}
.color1 {
  color: #4e57aa !important;
}
.color2 {
  color: #23b899 !important;
}
.color3 {
  color: #f89f5b !important;
}
</style>


