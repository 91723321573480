import request from "@/utils/request";

export function examInfoList(startTime,endTime,projectId,pageNum,pageSize) {
  return request({
    url: "/jxzy/examInfo/list",
    method: "post",
    data:{startTime,endTime,projectId},
    params:{pageNum,pageSize}
  });
}

export function examInfoDetail(examInfoId) {
  return request({
    url: "/jxzy/examInfo/detail",
    method: "post",
    data:{examInfoId},
  });
}

export function examUserList(examInfoId,isPass,pageNum,pageSize) {
  return request({
    url: "/jxzy/examUser/list",
    method: "post",
    data: { examInfoId,isPass },
    params:{pageNum,pageSize}
  });
}

export function projectList() {
  return request({
    url: "/jxzy/project/list",
    method: "post",
  });
}