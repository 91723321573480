<template>
  <div class="speechAnalysis">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/wordsAnalyze' }"
          >话术分析</el-breadcrumb-item
        >
        <el-breadcrumb-item>数据导入/导出</el-breadcrumb-item>
      </el-breadcrumb>
      <!-- <div class="items">
        <span class="marginRight2vw">上海布点项目20210201</span>
        <span>时间：2020/12/01-2021/06/01</span>
      </div> -->
    </div>
    <div class="main">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="数据导入" name="first">
                <div class="title">
                    <div class="left">
                    <i class="sep"></i>
                    <h4>数据导入</h4>
                    </div>
                </div>
                    <div class="marginTop inputBox">
                        <div class="inputBoxCol marginRight1vw" style="width: 20%">
                            <span class="selectName">项目：</span>
                            <!-- <el-input class="input" v-model="formData.projectName"></el-input> -->
                            <el-select v-model="projectvalue" value-key="projectId" @change="handleProject" clearable filterable>
                                <el-option
                                v-for="item in projectMenu"
                                :key="item.projectId"
                                :value="item"
                                :label="item.projectName"
                                >
                                </el-option>
                            </el-select>
                        </div>
                        <div class="inputBoxCol marginRight1vw" style="width: 20%">
                            <span class="selectName">导入内容：</span>
                            <!-- <el-input class="input" v-model="formData.spaceName"></el-input> -->
                            <el-select v-model="statusvalue" value-key="statusCode" @change="handleCheck" clearable filterable>
                                <el-option
                                v-for="item in exportConList"
                                :key="item.statusCode"
                                :value="item"
                                :label="item.statusValue"
                                >
                                </el-option>
                            </el-select>
                        </div>
                        <div class="inputBoxCol marginRight1vw">
                            <span class="selectName">日期：</span>
                            <el-date-picker
                                v-model="exportDate"
                                type="daterange"
                                @change="handleDateTime"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                            </el-date-picker>
                        </div>
                    <div>
                      <el-button v-if="isShow" @click="handleUpload" class="searchBtn export">开始导入</el-button>
                      <el-upload
                        v-else
                        class="upload-demo"
                        action=""
                        :show-file-list="false"
                        :http-request="onImport"
                        accept=".xlsx,.xls">
                        <el-button :loading="uploadLoading" class="searchBtn export">开始导入</el-button>
                      </el-upload>
                </div>
                </div>
                <el-row :gutter="10">
                    <el-table
                       height="500"
                      :loading="importloading"
                      :data="dataExportTable"
                        style="width: 100%">
                      <el-table-column
                        label="序号"
                        prop="rowNum">
                      </el-table-column>
                      <el-table-column
                        label="项目标识"
                        prop="importTaskId">
                      </el-table-column>
                      <el-table-column
                        label="项目名称"
                        prop="projectName">
                      </el-table-column>
                      <el-table-column
                        label="表格标题"
                        prop="title"
                        width="180">
                         <template slot-scope="scope">
                            <el-popover trigger="hover" placement="top">
                              <p>{{scope.row.title}}</p>
                              <div slot="reference" class="name-wrapper">
                                <el-tag size="medium">查看标题</el-tag>
                              </div>
                            </el-popover>
                         </template>
                      </el-table-column>
                      <el-table-column
                        label="任务状态">
                        <template slot-scope="scope">
                          <span :style="{color:(scope.row.statusName == '已完成' ? '#67C23A' : (scope.row.statusName == '进行中' ? '#E6A23C' : '#F56C6C'))}">{{scope.row.statusName}}</span>
                         </template>
                      </el-table-column>
                      <el-table-column
                        label="导入文件类型"
                        prop="fileTypeName">
                      </el-table-column>
                      <el-table-column
                        label="统计开始日期"
                        prop="startDate">
                      </el-table-column>
                      <el-table-column
                        label="统计结束日期"
                        prop="endDate">
                      </el-table-column>
                      <el-table-column
                        label="创建时间"
                        prop="createDate">
                      </el-table-column>
                      <el-table-column
                        label="创建人"
                        prop="createByName">
                      </el-table-column>
                    </el-table>
                </el-row>
                <div class="block" style="margin-top: 50px">
                  <el-pagination
                    background
                    align="center"
                    @size-change="sizeChange"
                    @current-change="currentChange"
                    :current-page="pageData.pageNum"
                    :page-sizes="[10, 50, 100]"
                    :page-size="pageData.pageSize"
                    layout="sizes, prev, pager, next"
                    :total="pageData.total"
                  >
                  </el-pagination>
                </div>
            </el-tab-pane>
            <el-tab-pane label="数据导出" name="second">
                <div class="title">
                    <div class="left">
                    <i class="sep"></i>
                    <h4>数据导出</h4>
                    </div>
                </div>
                    <div class="marginTop inputBox">
                        <div class="inputBoxCol marginRight1vw" style="width: 20%">
                            <span class="selectName">项目：</span>
                            <el-select v-model="exportprojectvalue" value-key="projectId" @change="handleexportProject" clearable filterable>
                                <el-option
                                v-for="item in projectMenu"
                                :key="item.projectId"
                                :value="item"
                                :label="item.projectName"
                                >
                                </el-option>
                            </el-select>
                        </div>
                        <div class="inputBoxCol marginRight1vw" style="width: 20%">
                            <span class="selectName">表格标题：</span>
                            <!-- <el-input class="input" v-model="formData.spaceName"></el-input> -->
                            <el-select v-model="exportStatusvalue" value-key="statusCode" @change="handlexportCheck" clearable filterable>
                                <el-option
                                v-for="item in exportTitleList"
                                :key="item.statusCode"
                                :value="item"
                                :label="item.statusValue"
                                >
                                </el-option>
                            </el-select>
                        </div>
                        <div class="inputBoxCol marginRight1vw">
                            <span class="selectName">日期：</span>
                            <el-date-picker
                                v-model="uploadingDate"
                                type="daterange"
                                @change="handlexportDateTime"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                            </el-date-picker>
                        </div>
                    <div>
                    <el-button class="searchBtn" :loading="btnexportLoading" @click="onExport">生成导出记录</el-button>
                </div>
                </div>
                <el-row :gutter="10">
                    <el-table
                       height="500"
                      :loading="exportTableLoading"
                      :data="exportTable"
                        style="width: 100%">
                      <el-table-column
                        label="序号"
                        prop="rowNum">
                      </el-table-column>
                      <el-table-column
                        label="项目名称"
                        prop="projectName">
                      </el-table-column>
                      <el-table-column
                        label="表格标题"
                        prop="title"
                        width="180">
                         <template slot-scope="scope">
                            <el-popover trigger="hover" placement="top">
                              <p>{{scope.row.title}}</p>
                              <div slot="reference" class="name-wrapper">
                                <el-tag size="medium">查看标题</el-tag>
                              </div>
                            </el-popover>
                         </template>
                      </el-table-column>
                      <el-table-column
                        label="任务状态">
                        <template slot-scope="scope">
                          <span :style="{color:(scope.row.statusName == '已完成' ? '#67C23A' : (scope.row.statusName == '进行中' ? '#E6A23C' : '#F56C6C'))}">{{scope.row.statusName}}</span>
                         </template>
                      </el-table-column>
                      <el-table-column
                        label="导入文件类型"
                        prop="fileTypeName">
                      </el-table-column>
                      <el-table-column
                        label="信息"
                        prop="message">
                      </el-table-column>
                      <el-table-column
                        label="统计开始日期"
                        prop="startDate">
                      </el-table-column>
                      <el-table-column
                        label="统计结束日期"
                        prop="endDate">
                      </el-table-column>
                      <el-table-column
                        label="创建时间"
                        prop="createDate">
                      </el-table-column>
                      <el-table-column
                        label="创建人"
                        prop="createByName">
                      </el-table-column>
                      <el-table-column
                        label="操作"
                        width="200">
                        <template slot-scope="scope">
                          <el-button
                            @click="handleDownload(scope.row)"
                            :disabled="!scope.row.fileUrl"
                            class="searchBtn"
                            style="margin:0"
                            >下载</el-button
                          >
                          <el-button
                            v-if="scope.row.statusName == '失败'"
                            @click="handleMessage(scope.row.message)"
                            class="searchBtn"
                            style="margin:0 0 0 10px"
                            >失败信息</el-button
                          >
                        </template>
                      </el-table-column>
                    </el-table>
                </el-row>
                <div class="block" style="margin-top: 50px">
                  <el-pagination
                    background
                    align="center"
                    @size-change="exportsizeChange"
                    @current-change="exportcurrentChange"
                    :current-page="exportPageData.pageNum"
                    :page-sizes="[10, 50, 100]"
                    :page-size="exportPageData.pageSize"
                    layout="sizes, prev, pager, next"
                    :total="exportPageData.total"
                  >
                  </el-pagination>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
  </div>
</template>
<script>
import {
  getImportTask,
  getDicOptions,
  getimportTaskSave,
  getExportTask,
  getExportTaskInsert,
} from "@/api/dataAnalysis/project";
import {
  projectList,
} from "@/api/dataAnalysis/staff";
import TableChart from "../table";
import axios from "axios";
export default {
  components: {
    TableChart,
  },
  data() {
    return {
      importloading:false,
      statusvalue:{},
      exportStatusvalue:{},
      projectvalue:{},
      exportprojectvalue:{},
      uploadLoading:false,
      isShow:true,
      exportDate:[],
      uploadingDate:[],
      activeName:'first',
      projectMenu: [],
      exportConList:[],
      formData: {
        projectId:"",
        projectName: "",
        statusCode:"",
        statusName:"",
        startDate:"",
        endDate:"",
        importTaskId:""
      },
      exportformData: {
        projectId:"",
        projectName: "",
        statusCode:"",
        statusName:"",
        startDate:"",
        endDate:"",
      },
      exportTableLoading:false,
      exportTable:[],
      dataExportTable: [],
      pageData: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      exportPageData: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      btnexportLoading: false,
      path:"",
      dataExportTable:false,
      exportTitleList:[]
    };
  },
  created() {
    this.getTableData()
    this.getExportList()
  },
  mounted () {
    projectList().then((res) => {
      this.projectMenu = res.data;
    });
    getDicOptions({setCode:'IMPORT_TYPE'}).then((res) => {
      this.exportConList = res.data;
    })
    getDicOptions({setCode:'EXPORT_TYPE'}).then((res) => {
      this.exportTitleList = res.data;
    })
  },
  methods: {
    handleClick(val){
      this.getTableData()
      this.getExportList()
    },
    getTableData() {
      this.importloading = true;
      getImportTask(
        this.pageData.pageNum,
        this.pageData.pageSize
      ).then((res) => {
        this.importloading = false;
        this.dataExportTable = res.rows;
        this.pageData.total = res.total;
      });
    },
    getExportList(){
      this.exportTableLoading = true;
      getExportTask(
        this.exportPageData.pageNum,
        this.exportPageData.pageSize
      ).then((res) => {
        this.exportTableLoading = false;
        this.exportTable = res.rows;
        this.exportPageData.total = res.total;
      });
    },
    handleexportProject(val){
      this.exportformData.projectId = val.projectId
      this.exportformData.projectName = val.projectName
    },
    handleProject(val){
      this.formData.projectId = val.projectId
      this.formData.projectName = val.projectName
    },
    handleCheck(val){
      this.formData.statusCode = val.statusCode
      this.formData.statusName = val.statusValue
      this.isShow = typeof(val) != 'string' ? false : true
      this.path = val.statusCode == 'IMP_SFA' ? '/report-api/sfa/import' : '/report-api/sales/import'
    },
    handlexportCheck(val){
      this.exportformData.statusCode = val.statusCode
      this.exportformData.statusName = val.statusValue
    },
    handlexportDateTime(val){
      this.exportformData.startDate = val[0]
      this.exportformData.endDate = val[1]
    },
    handleDateTime(val){
      this.formData.startDate = val[0]
      this.formData.endDate = val[1]
    },
    handleUpload(){
      if(this.formData.projectId == ''){
        this.$message({
          message: '请选择项目！',
          type: "warning",
        });
      }else{
        if(this.exportDate == []){
          this.$message({
            message: '请选择时间段！',
            type: "warning",
          });
        }else{
          if(this.formData.statusCode == ''){
            this.$message({
              message: '请选择导入内容！',
              type: "warning",
            });
          }
        }
      }
    },
    onImport(data) {
      let formData = new FormData();
      formData.append("file", data.file);
      this.uploadLoading = true
      axios.post(this.path,formData).then(res=>{
        if (res.data.code == 200) {
          this.formData.importTaskId = res.data.data.importTaskId
          getimportTaskSave(this.formData).then(res=>{
            this.uploadLoading = false
            if(res.code == 200){
              this.$message({
                message: res.msg,
                type: "success",
              });
            }
          })
        }else{
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
        this.getTableData()
      })
    },
    onExport(){
      if(this.exportformData.projectId == ''){
        this.$message({
          message: '请选择项目！',
          type: "warning",
        });
      }else{
        if(this.uploadingDate == []){
          this.$message({
            message: '请选择时间段！',
            type: "warning",
          });
        }else{
          if(this.exportformData.statusCode == ''){
            this.$message({
              message: '请选择表格标题！',
              type: "warning",
            });
          }else{
            getExportTaskInsert(this.exportformData).then((res) => {
              if(res.code == 200){
                this.$message({
                  message: res.msg,
                  type: "success",
                });
              }
              this.getExportList()
            })
          }
        }
      }
    },
    currentChange(val) {
      this.pageData.pageNum = val;
      this.getTableData();
    },
    sizeChange(val) {
      this.pageData.pageNum = 1;
      this.pageData.pageSize = val;
      this.getTableData();
    },
    exportcurrentChange(val) {
      this.exportpageData.pageNum = val;
      this.getExportList();
    },
    exportsizeChange(val) {
      this.exportpageData.pageNum = 1;
      this.exportpageData.pageSize = val;
      this.getExportList();
    },
    handleDownload(row){
      window.location.href = row.fileUrl
    },
    handleMessage(msg){
      this.$message(msg)
    },
  },
};
</script>

<style lang="scss" scoped>
$baseColor: #4e57aa;
$white: #fff;
$fontColor: #333;
$fontSize: 1.2em;
.speechAnalysis {
  margin: 2vh 1vw;
  .breadcrumb {
    .items {
      font-size: 14px;
    }
  }
  .marginTop {
    margin-top: 2vh;
    margin-bottom: 2vh;
  }
  .title {
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;
      .sep {
        background: $baseColor;
        width: 5px;
        height: 1.5rem;
        display: inline-block;
        margin: 0 5px;
      }
      h4 {
        padding: 0;
        margin: 0;
      }
      span {
        margin-left: 1.1vw;
        font-size: 14px;
        font-weight: 600;
        color: #4e57aa;
        line-height: 1.5rem;
      }
    }
    .right {
      width: 3.24vh;
      height: 3.24vh;
      background: rgba(78, 87, 170, 0.1);
      border-radius: 8px;
      text-align: center;
      line-height: 3.24vh;
      font-size: 2.5vh;
      cursor: pointer;
    }
  }
  .main {
    background: #fff;
    padding: 2vh 1vw;
    margin-top: 2vh;
    border-radius: 2vh;
    height: 100%;
    min-height: 75vh;
    // .title {
    //   display: flex;
    //   justify-content: space-between;
    //   .left {
    //     display: flex;
    //     .sep {
    //       background: $baseColor;
    //       width: 5px;
    //       height: 1.5rem;
    //       display: inline-block;
    //       margin: 0 5px;
    //     }
    //     h4 {
    //       padding: 0;
    //       margin: 0;
    //     }
    //   }
    // }
    .inputBox {
      display: flex;
      position: relative;
      .fr {
        position: absolute;
        right: 0;
        // float: right;
        // margin-left: 10%;
        // order: 1;
        // align-self: flex-end;
      }
      .inputBoxCol {
          white-space: nowrap;
        // flex:1;
        /deep/.el-select {
          width: 70%;
          // width: 9vw;
        }
        .input {
          width: 70%;
        }
        .el-date-editor {
          /* width: 100%; */
          // padding: 0 0.5vw;
          padding-left: 0.5vw;
          padding-right: 0.2vw;
        }
        // .el-date-picker {
        //   // width: 15vw;
        // }
      }
    }
    .selectName {
      // width: 30%;
      // width: 7vh;
      font-size: 14px;
      display: inline-block;
      color: $fontColor;
      // text-align: justify;
      // text-align-last: justify;
    }
    .parent {
      position: relative;
      height: 32px;
      // margin: 0 5vw 0 1vw;
      .searchInput {
        position: absolute;
        right: 0;
        height: 100%;
        text-align: center;
        background: $baseColor;
        width: 2vw;
        right: 0px;
        top: 0;
        border-radius: 0 5px 5px 0;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .searchBtn {
      color: $white;
      background-color: $baseColor;
      margin-right: 1.5vw;
    }
  }
  .popTable {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.29);
    z-index: 100;
    .popContent {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 82.3%;
      height: 80vh;
      padding: 3.3vh 1.4vw;
      background: #fff;
      border-radius: 25px;
      box-sizing: border-box;
      .popDate {
        margin: 3.3vh 0 2.8vh 0;
        /deep/.el-input__inner {
          width: 100%;
        }
      }
      /deep/.el-table {
        height: 50vh !important;
      }
      /deep/.block {
        margin-top: 4.6vh !important;
      }
    }
  }
}

.table {
  margin-top: 2vh;
  .basicBtn {
    background: rgba(78, 87, 170, 0.1);
    color: #4e57aa;
  }
}
/deep/.el-table th > .cell {
  text-align: center;
}

/deep/.el-table .cell {
  text-align: center;
}
/deep/ {
  .el-table {
    tbody tr {
      &:hover {
        td {
          cursor: pointer;
        }
      }
    }
  }
}
</style>