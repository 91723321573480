<template>
  <div class="supervision">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/inspection' }"
          >云巡检</el-breadcrumb-item
        >
        <el-breadcrumb-item>预警日志</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main">
      <div class="title">
        <div class="left">
          <i class="sep"></i>
          <h4>预警日志</h4>
        </div>
      </div>
      <div class="form-content marginTop">
        <div class="select-date form-item">
          <el-date-picker
            v-model="date"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <div class="form-item">
          <span class="selectName">职称：</span>
          <el-select
            v-model="formData.roleName"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in roleMenu"
              :key="item.roleName"
              :value="item.roleName"
            >
            </el-option>
          </el-select>
        </div>
        <div class="form-item">
          <span class="selectName">项目：</span>
          <el-select
            v-model="formData.projectName"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in projectMenu"
              :key="item.projectName"
              :value="item.projectName"
            >
            </el-option>
          </el-select>
        </div>
        <div class="form-item">
          <span class="selectName">预警类型：</span>
          <el-select
            v-model="formData.warningTypeName"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in warnTypeMenu"
              :key="item.statusValue"
              :value="item.statusValue"
            >
            </el-option>
          </el-select>
        </div>
        <div class="form-item fr">
          <el-button class="searchBtn" @click="onInquire">查询</el-button>
          <el-button @click="onReset">重置</el-button>
        </div>
      </div>
      <el-row>
        <el-button class="export">导出</el-button>
      </el-row>
      <el-row :gutter="10">
        <TableChart
          :tableDatas="{
            id: 'alertLog',
            data: alertLogTable,
          }"
          :pageData="pageData"
          @changePage="currentChange"
          @changeSize="sizeChange"
        ></TableChart>
      </el-row>
    </div>
  </div>
</template>
<script>
import TableChart from "../table";
import { mapState, mapActions } from "vuex";
// import { getTjSession } from "@/api/inspection/promoter";
import { logOptions, warningLogList } from "@/api/inspection/log";
export default {
  computed: {
    // ...mapState("supervision", ["supervisionTable"]),
  },
  components: {
    TableChart,
  },
  data() {
    return {
      // supervisionTable: [],
      alertLogTable: {
        size: "",
        loading: false,
        columns: [
          {
            col: "序号",
            prop: "rowNum",
            width: "",
          },
          {
            col: "人员名称",
            prop: "userName",
            width: "",
          },
          {
            col: "职称",
            prop: "roleName",
            width: "",
          },
          {
            col: "预警类型",
            prop: "warningTypeName",
            width: "",
          },
          {
            col: "项目名称",
            prop: "projectName",
            width: "",
          },
          {
            col: "项目周期",
            prop: "projectTime",
            width: "",
          },

          {
            col: "开始时间",
            prop: "warningStartTime",
            width: "",
          },
          {
            col: "结束时间",
            prop: "warningEndTime",
            width: "",
          },
          {
            col: "累计预警时长",
            prop: "warningTime",
            width: "",
          },
        ],
        data: [],
      },
      pageData: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      date: [],
      formData: {
        roleName: "",
        warningTypeName: "",
        projectName: "",
      },
      roleMenu: [],
      projectMenu: [],
      warnTypeMenu: [],
    };
  },
  created() {
    logOptions().then((res) => {
      this.warnTypeMenu = res.data.warningTypeOptions;
      this.projectMenu = res.data.projects;
      this.roleMenu = res.data.roles;
    });
    this.getTableData();
  },
  methods: {
    getTableData() {
      this.alertLogTable.loading = true;
      let data = { ...this.formData };
      data.startDate = this.date[0];
      data.endDate = this.date[1];
      warningLogList(data, this.pageData.pageNum, this.pageData.pageSize).then(
        (res) => {
          this.alertLogTable.loading = false;
          this.alertLogTable.data = res.rows;
          this.pageData.total = res.total;
        }
      );
    },
    onInquire() {
      this.pageData.pageNum = 1;
      this.getTableData();
    },
    onReset() {
      this.date = [];
      this.formData.roleName = "";
      this.formData.projectName = "";
      this.formData.warningTypeName = "";
    },
    currentChange(val) {
      this.pageData.pageNum = val;
      this.getTableData();
    },
    sizeChange(val) {
      this.pageData.pageNum = 1;
      this.pageData.pageSize = val;
      this.getTableData();
    },
  },
};
</script>

<style lang="scss" scoped>
$baseColor: #4e57aa;
$white: #fff;
$fontColor: #333;
$fontSize: 1.2em;
.supervision {
  margin: 2vh 1.5vw;
  .marginTop {
    margin-top: 2vh;
  }
  .main {
    background: #fff;
    padding: 2vh 1vw;
    margin-top: 2vh;
    border-radius: 2vh;
    height: 100%;
    min-height: 75vh;
    .form-content {
      display: flex;
      flex-wrap: wrap;
      .form-item {
        margin-right: 1.5625vw;
        margin-bottom: 1.7vh;
        .el-select {
          width: 10.9375vw;
        }
      }
    }
    .export {
      float: right;
      color: #fff;
      background: #4e57aa;
    }
    // /deep/.el-select {
    //   width: 74%;
    // }
    /deep/.el-cascader {
      width: 74%;
    }
    .select-date {
      .el-input__inner {
        width: 13vw;
        padding-right: 0;
      }
    }
    .title {
      display: flex;
      justify-content: space-between;
      .left {
        display: flex;
        .sep {
          background: $baseColor;
          width: 5px;
          height: 1.5rem;
          display: inline-block;
          margin: 0 5px;
        }
        h4 {
          padding: 0;
          margin: 0;
        }
      }
    }
    .fr {
      float: right;
      width: auto;
      margin-left: 0;
    }
    .selectName {
      // width: 26%;
      font-size: 14px;
      display: inline-block;
      color: $fontColor;
      // text-align: left;
      // text-align-last: justify;
    }
    .indent2 {
      padding-right: 2em;
    }
    .parent {
      position: relative;
      .searchInput {
        position: absolute;
        right: 0;
        height: 100%;
        text-align: center;
        background: $baseColor;
        width: 2vw;
        right: 0px;
        top: 0;
        border-radius: 0 5px 5px 0;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .searchBtn {
      color: $white;
      background-color: $baseColor;
    }
  }
}
</style>