import request from "@/utils/request";

export function logOptions() {
  return request({
    url: "/jxzy/warningLog/options",
    method: "post",
  });
}

export function warningLogList(data,pageNum,pageSize) {
  return request({
    url: "/jxzy/warningLog/list",
    method: "post",
    data,
    params:{pageNum,pageSize}
  });
}