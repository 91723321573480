<template>
  <div class="speechAnalysis">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/infoManage' }"
          >基础信息管理</el-breadcrumb-item
        >
        <el-breadcrumb-item>热词管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main">
      <div class="title">
        <div class="left">
          <i class="sep"></i>
          <h4>热词管理</h4>
        </div>
      </div>
      <div class="form-content marginTop">
        <div class="form-item">
          <span class="selectName">客户：</span>
          <el-select v-model="formData.customerId" clearable filterable>
            <el-option
              v-for="item in cusMenu"
              :key="item.customerId"
              :label="item.customerName"
              :value="item.customerId"
            >
            </el-option>
          </el-select>
        </div>
        <div class="form-item">
          <span class="selectName">热词来源：</span>
          <el-select v-model="formData.contentSource" clearable filterable>
            <el-option
              v-for="item in sourceMenu"
              :key="item.statusCode"
              :label="item.statusValue"
              :value="item.statusCode"
            >
            </el-option>
          </el-select>
        </div>
        <div class="form-item">
          <el-button class="searchBtn" @click="onInquire">查询</el-button>
          <el-button @click="onReset">重置</el-button>
          <el-upload
            class="upload"
            action=""
            :show-file-list="false"
            :http-request="onImport"
            accept=".xlsx,.xls"
          >
            <el-button class="searchBtn">导入</el-button>
          </el-upload>
          <!-- <el-button class="searchBtn" @click="onImport">导入</el-button> -->
        </div>
      </div>
      <div style="display: flex; justify-content: flex-end; margin-top: 2vh">
        <el-button @click="onDownload">下载模板</el-button>
        <el-button
          type="primary"
          style="background: #4e57aa; border-color: #4e57aa"
          @click="add"
          >新增</el-button
        >
      </div>

      <el-row :gutter="10">
        <TableChart
          :tableDatas="{
            id: 'hotwords',
            data: hotwordsTable,
          }"
          :pageData="pageData"
          @changePage="currentChange"
          @changeSize="sizeChange"
        ></TableChart>
      </el-row>
    </div>
  </div>
</template>
<script>
import TableChart from "../table";
// import { mapState, mapActions } from "vuex";
import {
  hotWordList,
  hotWord2ListPage,
  fileImport,
  download,
} from "@/api/infoManage/hotword";

export default {
  computed: {
    // ...mapState("speechAnalysis", ["jurisdiction"]),
  },
  components: {
    TableChart,
  },
  data() {
    return {
      cusMenu: [],
      sourceMenu: [],
      hotwordsTable: {
        size: "",
        loading: false,
        columns: [
          // {
          //   col: "序号",
          //   prop: "hotWordId",
          //   width: "",
          // },
          {
            col: "客户",
            prop: "customerName",
            width: "",
          },
          {
            col: "热词",
            prop: "content",
            width: "",
          },
          {
            col: "热词来源",
            prop: "contentSourceName",
            width: "",
          },
          {
            col: "更新时间",
            prop: "updateDate",
            width: "",
          },
        ],
        data: [],
      },
      pageData: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      formData: {
        customerId: "",
        contentSource: "",
      },
    };
  },
  created() {
    // hotWord2ListPage().then((res) => {
    //   this.cusMenu = res.data.customerInfos;
    //   this.sourceMenu = res.data.contentSourceOptions;
    // });
    // this.getTableData();
  },
  methods: {
    getTableData() {
      this.hotwordsTable.loading = true;
      hotWordList(
        this.formData,
        this.pageData.pageNum,
        this.pageData.pageSize
      ).then((res) => {
        this.hotwordsTable.loading = false;
        this.hotwordsTable.data = res.rows;
        // this.getSortRows(this.hotwordsTable.data);
        this.pageData.total = res.total;
      });
    },
    onInquire() {
      this.pageData.pageNum = 1;
      this.getTableData();
    },
    onReset() {
      this.formData.customerId = "";
      this.formData.contentSource = "";
    },
    onImport(data) {
      let formData = new FormData();
      formData.append("file", data.file);
      fileImport(formData).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.msg,
            type: "success",
          });
        }
      });
    },
    onDownload() {
      download().then((res) => {
        let blob = new Blob([res], { type: `text/plain;charset=utf-8` });
        let downloadElement = document.createElement("a");
        let href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = "热词导入模板.xls";
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      });
    },
    currentChange(val) {
      this.pageData.pageNum = val;
      this.getTableData();
    },
    sizeChange(val) {
      this.pageData.pageNum = 1;
      this.pageData.pageSize = val;
      this.getTableData();
    },
    add() {
      this.$router.push("/infoManage/hotwords/hotWordSet");
    },
    // getSortRows(rows) {
    //   rows.sort(function (a, b) {
    //     return a.hotWordId - b.hotWordId;
    //   });
    // },
  },
  // activated() {
  //   console.log("activated");
  //   this.getTableData();
  // },
  beforeRouteEnter(to, from, next) {
    if (from.name === "hotWordSet") {
      next((vm) => {
        vm.getTableData();
      });
    } else {
      next((vm) => {
        hotWord2ListPage().then((res) => {
          vm.cusMenu = res.data.customerInfos;
          vm.sourceMenu = res.data.contentSourceOptions;
        });
        vm.getTableData();
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== "hotWordSet") {
      from.meta.keepAlive = false;
      next();
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
$baseColor: #4e57aa;
$white: #fff;
$fontColor: #333;
$fontSize: 1.2em;
.speechAnalysis {
  margin: 2vh 1.5vw;
  .marginTop {
    margin-top: 2vh;
  }
  .main {
    background: #fff;
    padding: 2vh 1vw;
    margin-top: 2vh;
    border-radius: 2vh;
    height: 100%;
    min-height: 75vh;
    .form-content {
      display: flex;
      flex-wrap: wrap;
      .form-item {
        margin-right: 1.5625vw;
        margin-bottom: 1.7vh;
        .el-select {
          width: 10.9375vw;
        }
        .el-input {
          width: 10.9375vw;
        }
        .upload {
          display: inline-block;
          margin-left: 10px;
        }
      }
    }
    // /deep/.input {
    //   width: 74%;
    // }
    // /deep/.el-select {
    //   width: 74%;
    // }
    .title {
      display: flex;
      justify-content: space-between;
      .left {
        display: flex;
        .sep {
          background: $baseColor;
          width: 5px;
          height: 1.5rem;
          display: inline-block;
          margin: 0 5px;
        }
        h4 {
          padding: 0;
          margin: 0;
        }
      }
    }
    .fr {
      float: left;
      width: auto;
      margin-left: 0;
    }
    .selectName {
      // width: 10vh;
      font-size: 14px;
      display: inline-block;
      color: $fontColor;
      // text-align: justify;
      // text-align-last: justify;
    }
    .indent2 {
      padding-right: 1em;
    }
    .parent {
      position: relative;
      .searchInput {
        position: absolute;
        right: 0;
        height: 100%;
        text-align: center;
        background: $baseColor;
        width: 2vw;
        right: 0px;
        top: 0;
        border-radius: 0 5px 5px 0;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .searchBtn {
      color: $white;
      background-color: $baseColor;
      border-color: $baseColor;
    }
  }
}
</style>