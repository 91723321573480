<template>
  <div class="line-chart" ref="lineChart"></div>
</template>

<script>
import resize from "../../../pc/mixin/echartsResize";

export default {
  data() {
    return {
      chart: null,
    };
  },
  mixins: [resize],
  props: ["lineData"],
  watch: {
    // lineData: {
    //   // // console.log(this.lineData);
    //   handler: function (val, newval) {
    //     console.log(val, this);
    //     this.initChart();
    //   },
    //   deep: true,
    //   immediate: true,
    // },
    lineData() {
      this.initChart();
    },
  },
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      this.chart = this.$echarts.init(this.$refs.lineChart);
      let warningUserCount = this.lineData.map((item) => item.warningUserCount);
      let warningAver = this.lineData.map((item) => item.warningAver);
      let averageTime = this.lineData.map((item) => item.averageTime);
      const option = {
        tooltip: {
          trigger: "axis",
          // formatter: '{b}<br />{a0}: {c0}<br />{a1}: {c1}'
          // // formatter: function (params) {
          // //     return  params[1].value + '%';
          // // }
        },
        color: ["#FF991F", "#4E57AA"],
        legend: {
          data: ["预警人数", "预警率"],
          left: "1%",
          itemGap: 50,
        },
        grid: {
          left: "2%",
          //   top: "14%",
          right: "2%",
          bottom: 0,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: averageTime,
          // data: [
          //   "10:00",
          //   "10:30",
          //   "11:00",
          //   "11:30",
          //   "12:00",
          //   "12:30",
          //   "13:00",
          //   "13:30",
          //   "14:00",
          //   "14:30",
          //   "15:00",
          //   "15:30",
          //   "16:00",
          //   "16:30",
          //   "17:00",
          // ],
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            margin: 20,
          },
        },
        yAxis: [
          {
            name: "预警人数",
            type: "value",
            // max: 10,
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              margin: 30,
            },
          },
          {
            name: "预警率",
            // minInterval: 10,
            // interval: 20,
            // max: 100,
            type: "value",
            axisLabel: {
              formatter: function (val) {
                return val == 0 ? 0 : val + "%";
              },
              margin: 30,
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "预警人数",
            type: "line",
            // showSymbol: false,
            symbolSize: 10,
            symbol: "circle",
            data: warningUserCount,
          },
          {
            name: "预警率",
            type: "line",
            yAxisIndex: 1,
            symbol: "circle",
            // showSymbol: false,
            symbolSize: 10,
            // tooltip: {
            //   formatter: "{b}: {c}+'%'",
            // },
            data: warningAver,
          },
        ],
      };
      this.chart.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
