import request from "@/utils/request";

export function roleList(data,pageNum,pageSize) {
  return request({
    url: "/jxzy/role/list",
    method: "post",
    data,
    params:{pageNum,pageSize}
  });
}

export function roleDetail(roleId) {
  return request({
    url: "/jxzy/role/detail",
    method: "post",
    data:{roleId},
  });
}

export function toInsertPage() {
  return request({
    url: "/jxzy/role/toInsertPage",
    method: "post",
  });
}

export function insert(data) {
  return request({
    url: "/jxzy/role/insert",
    method: "post",
    data
  });
}

export function update(data) {
  return request({
    url: "/jxzy/role/update",
    method: "post",
    data
  });
}

export function getMenuInfo(roleId) {
  return request({
    url: "/jxzy/role/getMenuInfo",
    method: "post",
    data:{roleId}
  });
}

export function saveRoleMenu(roleId,menusArr) {
  return request({
    url: "/jxzy/role/saveRoleMenu",
    method: "post",
    data:{roleId,menusArr}
  });
}




