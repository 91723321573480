import request from "@/utils/request";

export function projectList() {
  return request({
    url: "/jxzy/project/list",
    method: "post",
  });
}

export function getProvince() {
  return request({
    url: "/jxzy/place/getProvince",
    method: "post",
  });
}

export function getProvinceDown(code) {
  return request({
    url: "/jxzy/place/getProvinceDown",
    method: "post",
    data:{code},
  });
}

export function getProvinceEnd(code) {
  return request({
    url: "/jxzy/place/getProvinceEnd",
    method: "post",
    data:{code},
  });
}

export function warningInfoList(data,pageNum,pageSize) {
  return request({
    url: "/jxzy/warningInfo/list",
    method: "post",
    data,
    params:{pageNum,pageSize}
  });
}

export function warningDetail(data) {
  return request({
    url: "/jxzy/warning/detail",
    method: "post",
    data,
  });
}

export function spaceAvg(spaceId,projectId,sdate) {
  return request({
    url: "/jxzy/warning/spaceAvg",
    method: "post",
    data:{spaceId,projectId,sdate},
  });
}

export function projectAvg(projectId,sdate) {
  return request({
    url: "/jxzy/warning/projectAvg",
    method: "post",
    data:{projectId,sdate},
  });
}

export function userAvg(projectId,sdate,userId) {
  return request({
    url: "/jxzy/warning/userAvg",
    method: "post",
    data:{projectId,sdate,userId},
  });
}

export function listExport(data) {
  return request({
    url: "/jxzy/warningInfo/listExport",
    method: "post",
    responseType: "blob",
    data,
  });
}