import request from "@/utils/request";

export function operLogList(startDate,endDate,pageNum,pageSize) {
  return request({
    url: "/jxzy/operLog/list",
    method: "post",
    data:{startDate,endDate},
    params:{pageNum,pageSize}
  });
}

export function logininforList(pageNum,pageSize) {
  return request({
    url: "/jxzy/logininfor/list",
    method: "post",
    params:{pageNum,pageSize}
  });
}