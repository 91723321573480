import request from "@/utils/request";

export function libraryList(data,pageNum,pageSize) {
  return request({
    url: "/jxzy/scriptLibrary/list",
    method: "post",
    data,
    params:{pageNum,pageSize}
  });
}

export function toListPage() {
  return request({
    url: "/jxzy/scriptLibrary/toListPage",
    method: "post",
  });
}

export function categoryList(brandId) {
  return request({
    url: "/jxzy/category/list",
    method: "post",
    data:{brandId}
  });
}

export function waresList(brandId,categoryId) {
  return request({
    url: "/jxzy/wares/list",
    method: "post",
    data:{brandId,categoryId}
  });
}