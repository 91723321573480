<template>
  <div class="box">
    <ve-line
        v-if="show"
        :width="width"
        :height="height"
        :grid="options.grid"
        :xAxis="options.xAxis"
        :yAxis="options.yAxis"
        :series="options.series"
        :legend="options.legend" 
    />
  </div>
</template>

<script>
import VeLine from 'v-charts/lib/line.common'
import 'echarts/lib/component/grid'

const options = {
  grid: {
    show: false,
    left: "2%",
    top: "5%",
    right: "5%",
    bottom: 0,
  },
  legend: {
    show: false,
  },
  xAxis: {
    type: 'category',
    boundaryGap : false,
    data: [],
    splitLine: {
      show: false
    },
    axisLabel: {
      color: '#999999',
      fontSize: 10
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: '#EFF4FF'
      }
    }
  },
  yAxis: {
    show:true,
    type: 'value',
    max:'',
    min:'',
    axisLabel: {       //纵坐标刻度数字
      show: false,
      color:'#999999',
      fontSize: 10
    },
    axisTick: {      //纵坐标刻度线
      show: false
    },
    axisLine: {
      show: false,    //纵坐标竖线
      lineStyle: {
        color: '#999999'
      }
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: '#EFF4FF'   
      }  
    }
  },
  series: [{
    data: [],
    name: '',
    type: 'line',
    symbol: 'circle',
    symbolSize: 3,
    smooth: 0,
    connectNulls: true,
    itemStyle: {
         normal: {
           color: "#4E57AA",//折线点的颜色
           lineStyle: {
           color: "#4E57AA"//折线的颜色
          }
        }
     },
    areaStyle: {
        color: {
            type: 'linear',
            x: 0,
            y: 1,
            x2: 0,
            y2: 0,
            colorStops: [{
                offset: 0, color: '' // 0% 处的颜色
            }, {
                offset: 1, color: '' // 100% 处的颜色
            }],
            global: false // 缺省为 false
        }
    },
    label: {
        normal: {
            show: false,
            position: 'top',
            fontSize: 10,
            color: 'yellow'
        }
    },
    animationEasing: 'linear'
  }]
}

export default {
  name: 'CuLineXs',
  components: {
    VeLine
  },
  data(){
    return{
      show:true,
      timer1:null,
      time2r:null,
    }
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    dataMap: {
      type: Object,
      default: () => ({})
    },
    label: {
      type: Object,
      default: () => ({})
    },
    tooltip: {
      type: Object,
      default: () => ({
        show:false
      })
    },
    height: {
      type: String
    },
    width: {
        type: String
    },
    linecolor: {
        type: String
    },
    gradient: {
      type: Object,
      default: () => ({})
    },
    yAxisShow:{
      type:Object,
      default: () => ({})
    },
    lableShow:{
      type: Boolean
    }
  },
  mounted:function(){
      this.timer1=setInterval(() => {
          this.show = false;
          this.timer2=setTimeout(() => {
              this.show = true;
          }, 50);
      }, 30000);
    },
    beforeDestroy() {
      clearInterval(this.timer1);        
      this.timer1 = null;
      clearTimeout(this.timer2);        
      this.timer2 = null;
    },
  computed: {
    options () {
      const { dataMap } = this
      return {
        ...options,
        xAxis: {
          ...options.xAxis,
          data: this.data.data.map(i => i[dataMap.x])
        },
        yAxis: {
          ...options.yAxis,
          max: this.data.max,
          min: this.data.min,
          show: this.yAxisShow.show,
          axisLabel: {       //纵坐标刻度数字
            show: this.yAxisShow.show,
            color:'#999999',
            fontSize:10
          },
          axisTick: {     //纵坐标刻度线
            show: this.yAxisShow.show
          },
          axisLine: {
            show: this.yAxisShow.show,    //纵坐标竖线
            lineStyle: {
              color: this.yAxisShow.color,
            }
          }
        },
        series: {
          ...options.series[0],
          data: this.data.data.map(i => i[dataMap.y]),
          name: `${this.data.name}${this.tooltip.y}`,
          itemStyle: {
            normal: {
              color: this.gradient.linecolor,//折线点的颜色
              lineStyle: {
                color: this.gradient.linecolor//折线的颜色
              }
            }
          },
          areaStyle: {
              color: {
                  type: 'linear',
                  x: 0,
                  y: 1,
                  x2: 0,
                  y2: 0,
                  colorStops: [{
                      offset: 1, color: this.gradient.top // 0% 处的颜色
                  }, {
                      offset: 0, color: this.gradient.bottom // 100% 处的颜色
                  }],
                  global: false // 缺省为 false
              }
          },
          label: {
            normal: {
                show: false,
                position: 'top',
                fontSize: 10,
                color: '#FFF'
            }
          },
        }
      }
    }
  },
  created:function(){
      
  },
}
</script>
<style>

</style>
