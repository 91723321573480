<template>
  <div class="chart" />
</template>

<script>
import echarts from "echarts";
require("echarts/theme/macarons"); // echarts theme
import resize from "../mixin/echartsResize";

const animationDuration = 3000;

export default {
  mixins: [resize],
  props: {
    id: {
      type: String,
    },
    spaceData: {
      type: Object,
    },
    projectData: {
      type: Object,
    },
    userData: {
      type: Object,
    },
  },
  data() {
    return {
      chart: null,
      color: [],
      indicator: [],
    };
  },
  watch: {
    spaceData() {
      this.initChart();
    },
    projectData() {
      this.initChart();
    },
    userData() {
      this.initChart();
    },
  },
  mounted() {
    // this.$nextTick(() => {
    switch (this.id) {
      case "promoterWarn":
        this.color = ["#4E57AA", "#FE9630", "#22F6F6"];
        this.indicator = [
          { name: "通话时长" },
          { name: "脱岗次数" },
          { name: "脱岗时间" },
          { name: "设备静止次数" },
          { name: "行动步数" },
        ];
        break;
      case "staffToday":
        this.color = ["#58CFFF", "#FFC531", "#F9837D"];
        this.indicator = [
          { name: "通话时长" },
          { name: "脱岗次数" },
          { name: "脱岗时间" },
          { name: "设备静止次数" },
          { name: "行动步数" },
        ];
        break;
    }
    this.initChart();
    // });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, "macarons");
      let spaceAvg = [];
      let projectAvg = [];
      let userAvg = [];

      switch (this.id) {
        case "promoterWarn":
          spaceAvg = [
            0,
            this.spaceData.osc,
            this.spaceData.oscaTime,
            this.spaceData.ssc,
            this.spaceData.step,
          ];
          projectAvg = [
            0,
            this.projectData.osc,
            this.projectData.oscaTime,
            this.projectData.ssc,
            this.projectData.step,
          ];
          userAvg = [
            0,
            this.userData.osc,
            this.userData.oscaTime,
            this.userData.ssc,
            this.userData.step,
          ];
          break;
        case "staffToday":
          spaceAvg = [
            0,
            this.spaceData.odc,
            this.spaceData.odd,
            this.spaceData.ssc,
            this.spaceData.step,
          ];
          projectAvg = [
            0,
            this.projectData.odc,
            this.projectData.odd,
            this.projectData.ssc,
            this.projectData.step,
          ];
          userAvg = [
            0,
            this.userData.odc,
            this.userData.odd,
            this.userData.ssc,
            this.userData.step,
          ];
          break;
      }
      // let spaceAvg = [0, this.spaceData.odc, this.spaceData.step, 0, 0, 0];
      // let projectAvg = [
      //   0,
      //   this.projectData.odc,
      //   this.projectData.step,
      //   0,
      //   0,
      //   0,
      // ];
      // let userAvg = [0, this.userData.odc, this.userData.step, 0, 0, 0];
      // console.log(spaceAvg, projectAvg);
      this.chart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        color: this.color,
        radar: {
          radius: "50%",
          center: ["50%", "50%"],
          splitNumber: 4,
          splitArea: {
            areaStyle: {
              color: "transparent",
              opacity: 1,
              shadowBlur: 45,
              shadowColor: "rgba(0,0,0,.5)",
              shadowOffsetX: 0,
              shadowOffsetY: 15,
            },
          },
          indicator: this.indicator,
          name: {
            textStyle: {
              color: "rgba(0,0,0,0.8)",
            },
          },
          axisLine: {
            lineStyle: {
              color: "#E1E1E1 ", //对角线颜色
              type: "dashed",
            },
          },
          splitLine: {
            lineStyle: {
              color: "#E1E3E9",
            },
          },
        },
        legend: {
          left: "center",
          // top: "bottom",
          bottom: 0,
          icon: "circle",
          itemGap: 20,
          // padding: [20, 5, 5, 5],
          // data: ["个人", "门店人均", "城市", "项目人均"],
        },
        // grid: {
        //   containLabel: true,
        // },
        series: [
          {
            type: "radar",
            symbolSize: 0,
            areaStyle: {
              normal: {
                shadowBlur: 13,
                shadowColor: "rgba(0,0,0,.2)",
                shadowOffsetX: 0,
                shadowOffsetY: 10,
                opacity: 1,
              },
            },
            data: [
              {
                value: userAvg,
                name: "个人",
                areaStyle: {
                  opacity: 0.2,
                  color: "#D3D5EA",
                },
              },
              {
                // value: [4000, 9000, 1500, 15000, 13000, 11000],
                value: spaceAvg,
                name: "门店人均",
                areaStyle: {
                  opacity: 0.2,
                  color: "#FFF0E0",
                },
              },
              // {
              //   value: [5500, 11000, 12000, 15000, 12000, 12000],
              //   name: "城市",
              //   areaStyle: {
              //     opacity: 0.2,
              //     color: "#98D9FA",
              //   },
              // },
              {
                // value: [500, 1000, 15000, 1800, 2900, 1200],
                value: projectAvg,
                name: "项目人均",
                areaStyle: {
                  opacity: 0.2,
                  color: "#C7FDFD",
                },
              },
            ],
            animationDuration: animationDuration,
          },
        ],
      });
    },
  },
};
</script>
