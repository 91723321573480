<template>
  <div class="supervisRule">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/infoManage' }"
          >基础信息管理</el-breadcrumb-item
        >
        <el-breadcrumb-item>督导规则列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main">
      <div class="title">
        <div class="left">
          <i class="sep"></i>
          <h4>督导规则列表</h4>
        </div>
      </div>

      <div style="display: flex; justify-content: flex-end">
        <!-- <el-button
          type="primary"
          icon="el-icon-plus"
          style="background: #4e57aa; border-color: #4e57aa"
          @click="add"
          >新建</el-button
        > -->
        <el-button class="export">导出</el-button>
      </div>

      <el-row :gutter="10">
        <TableChart
          :tableDatas="{
            id: 'supervisRule',
            data: supervisTableData,
          }"
          :pageData="pageData"
          @changePage="currentChange"
          @changeSize="sizeChange"
        ></TableChart>
      </el-row>
    </div>
  </div>
</template>

<script>
import TableChart from "../table";
import { supervisorRoleList } from "@/api/infoManage/rule";

export default {
  data() {
    return {
      supervisTableData: {
        size: "",
        loading: false,
        columns: [
          {
            col: "客户名称",
            prop: "customerName",
            width: "",
          },
          {
            col: "客户编码",
            prop: "customerId",
            width: "",
          },
          {
            col: "是否设置督导规则",
            prop: "isRole",
            width: "",
          },
          {
            col: "更新时间",
            prop: "updateDate",
            width: "",
          },
        ],
        data: [],
      },
      pageData: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  components: {
    TableChart,
  },
  created() {
    this.getTableData();
  },
  methods: {
    getTableData() {
      this.supervisTableData.loading = true;
      supervisorRoleList(this.pageData.pageNum, this.pageData.pageSize).then(
        (res) => {
          this.supervisTableData.loading = false;
          this.supervisTableData.data = res.rows;
          this.pageData.total = res.total;
        }
      );
    },
    currentChange(val) {
      this.pageData.pageNum = val;
      this.getTableData();
    },
    sizeChange(val) {
      this.pageData.pageNum = 1;
      this.pageData.pageSize = val;
      this.getTableData();
    },
    add() {
      this.$router.push("/infoManage/supervisRule/set");
    },
  },
};
</script>

<style lang="scss" scoped>
.supervisRule {
  margin: 2vh 1.5vw;
  .marginTop {
    margin-top: 2vh;
    margin-bottom: 5vh;
  }

  .main {
    background: #fff;
    padding: 2vh 1vw;
    margin-top: 2vh;
    border-radius: 2vh;
    height: 100%;
    min-height: 75vh;
    /deep/.input {
      width: 74%;
    }
    /deep/.el-select {
      width: 74%;
    }
    .title {
      display: flex;
      justify-content: space-between;
      .left {
        display: flex;
        .sep {
          background: #4e57aa;
          width: 5px;
          height: 1.5rem;
          display: inline-block;
          margin: 0 5px;
        }
        h4 {
          padding: 0;
          margin: 0;
        }
      }
    }
    .export {
      color: #fff;
      background: #4e57aa;
    }
  }
}
</style>
