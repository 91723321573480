<template>
  <div class="table" ref="tableCot">
    <el-table
      :data="tableDatas.data.data"
      v-loading="tableDatas.data.loading"
      :header-cell-style="rowClass"
      :height="Height"
      :cell-style="columnStyle"
      @row-click="clickRow"
    >
      <el-table-column
        v-if="tableDatas.id == 'hotwords' || tableDatas.id == 'sysLog' || tableDatas.id == 'staff'"
        type="index"
        width="100"
        label="序号"
        :index="indexMethod"
      >
      </el-table-column>
      <el-table-column
        v-for="(item, index) in tableDatas.data.columns"
        :key="index"
        :prop="item.prop"
        :label="item.col"
        :width="item.width"
      >
      </el-table-column>
      <!-- 查看与删除 -->
      <el-table-column
        v-if="tableDatas.id == 'speechAnalysisTable'"
        label="操作"
        width="200"
      >
        <template slot-scope="scope">
          <el-button
            @click="handleClick(scope.row)"
            size="small"
            plain
            class="basicBtn"
            >查看</el-button
          >
          <el-button type="danger" plain size="small">删除</el-button>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableDatas.id == 'exportTable'"
        label="操作"
        width="180"
      >
        <template slot-scope="scope">
          <el-button
            @click="handleDownload(scope.row)"
            size="small"
            plain
            class="basicBtn"
            >下载</el-button
          >
          <el-button
            v-if="scope.row.statusName == '失败'"
            @click="handleMessage(scope.row.message)"
            size="small"
            plain
            class="basicBtn"
            >失败信息</el-button
          >
        </template>
      </el-table-column>
      <!-- 查看 -->
      <el-table-column
        v-if="
          tableDatas.id == 'audioListTable' ||
          tableDatas.id == 'popAudioTable' ||
          tableDatas.id == 'supervisionTable' ||
          tableDatas.id == 'supervisorTable' ||
          tableDatas.id == 'quickTable' ||
          tableDatas.id == 'staff' ||
          tableDatas.id == 'stores' ||
          tableDatas.id == 'project'
        "
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            @click="handleSkip(scope.row)"
            size="small"
            plain
            class="basicBtn"
            >查看</el-button
          >
          <el-button
            v-if="tableDatas.id == 'popAudioTable'"
            @click="handleDownloadAudio(scope.row)"
            size="small"
            plain
            class="basicBtn"
            >生成下载链接</el-button
          >
        </template>

      </el-table-column>
      <!-- 编辑 -->
      <el-table-column
        v-if="tableDatas.id == 'supervisRule'"
        label="操作"
        width="100"
      >
        <template slot-scope="scope">
          <el-button
            @click="editRule(scope.row)"
            size="small"
            plain
            class="basicBtn"
            >编辑</el-button
          >
        </template>
      </el-table-column>
      <!-- 编辑与禁用 -->
      <el-table-column
        v-if="tableDatas.id == 'jurisdiction' || tableDatas.id == 'hotwords'"
        label="操作"
        width="200"
      >
        <template slot-scope="scope">
          <el-button
            @click="handleEdit(scope.row)"
            size="small"
            plain
            class="btn purpleBtn"
            >编辑</el-button
          >
          <el-button
            class="btn redBtn"
            plain
            size="small"
            @click="handleDisable(scope.row)"
            >{{ scope.row.status === "0" ? "禁用" : "启用" }}</el-button
          >
        </template>
      </el-table-column>
      <!-- 编辑与删除 -->
      <el-table-column
        v-if="tableDatas.id == 'character' || tableDatas.id == 'industry'"
        label="操作"
        width="200"
      >
        <template slot-scope="scope">
          <el-button
            @click="editAndDel(scope.row)"
            size="small"
            plain
            class="btn purpleBtn"
            :disabled="scope.row.roleType === 'system'"
            >编辑</el-button
          >
          <el-button class="btn redBtn" plain size="small">删除</el-button>
        </template>
      </el-table-column>
      <!-- 编辑、角色管理、账户管理、禁用 -->
      <el-table-column
        v-if="tableDatas.id == 'custom'"
        label="操作"
        width="300"
      >
        <template slot-scope="scope">
          <el-button
            @click="editCustom(scope.row)"
            size="small"
            plain
            class="btn purpleBtn"
            >编辑</el-button
          >
          <el-button
            class="btn yellowBtn"
            plain
            size="small"
            @click="
              $router.push(
                `/systemManage/role?customerId=${scope.row.customerId}`
              )
            "
            >角色管理</el-button
          >
          <el-button
            class="btn greenBtn"
            plain
            size="small"
            @click="
              $router.push(
                `/systemManage/account?customerId=${scope.row.customerId}`
              )
            "
            >账户管理</el-button
          >
          <el-button class="btn redBtn" plain size="small">禁用</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <div class="block" style="margin-top: 50px" v-if="pageData">
      <el-pagination
        background
        align="center"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageData.pageNum"
        :page-sizes="[10, 50, 100]"
        :page-size="pageData.pageSize"
        layout="sizes, prev, pager, next"
        :total="pageData.total"
      >
      </el-pagination>
      <!-- layout="total, sizes, prev, pager, next, jumper" -->
    </div>
  </div>
</template>
<script>
// import axios from "axios";
import { mapState, mapActions, mapMutations } from "vuex";
import { update } from "@/api/sysManage/account";
import { hotWordSave } from "@/api/infoManage/hotword";
export default {
  props: ["tableDatas", "pageData"],
  computed: {
    getTableDatas() {
      let id = this.tableDatas.id;
      let newDatas = this.tableDatas.data;
    },
    // indexMethod(index) {
    //   return this.pageData.pageSize * (this.pageData.pageNum - 1) + (index + 1);
    // },
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        // let heightStyle = that.$refs.tableCot.offsetHeight
        // that.Height = heightStyle
      })();
    };
  },
  created() {
    let that = this;
    // let heightStyle = that.$refs.tableCot.offsetHeight
    // that.Height = heightStyle
    //     let heightStyle = that.$refs.tableCot.offsetHeight
    // this.Height = heightStyle
  },
  components: {},
  watch: {},
  data() {
    return {
      // disableAccount:0,
      Height: 500, //表格高度
      currentPage: 1, // 当前页码
      total: 20, // 总条数
      pageSize: 10, // 每页的数据条数
    };
  },
  mounted() {},
  methods: {
    ...mapMutations("supervision", ["setCxyRowData"]),
    //表头样式
    rowClass({ row, rowIndex }) {
      // console.log(rowIndex); //表头行下标
      return "background:rgba(78,87,170,0.05);color:#000;fontSize:1.1em";
    },
    //指定列样式
    //row,  每一行上的数据
    //column, 每一列上的数据
    //rowIndex,  行数的下标从0开始
    //columnIndex   列数下标从0开始
    columnStyle({ row, column, rowIndex, columnIndex }) {
      const id = this.tableDatas.id;
      // if (id == "supervisionTable" && columnIndex == 4) {
      //   //第三第四列的背景色就改变了2和3都是列数的下标
      //   return "color:#F83B10;";
      // }
    },
    indexMethod(index) {
      return this.pageData.pageSize * (this.pageData.pageNum - 1) + (index + 1);
    },
    //点击查看
    handleClick(row) {
      console.log(row);
    },
    // viewPopAudio(row) {
    //   this.$emit("audioShow", row.audioId, true);
    // },
    // toAudioDetail(row) {
    //   this.$router.push(
    //     `/wordsAnalyze/audioRecord/audioDetail?audioDayId=${row.audioDayId}`
    //   );
    // },
    editRule(row) {
      this.$router.push(
        `/infoManage/supervisRule/set?supervisorRoleId=${row.supervisorRoleId}`
      );
    },
    editCustom(row) {
      this.$router.push(
        `/systemManage/customer/cusInfo?customerId=${row.customerId}`
      );
    },
    editAndDel(row) {
      const id = this.tableDatas.id;
      switch (id) {
        case "character":
          this.$router.push(`/systemManage/role/roleInfo?roleId=${row.roleId}`);
          break;
        case "industry":
          this.$router.push(
            `/systemManage/industry/indusInfo?industryId=${row.industryId}`
          );
          break;
      }
    },
    handleEdit(row) {
      const id = this.tableDatas.id;
      switch (id) {
        case "jurisdiction":
          this.$router.push(
            `/systemManage/account/accountInfo?userId=${row.userId}`
          );
          break;
        case "hotwords":
          this.$router.push(
            `/infoManage/hotwords/hotWordSet?hotWordId=${row.hotWordId}`
          );
          break;
      }
    },
    handleDisable(row) {
      const id = this.tableDatas.id;
      switch (id) {
        case "jurisdiction":
          if (row.status === "0") {
            update({ userId: row.userId, status: "1" }).then((res) => {
              if (res.code === 200) {
                row.status = "1";
              }
            });
          } else {
            update({ userId: row.userId, status: "0" }).then((res) => {
              if (res.code === 200) {
                row.status = "0";
              }
            });
          }
          break;
        case "hotwords":
          if (row.status === "0") {
            hotWordSave({ hotWordId: row.hotWordId, status: "1" }).then(
              (res) => {
                if (res.code === 200) {
                  row.status = "1";
                }
              }
            );
          } else {
            hotWordSave({ hotWordId: row.hotWordId, status: "0" }).then(
              (res) => {
                if (res.code === 200) {
                  row.status = "0";
                }
              }
            );
          }
          break;
      }
    },
    //每页条数改变时触发 选择一页显示多少行
    handleSizeChange(val) {
      this.$emit("changeSize", val);
      // console.log(`每页 ${val} 条`);
      // this.currentPage = 1;
      // this.pageSize = val;
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
      this.$emit("changePage", val);
    },
    handleDownload(row){
      window.location.href = row.fileUrl
    },
    handleMessage(msg){
      this.$message(msg)
    },
    handleSkip(row) {
      console.log(this.tableDatas.id, "<<<<<<<<");
      const id = this.tableDatas.id;
      switch (id) {
        case "supervisionTable":
          localStorage.setItem(
            "promoteRow",
            JSON.stringify({
              // warningType: row.warningType,
              // staffId: row.staffId,
              // userName: row.userName,
              userId: row.userId,
              sdate: row.sdate,
            })
          );
          this.$router.push("/inspection/promoterWarn/warnDetail");
          break;
        case "supervisorTable":
          localStorage.setItem(
            "supervisRow",
            JSON.stringify({
              warningType: row.warningType,
              userId: row.userId,
              projectId: row.projectId,
              sdate: row.sdate,
            })
          );
          this.$router.push("/inspection/supervisorWarn/warnDetail");
          break;
        case "quickTable":
          this.$router.push(
            `/quickTest/testCase/detail?examInfoId=${row.examInfoId}`
          );
          break;
        case "audioListTable":
          this.$emit("audioShow", row.audioId, true);
          break;
        case "popAudioTable":
			console.log(row,'-------')
			//row.audioDayId
          this.$emit("audioShowDis", row, false);
          break;
        case "staff":
          localStorage.setItem(
            "staffRow",
            JSON.stringify({
              userId: row.userId,
              startDate: new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate(),
              endDate: new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate()
            })
          );
          this.$router.push("/dataAnalysis/staff/staffToday");
          break;
        case "stores":
          this.$router.push({
            path: "/dataAnalysis/stores/storeDetails?name=" + row.spaceName,
          });
          localStorage.setItem(
            "storeDetailsRow",
            JSON.stringify({
              storeProjectId: row.projectId,
              spaceId: row.spaceId,
            })
          );
          break;
        case "project":
          this.$router.push({ name: "projectDetails" });
          localStorage.setItem(
            "storeDetailsRow",
            JSON.stringify({
              storeProjectId: row.projectId,
              spaceId: row.spaceId,
            })
          );
        // this.$router.push(`/dataAnalysis/stores/storeDetails?projectId=${row.projectId}&spaceId=${row.spaceId}`);
      }
    },
    handleDownloadAudio(row){
      this.$emit("handleLoad", row.audioDayId, false);
    },
    //点击行跳转
    clickRow(row, event, column) {
      //   const id = this.tableDatas.id;
      //   switch (id) {
      //     case "supervisionTable":
      //       localStorage.setItem(
      //         "promoteRow",
      //         JSON.stringify({
      //           warningType: row.warningType,
      //           staffId: row.staffId,
      //           userName: row.userName,
      //           sdate: row.sdate,
      //         })
      //       );
      //       this.$router.push("/inspection/promoterWarn/warnDetail");
      //       break;
      //     case "supervisorTable":
      //       localStorage.setItem(
      //         "supervisRow",
      //         JSON.stringify({
      //           warningType: row.warningType,
      //           userId: row.userId,
      //           projectId: row.projectId,
      //           sdate: row.sdate,
      //         })
      //       );
      //       this.$router.push("/inspection/supervisorWarn/warnDetail");
      //       break;
      //     case "quickTable":
      //       this.$router.push(
      //         `/quickTest/testCase/detail?examInfoId=${row.examInfoId}`
      //       );
      //       break;
      //   }
    },
  },
};
</script>
<style lang="scss">
.el-table td {
  border-bottom: 0px solid #ddd;
}
.el-table::before,
.el-table__fixed-right::before {
  background: transparent;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #4e57aa;
}
</style>
<style lang="scss" scoped>
.table {
  margin-top: 1.7vh;
  .basicBtn {
    background: rgba(78, 87, 170, 0.1);
    color: #4e57aa;
  }
  .btn {
    height: 30px;
    border: 0;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 4px;
  }
  .purpleBtn {
    background: rgba(78, 87, 170, 0.1);
    color: #4e57aa;
  }
  .redBtn {
    background: rgba(248, 74, 34, 0.1);
    color: #f84a22;
  }
  .yellowBtn {
    background: rgba(255, 197, 49, 0.1);
    color: #ffc531;
  }
  .greenBtn {
    background: rgba(35, 184, 153, 0.1);
    color: #23b899;
  }
}
/deep/.el-table th > .cell {
  text-align: center;
}

/deep/.el-table .cell {
  text-align: center;
}
/deep/ {
  .el-table {
    tbody tr {
      &:hover {
        td {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
