<template>
  <div class="warnDetail">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/inspection' }"
          >云巡检</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/inspection/promoterWarn' }"
          >促销员巡检列表</el-breadcrumb-item
        ><el-breadcrumb-item>促销员巡检详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="items">
        <span class="marginRight2vw">{{ promoteData.projectName }}</span>
        <span
          >时间：{{ promoteData.projectStartDate }} ~
          {{ promoteData.projectEndDate }}</span
        >
      </div>
    </div>
    <div class="main greyShadow">
      <div class="title">
        <div class="left">
          <i class="sep"></i>
          <h4>促销员巡检详情</h4>
        </div>
      </div>
      <div class="details marginTop">
        <div class="left">
          <div class="block">
            <el-avatar icon="el-icon-user-solid"></el-avatar>
          </div>
          <div class="flexl">
            <p>{{ promoteData.userName }}</p>
            <p>{{ promoteData.spaceNamePath }}</p>
          </div>
          <div class="group flex3">
            <p>{{ promoteData.positionName }}</p>
            <p>手机号：{{ promoteData.phone }}</p>
            <p>编号：{{ promoteData.staffNo }}</p>
          </div>
        </div>
        <div class="right" style="overflow: hidden">
          <!-- <div class="flex">
            <i
              style="font-size: 18px"
              class="el-icon-map-location marginRight1vw"
            ></i>
            {{ promoteData.warningTypeName }}
            <div class="tag">一级预警</div>
          </div> -->
          <div class="warning">
            <p>预警类型</p>
            <p>预警开始时间</p>
            <p>预警结束时间</p>
            <p>预警时长</p>
            <p>预警次数</p>
          </div>
          <vue-seamless-scroll
            :data="warningLogs"
            class="seamless-warp"
            :class-option="optionSetting"
          >
            <div
              class="warning"
              v-show="warningLogs.length"
              v-for="(item, index) in warningLogs"
              :key="index"
            >
              <p>{{ item.warningTypeName || "—" }}</p>
              <p>{{ item.warningStartDate || "—" }}</p>
              <p>{{ item.warningEndDate || "—" }}</p>
              <p>{{ item.warningTime ? item.warningTime + "min" : "—" }}</p>
              <p>{{ item.warningCount ? item.warningCount + "次" : "—" }}</p>
            </div>
            <div class="warning" v-show="!warningLogs.length">
              <p>—</p>
              <p>—</p>
              <p>—</p>
              <p>—</p>
              <p>—</p>
            </div>
          </vue-seamless-scroll>
        </div>
      </div>
      <el-row :gutter="20" class="marginTop chartDiv flex">
        <div class="left">
          <div class="inlineTitle">工作情况指数</div>
          <div
            class="notes box-card greyShadow"
            v-for="(note, index) in notes"
            :key="index"
          >
            <!-- <el-card class="box-card"> -->
            <img :src="getImgUrl(note.url)" alt="" />
            <span class="flex7">{{ note.name }}</span>
            <span class="num">{{ note.num }}</span>
            <!-- </el-card> -->
          </div>
        </div>
        <div class="right">
          <div class="inlineTitle">工作情况指数</div>
          <div class="radar">
            <Radar
              id="promoterWarn"
              :spaceData="spaceData"
              :projectData="projectData"
              :userData="userData"
            ></Radar>
          </div>
        </div>
      </el-row>
    </div>
  </div>
</template>
<script>
import Tool from "@/utils/tool";
import { mapState, mapActions } from "vuex";
import Radar from "../radar";
import {
  warningDetail,
  spaceAvg,
  projectAvg,
  userAvg,
} from "@/api/inspection/promoter";
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  name: "warnDetail",
  computed: {
    ...mapState("supervision", ["warnDetailData", "cxyRowData"]),
  },
  components: {
    Radar,
    vueSeamlessScroll,
  },
  created() {
    let promoteRow = JSON.parse(localStorage.getItem("promoteRow"));
    warningDetail(promoteRow).then((res) => {
      this.promoteData = res.data;
      this.warningLogs = res.data.warningLogs;
      this.notes = [
        {
          url: "chat",
          name: "沟通时长",
          num: this.promoteData.staffDuration
            ? this.promoteData.staffDuration + "s"
            : "—",
        },
        {
          url: "step",
          name: "行动步数",
          num: this.promoteData.step ? this.promoteData.step : "—",
        },
        {
          url: "leaveTimes",
          name: "离岗次数",
          num: this.promoteData.odc ? this.promoteData.odc : "—",
        },
        {
          url: "leaveTime",
          name: "离岗时长",
          num: this.promoteData.odd ? this.promoteData.odd + "min" : "—",
        },
        {
          url: "staticTimes",
          name: "静止次数",
          num: this.promoteData.ssc ? this.promoteData.ssc : "—",
        },
        {
          url: "warnTime",
          name: "预警总时长",
          num: this.promoteData.allWarningTime
            ? this.promoteData.allWarningTime + "min"
            : "—",
        },
        {
          url: "staticTime",
          name: "静止时长",
          num: this.promoteData.ssd ? this.promoteData.ssd + "min" : "—",
        },
        {
          url: "warnTimes",
          name: "预警总次数",
          num: this.promoteData.allWarningCount
            ? this.promoteData.allWarningCount
            : "—",
        },
      ];
      spaceAvg(res.data.spaceId, res.data.projectId, res.data.sdate).then(
        (res) => {
          this.spaceData = res.data;
          console.log(res.data, "<<<<spaceData");
        }
      );
      projectAvg(res.data.projectId, res.data.sdate).then((res) => {
        this.projectData = res.data;
        console.log(res.data, "projectData");
      });
      userAvg(res.data.projectId, res.data.sdate, res.data.userId).then(
        (res) => {
          this.userData = res.data;
          console.log(res.data, "userData");
        }
      );
    });
  },
  data() {
    return {
      // cxyWorkInfo: {},
      warningLogs: [],
      spaceData: {},
      projectData: {},
      userData: {},
      promoteData: {},
      notes: [],
      date: "",
      warnLevel: {
        value: "",
        options: [
          {
            value: 0,
            label: "一级预警",
          },
          {
            value: 1,
            label: "二级预警",
          },
        ],
      },
      project: {
        value: "",
        options: [
          {
            value: 0,
            label: "语音项目",
          },
          {
            value: 1,
            label: "项目",
          },
        ],
      },
      warnType: {
        value: "",
        options: [
          {
            value: 0,
            label: "红色预警",
          },
          {
            value: 1,
            label: "黄色预警",
          },
        ],
      },
      area: {
        value: "",
        options: [
          {
            label: "热门城市",
            options: [
              {
                value: "Shanghai",
                label: "上海",
              },
              {
                value: "Beijing",
                label: "北京",
              },
            ],
          },
          {
            label: "城市名",
            options: [
              {
                value: "Chengdu",
                label: "成都",
              },
              {
                value: "Shenzhen",
                label: "深圳",
              },
              {
                value: "Guangzhou",
                label: "广州",
              },
              {
                value: "Dalian",
                label: "大连",
              },
            ],
          },
        ],
      },
      searchInput: "",
    };
  },
  computed: {
    optionSetting() {
      return {
        step: 0.2, //速度，值越大，速度越快
        hoverStop: true, //鼠标悬停效果，false为关闭该效果
        singleHeight: 26, //单行停顿
        waitTime: 0, //单行停顿的时间
      };
    },
  },
  methods: {
    getImgUrl(url) {
      return require("@/assets/pc/" + url + ".png");
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === "promoterWarn") {
      to.meta.keepAlive = true;
    }
    next();
  },
};
</script>

<style lang="scss" scoped>
.seamless-warp {
  height: 229px;
  overflow: hidden;
}
@import "../../../../assets/common.scss";
$baseColor: #4e57aa;
$white: #fff;
$fontColor: #333;
$fontSize: 1.2em;
p {
  margin: 0;
  padding: 0;
}
.warnDetail {
  margin: 2vh 1.5vw;
  .breadcrumb {
    .items {
      font-size: 14px;
    }
  }
  .main {
    background: #fff;
    padding: 2vh 1vw;
    margin-top: 2vh;
    border-radius: 2vh;
    height: 100%;
    min-height: 75vh;
    .title {
      display: flex;
      justify-content: space-between;
      .left {
        display: flex;
        .sep {
          background: $baseColor;
          width: 5px;
          height: 1.5rem;
          display: inline-block;
          margin: 0 5px;
        }
        h4 {
          padding: 0;
          margin: 0;
        }
      }
    }

    .details {
      background: $baseColor;
      border-radius: 25px;
      // height: 13vh;
      margin-bottom: 5vh;
      display: flex;
      align-items: center;
      // padding: 5vh 3vw;
      padding: 3.9vh 0;
      color: #fff;
      .left {
        position: relative;
        display: flex;
        // align-items: center;
        // width: 75%;
        width: 46%;
        // border-right: 1px solid rgba(255, 255, 255, 0.5);
        .block {
          position: absolute;
          top: 0;
          bottom: 0;
          // margin: auto;
          margin: auto 1.5625vw;
          width: 5.2vw;
          height: 5.2vw;
          > span {
            width: 100%;
            height: 100%;
            line-height: 5.2vw;
          }
        }
        .flexl {
          // margin-top: 3.5vh;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          margin-right: 3vw;
          margin-left: 8.325vw;
          // flex: 2;
          // margin: 1vh 2vh;
          // margin-left: calc(110vw / 19.2);
          p {
            font-size: 16px;
            margin-bottom: 1.75vh;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        .flex3 {
          // margin-top: 3.3vh;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          // flex: 2;
          // margin: 1vh 2vh;
          p {
            font-size: 14px;
            margin-bottom: 1.75vh;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        // .group {
        //   font-size: 0.9em;
        // }
      }
      .right {
        flex: 1;
        padding: 0 1vw;
        border-left: 1px solid rgba(255, 255, 255, 0.5);
        position: relative;
        // padding-left: 20%;
        // width: 50%;
        font-size: 14px;
        max-height: 15vh;
        overflow: auto;
        // margin-top: 3vh;
        // margin: 1vh 0;
        .warning {
          display: flex;
          justify-content: space-between;
          margin-bottom: 2vh;
          text-align: center;
          &:last-child {
            margin-bottom: 0;
          }
          p {
            flex: 1;
          }
        }
        .flex {
          height: 3vh;
          line-height: 3vh;
          margin-bottom: 2vh;
          i {
            position: absolute;
            left: 33%;
            line-height: 3vh;
          }
          .tag {
            color: #ffc531;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 1vw;
            margin-left: 1vw;
            padding: 0 1vw;
            font-size: 12px;
          }
        }
        // p {
        //   margin-bottom: 2vh;
        //   &:last-child {
        //     margin-bottom: 0;
        //   }
        // }
      }
    }
    .chartDiv {
      .left {
        flex: 6;
        .notes {
          background: #f9f9fd;
          border-radius: 8px;
          display: flex;
          width: 40%;
          margin: 2vh 2vh 0;
          padding: 2vh;
          float: left;
          transition: 0.3s;
          img {
            margin-right: 8px;
          }
          // box-shadow: 0 2px 12px rgb(0 0 0 / 10%);
          .flex7 {
            flex: 7;
          }
          .num {
            color: #4e57aa;
            // font-size: 1.2rem;
            font-size: 18px;
          }
        }
      }
      .right {
        flex: 4;
        .radar {
          height: 35vh;
          margin-top: -2vh;
          .chart {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>