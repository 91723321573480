<template>
  <div class="cusInfo">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/systemManage' }"
          >系统管理</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/systemManage/customer' }"
          >客户管理</el-breadcrumb-item
        >
        <el-breadcrumb-item v-if="$route.query.customerId"
          >编辑客户</el-breadcrumb-item
        >
        <el-breadcrumb-item v-else>新增客户</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main">
      <div class="title">
        <div class="left">
          <i class="sep"></i>
          <h4>客户信息</h4>
        </div>
      </div>
      <div class="form_content">
        <h5>基本信息</h5>
        <el-form :inline="true" :model="formData" :rules="rules" ref="ruleForm">
          <el-form-item label="客户名称：" prop="customerName" class="indent">
            <el-input v-model="formData.customerName"></el-input>
          </el-form-item>
          <el-form-item label="公司电话：">
            <el-input v-model="formData.companyTel"></el-input>
          </el-form-item>
          <el-form-item label="传真：">
            <el-input v-model="formData.fax"></el-input>
          </el-form-item>
          <el-form-item label="品牌：">
            <el-input v-model="formData.brand"></el-input>
            <!-- <el-select v-model="formData.brand">
              <el-option value="选择一"></el-option>
              <el-option value="选择二"></el-option>
            </el-select> -->
          </el-form-item>
          <el-form-item
            label="客户全称："
            prop="customerFullname"
            class="indent"
          >
            <el-input v-model="formData.customerFullname"></el-input>
          </el-form-item>
          <el-form-item label="公司注册地址：" class="w2">
            <el-input v-model="formData.companyPlace"></el-input>
          </el-form-item>
          <el-form-item label="省份：">
            <el-select
              v-model="formData.province"
              @change="changeProvince"
              clearable
              filterable
            >
              <el-option
                v-for="item in provinceMenu"
                :key="item.code"
                :label="item.value"
                :value="item.code"
              >
              </el-option>
              <!-- <el-option value="选择一"></el-option>
              <el-option value="选择二"></el-option> -->
            </el-select>
          </el-form-item>
          <el-form-item label="行业类别：" prop="industryType" class="indent">
            <el-select v-model="formData.industryType" filterable>
              <el-option
                v-for="item in industryTypeMenu"
                :key="item.industryId"
                :label="item.industryName"
                :value="item.industryId"
              >
              </el-option>
              <!-- <el-option value="选择一"></el-option>
              <el-option value="选择二"></el-option> -->
            </el-select>
          </el-form-item>
          <el-form-item label="所在城市：">
            <el-select
              v-model="formData.city"
              @change="changeCity"
              clearable
              filterable
            >
              <el-option
                v-for="item in cityMenu"
                :key="item.code"
                :label="item.value"
                :value="item.code"
              >
              </el-option>
              <!-- <el-option value="选择一"></el-option>
              <el-option value="选择二"></el-option> -->
            </el-select>
          </el-form-item>
          <el-form-item label="区域：" class="last_item">
            <el-select v-model="formData.county" clearable filterable>
              <el-option
                v-for="item in countyMenu"
                :key="item.code"
                :label="item.value"
                :value="item.code"
              >
              </el-option>
              <!-- <el-option value="选择一"></el-option>
              <el-option value="选择二"></el-option> -->
            </el-select>
          </el-form-item>
          <el-form-item label="采音胸牌数量：" prop="breastplate">
            <el-input v-model="formData.breastplate"></el-input>
          </el-form-item>
          <el-form-item label="营业地址：" class="last_item w3">
            <el-input v-model="formData.businessPlace"></el-input>
          </el-form-item>
          <!-- <el-form-item label="角色：" class="block indent2">
            <el-input v-model="formData.roleId"></el-input>
          </el-form-item> -->
          <el-form-item label="蓝牙信标数量：" prop="bluetoothBreastplate">
            <el-input v-model="formData.bluetoothBreastplate"></el-input>
          </el-form-item>
          <el-form-item
            label="客户类型："
            prop="customerType"
            class="block radio indent"
          >
            <el-radio-group v-model="formData.customerType">
              <el-radio label="direct_customer">直客</el-radio>
              <el-radio label="no_direct_customer">非直客</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="客户状态："
            prop="customerStatus"
            class="block indent"
          >
            <el-switch
              v-model="formData.customerStatus"
              active-color="#4e57aa"
              inactive-color="#cfcfcf"
              active-value="status_start"
              inactive-value="status_stop"
            ></el-switch>
          </el-form-item>
          <el-form-item label="备注：" class="block textarea indent2">
            <el-input
              type="textarea"
              v-model="formData.remarks"
              resize="none"
            ></el-input>
          </el-form-item>
        </el-form>
        <el-button
          type="primary"
          icon="el-icon-refresh-right"
          class="subBtn"
          @click="onSubmit"
          >提交</el-button
        >
        <el-button @click="onBack">返回列表</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getListInfo,
  insertInfo,
  customerInfoXq,
  updateCustomerInfo,
  toInsertPage,
} from "@/api/sysManage/customer";
import {
  getProvince,
  getProvinceDown,
  getProvinceEnd,
} from "@/api/inspection/promoter";
export default {
  data() {
    return {
      industryTypeMenu: [],
      provinceMenu: [],
      cityMenu: [],
      countyMenu: [],
      formData: {
        customerName: "",
        companyTel: "",
        fax: "",
        brand: "",
        customerFullname: "",
        companyPlace: "",
        province: "",
        industryType: "",
        city: "",
        county: "",
        breastplate: "",
        businessPlace: "",
        bluetoothBreastplate: "",
        roleId: "",
        customerType: "direct_customer",
        customerStatus: "status_start",
        remarks: "",
      },
      rules: {
        customerName: [
          { required: true, message: "请填写客户名称", trigger: "blur" },
        ],
        customerFullname: [
          { required: true, message: "请填写客户全称", trigger: "blur" },
        ],
        industryType: [
          { required: true, message: "请选择行业类别", trigger: "change" },
        ],
        breastplate: [
          { required: true, message: "请填写采音胸牌数量", trigger: "blur" },
        ],
        bluetoothBreastplate: [
          { required: true, message: "请填写蓝牙胸牌数量", trigger: "blur" },
        ],
        customerType: [
          { required: true, message: "请选择客户类型", trigger: "change" },
        ],
        customerStatus: [
          { required: true, message: "请选择客户状态", trigger: "change" },
        ],
      },
    };
  },
  created() {
    if (this.$route.query.customerId) {
      customerInfoXq(this.$route.query.customerId).then((res) => {
        this.formData = res.data;
        this.formData.province = Number(res.data.province);
        getProvinceDown(this.formData.province).then((res) => {
          this.cityMenu = res.data;
        });
        this.formData.city = Number(res.data.city);
        getProvinceEnd(this.formData.city).then((res) => {
          this.countyMenu = res.data;
        });
        this.formData.county = Number(res.data.county);
        this.$refs.ruleForm.resetFields();
      });
    }
    // getListInfo().then((res) => {
    //   this.industryTypeMenu = res.industryType;
    // });
    toInsertPage().then((res) => {
      this.industryTypeMenu = res.data.industries;
    });
    getProvince().then((res) => {
      this.provinceMenu = res.data;
    });
  },
  methods: {
    changeProvince(value) {
      if (value) {
        getProvinceDown(value).then((res) => {
          this.cityMenu = res.data;
        });
      } else {
        this.cityMenu = [];
        this.formData.city = "";
        this.formData.county = "";
      }
    },
    changeCity(value) {
      if (value) {
        getProvinceEnd(value).then((res) => {
          this.countyMenu = res.data;
        });
      } else {
        this.countyMenu = [];
        this.formData.county = "";
      }
    },
    onSubmit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.$route.query.customerId) {
            let updateData = { ...this.formData };
            updateData.customerId = this.$route.query.customerId;
            updateCustomerInfo(updateData).then((res) => {
              if (res.code === 200) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
                this.onBack();
              }
            });
          } else {
            insertInfo(this.formData).then((res) => {
              if (res.code === 200) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                });
                this.onBack();
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    onBack() {
      this.$router.push("/systemManage/customer");
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === "customer") {
      to.meta.keepAlive = true;
    }
    next();
  },
};
</script>

<style lang="scss" scoped>
$baseColor: #4e57aa;
.cusInfo {
  margin: 2vh 1.5vw;
  .main {
    background: #fff;
    padding: 2vh 1vw;
    margin-top: 2vh;
    border-radius: 2vh;
    height: 100%;
    min-height: 75vh;
    .title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4.6vh;
      .left {
        display: flex;
        .sep {
          background: $baseColor;
          width: 5px;
          height: 1.5rem;
          display: inline-block;
          margin: 0 5px;
        }
        h4 {
          padding: 0;
          margin: 0;
        }
      }
    }
    .form_content {
      padding-left: 1vw;
      h5 {
        margin-bottom: 2.68vh;
        font-size: 14px;
      }
      /deep/.el-form {
        margin-bottom: 12.13vh;
        // display: flex;
        // flex-wrap: wrap;
        .el-form-item {
          width: 23%;
          margin-right: 2%;
          margin-bottom: 1.85vh;
          // display: flex;
          .el-form-item__label {
            padding: 0;
            color: #333333;
          }
          .el-form-item__content {
            width: 50%;
            .el-select {
              width: 100%;
            }
            .el-input__inner {
              font-size: 14px;
              color: #333333;
            }
          }
        }
        .textarea {
          width: 40%;
          .el-textarea__inner {
            height: 15.74vh;
            font-size: 14px;
            color: #333333;
          }
        }
        .indent2 {
          .el-form-item__label {
            text-indent: 4.65em;
          }
        }
        .indent {
          .el-form-item__label {
            text-indent: 2em;
          }
        }
        .radio {
          width: 35%;
          .el-form-item__content {
            .el-radio__label {
              color: #333333;
            }
            .el-radio__inner {
              width: 18px;
              height: 18px;
              border: 1px solid #cfcfcf;
            }
            .el-radio__inner::after {
              width: 12px;
              height: 12px;
              background-color: #4e57aa;
            }
            .el-radio__input.is-checked .el-radio__inner {
              border-color: #4e57aa;
              background: #fff;
            }
          }
        }
        .w2 {
          width: 48%;
          .el-form-item__content {
            width: 68.5%;
          }
        }
        .w3 {
          width: 48%;
          .el-form-item__content {
            width: 72.5%;
          }
        }
        .last_item {
          margin-right: 20%;
        }
        .block {
          display: block;
        }
      }
      .subBtn {
        background: #4e57aa;
        border-color: #4e57aa;
        margin-right: 1.09375vw;
      }
    }
  }
}
</style>
