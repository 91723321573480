<template>
  <div class="supervision">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/dataAnalysis' }"
          >数据分析</el-breadcrumb-item
        >
        <el-breadcrumb-item>门店数据分析列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main">
      <div class="title">
        <div class="left">
          <i class="sep"></i>
          <h4>门店数据分析列表</h4>
        </div>
      </div>
      <div class="form-content marginTop">
        <div class="form-item">
          <span class="selectName">项目：</span>
          <el-select
            v-model="formData.projectName"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in projectNameList"
              :key="item.projectName"
              :value="item.projectName"
            >
            </el-option>
          </el-select>
        </div>
        <!-- <div class="form-item">
          <span class="selectName">区域/渠道：</span>
          <el-select v-model="formData.projectId" clearable placeholder="全部">
            <el-option
              v-for="item in projectMenu"
              :key="item.projectId"
              :label="item.projectName"
              :value="item.projectId"
            >
            </el-option>
          </el-select>
        </div> -->
        <!-- <div class="form-item">
          <span class="selectName">省：</span>
          <el-select
            v-model="formData.warningType"
            clearable
            placeholder="全部"
          >
            <el-option
              v-for="item in warnTypeMenu"
              :key="item.statusCode"
              :label="item.statusValue"
              :value="item.statusCode"
            >
            </el-option>
          </el-select>
        </div> -->
        <!-- <div class="form-item">
          <span class="selectName">市：</span>
          <el-select
            v-model="formData.warningType"
            clearable
            placeholder="全部"
          >
            <el-option
              v-for="item in warnTypeMenu"
              :key="item.statusCode"
              :label="item.statusValue"
              :value="item.statusCode"
            >
            </el-option>
          </el-select>
        </div> -->
        <div class="form-item">
          <span class="selectName">门店关键字：</span>
          <el-input v-model="formData.spaceName"></el-input>
        </div>
        <div class="form-item">
          <span class="selectName">省：</span>
          <el-select
            v-model="formData.province"
            clearable
            placeholder="全部"
            @change="changeStore"
          >
            <el-option
              v-for="item in storeMenu"
              :key="item.code"
              :label="item.value"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </div>
        <div class="form-item">
          <span class="selectName">市：</span>
          <!-- <el-input v-model="formData.nickName"></el-input> -->
          <el-select v-model="formData.city" clearable placeholder="全部">
            <el-option
              v-for="item in projectMenu"
              :key="item.code"
              :label="item.value"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </div>
        <div class="form-item">
          <el-button class="searchBtn" @click="onInquire">查询</el-button>
          <el-button @click="onReset">重置</el-button>
        </div>
      </div>
      <el-row>
        <el-button class="searchBtn export" @click="handleExport"
          >导出</el-button
        >
      </el-row>
      <el-row :gutter="10">
        <TableChart
          :tableDatas="{
            id: 'stores',
            data: staffTable,
          }"
          :pageData="pageData"
          @changePage="currentChange"
          @changeSize="sizeChange"
        ></TableChart>
      </el-row>
    </div>
  </div>
</template>
<script>
import TableChart from "../table";
import { mapState, mapActions } from "vuex";
// import { getTjSession } from "@/api/inspection/promoter";
// import { audioSpace } from "@/api/wordsAnalyze/audio";
import { getProvinceDown, getProvince } from "@/api/inspection/promoter";
import { getStoreList, spaceAnalExport } from "@/api/dataAnalysis/staff";
import { projectList } from "@/api/inspection/promoter";
export default {
  computed: {
    // ...mapState("supervision", ["supervisionTable"]),
  },
  components: {
    TableChart,
  },
  data() {
    return {
      // supervisionTable: [],
      areaMenu: [],
      provinceMenu: [],
      cityMenu: [],
      storeMenu: [],
      staffTable: {
        size: "",
        loading: false,
        columns: [
          {
            col: "序号",
            prop: "rowNum",
            width: "",
          },
          {
            col: "门店编号",
            prop: "spaceNo",
            width: "",
          },
          {
            col: "门店名称",
            prop: "spaceName",
            width: "",
          },
          {
            col: "负责人",
            prop: "supervisorName",
            width: "",
          },
          {
            col: "门店地址",
            prop: "spacePath",
            width: "",
          },
          {
            col: "门店人数",
            prop: "userCount",
            width: "",
          },
          {
            col: "参与项目",
            prop: "projectName",
            width: "",
          },
          {
            col: "销售数量",
            prop: "saleCount",
            width: "",
          },
          {
            col: "预警人数",
            prop: "warningUserCount",
            width: "",
          },
          {
            col: "预警次数",
            prop: "warningCount",
            width: "",
          },
        ],
        data: [],
      },
      pageData: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      formData: {
        city: "",
        spaceName: "",
        province: "",
        projectName:""
      },
      projectMenu: [],
      warnTypeMenu: [],
      projectNameList: []
    };
  },
  created() {
    getProvince().then((res) => {
      this.storeMenu = res.data;
    });
    projectList().then((res) => {
      this.projectNameList = res.data;
      console.log(res,'<<')
    });
    this.getTableData();
  },
  methods: {
    changeStore(val) {
      this.formData.city = "";
      getProvinceDown(val).then((res) => {
        this.projectMenu = res.data;
      });
    },
    getTableData() {
      this.staffTable.loading = true;
      this.projectNameList.map(item=>{
        if(item.projectName == this.formData.projectName){
          this.formData.projectId = item.projectId
          delete this.formData.projectName
        }
      })
      getStoreList(
        this.formData,
        this.pageData.pageNum,
        this.pageData.pageSize
      ).then((res) => {
        this.staffTable.loading = false;
        this.staffTable.data = res.rows;
        this.pageData.total = res.total;
      });
    },
    onInquire() {
      this.pageData.pageNum = 1;
      this.getTableData();
    },
    onReset() {
      this.formData.province = "";
      this.formData.spaceName = "";
      this.formData.city = "";
    },
    currentChange(val) {
      this.pageData.pageNum = val;
      this.getTableData();
    },
    sizeChange(val) {
      this.pageData.pageNum = 1;
      this.pageData.pageSize = val;
      this.getTableData();
    },
    changeProject(val) {
      if (val) {
        this.formData.spaceId = "";
      }
      getProvince(val).then((res) => {
        this.storeMenu = res.data;
      });
    },
    handleExport() {
      let id = ''
      this.projectNameList.map(item=>{
        if(item.projectName == this.projectName){
          id = item.projectId
        }
      })
      spaceAnalExport({
        province: this.formData.city,
        city: this.formData.province,
        spaceName: this.formData.spaceName,
        projectId: id
      }).then((res) => {
        let blob = new Blob([res], { type: `text/plain;charset=utf-8` });
        let downloadElement = document.createElement("a");
        let href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = "人员数据分析列表.xls";
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === "staffToday") {
      next((vm) => {
        vm.getTableData();
      });
    } else {
      next();
    }
  },
  beforeRouteLeave(to, from, next) {
    from.meta.keepAlive = false;
    next();
  },
};
</script>

<style lang="scss" scoped>
$baseColor: #4e57aa;
$white: #fff;
$fontColor: #333;
$fontSize: 1.2em;
.supervision {
  margin: 2vh 1.5vw;
  .marginTop {
    margin-top: 2vh;
  }
  .main {
    background: #fff;
    padding: 2vh 1vw;
    margin-top: 2vh;
    border-radius: 2vh;
    height: 100%;
    min-height: 75vh;
    .form-content {
      display: flex;
      flex-wrap: wrap;
      .form-item {
        margin-right: 1.5625vw;
        margin-bottom: 1.7vh;
        .el-select {
          width: 10.9375vw;
        }
        .el-input {
          width: 10.9375vw;
        }
        .el-cascader {
          width: 10.9375vw;
        }
      }
    }
    .export {
      float: right;
    }
    // /deep/.el-select {
    //   width: 74%;
    // }
    // /deep/.el-cascader {
    //   width: 74%;
    // }
    .title {
      display: flex;
      justify-content: space-between;
      .left {
        display: flex;
        .sep {
          background: $baseColor;
          width: 5px;
          height: 1.5rem;
          display: inline-block;
          margin: 0 5px;
        }
        h4 {
          padding: 0;
          margin: 0;
        }
      }
    }
    .fr {
      float: right;
      width: auto;
      margin-left: 0;
    }
    .selectName {
      // width: 26%;
      font-size: 14px;
      display: inline-block;
      color: $fontColor;
      // text-align: left;
      // text-align-last: justify;
    }
    .indent2 {
      padding-right: 2em;
    }
    .parent {
      position: relative;
      .searchInput {
        position: absolute;
        right: 0;
        height: 100%;
        text-align: center;
        background: $baseColor;
        width: 2vw;
        right: 0px;
        top: 0;
        border-radius: 0 5px 5px 0;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .searchBtn {
      color: $white;
      background-color: $baseColor;
    }
  }
}
</style>