    <template>
  <div class="audioDetail">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/wordsAnalyze' }">话术分析</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/wordsAnalyze/audioRecord' }"
          >员工音频记录</el-breadcrumb-item
        >
        <el-breadcrumb-item>音频详情</el-breadcrumb-item>
      </el-breadcrumb>
      <!-- <div class="items">
        <span class="marginRight2vw">上海布点项目20210201</span>
        <span>时间：2020/12/01-2021/06/01</span>
      </div> -->
    </div>
    <div class="main greyShadow">
      <div class="title">
        <div class="left">
          <i class="sep"></i>
          <h4>音频详情</h4>
        </div>
        <el-button-group>
          <el-button type="primary" icon="el-icon-arrow-left" @click="handleLastDay"
            >上一天</el-button
          >
          <el-button type="primary" @click="handleNextDay">
            下一天
            <i class="el-icon-arrow-right el-icon--right"></i>
          </el-button>
        </el-button-group>
      </div>
      <!-- <div class="calender">
        <el-calendar>
          <template slot="dateCell" slot-scope="{ date, data }">
            <p :class="data.isSelected ? 'is-selected' : ''">
              {{ data.day.split("-").slice(1).join("-") }}
              {{ data.isSelected ? "✔️" : "" }}
            </p>
          </template>
        </el-calendar>
      </div> -->
      <div class="details marginTop">
        <div class="flexl">
          <div class="flexContent">
            <div class="avatar">
              <el-avatar :size="100" icon="el-icon-user-solid"></el-avatar>
            </div>
            <div class="info">
              <!-- <h4>员工信息</h4> -->
              <p>姓名：{{ audioInfo.userName }}</p>
              <p>设备号：{{ audioInfo.sn }}</p>
              <p>手机号：{{ audioInfo.phone }}</p>
            </div>
          </div>
          <di class="flexInfo">
            <p>所属项目：{{ audioInfo.projectName }}</p>
            <p>所属门店：{{ audioInfo.spaceName }}</p>
          </di>
        </div>
        <div class="flexr">
          <div class="content">
            <h4>门店信息</h4>
            <div class="infoShow">
              <p>
                门店语音文件数：{{
                  spaceDetail.spaceAudioFileCount === 0
                    ? 0
                    : spaceDetail.spaceAudioFileCount
                    ? spaceDetail.spaceAudioFileCount
                    : audioInfo.spaceAudioFileCount === 0
                    ? 0
                    : audioInfo.spaceAudioFileCount
                    ? audioInfo.spaceAudioFileCount
                    : '-'
                }}条
              </p>
              <p>
                门店上传文件数：{{
                  spaceDetail.spaceUploadCount === 0
                    ? 0
                    : spaceDetail.spaceUploadCount
                    ? spaceDetail.spaceUploadCount
                    : audioInfo.spaceUploadCount === 0
                    ? 0
                    : audioInfo.spaceUploadCount
                    ? audioInfo.spaceUploadCount
                    : '-'
                }}条
              </p>
              <p>
                门店员工沟通时长：{{
                  spaceDetail.spaceLeftStaffDuration / 1000 !== 0
                    ? spaceDetail.spaceLeftStaffDuration / 1000
                    : audioInfo.spaceLeftStaffDuration / 1000
                }}s
              </p>
              <p>
                门店总沟通时长：{{
                  Number(spaceDetail.spaceStaffDuration) / 1000 !== 0
                    ? Number(spaceDetail.spaceStaffDuration) / 1000
                    : Number(audioInfo.spaceStaffDuration) / 1000
                }}s
              </p>
              <p class="complete" @click="handleOpenComplete('space')">
                门店当日话术完成率：<span
                  :class="
                    Number(audioInfo.spaceVerbalTrickCompleteness) >= 0.75
                      ? 'green'
                      : Number(audioInfo.spaceVerbalTrickCompleteness) < 0.75 &&
                        Number(audioInfo.spaceVerbalTrickCompleteness) >= 0.25
                      ? 'yel'
                      : 'red'
                  "
                >
                  {{
                    Number(spaceDetail.spaceVerbalTrickCompleteness) === 0
                      ? 0
                      : Number(spaceDetail.spaceVerbalTrickCompleteness)
                      ? (Number(spaceDetail.spaceVerbalTrickCompleteness) * 100).toFixed(2)
                      : Number(audioInfo.spaceVerbalTrickCompleteness) === 0
                      ? 0
                      : Number(audioInfo.spaceVerbalTrickCompleteness)
                      ? (Number(audioInfo.spaceVerbalTrickCompleteness) * 100).toFixed(2)
                      : '-'
                  }}%
                </span>
              </p>
              <p class="complete" @click="handleOpenKeywords('space')">
                门店关键字提取次数：{{
                  spaceDetail.spaceKeywordGet === 0
                    ? 0
                    : spaceDetail.spaceKeywordGet
                    ? spaceDetail.spaceKeywordGet
                    : audioInfo.spaceKeywordGet === 0
                    ? 0
                    : audioInfo.spaceKeywordGet
                    ? audioInfo.spaceKeywordGet
                    : '-'
                }}次
              </p>
            </div>
          </div>
          <div class="stati">
            <h4>个人信息</h4>
            <div class="infoShow">
              <p>
                个人语音文件数：{{
                  detailData.audioFileCount === 0
                    ? 0
                    : detailData.audioFileCount
                    ? detailData.audioFileCount
                    : '-'
                }}条
              </p>
              <p>
                个人上传文件数：{{
                  detailData.uploadCount === 0
                    ? 0
                    : detailData.uploadCount
                    ? detailData.uploadCount
                    : '-'
                }}次
              </p>
              <p>
                个人员工沟通时长：{{
                  detailData.leftStaffDuration / 1000 === 0
                    ? 0
                    : detailData.leftStaffDuration / 1000 || '-'
                }}s
              </p>
              <p>个人总沟通时长：{{ (detailData.staffDuration / 1000).toFixed(2) || '-' }}s</p>
              <p class="complete" @click="handleOpenComplete">
                个人当日话术完成率：
                <span
                  :class="
                    Number(detailData.verbalTrickCompleteness) >= 0.75
                      ? 'green'
                      : Number(detailData.verbalTrickCompleteness) < 0.75 &&
                        Number(detailData.verbalTrickCompleteness) >= 0.25
                      ? 'yel'
                      : 'red'
                  "
                >
                  {{
                    detailData.verbalTrickCompleteness * 100 === 0
                      ? 0
                      : detailData.verbalTrickCompleteness * 100
                      ? (detailData.verbalTrickCompleteness * 100).toFixed(2)
                      : '-'
                  }}%
                </span>
              </p>
              <p class="complete" @click="handleOpenKeywords">
                个人关键字提取：{{
                  detailData.scriptLibraryGetCount === 0
                    ? 0
                    : detailData.scriptLibraryGetCount
                    ? detailData.scriptLibraryGetCount
                    : '-'
                }}次
              </p>
            </div>
          </div>
        </div>
        <!-- <div class="left" style="width: 100%">
          <div class="flex1">
            <el-avatar :size="100" icon="el-icon-user-solid"></el-avatar>
          </div> 
          <div class="flex2 posoRela marginLeft2vw">
            <h4>员工信息</h4>
            <p>姓名：{{ audioInfo.userName }}</p>
            <p>编号：{{ audioInfo.userId }}</p>
            <p>手机号：{{ audioInfo.phone }}</p>
          </div>
          <div class="flex2 posoRela marginLeft2vw">
            <h4>内容信息</h4>
            <p>所属项目：{{ audioInfo.projectName }}</p>
            <p>所属门店：{{ audioInfo.spaceName }}</p>
          </div>
          <div class="flex2 posoRela marginLeft2vw">
            <h4>录音信息</h4>
            <p>录音开始时间：{{ audioInfo.audioDate }}</p>
            <p>录音时长：{{ audioInfo.audioDuration }}毫秒</p>
          </div>
        </div> -->
      </div>
    </div>
    <div>
      <el-row class="bottom flex">
        <div class="main flex1 greyShadow">
          <div class="left" style="height: 100%; overflow-y: auto" v-loading="audioloading">
            <el-col
              class="export"
              :span="6"
              :offset="6"
              style="float: right; margin: 0; padding: 0"
            >
              <el-button
                plain
                style="width: 100%"
                class="basicBtn"
                icon="el-icon-upload2"
                @click="handleDownload('all')"
                >导出全部文本</el-button
              >
            </el-col>
            <!-- <el-col :span="3" style="float: right; margin-right: 20px">
              <el-button>重置</el-button>
            </el-col> -->
            <el-col :span="10" style="float: right; margin-right: 20px">
              <el-select
                filterable
                clearable
                v-model="selectAudio"
                placeholder="请选择"
                style="width: 100%"
                @visible-change="getSelectAudio"
                @change="setSpaceId"
                @clear="handleClearAudio"
              >
                <el-option
                  v-for="item in selectAudioOptions"
                  :key="item.spaceId"
                  :label="item.spaceName"
                  :value="item.spaceId"
                >
                </el-option>
              </el-select>
            </el-col>

            <div class="subtitle">音频列表</div>
            <div class="audio-content">
              <div style="margin-bottom: 1vw" v-for="(item, index) in audioList" :key="index">
                <div class="des">
                  <span :class="item.isGet === 'Y' ? 'startTime' : ''">{{
                    item.audioStartTime
                  }}</span>
                  <div>{{ item.spaceName }}</div>
                  <div>
                    <span :id="'audioCurTime' + index">0`0``</span>/
                    <span :id="'audioTime' + index"></span>
                  </div>
                </div>
                <div class="audio-wrapper">
                  <audio class="audio" controls>
                    <source :src="item.resourceUrl" type="audio/mpeg" />
                  </audio>
                  <img :id="'audioPlayer' + index" src="../../../../assets/img/play.png" />
                  <div class="progress-bar-bg" :id="'progressBarBg' + index">
                    <span :id="'progressDot' + index"></span>
                    <div class="progress-bar" :id="'progressBar' + index"></div>
                  </div>
                </div>
                <!-- <div
                  class="flexBetween greyColor"
                  style="padding: 0 8px 5px 8px"
                >
                  <span>{{ item.audioStartTime }}</span>
                </div>
                <audio
                  class="audio"
                  controls
                  @play="onPlay($event, index)"
                  @pause="onPause($event)"
                >
                  <source :src="item.resourceUrl" type="audio/wav" />
                </audio> -->
              </div>
            </div>
            <div class="block" style="margin-top: 20px">
              <el-pagination
                align="center"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageNum"
                :page-sizes="[5]"
                :page-size="pageSize"
                layout="sizes, prev, pager, next"
                :total="total"
              >
              </el-pagination>
            </div>
            <!-- <div class="audio-load" v-else></div> -->
          </div>
        </div>
        <div class="main flex2 marginLeft1vw greyShadow">
          <div class="right padding11">
            <el-row :gutter="20" style="margin-right: 0; margin-bottom: 3vh">
              <el-col :span="8">
                <div class="buttons parent">
                  <el-input placeholder="请输入" v-model="searchInput"> </el-input>
                  <i class="el-icon-search searchInput"></i>
                </div>
              </el-col>
              <el-col :span="2">
                <el-button class="searchBtn">查询</el-button>
              </el-col>

              <el-col
                class="export"
                :span="4"
                :offset="6"
                style="float: right; margin: 0; padding: 0"
              >
                <el-button
                  plain
                  style="width: 100%"
                  class="basicBtn"
                  icon="el-icon-upload2"
                  @click="handleDownload('one')"
                  >导出本段文本</el-button
                >
              </el-col>
              <el-col :span="6" style="float: right; margin-right: 20px">
                <el-select
                  filterable
                  v-model="selectRole"
                  placeholder="请选择"
                  @change="changeRole"
                >
                  <el-option value="全部"> </el-option>
                  <el-option value="员工"> </el-option>
                  <el-option value="客户"> </el-option>
                </el-select>
              </el-col>
            </el-row>
            <div
              v-if="textList.length"
              style="width: 100%; height: 45vh; overflow: auto"
              class="right-content"
            >
              <div
                v-for="(item, index) in textList"
                :key="index"
                style="width: 100%; margin-bottom: 1.5vh"
              >
                <div class="saudio-content">
                  <div class="des">
                    <h5>{{ item.vocalTract }}</h5>
                    <span>{{ item.startTime }}</span>
                  </div>

                  <!-- <span style="margin:1vh;">{{item.start}}ms</span>
                      <span style="margin:1vh;">{{item.end}}ms</span> -->
                  <div class="sAudio-wrapper">
                    <audio class="sAudio">
                      <source :src="audioSource" type="audio/mp3" />
                    </audio>
                    <img
                      :id="'sAudioPlayer' + index"
                      src="../../../../assets/img/play.png"
                      @click="playerClick(index)"
                    />
                    <span :id="'sAudioTime' + index"></span>
                  </div>
                  <!-- <audio
                    style="height: 4vh; width: 7vw"
                    :id="'audio' + index"
                    controls
                    @play="
                      onPlay(
                        $event,
                        index,
                        'audio' + index,
                        item.start,
                        item.end
                      )
                    "
                    @pause="onPause($event)"
                  >
                    <source :src="audioSource" type="audio/wav" />
                  </audio> -->
                  <!-- <svg style= "width:4vw;height:4vh" @click="startplay(item.start,item.end,'audio'+index)" t="1614916180671" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1240" width="200" height="200"><path d="M511.33 62.75C263.22 62.75 62.08 263.89 62.08 512s201.14 449.25 449.25 449.25S960.58 760.11 960.58 512 759.45 62.75 511.33 62.75zM779.5 780.17a377.79 377.79 0 1 1 81.31-120.57 378 378 0 0 1-81.31 120.57z" fill="#CCCCCC" p-id="1241"></path><path d="M687.68 484l-236.2-176.2a35 35 0 0 0-55.92 28.06v352.33a35 35 0 0 0 55.92 28.06l236.2-176.2a35 35 0 0 0 0-56.1zM465.56 618.42V405.58L608.21 512z" fill="#CCCCCC" p-id="1242"></path></svg> -->
                  <!-- <svg style= "width:4vw;height:4vh;display: none;" t="1614916325329" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1779" width="200" height="200"><path d="M385.7 692c-11 0-20-9-20-20V352c0-11 9-20 20-20s20 9 20 20v320c0 11-9 20-20 20zM638.1 692c-11 0-20-9-20-20V352c0-11 9-20 20-20s20 9 20 20v320c0 11-8.9 20-20 20z" p-id="1780" fill="#bfbfbf"></path><path d="M512.5 958.3c-60.5 0-119.1-11.8-174.4-35.2-53.3-22.6-101.3-54.9-142.4-96-41.1-41.1-73.4-89-96-142.4-23.4-55.2-35.2-113.9-35.2-174.4s11.8-119.1 35.2-174.4c22.6-53.3 54.9-101.3 96-142.4s89-73.4 142.4-96C393.3 74.1 452 62.3 512.5 62.3s119.1 11.8 174.4 35.2c53.3 22.6 101.3 54.9 142.4 96 41.1 41.1 73.4 89 96 142.4 23.4 55.2 35.2 113.9 35.2 174.4s-11.8 119.1-35.2 174.4c-22.6 53.3-54.9 101.3-96 142.4-41.1 41.1-89 73.4-142.4 96-55.2 23.3-113.9 35.2-174.4 35.2z m0-856c-225 0-408 183-408 408s183 408 408 408 408-183 408-408-183-408-408-408z" p-id="1781" fill="#bfbfbf"></path></svg> -->
                </div>
                <div :id="'text' + index" class="con">{{ item.text }}</div>
              </div>
            </div>
            <div v-else style="width: 100%; height: 45vh" class="right-content">
              <span v-show="!allTextList.length" class="empty-text">此音频暂无文字信息</span>
              <span v-show="allTextList.length" class="empty-text">
                此音频下{{ selectRole }}暂无文字信息
              </span>
            </div>
          </div>
        </div>
      </el-row>
    </div>
    <el-dialog title="话术完成状态" :visible.sync="completeVisible">
      <el-tabs v-model="tabActive">
        <el-tab-pane :label="`未完成(${completeData.noFinishVerbalCount})`" name="undone">
          <el-tag
            style="margin: 0 0 10px 10px;"
            v-for="item in completeData.noFinishVerbal"
            :key="item"
            type="danger"
            effect="dark"
          >
            {{ item }}
          </el-tag>
        </el-tab-pane>
        <el-tab-pane :label="`已完成(${completeData.finishVerbalCount})`" name="done">
          <el-tag
            style="margin: 0 0 10px 10px;"
            v-for="item in completeData.finishVerbal"
            :key="item"
            effect="dark"
          >
            {{ item }}
          </el-tag>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <el-dialog title="关键字" :visible.sync="keywordsVisible">
      <el-tag style="margin: 0 0 10px 10px;" v-for="item in keywordsData" :key="item" effect="dark">
        {{ item.content }}({{ item.getCount }})
      </el-tag>
    </el-dialog>
  </div>
</template>
<script>
import playImg from '../../../../assets/img/play.png';
import pauseImg from '../../../../assets/img/pause.png';
import _ from 'lodash';
import {
  audioDayDetail,
  audioDayTextList,
  getAudioList,
  exportOneAudio,
  exportOneAudioExcel,
  exportAllAudio,
  exportAllAudioExcel,
  audioDayExportLogs,
  getAudioDayDetail,
  getAudioDaySpaces,
  getAudioDaySpacesDetail,
  getAudioDayDown,
  getAudioDayUp,
  finishVerbalInfo,
  verbalGetInfo,
  spaceFinishVerbalInfo,
  spaceVerbalGetInfo
} from '@/api/wordsAnalyze/audio';
import Tool from '@/utils/tool';
import { mapState, mapActions } from 'vuex';
import Radar from '../radar';
import { setTimeout } from 'timers';
const ls = window.sessionStorage;
var cur1 = 0;
var flag = 0;
var func = function(index) {
  var x = document.getElementById(index);
  alert(cur1);
  alert(x.currentTime);
  if (cur1 > 0 && x.currentTime >= cur1) {
    alert(5);
    cur1 = 0;
    x.pause();
    x.removeEventListener('timeupdate', func);
  }
};

function stopplay() {
  cur1 = 0;
  x.pause();
}
export default {
  name: 'audiodetail',
  computed: {
    ...mapState('supervision', ['notes', 'warnDetailData'])
  },
  components: {
    Radar
  },
  data() {
    return {
      completeData: {
        finishVerbal: [],
        noFinishVerbal: [],
        finishVerbalCount: 0,
        noFinishVerbalCount: 0
      },
      keywordsData: [],
      tabActive: 'undone',
      completeVisible: false,
      keywordsVisible: false,
      nextDayId: null,
      spaceDetail: { spaceLeftStaffDuration: 0, spaceStaffDuration: 0 },
      spaceId: '',
      selectAudio: '',
      selectAudioOptions: [],
      selectRole: '全部',
      exportloading: false,
      current_playId: '', // 当前右边显示的音频audioLogId
      audioInfo: { spaceLeftStaffDuration: 0, spaceStaffDuration: 0 },
      audioList: [],
      allTextList: [],
      textList: [],
      audioSource: '',
      show: false,
      cur: '',
      overAudioIndex: '',
      staffDetail: {},
      audioDetail: {},
      dialog: [],
      detailsResult: {},
      searchInput: '',
      textarea2: '',
      total: 0,
      pageSize: 5,
      pageNum: 1,
      audioloading: false,
      detailData: {
        audioFileCount: '',
        leftStaffDuration: '',
        scriptLibraryGetCount: '',
        staffDuration: '',
        uploadCount: '',
        verbalTrickCompleteness: ''
      },
      // 新增音频门店参数
      userId: '',
      deviceNo: '',
      audioPlayerEvent: null,
      timeupdate: null,
      ended: null,
    };
  },
  mounted() {
    this.getAudioDayDetail();
    // if (this.userId && this.deviceNo) {
    // }
  },

  methods: {
    async handleOpenKeywords(type) {
      this.keywordsVisible = true;
      this.keywordsData = [];
      if (type === 'space') {
        const res = await spaceVerbalGetInfo(
          this.audioInfo.audioDayId,
          this.audioInfo.userId,
          this.audioInfo.audioDate,
          this.spaceId || this.audioInfo.spaceId
        );
        this.keywordsData = res.code === 200 && res.data;
        return;
      }
      const res = await verbalGetInfo(this.audioInfo.userId, this.audioInfo.audioDate);
      this.keywordsData = res.code === 200 && res.data;
    },
    async handleOpenComplete(type) {
      this.completeVisible = true;
      this.completeData = {
        finishVerbal: [],
        noFinishVerbal: [],
        finishVerbalCount: 0,
        noFinishVerbalCount: 0
      };
      if (type === 'space') {
        const res = await spaceFinishVerbalInfo(
          this.audioInfo.audioDayId,
          this.audioInfo.userId,
          this.audioInfo.audioDate,
          this.spaceId || this.audioInfo.spaceId,
          this.audioInfo.projectId
        );
        // this.completeData.finishVerbal = res.code === 200 && res.data.spaceFinishVerbal;
        // this.completeData.noFinishVerbal = res.code === 200 && res.data.spaceNoFinishVerbal;
        // this.completeData.finishVerbalCount = res.code === 200 && res.data.spaceFinishVerbalCount;
        // this.completeData.noFinishVerbalCount =
        //   res.code === 200 && res.data.spaceNoFinishVerbalCount;
        this.completeData = res.code === 200 && res.data;
        return;
      }
      const res = await finishVerbalInfo(
        this.audioInfo.userId,
        this.audioInfo.audioDate,
        this.audioInfo.projectId
      );
      this.completeData = res.code === 200 && res.data;
    },
    async handleLastDay() {
      const res = await getAudioDayUp(this.audioInfo.audioDate, this.audioInfo.audioId);
      this.nextDayId = res.code === 200 && res.data ? res.data : null;
      !res.data && this.$message.warning('已经是最前一天了');
      res.data && this.getAudioDayDetail();
    },
    async handleNextDay() {
      const res = await getAudioDayDown(this.audioInfo.audioDate, this.audioInfo.audioId);
      this.nextDayId = res.code === 200 && res.data ? res.data : null;
      !res.data && this.$message.warning('已经是最后一天了');
      res.data && this.getAudioDayDetail();
    },
    handleClearAudio() {
      this.spaceId = '';
      this.getAudio();
      this.spaceDetail = { spaceLeftStaffDuration: 0, spaceStaffDuration: 0 };
    },
    async setSpaceId(id) {
      this.spaceId = id;
      this.getAudio();
      if (id) {
        const res = await getAudioDaySpacesDetail(this.audioInfo.audioDate, id);
        this.spaceDetail = res.code === 200 && res.data;
      }
    },
    async getSelectAudio() {
      const res = await getAudioDaySpaces(this.audioInfo.audioDate, this.userId);
      this.selectAudioOptions = res.code === 200 && res.data;
    },
    getAudioDayDetail() {
      getAudioDayDetail(this.nextDayId || this.$route.query.audioDayId).then(res => {
        if (res && res.code == 200) {
          let data = res.data;
          this.deviceNo = data.sn;
          this.userId = data.userId;
          this.audioInfo = res.data;
          // this.getAudio();
          const loading1 = this.$loading({
            background: 'rgba(255, 255, 255, 0.5)',
            target: document.getElementsByClassName('audio-content')[0]
          });
          const loading2 = this.$loading({
            background: 'rgba(255, 255, 255, 0.5)',
            target: document.getElementsByClassName('right-content')[0]
          });
          audioDayDetail(this.nextDayId || this.$route.query.audioDayId).then(async res => {
            loading1.close();
            loading2.close();
            this.audioInfo = res.data;
            getAudioList(
              this.nextDayId || this.$route.query.audioDayId,
              this.userId,
              this.deviceNo,
              '',
              this.pageNum,
              this.pageSize
            ).then(res => {
              this.audioloading = false;
              this.audioList = res.rows;
              this.allTextList = _.cloneDeep(res.rows[0].texts);
              this.textList = _.cloneDeep(res.rows[0].texts);
              this.current_playId = this.audioList[0].audioLogId; // 默认先赋值第一个
              this.audioSource = res.rows[0].resourceUrl;
              this.total = res.total;

              this.$nextTick(() => {
                const audios = document.getElementsByClassName('audio');
                for (var i = 0; i < audios.length; i++) {
                    this.initAudioEvent(i);
                }
                this.textList.forEach((item, index) => {
                  let sAudioTime = document.getElementById('sAudioTime' + index);
                  sAudioTime.innerText = this.transTime((item.end - item.start) / 1000);
                  let sText = document.getElementById('text' + index);

                  sText.innerHTML = this.setText(item);
                });
              });
            });
          });
          this.detailData = {
            audioFileCount: data.audioFileCount,
            leftStaffDuration: data.leftStaffDuration,
            scriptLibraryGetCount: data.scriptLibraryGetCount,
            staffDuration: data.staffDuration,
            uploadCount: data.uploadCount,
            verbalTrickCompleteness: data.verbalTrickCompleteness
          };
        } else {
          this.detailData = {
            audioFileCount: '',
            leftStaffDuration: '',
            scriptLibraryGetCount: '',
            staffDuration: '',
            uploadCount: '',
            verbalTrickCompleteness: ''
          };
        }
      });
    },
    async handleDownload(type) {
      if (type == 'one') {
        // 导出本段文本
        exportOneAudioExcel(this.current_playId, this.textList[0].startTime).then(res => {
          let blob = new Blob([res], { type: `text/plain;charset=utf-8` });
          let downloadElement = document.createElement('a');
          let href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '本段音频文字.xls';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        });
      } else {
        // 导出全部文本
        exportAllAudioExcel(this.audioInfo.audioDayId, this.audioInfo.audioDate).then(res => {
          let blob = new Blob([res], { type: `text/plain;charset=utf-8` });
          let downloadElement = document.createElement('a');
          let href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '当天音频文字.xls';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        });
      }
    },
    // // 音频链接下载  csv格式
    // async handleDownload(type) {
    //   let textData = "";
    //   if (type == "one") {
    //     // 导出本段文本
    //     textData = await exportOneAudio(
    //       this.current_playId,
    //       this.textList[0].startTime
    //     );
    //   } else {
    //     // 导出全部文本
    //     textData = await exportAllAudio(
    //       this.$route.query.audioDayId,
    //       this.$route.query.audioDate
    //     );
    //   }
    //   let blob = new Blob([textData], {
    //     type: `text/plain;charset=utf-8`,
    //   });
    //   let downloadElement = document.createElement("a");
    //   let href = window.URL.createObjectURL(blob);
    //   downloadElement.href = href;
    //   downloadElement.download = "音频文本.csv";
    //   document.body.appendChild(downloadElement);
    //   downloadElement.click();
    //   document.body.removeChild(downloadElement);
    //   window.URL.revokeObjectURL(href);
    // },
    getAudio() {
      this.audioloading = true;
      getAudioList(
        this.nextDayId || this.$route.query.audioDayId,
        this.userId,
        this.deviceNo,
        this.spaceId,
        this.pageNum,
        this.pageSize
      ).then(res => {
        this.audioList = res.rows;
        this.audioloading = false;
        this.allTextList = _.cloneDeep(res.rows[0].texts);
        this.textList = _.cloneDeep(res.rows[0].texts);
        this.audioSource = res.rows[0].resourceUrl;
        this.total = res.total;
        let audios = document.getElementsByClassName('audio');
        let sAudio = document.getElementsByClassName('sAudio');

        this.$nextTick(() => {
          this.textList.forEach((item, index) => {
            let sAudioTime = document.getElementById('sAudioTime' + index);
            sAudioTime.innerText = this.transTime((item.end - item.start) / 1000);
            let sText = document.getElementById('text' + index);
            sText.innerHTML = this.setText(item);
          });

          for (let i = 0; i < audios.length; i++) {
            audios[i].load();
            this.audioEnded(i);
            Promise.resolve().then(() => this.initAudioEvent(i));
          }
          for (let i = 0; i < sAudio.length; i++) {
            if (!sAudio[i].paused) {
              sAudio[i].load();
              document.getElementById('sAudioPlayer' + i).src = playImg;
            }
          }
        });
      });
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.allTextList = [];
      this.textList = [];
      this.getAudio();
    },
    handleSizeChange(val) {
      this.pageNum = 1;
      this.pageSize = val;
      this.getAudio();
    },
    initAudioEvent(index) {
      var audio = document.getElementsByClassName('audio')[index];
      var audioPlayer = document.getElementById('audioPlayer' + index);
      var audioTime = document.getElementById('audioTime' + index);
      audio.addEventListener('canplay', () => {
        audioTime.innerText = this.transTime(audio.duration);
      });

      const timeupdate = this.updateProgress.bind(this, audio, index);
      const ended = this.audioEnded.bind(this, index);
      const audioPlayerEvent = this.audioPlayerEventListener.bind(this, index)

      audio.ontimeupdate = () => this.updateProgress(audio, index);
      audio.onended = () => this.audioEnded(index);
      audioPlayer.onclick = () => this.audioPlayerEventListener(index);


        // // 监听音频播放时间并更新进度条
        // audio.addEventListener(
        //     'timeupdate', timeupdate, false
        // );
        //
        // // 监听播放完成事件
        // audio.addEventListener(
        //     'ended', ended, false
        // );
        //
        // // 点击播放/暂停图片时，控制音乐的播放与暂停
        // audioPlayer.addEventListener(
        //     'click', audioPlayerEvent,
        //     false
        // );

        // 点击进度条跳到指定点播放
        var progressBarBg = document.getElementById('progressBarBg' + index);
        progressBarBg.addEventListener(
            'mousedown',
            event => {
              // 只有音乐开始播放后才可以调节，已经播放过但暂停了的也可以
              if (!audio.paused || audio.currentTime != 0) {
                var pgsWidth = parseFloat(
                    window.getComputedStyle(progressBarBg, null).width.replace('px', '')
                );
                var rate = event.offsetX / pgsWidth;
                audio.currentTime = audio.duration * rate;
                this.updateProgress(audio, index);
              }
            },
            false
        );

        // 拖动进度点调节进度
        this.dragProgressDotEvent(audio, index);
    },

    async audioPlayerEventListener(index) {
      var audio = document.getElementsByClassName('audio')[index];
      var audioPlayer = document.getElementById('audioPlayer' + index);
        // 改变播放/暂停图片
        if (audio.paused) {
          const loadingRight = this.$loading({
            background: 'rgba(255, 255, 255, 0.5)',
            target: document.getElementsByClassName('right-content')[0]
          });
          // 暂停其他正在播放的音频
          var sAudio = document.getElementsByClassName('sAudio');
          var audios = document.getElementsByClassName('audio');
          for (var i = 0; i < audios.length; i++) {
            if (i !== index && !audios[i].paused) {
              audios[i].pause();
              document.getElementById('audioPlayer' + i).src = playImg;
            }
          }
          for (var i = 0; i < sAudio.length; i++) {
            if (!sAudio[i].paused) {
              sAudio[i].load();
              document.getElementById('sAudioPlayer' + i).src = playImg;
            }
          }
          // 开始播放当前点击的音频
          audio.play();
          audioPlayer.src = pauseImg;

          this.audioSource = this.audioList[index].resourceUrl;

          document.getElementsByClassName('right-content')[0].scrollTop = 0;
          this.current_playId = this.audioList[index].audioLogId;
          let textData = await audioDayTextList(
              this.audioList[index].audioLogId,
              this.audioList[index].audioStartTime
          );
          this.allTextList = textData.data.texts;
          this.textList = textData.data.texts;
          // this.allTextList = this.audioList[index].texts;
          // this.textList = this.audioList[index].texts;
          this.changeRole(this.selectRole);
          loadingRight.close();
        } else {
          audio.pause();
          audioPlayer.src = playImg;
        }
    },

    playTime(sAudioTime, starttime, endtime) {
      sAudioTime.innerText = this.transTime((endtime - starttime) / 1000);
    },
    playerClick(index) {
      var sAudio = document.getElementsByClassName('sAudio')[index];
      var sAudioPlayer = document.getElementById('sAudioPlayer' + index);
      var starttime = this.textList[index].start;
      var endtime = this.textList[index].end;

      if (sAudio.paused) {
        // 暂停其他正在播放的音频
        var audios = document.getElementsByClassName('audio');
        for (var ix = 0; ix < audios.length; ix++) {
          if (!audios[ix].paused) {
            audios[ix].pause();
            document.getElementById('audioPlayer' + ix).src = playImg;
          }
        }

        var sAudios = document.getElementsByClassName('sAudio');
        for (var i = 0; i < sAudios.length; i++) {
          if (i != index && !sAudios[i].paused) {
            sAudios[i].pause();
            document.getElementById('sAudioPlayer' + i).src = playImg;
          }
        }
        // 开始播放当前点击的音频
        sAudio.src = this.audioSource;
        sAudio.currentTime = starttime / 1000;
        sAudio.play();

        sAudioPlayer.src = pauseImg;
        var sTime = setTimeout(function() {
          sAudio.pause();
          sAudioPlayer.src = playImg;
        }, endtime - starttime);
      } else {
        clearTimeout(sTime);
        sAudio.pause();
        sAudioPlayer.src = playImg;
      }
    },

    dragProgressDotEvent(audio, index) {
      let _this = this;
      var dot = document.getElementById('progressDot' + index);

      var position = {
        oriOffestLeft: 0, // 移动开始时进度条的点距离进度条的偏移值
        oriX: 0, // 移动开始时的x坐标
        maxLeft: 0, // 向左最大可拖动距离
        maxRight: 0 // 向右最大可拖动距离
      };
      var flag = false; // 标记是否拖动开始

      // 鼠标按下时
      dot.addEventListener('mousedown', down, false);
      dot.addEventListener('touchstart', down, false);

      // 开始拖动
      document.addEventListener('mousemove', move, false);
      document.addEventListener('touchmove', move, false);

      // 拖动结束
      document.addEventListener('mouseup', end, false);
      document.addEventListener('touchend', end, false);

      function down(event) {
        if (!audio.paused || audio.currentTime != 0) {
          // 只有音乐开始播放后才可以调节，已经播放过但暂停了的也可以
          flag = true;

          position.oriOffestLeft = dot.offsetLeft;
          position.oriX = event.touches ? event.touches[0].clientX : event.clientX; // 要同时适配mousedown和touchstart事件
          position.maxLeft = position.oriOffestLeft; // 向左最大可拖动距离
          position.maxRight =
            document.getElementById('progressBarBg' + index).offsetWidth - position.oriOffestLeft; // 向右最大可拖动距离

          // 禁止默认事件（避免鼠标拖拽进度点的时候选中文字）
          if (event && event.preventDefault) {
            event.preventDefault();
          } else {
            event.returnValue = false;
          }

          // 禁止事件冒泡
          if (event && event.stopPropagation) {
            event.stopPropagation();
          } else {
            window.event.cancelBubble = true;
          }
        }
      }

      function move(event) {
        if (flag) {
          var clientX = event.touches ? event.touches[0].clientX : event.clientX; // 要同时适配mousemove和touchmove事件
          var length = clientX - position.oriX;
          if (length > position.maxRight) {
            length = position.maxRight;
          } else if (length < -position.maxLeft) {
            length = -position.maxLeft;
          }
          var progressBarBg = document.getElementById('progressBarBg' + index);
          var pgsWidth = parseFloat(
            window.getComputedStyle(progressBarBg, null).width.replace('px', '')
          );
          var rate = (position.oriOffestLeft + length) / pgsWidth;
          audio.currentTime = audio.duration * rate;
          _this.updateProgress(audio, index);
        }
      }

      function end() {
        flag = false;
      }
    },
    updateProgress(audio, index) {
      var value = audio.currentTime / audio.duration;
      document.getElementById('progressBar' + index).style.width = value * 100 + '%';
      document.getElementById('progressDot' + index).style.left = value * 100 + '%';
      document.getElementById('audioCurTime' + index).innerText = this.transTime(audio.currentTime);
    },
    audioEnded(index) {
      document.getElementById('progressBar' + index).style.width = 0;
      document.getElementById('progressDot' + index).style.left = 0;
      document.getElementById('audioCurTime' + index).innerText = this.transTime(0);
      document.getElementById('audioPlayer' + index).src = playImg;
    },
    transTime(value) {
      var time = '';
      var h = parseInt(value / 3600);
      value %= 3600;
      var m = parseInt(value / 60);
      var s = parseInt(value % 60);
      if (h > 0) {
        time = h + '°' + m + '`' + s + '```';
      } else {
        time = m + '`' + s + '``';
      }

      return time;
    },

    changeRole(val) {
      if (val !== '全部') {
        let newList = [];
        this.allTextList.forEach(item => {
          if (item.vocalTract === val) {
            newList.push(item);
          }
        });
        this.textList = newList;
      } else {
        this.textList = this.allTextList;
      }

      this.$nextTick(() => {
        this.textList.forEach((item, index) => {
          let sAudioTime = document.getElementById('sAudioTime' + index);
          sAudioTime.innerText = this.transTime((item.end - item.start) / 1000);
          let sText = document.getElementById('text' + index);
          sText.innerHTML = this.setText(item);
        });
      });
    },

    setText(item) {
      let str = item.text;
      let highlightText = item.highlightText;
      let strList = [];
      let list = [];
      if (str) {
        strList = str.split('');
      }
      if (highlightText) {
        list = JSON.parse(highlightText);
      }
      if (list.length) {
        for (let i = 0; i < list.length; i++) {
          let startNum = list[i].position[0];
          let endNum = list[i].position[1];
          if (startNum == endNum - 1) {
            strList[startNum] = `<span class="highlight">${str[startNum]}</span>`;
          } else {
            strList[startNum] = `<span class="highlight">${str[startNum]}`;
            strList[endNum - 1] = `${str[endNum - 1]}</span>`;
          }
        }
      }

      return strList.join('');
      // let newText = item.text;
      // if (item.highlightText) {
      //   let highlightText = item.highlightText.replaceAll("'", '"');
      //   if (JSON.parse(highlightText).length) {
      //     JSON.parse(highlightText).forEach((im, ix) => {
      //       newText = newText.replace(
      //         item.text.slice(im.position[0], im.position[1]),
      //         `<span class="highlight">${im.content}</span>`
      //       );
      //     });
      //   }
      // }
      // return newText;
    },

    onPlay(e, index, index2, starttime, endtime) {
      if (starttime > 0) {
        var x = document.getElementById(index2); //获取audio
        console.log(x);
        setTimeout(function() {
          x.pause();
        }, endtime - starttime);
        x.currentTime = starttime / 1000;
        x.play();
      } else {
        this.audioSource = this.audioList[index].resourceUrl;
        if (!this.cur) {
          this.cur = e.target;
          this.overAudioIndex = index;
        } else {
          if (this.overAudioIndex !== index) {
            this.cur.currentTime = 0;
            this.cur.pause();
            this.overAudioIndex = index;
          }
          this.cur = e.target;
        }
        this.textList = this.audioList[index].texts;
      }
    },
    onPause(e) {
      console.log(e);
    },
    getSessionSto() {
      this.detailsResult = JSON.parse(ls.getItem('detailResult'));
      this.audioDetail = this.detailsResult.audioDetail;
      this.staffDetail = this.detailsResult.staffDetail;
      this.dialog = this.detailsResult.dialogList[0].dialog;
      this.aa = this.detailsResult.dialogList[0].complexUrl;

      console.log(99);
    },

    getImgUrl(url) {
      return require('@/assets/pc/' + url + '.png');
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'audioRecord') {
      to.meta.keepAlive = true;
    }
    next();
  }
};
</script>

<style>
/* .orange {
  color: #fff;
  background: orange;
}
.danger {
  color: #fff;
  background: #f56c6c;
}
.green {
  color: #fff;
  background: #6aff00;
} */
.highlight {
  color: #23b899;
}
</style>

<style lang="scss" scoped>
@import '../../../../assets/common.scss';
$baseColor: #4e57aa;
$white: #fff;
$fontColor: #333;
$fontSize: 1.2em;

.audioDetail {
  margin: 2vh 1.5vw;

  .inlineTitle {
    height: 3vh;
    line-height: 3vh;
    font-size: 16px;
    margin-bottom: 1vw;
  }

  .subtitle {
    display: inline-block;
    position: relative;
    height: 22px;
    padding-left: 10px;
    margin-bottom: 1.95vh;
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    line-height: 22px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 4px;
      height: 16px;
      background: #4e57aa;
    }
  }

  .empty-text {
    display: block;
    line-height: 45vh;
    text-align: center;
    color: #909399;
  }

  .audio-content {
    height: 45vh;
    overflow: auto;

    > div {
      &:last-child {
        margin-bottom: 0 !important;
      }
    }

    .des {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.463vh;
      padding-left: 0.68vw;
      padding-right: 1.25vw;
      font-size: 14px;
      color: #999999;
      box-sizing: border-box;
      .startTime {
        &::after {
          content: '*';
          margin-left: 5px;
          color: red;
        }
      }
    }

    .audio-wrapper {
      background-color: rgba(78, 87, 170, 0.1);
      border-radius: 12px;
      width: 100%;
      height: 4.8vh;
      padding: 0 1.04vw;
      display: flex;
      align-items: center;
      box-sizing: border-box;

      .audio {
        display: none;
      }

      img {
        width: 1.6146vw;
        height: 1.6146vw;
        cursor: pointer;
      }

      .progress-bar-bg {
        flex: 1;
        background-color: rgba(78, 87, 170, 0.3);
        position: relative;
        height: 2px;
        // margin-left: 0.3125vw;
        cursor: pointer;
        border-radius: 0.2vw;

        span {
          width: 0.625vw;
          height: 0.625vw;
          border-radius: 50%;
          background-color: #fff;
          border: 0.1vw solid #4e57aa;
          position: absolute;
          // left: 0.3125vw;
          top: 50%;
          margin-top: -0.3125vw;
          // margin-left: -0.3125vw;
          cursor: pointer;
          box-sizing: border-box;
        }

        .progress-bar {
          background-color: #4e57aa;
          width: 0;
          height: 2px;
        }
      }
    }
  }

  .audio-load {
    height: 45vh;
  }

  .saudio-content {
    width: 100%;
    // height: 3vh;
    padding-right: 1.354vw;
    margin-bottom: 1.39vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

    .des {
      // height: 100%;
      display: flex;
      align-items: center;

      h5 {
        margin: 0;
        margin-right: 0.834vw;
        font-size: 16px;
        font-weight: 600;
        color: #333333;
      }

      > span {
        font-size: 14px;
        color: #999999;
      }
    }

    .sAudio-wrapper {
      display: flex;
      align-items: center;
      width: 80px;

      .sAudio {
        display: none;
      }

      > img {
        width: 1.6146vw;
        height: 1.6146vw;
        margin-right: 0.573vw;
        cursor: pointer;
      }

      > span {
        font-size: 14px;
        color: #999999;
        line-height: 1.6146vw;
      }
    }
  }

  .breadcrumb {
    .items {
      font-size: 14px;
    }
  }

  .main {
    background: #fff;
    padding: 2vh 1vw;
    margin-top: 2vh;
    border-radius: 2vh;

    .title {
      display: flex;
      justify-content: space-between;

      .left {
        display: flex;

        .sep {
          background: $baseColor;
          width: 5px;
          height: 1.5rem;
          display: inline-block;
          margin: 0 5px;
        }

        h4 {
          padding: 0;
          margin: 0;
        }
      }
    }

    .details {
      background: $baseColor;
      border-radius: 25px;
      // height: 15vh;
      display: flex;
      padding: 4.6vh 0;
      color: #fff;

      .flexl {
        flex: 30%;
        // padding: 1vh 0;
        // padding-right: 4.7vw;
        border-right: 1px solid rgba(229, 229, 255, 0.3);
        box-sizing: border-box;
        .flexContent {
          display: flex;
          align-items: center;
        }
        .flexInfo {
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          padding: 0 1vw;
          > p {
            margin: 1.5vh 0 0 0;
            white-space: nowrap;
          }
        }

        .avatar {
          width: 5.2vw;
          height: 5.2vw;
          margin: 0 1.5625vw;

          /deep/.el-avatar {
            width: 100% !important;
            height: 100% !important;
            line-height: 5.2vw !important;

            i {
              font-size: 28px;
            }
          }
        }

        .info {
          p {
            margin: 0;
            font-size: 14px;
            margin-bottom: 1.94vh;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      .flexr {
        flex: 70%;
        display: flex;
        align-items: center;
        padding: 0 5.47vw;
        box-sizing: border-box;

        h4 {
          margin: 0;
          font-size: 16px;
          margin-bottom: 1.39vh;
        }

        p {
          margin: 0;
          font-size: 14px;
        }

        .content {
          margin-right: 3vw;
        }
        .infoShow {
          display: grid;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          gap: 20px 25px;
          > p {
            margin: 0;
            white-space: nowrap;
          }
          .complete {
            text-decoration: underline;
            text-underline-offset: 0.3em;
            cursor: pointer;
            .green {
              color: rgb(129, 244, 129);
            }
            .yel {
              color: rgb(252, 252, 130);
            }
            .red {
              color: rgb(253, 109, 109);
            }
          }
        }
        .stati-content {
          display: flex;
          .stati-content-item1 {
            margin-right: 40px;
          }
        }
      }

      .left {
        display: flex;
        width: 75%;

        // border-right: 1px solid rgba(255, 255, 255, 0.5);
        .flex1 {
          flex: 1;
        }

        .flex3 {
          flex: 3;
        }

        .group {
          font-size: 0.9em;
        }
      }

      .right {
        padding-left: 10%;
        width: 50%;

        .flex {
          height: 3vh;
          line-height: 3vh;

          .tag {
            color: #ffc531;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 1vw;
            margin-left: 1vw;
            padding: 1px 1vw;
          }
        }
      }
    }

    .chartDiv {
      .left {
        flex: 6;

        .notes {
          background: #f9f9fd;
          border-radius: 8px;
          display: flex;
          width: 40%;
          margin: 2vh 2vh 0;
          padding: 2vh;
          float: left;

          .flex7 {
            flex: 7;
          }

          .num {
            color: #4e57aa;
            font-size: 1.2rem;
          }
        }
      }

      .right {
        flex: 4;
      }
    }
  }

  .bottom {
    // .main {
    //   height: 50vh;
    // }
    // .audio {
    //   width: 100%;
    // }
    .con {
      height: auto;
      padding: 10px;
      background: #f7f7f9;
      border-radius: 8px;
    }
  }
}

.block /deep/ .el-pager li.active {
  color: #fff;
  cursor: default;
  background: #4e57aa;
  border-radius: 5px;
}
/deep/ .el-calendar {
  background: rgba(0, 0, 0, 0) !important;
}
/deep/ .el-calender tbody {
  display: flex;
}
/deep/ .el-calendar__header {
  display: none;
}
/deep/ .el-calendar-table .el-calendar-day {
  width: 40px;
  height: 40px;
}
/deep/ .el-calendar-day {
  border: 1px solid rgba(16, 132, 134, 0.4);
}
/deep/ .el-calendar-day:hover {
  background: rgba(0, 0, 0, 0) !important;
}
/deep/ .current {
  background: rgba(0, 0, 0, 0) !important;
}
/deep/ .is-selected .el-calendar-day {
  border: 1px solid #20f6f6 !important;
}
/deep/ .el-calendar-table tr td {
  border: none !important;
}
</style>
