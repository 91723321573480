<template>
  <div class="bubble-chart" ref="bubbleChart"></div>
</template>

<script>
import resize from "../../../pc/mixin/echartsResize";

export default {
  data() {
    return {
      chart: null,
    };
  },
  mixins: [resize],

  props: ["bubbleData"],
  watch: {
    bubbleData() {
      this.initChart();
    },
  },
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      this.chart = this.$echarts.init(this.$refs.bubbleChart);
      let min = 1;
      let max = 100;
      function getScale([d1, d2], [r1, r2]) {
        return function (val) {
          return ((val - d1) / (d2 - d1)) * (r2 - r1) + r1;
        };
      }
      let scale = getScale([min, max], [38, 100]);
      let scaleFontSizeName = getScale([min, max], [10, 16]);
      let scaleFontSize = getScale([min, max], [12, 30]);
      // let data = [
      //   {
      //     name: "枫林1",
      //     value: 100,
      //   },
      //   {
      //     name: "枫林2",
      //     value: 300,
      //   },
      //   {
      //     name: "枫林3",
      //     value: 200,
      //   },
      //   {
      //     name: "枫林4",
      //     value: 500,
      //   },
      //   {
      //     name: "枫林5",
      //     value: 800,
      //   },
      //   {
      //     name: "枫林6",
      //     value: 600,
      //   },
      // ];
      let chartdata = this.bubbleData.map((item) => {
        return {
          name: item.content,
          value: item.getCount,
          symbolSize: scale(item.getCount),
          label: {
            rich: {
              name: {
                fontSize: parseInt(scaleFontSizeName(item.getCount)),
                lineHeight: parseInt(scaleFontSizeName(item.getCount)) * 1.5,
              },
              value: {
                fontSize: parseInt(scaleFontSize(item.getCount)),
                lineHeight: parseInt(scaleFontSize(item.getCount)) * 1.5,
              },
            },
          },
        };
      });
      const option = {
        // 图表标题
        backgroundColor: "#fff",
        tooltip: {},
        animationDurationUpdate: function (idx) {
          // 越往后的数据延迟越大
          return idx * 100;
        },
        animationEasingUpdate: "bounceIn",
        color: ["#4E57AA"],
        draggable: true,
        itemStyle: {
          shadowBlur: 10,
          shadowColor: "rgba(220, 211, 255, 0.5)",
          shadowOffsetX: 0,
          shadowOffsetY: 2,
        },
        series: [
          {
            type: "graph",
            layout: "force",
            force: {
              repulsion: 120,
              edgeLength: 20,
            },
            roam: true,
            label: {
              show: true,
              formatter: ["{value|{c}}", "{name|{b}}"].join("\n"),
              rich: {
                value: {
                  color: "#FFC531",
                  align: "center",
                  //   padding: [0, 0, 5, 0],
                },
                name: {
                  color: "#fff",
                  align: "center",
                  // padding:[3,0],
                },
              },
            },
            data: chartdata,
          },
        ],
      };

      this.chart.setOption(option);
    },
  },
};
</script>


