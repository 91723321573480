<template>
  <div class="speechAnalysis">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/wordsAnalyze' }"
          >话术分析</el-breadcrumb-item
        >
        <el-breadcrumb-item>王牌话术分析列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main">
      <div class="title">
        <div class="left">
          <i class="sep"></i>
          <h4>王牌话术分析</h4>
        </div>
        <div class="right">
          <el-date-picker
            v-model="date"
            type="daterange"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
      </div>
      <el-row :gutter="30" class="marginTop">
        <el-col :span="7">
          <span class="selectName">王牌销售：</span>
          <el-select
            v-model="sales.value"
            clearable
            placeholder="请选择"
            filterable
          >
            <el-option
              v-for="item in sales.options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="7">
          <span class="selectName">项目：</span>
          <el-select
            v-model="project.value"
            clearable
            placeholder="全部"
            filterable
          >
            <el-option
              v-for="item in project.options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="30" class="marginTop">
        <el-col :span="7">
          <span class="selectName indent2">行业：</span>
          <el-select
            v-model="profession.value"
            clearable
            placeholder="请选择"
            filterable
          >
            <el-option
              v-for="item in profession.options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="7">
          <span class="selectName">区域：</span>
          <el-select
            v-model="area.value"
            clearable
            placeholder="请选择"
            filterable
          >
            <el-option-group
              v-for="group in area.options"
              :key="group.label"
              :label="group.label"
            >
              <el-option
                v-for="item in group.options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-option-group>
          </el-select>
        </el-col>
        <el-col :span="5" class="parent">
          <el-input placeholder="请输入内容" v-model="searchInput"> </el-input>
          <i class="el-icon-search searchInput"></i>
        </el-col>
        <el-col :span="3" :offset="3" class="fr">
          <el-button class="searchBtn">查询</el-button>
          <el-button>重置</el-button>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <TableChart
          :tableDatas="{
            id: 'speechAnalysisTable',
            data: speechAnalysisTable,
          }"
          :pageData="pageData"
        ></TableChart>
      </el-row>
    </div>
  </div>
</template>
<script>
import TableChart from "../table";
import { mapState, mapActions } from "vuex";
export default {
  computed: {
    ...mapState("speechAnalysis", ["speechAnalysisTable"]),
  },
  components: {
    TableChart,
  },
  data() {
    return {
      pageData: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      date: "",
      sales: {
        value: "",
        options: [
          {
            value: 0,
            label: "张雨晨",
          },
          {
            value: 1,
            label: "王楠",
          },
        ],
      },
      project: {
        value: "",
        options: [
          {
            value: 0,
            label: "语音项目",
          },
          {
            value: 1,
            label: "项目",
          },
        ],
      },
      profession: {
        value: "",
        options: [
          {
            value: 0,
            label: "直播",
          },
          {
            value: 1,
            label: "餐饮",
          },
        ],
      },
      area: {
        value: "",
        options: [
          {
            label: "热门城市",
            options: [
              {
                value: "Shanghai",
                label: "上海",
              },
              {
                value: "Beijing",
                label: "北京",
              },
            ],
          },
          {
            label: "城市名",
            options: [
              {
                value: "Chengdu",
                label: "成都",
              },
              {
                value: "Shenzhen",
                label: "深圳",
              },
              {
                value: "Guangzhou",
                label: "广州",
              },
              {
                value: "Dalian",
                label: "大连",
              },
            ],
          },
        ],
      },
      searchInput: "",
    };
  },
};
</script>

<style lang="scss" scoped>
$baseColor: #4e57aa;
$white: #fff;
$fontColor: #333;
$fontSize: 1.2em;
.speechAnalysis {
  margin: 2vh 1.5vw;
  .marginTop {
    margin-top: 2vh;
  }
  .main {
    background: #fff;
    padding: 2vh 1vw;
    margin-top: 2vh;
    border-radius: 2vh;
    height: 100%;
    min-height: 75vh;
    /deep/.el-select {
      width: 74%;
    }
    .title {
      display: flex;
      justify-content: space-between;
      .left {
        display: flex;
        .sep {
          background: $baseColor;
          width: 5px;
          height: 1.5rem;
          display: inline-block;
          margin: 0 5px;
        }
        h4 {
          padding: 0;
          margin: 0;
        }
      }
    }
    .fr {
      float: right;
      width: auto;
      margin-left: 0;
    }
    .selectName {
      // width: 26%;
      font-size: 14px;
      display: inline-block;
      color: $fontColor;
      // text-align: left;
      // text-align-last: justify;
    }
    .indent2 {
      padding-right: 2em;
    }
    .parent {
      position: relative;
      .searchInput {
        position: absolute;
        right: 0;
        height: 100%;
        text-align: center;
        background: $baseColor;
        width: 2vw;
        right: 0px;
        top: 0;
        border-radius: 0 5px 5px 0;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .searchBtn {
      color: $white;
      background-color: $baseColor;
    }
  }
}
</style>