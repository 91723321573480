<template>
  <div class="warnDetail">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/inspection' }"
          >云巡检</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/inspection/supervisorWarn' }"
          >督导员预警列表</el-breadcrumb-item
        ><el-breadcrumb-item>督导员预警详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="details">
      <div class="title">
        <div class="left">
          <i class="sep"></i>
          <h4>督导员预警详情</h4>
        </div>
      </div>
      <div class="board marginTop">
        <div class="left">
          <div class="block">
            <el-avatar icon="el-icon-user-solid"></el-avatar>
          </div>
          <div class="info">
            <p class="name">{{ supervisData.userName }}</p>
            <p class="no">
              <span style="margin-right: 0.78vw"
                >手机号：{{ supervisData.phone }}</span
              >
              <span>身份证号：{{ supervisData.idCard }}</span>
            </p>
          </div>
        </div>
        <div class="right">
          <div class="stau">
            <p>{{ supervisData.warningTypeName }}</p>
            <span>预警时间：{{ supervisData.warningStartTime }}</span>
            <span>预警时长：{{ supervisData.warningTime }}min</span>
          </div>
        </div>
      </div>
    </div>
    <div class="table-content">
      <div class="head">
        <div class="left">
          <i class="sep"></i>
          <h4>上海布点项目</h4>
        </div>
        <div class="right">
          <el-date-picker
            v-model="date"
            readonly
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
      </div>
      <TableChart
        class="mb"
        :tableDatas="{
          id: 'sDetailTable1',
          data: sDetailTable1,
        }"
      ></TableChart>
      <TableChart
        class="wh"
        :tableDatas="{
          id: 'sDetailTable2',
          data: sDetailTable2,
        }"
      ></TableChart>
    </div>
    <div class="chart-content">
      <div class="head">
        <i class="sep"></i>
        <h4>门店情况指数</h4>
      </div>
      <ul>
        <li v-for="(item, index) in spaceCharts" :key="index">
          <ring-chart
            color="#5885FF"
            :value="item.warningAver"
            @click.native="changeLine(item)"
          ></ring-chart>
          <p>{{ item.spaceName }}</p>
          <span>预警率</span>
        </li>
        <!-- <li>
          <ring-chart color="#5243AA" :value="50"></ring-chart>
          <p>门店二</p>
          <span>预警率</span>
        </li>
        <li>
          <ring-chart color="#FF991F" :value="25"></ring-chart>
          <p>门店三</p>
          <span>预警率</span>
        </li>
        <li>
          <ring-chart color="#00875A" :value="75"></ring-chart>
          <p>门店四</p>
          <span>预警率</span>
        </li>
        <li>
          <ring-chart color="#FFC531" :value="75"></ring-chart>
          <p>门店五</p>
          <span>预警率</span>
        </li> -->
      </ul>
      <h4>预警情况</h4>
      <div class="warning">
        <line-chart :lineData="warnCharts"></line-chart>
      </div>
    </div>
  </div>
</template>

<script>
import TableChart from "../table";
import ringChart from "./ringChart";
import lineChart from "./lineChart";
import { supervisorDetail, spaceInfo } from "@/api/inspection/supervisor";

export default {
  data() {
    return {
      supervisData: {},
      spaceCharts: [],
      warnCharts: [],
      date: [],
      sDetailTable1: {
        columns: [
          {
            col: "负责门店数量",
            prop: "spaceNum",
            width: "",
          },
          {
            col: "督导预警次数",
            prop: "warningCount",
            width: "",
          },
          {
            col: "促销员预警人数",
            prop: "averageUserNum",
            width: "",
          },
          {
            col: "促销员预警次数",
            prop: "averageWarningNum",
            width: "",
          },
        ],
        data: [],
      },
      sDetailTable2: {
        columns: [
          {
            col: "序号",
            prop: "supervisorWarningLogId",
            width: "",
          },
          {
            col: "督导预警类型",
            prop: "warningTypeName",
            width: "",
          },
          {
            col: "预警门店数",
            prop: "supervisorSpaceSum",
            width: "",
          },
          {
            col: "促销员预警人数",
            prop: "supervisorUserSum",
            width: "",
          },
          {
            col: "督导次数",
            prop: "supervisorCheckSum",
            width: "",
          },
          {
            col: "预警开始时间",
            prop: "warningStartTime",
            width: "",
          },
          {
            col: "预警结束时间",
            prop: "warningEndTime",
            width: "",
          },
        ],
        data: [],
      },
    };
  },
  components: {
    TableChart,
    ringChart,
    lineChart,
  },
  created() {
    let supervisRow = JSON.parse(localStorage.getItem("supervisRow"));
    supervisorDetail(supervisRow).then((res) => {
      this.supervisData = res.data;
      this.spaceCharts = res.data.allSpaceInfos;
      let date = [];
      date[0] = res.data.projectStartTime;
      date[1] = res.data.projectEndTime;
      this.date = date;
      let table1 = {};
      table1.spaceNum = res.data.spaceNum;
      table1.warningCount = res.data.warningCount;
      table1.averageUserNum = res.data.averageUserNum;
      table1.averageWarningNum = res.data.averageWarningNum;
      this.sDetailTable1.data[0] = table1;
      this.sDetailTable2.data = res.data.warningLogs;
      this.spaceCharts = res.data.allSpaceInfos;
      // let warningUserCount = res.data.spaceInfos.map((item) => item.warningUserCount);
      // let warningAver = res.data.spaceInfos.map((item) => item.warningAver);
      // let averageTime = res.data.spaceInfos.map((item) => item.averageTime);

      // this.warnCharts.warningUserCount = warningUserCount;
      // this.warnCharts.warningAver = warningAver;
      // this.warnCharts.averageTime = averageTime;
      this.warnCharts = res.data.spaceInfos;
      // console.log(this.warnCharts)
    });
  },
  methods: {
    changeLine(item) {
      spaceInfo(item.spaceId, item.sdate, item.projectId).then((res) => {
        this.warnCharts = res.data.spaceInfos;
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === "supervisorWarn") {
      to.meta.keepAlive = true;
    }
    next();
  },
};
</script>

<style lang="scss" scoped>
$baseColor: #4e57aa;
$white: #fff;
$fontColor: #333;
$fontSize: 1.2em;
p {
  margin: 0;
  padding: 0;
}
.warnDetail {
  margin: 2vh 1.5vw;
  .details {
    background: #fff;
    padding: 2vh 1vw;
    margin-top: 2vh;
    margin-bottom: 1.852vh;
    border-radius: 1.5vh;
    .title {
      display: flex;
      justify-content: space-between;
      .left {
        display: flex;
        .sep {
          background: $baseColor;
          width: 5px;
          height: 1.5rem;
          display: inline-block;
          margin: 0 5px;
        }
        h4 {
          padding: 0;
          margin: 0;
        }
      }
    }
    .board {
      display: flex;
      padding: 4.6vh 0;
      background: #4e57aa;
      border-radius: 2.3vh;
      .left {
        width: 48%;
        display: flex;
        // padding: 0 6.9vw 0 1.56vw;
        border-right: 1px solid #e5e5ff;
        .block {
          width: 5.2vw;
          height: 5.2vw;
          margin: 0 1.56vw;
          > span {
            width: 100%;
            height: 100%;
          }
          /deep/.el-avatar--icon {
            font-size: 28px;
            line-height: 5.2vw;
          }
        }
        .info {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .name {
            font-size: 16px;
            color: #ffffff;
          }
          .no {
            font-size: 14px;
            color: #ffffff;
          }
        }
      }
      .right {
        flex: 1;
        .stau {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          margin-left: 14.7vw;
          p {
            font-size: 16px;
            font-weight: 600;
            color: #ff5634;
          }
          span {
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
    }
  }
  .table-content {
    overflow: hidden;
    background: #fff;
    margin-bottom: 1.852vh;
    padding-bottom: 2.6vh;
    border-radius: 1.5vh;
    .head {
      display: flex;
      justify-content: space-between;
      height: 3.8vh;
      margin: 1.85vh 1vw 1vh 1vw;
      .left {
        display: flex;
        height: 100%;
        align-items: center;
        .sep {
          background: $baseColor;
          width: 5px;
          height: 1.5rem;
          display: inline-block;
          margin: 0 5px;
        }
        h4 {
          padding: 0;
          margin: 0;
        }
      }
      .right {
        width: 24%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        /deep/.el-range-input {
          width: 44%;
        }
        /deep/.el-range__close-icon {
          display: none;
        }
        // /deep/.el-range-editor--small.el-input__inner {
        //   height: 100%;
        // }
      }
    }
    .table {
      margin-left: 1.4vw;
      margin-right: 1.4vw;
      /deep/.el-table {
        height: auto !important;
      }
    }
    .mb {
      margin-bottom: 1.63vh;
    }
    .wh {
      height: 35vh;
      /deep/.el-table {
        height: 100% !important;
      }
    }
  }
  .chart-content {
    padding: 1.852vh 1.56vw 7.8vh 1.56vw;
    background: #fff;
    border-radius: 1.5vh;
    .head {
      display: flex;
      margin-bottom: 3.4vh;
      .sep {
        background: $baseColor;
        width: 5px;
        height: 1.5rem;
        display: inline-block;
        margin: 0 5px;
      }
      h4 {
        padding: 0;
        margin: 0;
      }
    }
    ul {
      display: flex;
      flex-wrap: nowrap;
      // min-height: 20vh;
      overflow: hidden;
      overflow-x: auto;
      justify-content: space-between;
      margin: 0 4.5vw;
      margin-bottom: 2.22vh;
      padding: 0;
      padding-bottom: 2vh;
      &::-webkit-scrollbar {
        height: 2vh;
      }
      li {
        list-style: none;
        margin-right: 8vw;
        text-align: center;
        &:last-child {
          margin-right: 0;
        }
        .ring-chart {
          width: 6.25vw;
          height: 6.25vw;
          margin: 0 auto;
          margin-bottom: 2vh;
          /deep/ canvas {
            cursor: pointer;
          }
        }
        p {
          white-space: nowrap;
          // height: 2.04vh;
          // line-height: 2.04vh;
          font-size: 16px;
          font-weight: 400;
          color: #171717;
          margin-bottom: 1.3vh;
        }
        span {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
        }
      }
    }
    h4 {
      font-size: 18px;
      font-weight: 600;
      color: #333333;
      margin-bottom: 3.7vh;
    }
    .warning {
      width: 100%;
      height: 55.5vh;
      .line-chart {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
