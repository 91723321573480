<template>
  <div class="speechAnalysis">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">系统管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/systemManage/account' }"
          >账户管理</el-breadcrumb-item
        >
        <el-breadcrumb-item v-if="$route.query.userId"
          >编辑账户</el-breadcrumb-item
        >
        <el-breadcrumb-item v-else>新增账户</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main">
      <div class="title">
        <div class="left">
          <i class="sep"></i>
          <h3 v-show="activeName === 'first'">基础信息</h3>
          <h3 v-show="activeName === 'second'">权限配置</h3>
        </div>
      </div>
      <div class="tabs">
        <el-tabs v-model="activeName" @tab-click="tabClick">
          <el-tab-pane name="first">
            <div slot="label">
              <img
                class="tab-img"
                v-if="activeName === 'first'"
                src="../../../../assets/pc/基础信息-选中.png"
              />
              <img
                class="tab-img"
                v-else
                src="../../../../assets/pc/基础信息.png"
              />
              基础信息
            </div>
            <el-form
              :inline="true"
              :model="basicInfoForm"
              :rules="rules"
              ref="ruleForm"
              class="basic_form"
            >
              <h5 style="margin-bottom: 1.85vh">基本信息</h5>
              <el-form-item label="用户名：" prop="userName" class="indent1">
                <el-input v-model="basicInfoForm.userName"></el-input>
              </el-form-item>
              <!-- <el-form-item label="新密码：" class="indent15">
                <el-button class="editpass">修改密码</el-button>
              </el-form-item> -->
              <el-form-item
                label="密码："
                :prop="$route.query.userId ? '' : 'password'"
                :class="$route.query.userId ? 'indent25' : 'indent2'"
              >
                <el-input
                  v-model="basicInfoForm.password"
                  show-password
                ></el-input>
              </el-form-item>
              <el-form-item label="性别：" class="indent25">
                <el-select v-model="basicInfoForm.sex" filterable>
                  <el-option label="男" value="0"></el-option>
                  <el-option label="女" value="1"></el-option>
                  <el-option label="未知" value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="重复密码："
                :prop="$route.query.userId ? '' : 'repassword'"
                :class="$route.query.userId ? 'indent16' : ''"
              >
                <el-input
                  v-model="basicInfoForm.repassword"
                  show-password
                ></el-input>
              </el-form-item>
              <el-form-item label="电子邮件：" class="indent16">
                <el-input v-model="basicInfoForm.email"></el-input>
              </el-form-item>
              <el-form-item label="姓名：" prop="nickName" class="indent2">
                <el-input v-model="basicInfoForm.nickName"></el-input>
              </el-form-item>

              <h5 class="form_title">所属角色</h5>
              <el-form-item label="手机号：" prop="phone" class="indent1">
                <el-input v-model="basicInfoForm.phone"></el-input>
              </el-form-item>
              <el-form-item label="用户角色：" prop="roleId">
                <el-select v-model="basicInfoForm.roleId" filterable>
                  <el-option
                    v-for="item in roleMenu"
                    :key="item.roleId"
                    :label="item.roleName"
                    :value="item.roleId"
                  >
                  </el-option>
                  <!-- <el-option value="选择一"></el-option>
                  <el-option value="选择二"></el-option> -->
                </el-select>
                <!-- <el-input v-model="basicInfoForm.roleId"></el-input> -->
              </el-form-item>
              <el-form-item label="身份证号：" prop="idCard">
                <el-input v-model="basicInfoForm.idCard"></el-input>
              </el-form-item>
              <el-form-item label="用户状态：" prop="status">
                <el-switch
                  v-model="basicInfoForm.status"
                  active-color="#4e57aa"
                  inactive-color="#cfcfcf"
                  active-value="0"
                  inactive-value="1"
                ></el-switch>
              </el-form-item>
              <el-form-item label="客户名称：" prop="customerId">
                <el-select v-model="basicInfoForm.customerId" filterable>
                  <el-option
                    v-for="item in customerMenu"
                    :key="item.customerId"
                    :label="item.customerName"
                    :value="item.customerId"
                  >
                  </el-option>
                  <!-- <el-option value="选择一"></el-option>
                  <el-option value="选择二"></el-option> -->
                </el-select>
              </el-form-item>
            </el-form>
            <el-button
              type="primary"
              icon="el-icon-refresh-right"
              class="subBtn"
              @click="onSubmitInfo"
              >提交</el-button
            >
            <el-button @click="onBack">返回列表</el-button>
          </el-tab-pane>
          <el-tab-pane name="second">
            <div slot="label">
              <img
                class="tab-img"
                v-if="activeName === 'second'"
                src="../../../../assets/pc/权限-选中.png"
              />
              <img
                class="tab-img"
                v-else
                src="../../../../assets/pc/权限.png"
              />
              权限配置
            </div>
            <h5>数据权限账户</h5>
            <el-form :model="permitForm" label-width="auto" class="permit_form">
              <el-form-item label="姓名：">
                <!-- <el-input v-model="permitForm.name"></el-input> -->
                <span>{{ permitForm.nickName }}</span>
              </el-form-item>
              <el-form-item label="手机号：">
                <!-- <el-input v-model="permitForm.tel"></el-input> -->
                <span>{{ permitForm.phone }}</span>
              </el-form-item>
              <el-form-item label="身份证号：">
                <!-- <el-input v-model="permitForm.id"></el-input> -->
                <span>{{ permitForm.idCard }}</span>
              </el-form-item>
              <el-form-item label="用户状态：">
                <el-switch
                  :value="permitForm.status"
                  active-color="#4e57aa"
                  inactive-color="#cfcfcf"
                  active-value="0"
                  inactive-value="1"
                ></el-switch>
              </el-form-item>
              <el-form-item label="所属角色：">
                <span>{{ permitForm.roleName }}</span>
                <!-- <el-input v-model="permitForm.type"></el-input> -->
                <!-- <el-select v-model="permitForm.type" class="allw">
                  <el-option value="选择一"></el-option>
                  <el-option value="选择二"></el-option>
                </el-select> -->
              </el-form-item>
              <el-form-item label="所属项目门店：">
                <el-select
                  filterable
                  v-model="customerId"
                  placeholder="客户名称"
                  @change="changeCustomer"
                >
                  <el-option
                    v-for="item in customerMenu"
                    :key="item.customerId"
                    :label="item.customerName"
                    :value="item.customerId"
                  >
                  </el-option>
                </el-select>
                <el-select
                  filterable
                  v-model="deptId"
                  placeholder="部门名称"
                  @change="changeDept"
                >
                  <el-option
                    v-for="item in deptMenu"
                    :key="item.deptId"
                    :label="item.deptName"
                    :value="item.deptId"
                  >
                  </el-option>
                </el-select>
                <el-select
                  filterable
                  v-model="projectId"
                  placeholder="项目名称"
                  @change="changeProject"
                >
                  <el-option
                    v-for="item in projectMenu"
                    :key="item.projectId"
                    :label="item.projectName"
                    :value="item.projectId"
                  >
                  </el-option>
                </el-select>
                <!-- <div> -->
                <el-cascader
                  v-if="projectId"
                  v-model="address"
                  :props="addressProps"
                  @change="changeAddress"
                  @expand-change="addressChange"
                  clearable
                ></el-cascader>
                <el-select v-else placeholder="区域"></el-select>
                <!-- </div> -->
                <el-select v-model="spaceId" placeholder="门店" filterable>
                  <el-option
                    v-for="item in storeMenu"
                    :key="item.spaceId"
                    :label="item.spaceName"
                    :value="item.spaceId"
                  >
                  </el-option>
                </el-select>
                <el-button type="primary" @click="addTable">添加</el-button>
              </el-form-item>
              <el-form-item>
                <table>
                  <tr>
                    <th>部门编码</th>
                    <th>部门名称</th>
                    <th>项目编码</th>
                    <th>项目名称</th>
                    <th>区域</th>
                    <th>门店</th>
                    <th></th>
                  </tr>
                  <tr v-for="(item, index) in tableData" :key="index">
                    <td>{{ item.deptNo }}</td>
                    <td>{{ item.deptName }}</td>
                    <td>{{ item.projectNo }}</td>
                    <td>{{ item.projectName }}</td>
                    <td>{{ item.area }}</td>
                    <td>{{ item.spaceName }}</td>
                    <td>
                      <i
                        @click="delRow(item, index)"
                        class="el-icon-close"
                        style="color: #f84a22; cursor: pointer"
                      ></i>
                    </td>
                  </tr>
                </table>
              </el-form-item>
            </el-form>
            <!-- <el-button
              type="primary"
              icon="el-icon-refresh-right"
              class="subBtn"
              >提交</el-button
            > -->
            <el-button @click="onBack">返回列表</el-button>
          </el-tab-pane>
        </el-tabs>
      </div>

      <!-- 基本信息 -->
      <!-- <div class="title-row">
        <h4>基本信息</h4>
        <div class="title-user">
          <div class="title-value1">
            <p>用户名:</p>
            <el-input
              class="Input"
              size="medium"
              v-model="input"
              placeholder="张弛"
            ></el-input>
          </div>
          <div class="title-value">2222</div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import TableChart from "../table";
import sha1 from "js-sha1";
import { mapState, mapActions } from "vuex";
import {
  userDetail,
  toInsertPage,
  insert,
  update,
  toUDRPage,
  depts,
  projects,
  provinces,
  citys,
  countys,
  spaces,
  insertPsu,
  deletePsu,
} from "@/api/sysManage/account";

export default {
  computed: {
    ...mapState("speechAnalysis", ["character"]),
  },
  components: {
    TableChart,
  },
  data() {
    var validatePass = (rule, value, callback) => {
      let reg = /[A-Za-z].*[0-9]|[0-9].*[A-Za-z]/;
      // let reg = /^(\d+[a-zA-Z]+|[a-zA-Z]+\d+)([0-9a-zA-Z]*)$/;
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (!reg.test(value)) {
          callback(new Error("必须包含字母和数字"));
        } else {
          if (this.basicInfoForm.repassword !== "") {
            this.$refs.ruleForm.validateField("repassword");
          }
          callback();
        }
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.basicInfoForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    let _this = this;
    return {
      address: [],
      // addressProps: {},
      addressProps: {
        // checkStrictly: true,
        lazy: true,
        lazyLoad(node, resolve) {
          const { level } = node;
          if (level === 0 && _this.projectId) {
            // console.log(this.projectId);
            provinces(_this.projectId).then((res) => {
              console.log(res.data.provinces);
              let province = res.data.provinces.map((item) => ({
                value: item.code,
                label: item.value,
                leaf: level >= 2,
              }));
              resolve(province);
            });
          } else if (level === 1) {
            citys(_this.projectId, node.value).then((res) => {
              let citys = res.data.citys.map((item) => ({
                value: item.code,
                label: item.value,
                leaf: level >= 2,
              }));
              // _this.addressProps.checkStrictly = true;
              resolve(citys);
            });
          } else if (level === 2) {
            countys(_this.projectId, node.value).then((res) => {
              let area = res.data.countys.map((item) => ({
                value: item.code,
                label: item.value,
                leaf: level >= 2,
              }));
              // _this.addressProps.checkStrictly = true;
              resolve(area);
            });
          } else {
            // _this.addressProps.checkStrictly = false;
            resolve();
          }
        },
      },
      rules: {
        userName: [
          { required: true, message: "请填写用户名", trigger: "blur" },
        ],
        password: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
        repassword: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
        nickName: [{ required: true, message: "请填写姓名", trigger: "blur" }],
        phone: [{ required: true, message: "请填写手机号", trigger: "blur" }],
        idCard: [
          { required: true, message: "请填写身份证号", trigger: "blur" },
        ],
        customerId: [
          { required: true, message: "请选择客户名称", trigger: "change" },
        ],
        roleId: [
          { required: true, message: "请选择用户角色", trigger: "change" },
        ],
        status: [
          { required: true, message: "请选择用户状态", trigger: "change" },
        ],
      },
      userId: "",
      projectId: "",
      spaceId: "",
      deptId: "",
      customerId: "",
      areaId: "",
      county: "",
      // storeId: "",
      customerMenu: [],
      deptMenu: [],
      projectMenu: [],
      areaMenu: [],
      storeMenu: [],
      roleMenu: [],
      tableData: [],
      basicInfoForm: {
        userName: "",
        password: "",
        repassword: "",
        sex: "",
        email: "",
        nickName: "",
        phone: "",
        idCard: "",
        roleId: null,
        status: "0",
        customerId: null,
      },
      permitForm: {
        nickName: "",
        phone: "",
        idCard: "",
        status: "",
        roleName: "",
      },
      activeName: "first",
      date: "",
      searchInput: "",
    };
  },
  created() {
    if (this.$route.query.userId) {
      userDetail(this.$route.query.userId).then((res) => {
        this.customerMenu = res.data.customers;
        this.roleMenu = res.data.roles;
        this.basicInfoForm = res.data;
        this.$refs.ruleForm.resetFields();
        // this.$refs.ruleForm.clearValidate(["password", "repassword"]);
      });
      // this.rules.password[0].required = false;
      // this.rules.repassword[0].required = false;
      // this.rules.password = [];
      // this.rules.repassword = [];
    } else {
      toInsertPage().then((res) => {
        this.customerMenu = res.data.customers;
        this.roleMenu = res.data.roles;
      });
    }
  },
  methods: {
    addTable() {
      let userId = this.$route.query.userId
        ? this.$route.query.userId
        : this.userId;
      if (this.permitForm.roleName === "促销员" && this.tableData.length >= 1) {
        this.$message({
          message: "该促销员已经分配过所属门店",
          type: "error",
        });
      } else {
        if (this.spaceId) {
          insertPsu(this.projectId, this.spaceId, userId).then((res) => {
            if (res.code === 200) {
              this.tableData.push(res.data);
              this.$message({
                message: "添加成功",
                type: "success",
              });
            }
          });
        } else {
          this.$message({
            message: "请先选好门店",
            type: "warning",
          });
        }
      }
    },
    delRow(row, index) {
      this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          deletePsu(row.projectId, row.spaceId, row.userId).then((res) => {
            if (res.code === 200) {
              this.tableData.splice(index, 1);
              this.$message({
                message: "删除成功",
                type: "success",
              });
            }
          });
        })
        .catch(() => {});
    },
    changeAddress(value) {
      this.county = value[2] ? value[2] : "";
      this.spaceId = "";
      spaces(this.projectId, this.county).then((res) => {
        this.storeMenu = res.data.spaces;
      });
    },
    addressChange(value) {
      // this.addressProps.checkStrictly = true;
      this.address = value;
      this.county = value[2] ? value[2] : "";
    },
    changeCustomer(value) {
      this.deptId = "";
      this.projectId = "";
      this.address = [];
      this.spaceId = "";
      this.projectMenu = [];
      this.storeMenu = [];
      depts(value).then((res) => {
        this.deptMenu = res.data.depts;
      });
    },
    changeDept(value) {
      this.projectId = "";
      this.address = [];
      this.spaceId = "";
      this.storeMenu = [];
      projects(value).then((res) => {
        this.projectMenu = res.data.projects;
      });
    },
    changeProject(value) {
      this.address = [];
      this.spaceId = "";
      this.storeMenu = [];
      console.log(this.projectId);

      // this.addressProps = this.addressProps1;
      // provinces(value).then(res=>{
      // })
    },
    onSubmitInfo() {
      // if (this.$route.query.userId) {
      //   // this.$refs["ruleForm"].clearValidate(["password", "repassword"]);
      //   this.$refs.pass.clearValidate();
      //   this.$refs.repass.clearValidate();
      // }
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.$route.query.userId) {
            let updateData = { ...this.basicInfoForm };
            updateData.password = this.basicInfoForm.password
              ? sha1(this.basicInfoForm.password)
              : this.basicInfoForm.password;
            updateData.repassword = this.basicInfoForm.repassword
              ? sha1(this.basicInfoForm.repassword)
              : this.basicInfoForm.repassword;
            updateData.userId = this.$route.query.userId;
            update(updateData).then((res) => {
              if (res.code === 200) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
                this.activeName = "second";
                toUDRPage(this.$route.query.userId).then((res) => {
                  this.permitForm = res.data;
                  this.tableData = res.data.psus;
                });
              }
            });
          } else {
            let insertData = { ...this.basicInfoForm };
            insertData.password = sha1(this.basicInfoForm.password);
            insertData.repassword = sha1(this.basicInfoForm.repassword);
            insert(insertData).then((res) => {
              if (res.code === 200) {
                this.userId = res.data.userId;
                this.$message({
                  message: "添加成功",
                  type: "success",
                });
                this.activeName = "second";
                toUDRPage(this.userId).then((res) => {
                  this.permitForm = res.data;
                  this.tableData = res.data.psus;
                });
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    add() {
      this.$router.push();
      //带参数跳转
      //  this.$router.push({path:'/testDemo',query:{setid:123456}});
      //  this.$router.push({name:'testDemo',params:{setid:111222}});
    },
    tabClick(tab, event) {
      // console.log(tab.name,this.userName)
      if (tab.name === "second") {
        if (!this.$route.query.userId && !this.userId) {
          this.$alert("请先填写基础信息", "提示", {
            confirmButtonText: "确定",
            showClose: false,
            callback: (action) => {
              if (action === "confirm") {
                this.activeName = "first";
              }
            },
          });
        } else {
          let userId = this.$route.query.userId
            ? this.$route.query.userId
            : this.userId;
          toUDRPage(userId).then((res) => {
            this.permitForm = res.data;
            this.tableData = res.data.psus;
            // console.log(res);
          });
        }
      }
    },
    onBack() {
      this.$router.push("/systemManage/account");
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === "account") {
      to.meta.keepAlive = true;
    }
    next();
  },
};
</script>

<style lang="scss" scoped>
$baseColor: #4e57aa;
$white: #fff;
$fontColor: #333;
$fontSize: 1.2em;
.speechAnalysis {
  margin: 2vh 1.5vw;
  .marginTop {
    margin-top: 2vh;
  }
  .main {
    background: #fff;
    padding: 2vh 1vw;
    margin-top: 2vh;
    border-radius: 2vh;
    height: 100%;
    min-height: 75vh;
    /deep/.el-tabs__nav-wrap::after {
      height: 1px;
    }
    /deep/.el-tabs--top .el-tabs__item.is-top:nth-child(2) {
      padding-left: 0;
    }
    /deep/.el-tabs--top .el-tabs__item.is-top:last-child {
      padding-right: 0;
    }
    /deep/.el-tabs__nav {
      width: 100%;
      .el-tabs__active-bar {
        background-color: #4e57aa;
      }
      .el-tabs__item {
        width: 13%;
        height: 6.6vh;
        line-height: 6.6vh;
        padding: 0 1vw;
        text-align: center;
        &:hover {
          color: #4e57aa;
        }
      }
      .el-tabs__item.is-active {
        color: #4e57aa;
      }
    }
    .title-row {
      margin-left: 1vw;
    }
    .title-user {
      display: flex;

      .title-value {
        margin-left: 30vw;
      }
    }
    .title {
      display: flex;
      justify-content: space-between;
      .left {
        display: flex;
        .sep {
          background: $baseColor;
          width: 5px;
          height: 1.5rem;
          display: inline-block;
          margin: 20px 5px;
        }
        h4 {
          padding: 0;
          margin: 0;
        }
      }
    }
    .tabs {
      .subBtn {
        background: #4e57aa;
        border-color: #4e57aa;
        margin-right: 1.09375vw;
      }
      /deep/.el-tabs__content {
        margin-left: 2vw;
        h5 {
          margin: 2.5vh 0;
          font-size: 14px;
        }
        .basic_form {
          margin-bottom: 11.57vh;
          // .indent {
          //   .el-form-item__label {
          //     text-indent: 0.35em;
          //   }
          // }
          .form_title {
            display: inline-block;
            width: 30%;
            margin-right: 8.6vw;
            margin-top: 1.85vh;
            margin-bottom: 1.85vh;
          }
          .indent1 {
            .el-form-item__label {
              text-indent: 1em;
            }
          }
          .indent15 {
            .el-form-item__label {
              text-indent: 1.6em;
            }
            .editpass {
              border: 0;
              padding: 6px 10px;
              background: rgba(78, 87, 170, 0.1);
              border-radius: 6px;
              color: #4e57aa;
            }
          }
          .indent25 {
            .el-form-item__label {
              text-indent: 2.7em;
            }
          }
          .indent16 {
            .el-form-item__label {
              text-indent: 0.6em;
            }
          }
          .indent2 {
            .el-form-item__label {
              text-indent: 2em;
            }
          }
          .el-form-item {
            width: 30%;
            margin-right: 8.6vw;
            margin-bottom: 1.85vh;
            .el-form-item__label {
              padding-right: 0;
              color: #333;
            }
            .el-form-item__content {
              width: 70%;
              .el-select {
                width: 100%;
              }
              .el-input__inner {
                font-size: 14px;
                color: #333;
              }
              // .el-select {
              //   width: 18%;
              //   margin-right: 0.78125vw;
              // }
              // .el-button {
              //   background-color: #4e57aa;
              //   border-color: #4e57aa;
              // }
            }
          }
        }
        .permit_form {
          margin-bottom: 10vh;
          .el-form-item {
            margin-bottom: 1.85vh;
            .el-form-item__label {
              color: #333;
            }
            .el-form-item__content {
              > .el-input {
                width: 30%;
              }
              .el-select.allw {
                width: 30%;
              }
              .el-cascader {
                width: 15%;
                margin-right: 0.78125vw;
              }
              .el-select {
                width: 15%;
                margin-right: 0.78125vw;
              }
              .el-input__inner {
                font-size: 14px;
                color: #333;
              }
              .el-button {
                background-color: #4e57aa;
                border-color: #4e57aa;
              }
            }
          }
        }
      }
      .tab-img {
        width: 1.25vw;
        height: 1.25vw;
        margin-right: 0.78vw;
        vertical-align: middle;
      }
    }
    table {
      width: 80%;
      // border-collapse: collapse;
      border: 1px solid rgba(78, 87, 170, 0.5);
      border-radius: 10px;
      color: #666666;
      font-size: 12px;
      td {
        text-align: center;
      }
    }
  }
}
</style>