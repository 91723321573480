import request from "@/utils/request";

export function supervisorList(data,pageNum,pageSize) {
  return request({
    url: "/jxzy/supervisor/list",
    method: "post",
    data,
    params:{pageNum,pageSize}
  });
}

export function toListPage() {
  return request({
    url: "/jxzy/supervisor/toListPage",
    method: "post",
  });
}

export function supervisorDetail(data) {
  return request({
    url: "/jxzy/supervisor/detail",
    method: "post",
    data,
  });
}

export function spaceInfo(spaceId,sdate,projectId) {
  return request({
    url: "/jxzy/supervisor/spaceInfo",
    method: "post",
    data:{spaceId,sdate,projectId},
  });
}