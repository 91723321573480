<template>
  <div class="ring-chart" ref="ringChart"></div>
</template>

<script>
import resize from "../../../pc/mixin/echartsResize";

export default {
  data() {
    return {
      chart: null,
    };
  },
  mixins: [resize],

  props: ["color", "value"],
  watch: {
    value() {
      this.initChart();
    },
  },
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      this.chart = this.$echarts.init(this.$refs.ringChart);
      let getvalue = [this.value];
      const option = {
        title: {
          text: getvalue + "%",
          textStyle: {
            color: "#171717",
            fontSize: 18,
          },
          //   subtext: "综合得分",
          //   subtextStyle: {
          //     color: "#666666",
          //     fontSize: 30,
          //   },
          //   itemGap: 20,
          left: "center",
          top: "center",
        },
        // tooltip: {
        //   formatter: function (params) {
        //     return (
        //       '<span style="color: #fff;">综合得分：' + getvalue + "分</span>"
        //     );
        //   },
        // },
        angleAxis: {
          max: 100,
          clockwise: true, // 逆时针
          // 隐藏刻度线
          show: false,
        },
        radiusAxis: {
          type: "category",
          show: true,
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        polar: {
          center: ["50%", "50%"],
          radius: "155%", //图形大小
        },
        series: [
          {
            type: "bar",
            data: getvalue,
            showBackground: true,
            backgroundStyle: {
              color: "rgba(143, 146, 161,0.1)",
            },
            coordinateSystem: "polar",
            roundCap: true,
            barWidth: 8,
            itemStyle: {
              normal: {
                opacity: 1,
                color: this.color,
                // shadowBlur: 5,
                // shadowColor: "#2A95F9",
              },
            },
          },
        ],
      };
      this.chart.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
