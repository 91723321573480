<template>
  <div class="supervision">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/inspection' }"
          >云巡检</el-breadcrumb-item
        >
        <el-breadcrumb-item>促销员巡检列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main">
      <div class="title">
        <div class="left">
          <i class="sep"></i>
          <h4>促销员巡检列表</h4>
        </div>
        <!-- <div class="right">
          <el-date-picker
            v-model="date"
            type="daterange"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div> -->
      </div>
      <div class="form-content">
        <div class="form-item">
          <span class="selectName">项目：</span>
          <el-select
            v-model="formData.projectName"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in projectMenu"
              :key="item.projectName"
              :value="item.projectName"
            >
            </el-option>
          </el-select>
        </div>
        <div class="form-item">
          <span class="selectName">区域：</span>
          <el-cascader
            v-model="address"
            :props="addressProps"
            @change="changeAddress"
            @expand-change="addressChange"
            clearable
          ></el-cascader>
        </div>
        <div class="form-item">
          <span class="selectName">人员名称：</span>
          <el-input placeholder="请输入" v-model="formData.userName"></el-input>
        </div>
        <div class="form-item">
          <span class="selectName">设备状态：</span>
          <el-select
            v-model="status"
            clearable
            filterable
            placeholder="请选择"
            @change="changeStatus"
            @clear="formData.status = ''"
          >
            <el-option
              v-for="item in statusList"
              :key="item.statusid"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <!-- <div class="form-item parent">
          <el-input placeholder="请输入人员名称" v-model="formData.userName">
          </el-input>
          <i class="el-icon-search searchInput"></i>
        </div> -->
        <div class="select-date form-item">
          <span class="selectName">统计日期：</span>
          <el-date-picker
            v-model="formData.sdate"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
          <!-- <el-date-picker
            v-model="date"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker> -->
        </div>
        <div class="form-item fr">
          <el-button
            class="searchBtn"
            @click="onInquire"
            v-hasPremi:促销员预警列表
            >查询</el-button
          >
          <el-button @click="onReset" v-hasPremi:促销员预警列表>重置</el-button>
        </div>
      </div>
      <el-row>
        <el-button class="searchBtn export" @click="handleExport"
          >导出</el-button
        >
      </el-row>
      <!-- <el-row :gutter="30" class="marginTop"> -->
      <!-- <el-col :span="7">
          <span class="selectName">预警级别：</span>
          <el-select
            v-model="formData.warningLevel"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in warnLevelMenu"
              :key="item.setCode"
              :label="item.setValue"
              :value="item.setCode"
            >
            </el-option>
          </el-select>
        </el-col> -->
      <!-- <el-col :span="7">
          <span class="selectName">预警类型：</span>
          <el-select
            v-model="formData.warningType"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in warnTypeMenu"
              :key="item.setCode"
              :label="item.setValue"
              :value="item.setCode"
            >
            </el-option>
          </el-select>
        </el-col> -->
      <!-- <el-col :span="5" class="parent">
          <el-input placeholder="请输入" v-model="searchInput"> </el-input>
          <i class="el-icon-search searchInput"></i>
        </el-col>
        <el-col :span="3" :offset="3" class="fr">
          <el-button
            class="searchBtn"
            @click="onInquire"
            v-hasPremi:促销员预警列表
            >查询</el-button
          >
          <el-button @click="onReset" v-hasPremi:促销员预警列表>重置</el-button>
        </el-col> -->
      <!-- </el-row> -->
      <el-row :gutter="10">
        <TableChart
          :tableDatas="{
            id: 'supervisionTable',
            data: supervisionTable,
          }"
          :pageData="pageData"
          @changePage="currentChange"
          @changeSize="sizeChange"
        ></TableChart>
      </el-row>
    </div>
  </div>
</template>
<script>
import TableChart from "../table";
import { mapState, mapActions } from "vuex";
import {
  projectList,
  getProvince,
  getProvinceDown,
  getProvinceEnd,
  warningInfoList,
  listExport,
} from "@/api/inspection/promoter";

export default {
  computed: {
    // ...mapState("supervision", ["supervisionTable"]),
  },
  components: {
    TableChart,
  },
  data() {
    let _this = this;
    return {
      statusList: [
        {
          value: "在线",
          statusid: "0",
        },
        {
          value: "离线",
          statusid: "1",
        },
      ],
      status: "",
      // supervisionTable: [],
      supervisionTable: {
        size: "",
        loading: false,
        columns: [
          {
            col: "序号",
            prop: "rowNum",
            width: "",
          },
          {
            col: "人员名称",
            prop: "userName",
            width: "",
          },
          {
            col: "项目名称",
            prop: "projectName",
            width: "",
          },
          {
            col: "门店名称",
            prop: "spaceName",
            width: "",
          },

          // {
          //   col: "预警级别",
          //   prop: "warningLevelName",
          //   width: "",
          // },
          // {
          //   col: "预警类型",
          //   prop: "warningTypeName",
          //   width: "",
          // },
          // {
          //   col: "预警时长(min)",
          //   prop: "warningTime",
          //   width: "",
          // },
          {
            col: "更新时间",
            prop: "updateDate",
            width: "",
          },
          {
            col: "预警次数",
            prop: "warningCount",
            width: "",
          },
          {
            col: "设备状态",
            prop: "status",
            width: "",
          },
        ],
        data: [],
      },
      pageData: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },

      address: [],
      addressProps: {
        checkStrictly: true,
        lazy: true,
        lazyLoad(node, resolve) {
          const { level } = node;
          if (level === 0) {
            getProvince().then((res) => {
              let province = res.data.map((item) => ({
                value: item.code,
                label: item.value,
                leaf: level >= 2,
              }));
              resolve(province);
            });
          } else if (level === 1) {
            getProvinceDown(node.value).then((res) => {
              let citys = res.data.map((item) => ({
                value: item.code,
                label: item.value,
                leaf: level >= 2,
              }));
              _this.addressProps.checkStrictly = true;
              resolve(citys);
            });
          } else if (level === 2) {
            getProvinceEnd(node.value).then((res) => {
              let area = res.data.map((item) => ({
                value: item.code,
                label: item.value,
                leaf: level >= 2,
              }));
              _this.addressProps.checkStrictly = true;
              resolve(area);
            });
          } else {
            _this.addressProps.checkStrictly = false;
            resolve();
          }
        },
      },
      formData: {
        userName: "",
        sdate: "",
        province: "",
        city: "",
        county: "",
        projectName: "",
        status: "",
      },
      date: "",
      projectMenu: [],
      warnLevelMenu: [],
      project: {
        value: "",
        options: [
          {
            value: 0,
            label: "语音项目",
          },
          {
            value: 1,
            label: "项目",
          },
        ],
      },
      warnTypeMenu: [],
      area: {
        value: "",
        options: [
          {
            label: "热门城市",
            options: [
              {
                value: "Shanghai",
                label: "上海",
              },
              {
                value: "Beijing",
                label: "北京",
              },
            ],
          },
          {
            label: "城市名",
            options: [
              {
                value: "Chengdu",
                label: "成都",
              },
              {
                value: "Shenzhen",
                label: "深圳",
              },
              {
                value: "Guangzhou",
                label: "广州",
              },
              {
                value: "Dalian",
                label: "大连",
              },
            ],
          },
        ],
      },
      searchInput: "",
    };
  },
  created() {},
  methods: {
    changeStatus(val) {
      console.log(val);
      this.formData.status = val == "在线" ? "Y" : "N";
    },
    changeAddress(value) {
      this.formData.province = value[0] ? value[0] : "";
      this.formData.city = value[1] ? value[1] : "";
      this.formData.county = value[2] ? value[2] : "";
    },
    addressChange(value) {
      this.addressProps.checkStrictly = true;
      this.address = value;
      this.formData.province = value[0] ? value[0] : "";
      this.formData.city = value[1] ? value[1] : "";
      this.formData.county = value[2] ? value[2] : "";
    },
    getTableData() {
      this.supervisionTable.loading = true;
      warningInfoList(
        this.formData,
        this.pageData.pageNum,
        this.pageData.pageSize
      ).then((res) => {
        this.supervisionTable.loading = false;
        res.rows.forEach((item) => {
          item.status = item.statusstatus == "1" ? "离线" : "在线";
        });
        this.supervisionTable.data = res.rows;
        this.pageData.total = res.total;
      });
    },
    onInquire() {
      this.pageData.pageNum = 1;
      this.getTableData();
    },
    onReset() {
      this.formData.projectName = "";
      this.formData.userName = "";
      this.formData.sdate = "";
      this.address = "";
      this.formData.province = "";
      this.formData.city = "";
      this.formData.county = "";
      this.formData.status = "";
      this.status = "";
    },
    currentChange(val) {
      this.pageData.pageNum = val;
      this.getTableData();
    },
    sizeChange(val) {
      this.pageData.pageNum = 1;
      this.pageData.pageSize = val;
      this.getTableData();
    },
    handleExport() {
      listExport(this.formData).then((res) => {
        let blob = new Blob([res], { type: `text/plain;charset=utf-8` });
        let downloadElement = document.createElement("a");
        let href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = "促销员巡检列表.xls";
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === "PwarnDetail") {
      next((vm) => {
        vm.getTableData();
      });
    } else {
      next((vm) => {
        let date = new Date();
        let s =
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate();
        vm.formData.sdate = s;
        projectList().then((res) => {
          vm.projectMenu = res.data;
        });
        vm.getTableData();
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== "PwarnDetail") {
      from.meta.keepAlive = false;
      next();
    } else {
      next();
    }
  },
};
</script>
<style lang="scss">
.el-cascader-panel {
  .el-radio__inner {
    display: none;
  }
}
</style>
<style lang="scss" scoped>
$baseColor: #4e57aa;
$white: #fff;
$fontColor: #333;
$fontSize: 1.2em;
.supervision {
  margin: 2vh 1.5vw;
  .marginTop {
    margin-top: 2vh;
  }
  .main {
    background: #fff;
    padding: 2.8vh 1vw;
    margin-top: 2vh;
    border-radius: 2vh;
    height: 100%;
    min-height: 75vh;
    .form-content {
      display: flex;
      flex-wrap: wrap;
      .form-item {
        margin-right: 1.5625vw;
        margin-bottom: 1.7vh;
        .el-select {
          width: 9vw;
        }
        .el-cascader {
          width: 9vw;
        }
        .el-input {
          width: 9vw;
        }
      }
    }
    .select-date {
      .el-input {
        width: 10vw;
        // padding-right: 0;
        // padding-left: 1.5625vw;
        // padding-right: 1.5625vw;
      }
    }
    .export {
      float: right;
      // width: 3.125vw;
      // height: 32px;
      // margin-bottom: 1.7vh;
      // background: #4e57aa;
      // color: #fff;
      // text-align: center;
      // line-height: 32px;
      // font-size: 14px;
      // cursor: pointer;
      // border-radius: 3px;
    }
    // /deep/.el-select {
    //   width: 74%;
    // }
    // /deep/.el-cascader {
    //   width: 74%;
    // }
    .title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2.8vh;
      .left {
        display: flex;
        .sep {
          background: $baseColor;
          width: 5px;
          height: 1.5rem;
          display: inline-block;
          margin: 0 5px;
        }
        h4 {
          padding: 0;
          margin: 0;
        }
      }
    }
    .fr {
      float: right;
      width: auto;
      margin-left: 0;
    }
    .selectName {
      // width: 26%;
      font-size: 14px;
      display: inline-block;
      color: $fontColor;
      // text-align: left;
      // text-align-last: justify;
    }
    .indent2 {
      padding-right: 2em;
    }
    .parent {
      position: relative;
      width: 12vw;
      .el-input {
        width: 10vw;
        /deep/.el-input__inner {
          border-radius: 4px 0 0 4px;
        }
      }
      .searchInput {
        position: absolute;
        right: 0;
        height: 100%;
        text-align: center;
        background: $baseColor;
        width: 2vw;
        right: 0px;
        top: 0;
        border-radius: 0 4px 4px 0;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .searchBtn {
      color: $white;
      background-color: $baseColor;
    }
  }
}
</style>