import request from "@/utils/request";

export function supervisorRoleList(pageNum,pageSize) {
  return request({
    url: "/jxzy/supervisorRole/list",
    method: "post",
    params:{pageNum,pageSize}
  });
}

export function supervisorRoleDetail(supervisorRoleId) {
  return request({
    url: "/jxzy/supervisorRole/detail",
    method: "post",
    data:{supervisorRoleId}
  });
}

export function supervisorRoleUpdate(data) {
  return request({
    url: "/jxzy/supervisorRole/update",
    method: "post",
    data,
  });
}