<template>
  <div class="project_examination_list_content">
    <div class="project_examination_list_content_header">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/quickTest' }"
          >快考分析</el-breadcrumb-item
        >
        <el-breadcrumb-item>快考记录列表</el-breadcrumb-item>
      </el-breadcrumb>
      <!-- <div class="project_examination_list_content_header_right">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>雀巢咖啡活动</el-breadcrumb-item>
          <el-breadcrumb-item>项目</el-breadcrumb-item>
        </el-breadcrumb>
        <div
          class="project_examination_list_content_header_right_times"
          v-text="'时间：2020/12/01-2021/03/01'"
        ></div>
      </div> -->
    </div>
    <div class="examination_small_content">
      <div class="examination_small_content_header">
        <div class="examination_small_content_header_color"></div>
        <div class="examination_small_content_header_text">快考记录列表</div>
      </div>
      <div class="examination_small_content_select">
        <div class="examination_small_content_select_left">
          <div class="select-date">
            <el-date-picker
              v-model="formData.date"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="~"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
          <div class="select-name">项目：</div>
          <el-select
            clearable
            filterable
            class="select-project"
            v-model="formData.projectId"
            placeholder="请选择"
          >
            <el-option
              v-for="item in projectMenu"
              :key="item.projectId"
              :label="item.projectName"
              :value="item.projectId"
            >
            </el-option>
          </el-select>
          <!-- <div class="searchbtn">
            <el-input placeholder="请输入内容" v-model="input" clearable>
            </el-input>
            <el-button type="primary" icon="el-icon-search"></el-button>
          </div> -->
        </div>
        <div class="examination_small_content_select_right">
          <el-button type="primary" @click="onInquire">查询</el-button>
          <el-button @click="onReset">重置</el-button>
        </div>
      </div>
      <el-row>
        <el-button class="export">导出</el-button>
      </el-row>
      <div class="examination_small_content_main_box">
        <TableChart
          :tableDatas="{
            id: 'quickTable',
            data: quickTestTable,
          }"
          :pageData="pageData"
          @changePage="currentChange"
          @changeSize="sizeChange"
        ></TableChart>
        <!-- <el-col :span="24">
          <el-row class="examination_small_content_main">
            <el-col class="examination_small_content_main_item" :span="4"
              >项目编号</el-col
            >
            <el-col class="examination_small_content_main_item" :span="4"
              >项目名称</el-col
            >
            <el-col class="examination_small_content_main_item" :span="4"
              >考试名称</el-col
            >
            <el-col class="examination_small_content_main_item" :span="4"
              >应考人数</el-col
            >
            <el-col class="examination_small_content_main_item" :span="4"
              >开始时间</el-col
            >
            <el-col class="examination_small_content_main_item" :span="4"
              >结束时间</el-col
            >
          </el-row>
          <el-row
            class="examination_small_content_centent"
            v-for="(item, index) in resultData"
            :key="index"
            @click.native="itemClick(item)"
          >
            <el-col
              :span="4"
              class="examination_small_content_main_centent_item"
              >{{ index + 1 }}</el-col
            >
            <el-col
              :span="4"
              class="examination_small_content_main_centent_item"
              >{{ item.projectName ? item.projectName : "无" }}</el-col
            >
            <el-col
              :span="4"
              class="examination_small_content_main_centent_item"
              >{{ item.paperName ? item.paperName : "无" }}</el-col
            >
            <el-col
              :span="4"
              class="examination_small_content_main_centent_item"
              >{{ item.examShouldCount ? item.examShouldCount : "无" }}</el-col
            >
            <el-col
              :span="4"
              class="examination_small_content_main_centent_item"
              >{{ item.startTime ? item.startTime : "无" }}</el-col
            >
            <el-col
              :span="4"
              class="examination_small_content_main_centent_item"
              >{{ item.endTime ? item.endTime : "无" }}</el-col
            >
          </el-row>
        </el-col> -->
      </div>
    </div>
  </div>
</template>
<script>
import { examInfoList, projectList } from "@/api/quickTest/test";
import TableChart from "../table";

const ls = window.sessionStorage;

import $ from "jquery";
export default {
  name: "projectexaminationlist",
  components: {},
  data() {
    return {
      projectMenu: [],
      date: "",
      input: "",
      quickTestTable: {
        size: "",
        loading: false,
        columns: [
          {
            col: "项目编号",
            prop: "projectId",
            width: "",
          },
          {
            col: "项目名称",
            prop: "projectName",
            width: "",
          },
          {
            col: "考试名称",
            prop: "paperName",
            width: "",
          },
          {
            col: "应考人数",
            prop: "examShouldCnt",
            width: "",
          },
          {
            col: "开始时间",
            prop: "startTime",
            width: "",
          },
          {
            col: "结束时间",
            prop: "endTime",
            width: "",
          },
        ],
        data: [],
      },
      pageData: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      formData: {
        date: [],
        projectId: "",
      },
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",

      resultData: [],
    };
  },
  components: {
    TableChart,
  },
  created() {},
  methods: {
    getTableData() {
      this.quickTestTable.loading = true;
      examInfoList(
        this.formData.date[0],
        this.formData.date[1],
        this.formData.projectId,
        this.pageData.pageNum,
        this.pageData.pageSize
      ).then((res) => {
        this.quickTestTable.loading = false;
        this.quickTestTable.data = res.rows;
        this.pageData.total = res.total;
      });
    },
    onInquire() {
      console.log(this.formData);
      this.pageData.pageNum = 1;
      this.getTableData();
    },
    onReset() {
      this.formData.projectId = "";
      this.formData.date = [];
    },
    currentChange(val) {
      this.pageData.pageNum = val;
      this.getTableData();
    },
    sizeChange(val) {
      this.pageData.pageNum = 1;
      this.pageData.pageSize = val;
      this.getTableData();
    },

    itemClick(item) {
      console.log(item);
      ls.setItem("itemData", JSON.stringify(item));
      this.$router.push("/quickTest/testCase/detail");
    },
    getData() {
      var _this = this;
      $.ajax({
        url: "http://39.98.215.198/ds/exchange/exam/select",
        type: "get",
        dataType: "json",
        data: {},
        success: function (res) {
          _this.resultData = res.resultData.reverse();
        },
        error: function (data) {
          console.log(data);
        },
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === "testCaseDetail") {
      next((vm) => {
        vm.getTableData();
      });
    } else {
      next((vm) => {
        projectList().then((res) => {
          vm.projectMenu = res.data;
        });
        vm.getTableData();
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== "testCaseDetail") {
      from.meta.keepAlive = false;
      next();
    } else {
      next();
    }
  },
  // mounted() {
  //   this.getData();
  // },
};
</script>
<style scoped lang="less">
div {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.project_examination_list_content {
  width: 100%;
  height: auto;
  // overflow-y: auto;
  background: #f9f9fd;
  padding: 2vh 1.5vw;
}
.project_examination_list_content_header {
  width: 100%;
  height: 5.6vh;
  // margin: 4vh 0vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.project_examination_list_content_header_right {
  // width: 30%;
  height: 4vh;
  display: flex;
  // flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /deep/.el-breadcrumb {
    margin-right: 50px;
  }
}
.project_examination_list_content_header_right_times {
  font-size: 14px;
  color: #333333;
}

.examination_small_content {
  width: 100%;
  // height: 80vh;
  border-radius: 10px;
  background: #fff;
  padding-bottom: 2vh;
}

.examination_small_content_header {
  width: 25%;
  // height: 11vh;
  padding: 3vh 0vw 3vh 1.5vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.examination_small_content_header_color {
  width: 6px;
  height: 3vh;
  font-size: 18px;
  background: #4e57aa;
}

.examination_small_content_header_text {
  margin-left: 0.7vw;
  font-size: 18px;
  line-height: 3vh;
}

.examination_small_content_select {
  width: 100%;
  height: 5vh;
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  align-items: center;
}
.export {
  float: right;
  margin-right: 1.5vw;
  color: #fff;
  background-color: #4e57aa;
}
.examination_small_content_select_left {
  // width: 50%;
  height: 5vh;
  margin-left: 1.5vw;
  display: flex;
  // flex-direction: row;
  // justify-content: space-around;
  align-items: center;
  .select-date {
    width: 40%;
    margin-right: 1.5vw;
  }
  .select-name {
    // width: 10%;
    line-height: 32px;
  }
  .select-project {
    width: 35%;
  }
  .searchbtn {
    width: 50%;
    margin-left: 5%;
    /deep/.el-input {
      width: 85%;
    }
    /deep/.el-button {
      width: 15%;
      padding: 0;
      height: 32px;
      line-height: 32px;
      border: 0;
      i {
        font-size: 14px;
      }
      // padding-left: 0;
      // padding-right: 0;
      // height: 100%;
      // padding: 0;
    }
  }
}

.examination_small_content_select_right {
  width: 12%;
  height: 5vh;
  margin-right: 1.5vw;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.el-input {
  width: 12vw;
}

.el-button--primary {
  background-color: #4e57aa;
  border-color: #4e57aa;
  color: #fff;
}

.el-button--primary:hover {
  background-color: #4e57aa;
  border-color: #4e57aa;
  color: #fff;
}

.examination_small_content_main_box {
  width: 100%;
  // height: 58vh;
  padding: 0 1.5vw;
  // padding-top: 4vh;
}

.examination_small_content_main {
  background: rgba(78, 87, 170, 0.05);
}

.examination_small_content_main_item {
  height: 6.5vh;
  line-height: 6.5vh;
  text-align: center;
  font-size: 14px;
  color: #333333;
  font-weight: 600;
}

.examination_small_content_centent {
  background: rgba(255, 255, 255, 0.2);
  cursor: pointer;
}

.examination_small_content_centent:hover {
  background: rgba(187, 187, 187, 0.2);
  cursor: pointer;
}

.examination_small_content_main_centent_item {
  height: 6.5vh;
  line-height: 6.5vh;
  text-align: center;
  font-size: 14px;
  color: #333333;
}

.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
  width: auto;
}
// .el-range-editor--small.el-input__inner {
//   height: 4.8vh;
// }

::-webkit-scrollbar {
  width: 3px;
}
::-webkit-scrollbar-track {
  border-radius: 8px;
  width: 8px;
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #bbb;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #eee;
}
</style>
