<template>
  <div class="speechAnalysis">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/systemManage' }"
          >系统管理</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/systemManage/role' }"
          >角色管理</el-breadcrumb-item
        >
        <el-breadcrumb-item v-if="$route.query.roleId"
          >编辑角色</el-breadcrumb-item
        >
        <el-breadcrumb-item v-else>新增角色</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main">
      <div class="title">
        <div class="left">
          <i class="sep"></i>
          <h4 v-show="activeName === 'first'">基础信息</h4>
          <h4 v-show="activeName === 'second'">权限配置</h4>
        </div>
      </div>
      <el-row :gutter="20" class="marginTop">
        <el-col :span="3" class="parent"> </el-col>
      </el-row>
      <div class="tabs">
        <el-tabs v-model="activeName">
          <el-tab-pane name="first">
            <div slot="label">
              <img
                class="tab-img"
                v-if="activeName === 'first'"
                src="../../../../assets/pc/基础信息-选中.png"
              />
              <img
                class="tab-img"
                v-else
                src="../../../../assets/pc/基础信息.png"
              />
              基础信息
            </div>
            <el-form
              :model="basicInfoForm"
              label-width="auto"
              class="basic_form"
              :rules="rules"
              ref="ruleForm"
            >
              <el-form-item label="客户名称：" prop="customerId">
                <el-select v-model="basicInfoForm.customerId" filterable>
                  <el-option
                    v-for="item in customerMenu"
                    :key="item.customerId"
                    :label="item.customerName"
                    :value="item.customerId"
                  >
                  </el-option>
                  <!-- <el-option value="选择一"></el-option>
                  <el-option value="选择二"></el-option> -->
                </el-select>
              </el-form-item>
              <el-form-item label="角色名称：" prop="roleName">
                <el-input v-model="basicInfoForm.roleName"></el-input>
              </el-form-item>
              <el-form-item label="角色类型：" prop="roleType">
                <el-select v-model="basicInfoForm.roleType" filterable>
                  <el-option
                    v-for="item in roleMenu"
                    :key="item.statusCode"
                    :label="item.statusValue"
                    :value="item.statusCode"
                  >
                  </el-option>
                  <!-- <el-option value="选择一"></el-option>
                  <el-option value="选择二"></el-option> -->
                </el-select>
              </el-form-item>
              <el-form-item label="角色标识：" prop="roleKey">
                <el-input v-model="basicInfoForm.roleKey"></el-input>
              </el-form-item>
              <el-form-item label="角色描述：">
                <el-input
                  type="textarea"
                  v-model="basicInfoForm.remark"
                  resize="none"
                ></el-input>
              </el-form-item>
            </el-form>
            <el-button
              type="primary"
              icon="el-icon-refresh-right"
              class="subBtn"
              @click="onSubmitInfo"
              >提交</el-button
            >
            <el-button @click="onBack">返回列表</el-button>
          </el-tab-pane>
          <el-tab-pane name="second">
            <div slot="label">
              <img
                class="tab-img"
                v-if="activeName === 'second'"
                src="../../../../assets/pc/权限-选中.png"
              />
              <img
                class="tab-img"
                v-else
                src="../../../../assets/pc/权限.png"
              />
              权限配置
            </div>
            <div class="buttons">
              <el-button
                round
                @click="handleClose"
                :class="isOpen ? '' : 'activebtn'"
                >收缩分类</el-button
              >
              <el-button
                round
                @click="handleOpen"
                :class="isOpen ? 'activebtn' : ''"
                >一键展开</el-button
              >
            </div>
            <div class="tree_content">
              <el-tree
                ref="tree"
                :data="treeData"
                check-strictly
                show-checkbox
                node-key="menuId"
                default-expand-all
                :default-expanded-keys="[]"
                :default-checked-keys="checked"
                :props="defaultProps"
                @check-change="handleCheckChange"
              >
              </el-tree>
            </div>
            <el-button
              type="primary"
              icon="el-icon-refresh-right"
              class="subBtn"
              @click="onSubmitPremi"
              >提交</el-button
            >
            <el-button @click="onBack">返回列表</el-button>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import TableChart from "../table";
// import Configuration from "../configuration";
import { mapState, mapActions } from "vuex";
// import Basics from "../basics";
import {
  roleDetail,
  toInsertPage,
  insert,
  update,
  getMenuInfo,
  saveRoleMenu,
} from "@/api/sysManage/role";

export default {
  computed: {
    ...mapState("speechAnalysis", ["character"]),
  },
  components: {
    TableChart,
    // Configuration,
    // Basics
  },
  data() {
    return {
      isOpen: true,
      rules: {
        customerId: [
          { required: true, message: "请选择客户名称", trigger: "change" },
        ],
        roleName: [
          { required: true, message: "请填写角色名称", trigger: "blur" },
        ],
        roleKey: [
          { required: true, message: "请填写角色标识", trigger: "blur" },
        ],
        roleType: [
          { required: true, message: "请选择角色类型", trigger: "change" },
        ],
      },
      roleId: "",
      checked: [],
      treeData: [
        // {
        //   id: 1,
        //   label: "一级 1",
        //   children: [
        //     {
        //       id: 4,
        //       label: "二级 1-1",
        //       children: [
        //         {
        //           id: 9,
        //           label: "三级 1-1-1",
        //         },
        //         {
        //           id: 10,
        //           label: "三级 1-1-2",
        //         },
        //       ],
        //     },
        //   ],
        // },
        // {
        //   id: 2,
        //   label: "一级 2",
        //   children: [
        //     {
        //       id: 5,
        //       label: "二级 2-1",
        //     },
        //     {
        //       id: 6,
        //       label: "二级 2-2",
        //     },
        //   ],
        // },
        // {
        //   id: 3,
        //   label: "一级 3",
        //   children: [
        //     {
        //       id: 7,
        //       label: "二级 3-1",
        //     },
        //     {
        //       id: 8,
        //       label: "二级 3-2",
        //     },
        //   ],
        // },
      ],
      defaultProps: {
        children: "childs",
        label: "menuName",
      },
      basicInfoForm: {
        customerId: "",
        roleName: "",
        remark: "",
        roleKey: "",
        roleType: "",
      },
      customerMenu: [],
      roleMenu: [],
      activeName: "first",
      date: "",
      searchInput: "",
    };
  },
  created() {
    if (this.$route.query.roleId) {
      roleDetail(this.$route.query.roleId).then((res) => {
        this.customerMenu = res.data.customerOptions;
        this.roleMenu = res.data.roleTypeOption;
        this.basicInfoForm = res.data;
        this.$refs.ruleForm.resetFields();
      });
      getMenuInfo(this.$route.query.roleId).then((res) => {
        console.log(res);
        this.treeData = res.data.menus;
        res.data.menus.forEach((item) => {
          if (item.flag === "Y") {
            this.checked.push(item.menuId);
            item.childs.forEach((im) => {
              if (im.flag === "Y") {
                this.checked.push(im.menuId);
                if (im.childs) {
                  im.childs.forEach((m) => {
                    if (m.flag === "Y") {
                      this.checked.push(m.menuId);
                    }
                  });
                }
              }
            });
          }
        });
      });
    } else {
      toInsertPage().then((res) => {
        console.log(res);
        this.customerMenu = res.data.customerOptions;
        this.roleMenu = res.data.roleTypeOption;
      });
      getMenuInfo().then((res) => {
        console.log(res);
        this.treeData = res.data.menus;
      });
    }
  },
  methods: {
    handleClose() {
      this.isOpen = false;
      for (var i = 0; i < this.$refs.tree.store._getAllNodes().length; i++) {
        this.$refs.tree.store._getAllNodes()[i].expanded = false;
      }
    },
    handleOpen() {
      this.isOpen = true;
      for (var i = 0; i < this.$refs.tree.store._getAllNodes().length; i++) {
        this.$refs.tree.store._getAllNodes()[i].expanded = true;
      }
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    onSubmitInfo() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.$route.query.roleId) {
            let updateData = { ...this.basicInfoForm };
            updateData.roleId = this.$route.query.roleId;
            update(updateData).then((res) => {
              if (res.code === 200) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
                this.activeName = "second";
              }
            });
          } else {
            insert(this.basicInfoForm).then((res) => {
              if (res.code === 200) {
                this.roleId = res.data.roleId;
                this.$message({
                  message: "添加成功",
                  type: "success",
                });
                this.activeName = "second";
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    onSubmitPremi() {
      if (this.$route.query.roleId) {
        saveRoleMenu(
          this.$route.query.roleId,
          this.$refs.tree.getCheckedKeys()
        ).then((res) => {
          if (res.code === 200) {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.onBack();
          }
        });
      } else {
        if (this.roleId) {
          saveRoleMenu(this.roleId, this.$refs.tree.getCheckedKeys()).then(
            (res) => {
              if (res.code === 200) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                });
                this.onBack();
              }
            }
          );
        } else {
          this.$alert("请先填写基础信息", "提示", {
            confirmButtonText: "确定",
            showClose: false,
            callback: (action) => {
              if (action === "confirm") {
                this.activeName = "first";
              }
            },
          });
          // this.$message({
          //   message: "请先填写基本信息",
          //   type: "error",
          // });
        }
      }
    },
    handleCheckChange(data, checked, indeterminate) {
      // console.log(this.$refs.tree.getCurrentNode());
      if (checked) {
        if (data.parentId) {
          this.$refs.tree.setChecked(data.parentId, true);
        }
      } else {
        if (data.childs.length) {
          // let noChecked = [];
          data.childs.forEach((item) => {
            // noChecked.push(item.menuId);
            this.$refs.tree.setChecked(item.menuId, false);
            // if (item.childs.length) {
            //   item.childs.forEach((im) => {
            //     // noChecked.push(im.menuId);
            //     this.$refs.tree.setChecked(im.menuId, false);
            //   });
            // }
          });
          // console.log(noChecked);
          // this.$refs.tree.setCheckedKeys(noChecked, false);
        }
      }
      // if (indeterminate) {
      //   if (!checked) {
      //     // this.$refs.tree.setChecked(data.menuId, false, true);
      //     console.log(111);
      //   }
      // }
      // console.log(data, checked, indeterminate);
    },
    onBack() {
      this.$router.push("/systemManage/role");
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === "role") {
      to.meta.keepAlive = true;
    }
    next();
  },
};
</script>

<style lang="scss" scoped>
$baseColor: #4e57aa;
$white: #fff;
$fontColor: #333;
$fontSize: 1.2em;
.speechAnalysis {
  margin: 2vh 1.5vw;
  .marginTop {
    margin-top: 2vh;
  }
  .main {
    background: #fff;
    padding: 2vh 1vw;
    margin-top: 2vh;
    border-radius: 2vh;
    height: 100%;
    min-height: 75vh;
    /deep/.el-tabs__nav-wrap::after {
      height: 1px;
    }
    /deep/.el-tabs--top .el-tabs__item.is-top:nth-child(2) {
      padding-left: 0;
    }
    /deep/.el-tabs--top .el-tabs__item.is-top:last-child {
      padding-right: 0;
    }
    /deep/.el-tabs__nav {
      width: 100%;
      .el-tabs__active-bar {
        background-color: #4e57aa;
      }
      .el-tabs__item {
        width: 13%;
        height: 6.6vh;
        line-height: 6.6vh;
        padding: 0 1vw;
        text-align: center;
        &:hover {
          color: #4e57aa;
        }
      }
      .el-tabs__item.is-active {
        color: #4e57aa;
      }
    }
    /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #4e57aa;
      border-color: #4e57aa;
    }
    .title {
      display: flex;
      justify-content: space-between;
      .left {
        display: flex;
        .sep {
          background: $baseColor;
          width: 5px;
          height: 1.5rem;
          display: inline-block;
          margin: 0 5px;
        }
        h4 {
          padding: 0;
          margin: 0;
        }
      }
    }
    .tabs {
      .subBtn {
        background: #4e57aa;
        border-color: #4e57aa;
        margin-right: 1.09375vw;
      }
      /deep/.el-tabs__header {
        margin-bottom: 4.16vh;
      }
      /deep/.el-tabs__content {
        margin-left: 2vw;
        .basic_form {
          margin-bottom: 6.3vh;
          .el-form-item {
            margin-bottom: 1.85vh;
            .el-form-item__label {
              color: #333;
            }
            .el-form-item__content {
              > .el-input {
                width: 20%;
              }
              .el-select {
                width: 20%;
              }
              .el-input__inner {
                font-size: 14px;
                color: #333;
              }
              .el-textarea {
                width: 60%;
                .el-textarea__inner {
                  height: 20.6vh;
                  font-size: 14px;
                  color: #333333;
                }
              }
            }
          }
        }
        .buttons {
          width: 30%;
          margin-bottom: 2vh;
          text-align: center;
          .el-button + .el-button {
            margin-left: 10%;
          }
          .el-button:hover {
            color: #4e57aa;
            border-color: #4e57aa;
            background-color: rgba(78, 87, 170, 0.1);
          }
          .activebtn {
            color: #4e57aa;
            border-color: #4e57aa;
            background-color: rgba(78, 87, 170, 0.1);
          }
        }
        .tree_content {
          width: 30%;
          min-height: 70.3vh;
          margin-bottom: 6.85vh;
          padding: 20px 0;
          background: #ffffff;
          // box-shadow: 0px 7px 16px 0px rgba(78, 87, 170, 0.2);
          border-radius: 18px;
          border: 1px solid rgba(78, 87, 170, 0.5);
        }
      }
      .tab-img {
        width: 1.25vw;
        height: 1.25vw;
        margin-right: 0.78vw;
        vertical-align: middle;
      }
    }
  }
  // .jichu {
  //   background-image: url("../../../../assets/pc/基础信息.png") no-repeat;
  // }
}
</style>