<template>
  <div class="ring-chart" ref="ringChart"></div>
</template>

<script>
import resize from "../../../pc/mixin/echartsResize";

export default {
  data() {
    return {
      chart: null,
    };
  },
  mixins: [resize],

  props: ["ringData"],
  watch: {
    ringData() {
      this.initChart();
    },
  },
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      let total = this.ringData.reduce((a, b) => {
        return a + b.value;
      }, 0);
      this.chart = this.$echarts.init(this.$refs.ringChart);
      const option = {
        color: ["#7A79FF", "#23B899", "#58CFFF", "#FFC531", "#F9837D"],
        tooltip: {
          trigger: "item",
          // formatter: "{a}<br />{b}: {c}h",
        },
        legend: {
          right: 0,
          top: "middle",
          orient: "vertical",
          icon: "circle",
          itemGap: 12,
          formatter: (name) => {
            let res = this.ringData.filter((v) => v.name === name);
            let percent = ((res[0].value * 100) / total).toFixed(1);
            let value = res[0].value;
            return name + " " + value + "h" + "     " + percent + "%";
          },
          textStyle: {
            color: "#999999",
          },
        },
        series: [
          {
            // name: "总时长",
            type: "pie",
            radius: ["50%", "70%"],
            center: ["35%", "50%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: "center",
              formatter: ["{a|" + total.toFixed(2) + "h}", "{b|总时长}"].join("\n"),
              rich: {
                a: {
                  color: "#4E57AA",
                  fontSize: 22,
                  lineHeight: 30,
                },
                b: {
                  color: "#4E57AA",
                  fontSize: 14,
                  lineHeight: 22,
                },
              },
            },
            emphasis: {
              label: {
                // show: true,
                // formatter: ["{a|" + total + "h}", "{b|总时长}"].join("\n"),
                // rich: {
                //   a: {
                //     color: "#4E57AA",
                //     fontSize: 22,
                //     lineHeight: 30,
                //   },
                //   b: {
                //     color: "#4E57AA",
                //     fontSize: 14,
                //     lineHeight: 22,
                //   },
                // },
              },
            },
            labelLine: {
              show: false,
            },
            data: this.ringData,
          },
        ],
      };
      this.chart.setOption(option);
    },
  },
};
</script>


