<template>
  <div class="store_data_analysis_detail_content">
    <div class="project_overview_header">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/dataAnalysis' }"
          >数据分析</el-breadcrumb-item
        >
        <el-breadcrumb-item>门店数据分析列表</el-breadcrumb-item>
        <el-breadcrumb-item>门店详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="store_data_analysis_detail_bigcontent">
      <div class="store_data_analysis_detail_area_proportion_content_header">
        <div class="project_overview_periphery_left_header_left">
          <div class="project_overview_periphery_left_header_left_color"></div>
          <div class="project_overview_periphery_left_header_left_text">
            门店详情-{{ $route.query.name }}
          </div>
        </div>
      </div>
      <div class="titleBox" style="padding: 0 20px;">
        <div class="project_overview_overview_left_header">
          <div class="project_overview_overview_left_header_color"></div>
          <div class="project_overview_overview_left_header_text">总览</div>
        </div>
            <div class="project_overview_overview_right_header">
            <div class="overview_right_header_left">
              <div
                :class="{
                  overview_right_header_left_button: true,
                  overview_right_header_left_button_checked: dayIndex == 0,
                  overview_right_header_left_button_nochecked: dayIndex !== 0,
                }"
                @click="changeDayIndex(0)"
              >
                今日
              </div>
              <div
                :class="{
                  overview_right_header_left_button: true,
                  overview_right_header_left_button_checked: dayIndex == 1,
                  overview_right_header_left_button_nochecked: dayIndex !== 1,
                }"
                @click="changeDayIndex(1)"
              >
                近7天
              </div>
              <div
                :class="{
                  overview_right_header_left_button: true,
                  overview_right_header_left_button_checked: dayIndex == 2,
                  overview_right_header_left_button_nochecked: dayIndex !== 2,
                }"
                @click="changeDayIndex(2)"
              >
                近一个月
              </div>
            </div>
            <div class="overview_right_header_right">
              <el-date-picker
                v-model="value1"
                type="daterange"
                size="small"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                @change="selectTime"
              >
              </el-date-picker>
            </div>
          </div>
      </div>
      <div class="titleBox" style="margin-left:20px;">
        <div class="titleItem ">
          <img class="itemBlue" src="@/assets/img/blue.png" alt="">
          <div class="itemcontent">
            <div class="">
              <span>销售数量</span>
              <img style="margin-left:5px;" src="@/assets/img/Frame.png" alt="">
            </div>
            <p class="titlenum">{{optionsData.saleCount}}</p>
          </div>
          <img class="titlerightImg" src="@/assets/img/Icon.png" alt="">
        </div>
        <div class="titleItem ">
          <img class="itemBlue" src="@/assets/img/yellow.png" alt="">
          <div class="itemcontent">
            <div class="">
              <span>门店人数</span>
              <img style="margin-left:5px;" src="@/assets/img/Frame.png" alt="">
            </div>
            <p class="titlenum">{{optionsData.userCount}}</p>
          </div>
          <img class="titlerightImg" src="@/assets/img/Icon (1).png" alt="">
        </div>
        <div class="titleItem ">
          <img class="itemBlue" src="@/assets/img/green.png" alt="">
          <div class="itemcontent">
            <div class="">
              <span>在岗异常次数</span>
              <img style="margin-left:5px;" src="@/assets/img/Frame.png" alt="">
            </div>
            <p class="titlenum">{{optionsData.warningCount}}</p>
          </div>
          <img class="titlerightImg" src="@/assets/img/Icon (2).png" alt="">
        </div>
      </div>
      <div class="project_overview_overview">
        <div class="project_overview_overview_left">
          <p>行为指标</p>
          <div class="indicatorBox">
            <div class="indicatorItem" v-for="(item,index) in indicatorList" :key="index" :style="{marginTop:(index > 1 ? '20px' : '')}">
              <div class="indicatorItemBox">
                <img :src="item.src" alt="">
                <span>{{item.name}}</span>
                <img src="@/assets/img/Frame.png" alt="">
              </div>
              <div :title="item.num" class="indicatorBtn">
                {{item.num}}
              </div>
            </div>
          </div>
          <p>话术指标</p>
          <div class="indicatorBox">
            <div class="indicatorItem" v-for="(item,index) in verbalList" :key="index" :style="{marginTop:(index > 1 ? '20px' : '')}">
              <div class="indicatorItemBox">
                <img :src="item.src" alt="">
                <span>{{item.name}}</span>
                <img src="@/assets/img/Frame.png" alt="">
              </div>
              <div :title="item.num" class="indicatorBtn">
                {{item.num}}
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="project_overview_overview_left">
          <div class="project_overview_overview_left_main">
            <div class="project_overview_overview_left_main_left">
              <div
                class="project_overview_overview_left_main_left_item"
                v-for="(item, index) in zlsj_l"
                :key="index"
              >
                <div class="overview_left_main_left_item_left">
                  <img :src="item.src" :style="{'margin-right':( index == 3 ? '17px' : '12px'),'margin-left':(index == 3 ? '5px' : '')}">
                  {{ item.text ? item.text : "无" }}
                </div>
                <div class="overview_left_main_left_item_right">
                  {{ item.num }}
                </div>
              </div>
            </div>
            <div class="project_overview_overview_left_main_left" style="margin-left:30px;">
              <div
                class="project_overview_overview_left_main_left_item"
                v-for="(item, index) in zlsj_r"
                :key="index"
              >
                <div class="overview_left_main_left_item_left">
                  <img :src="item.src">
                  {{ item.text ? item.text : "无" }}
                </div>
                <div class="overview_left_main_left_item_right">
                  {{ index == 1 || index == 3 ? item.num + '(min)' : (index == 5 ? item.num + '%' : item.num) }}
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="project_overview_overview_right">
          <div class="project_overview_overview_right_main">
            <div id="chart"></div>
          </div>
        </div>
      </div>
    </div>


    
    <div class="project_overview_periphery">
      <div class="project_overview_periphery_left">
        <div class="project_overview_periphery_left_header">
          <div class="project_overview_periphery_left_header_left">
            <div
              class="project_overview_periphery_left_header_left_color"
            ></div>
            <div class="project_overview_periphery_left_header_left_text">
              周边同期比
            </div>
          </div>
          <div class="project_overview_periphery_left_header_right">
          </div>
        </div>
        <div class="project_overview_periphery_left_mapcontent">
          <div class="toMapBtn" @click="toMap">
              点击进入全屏查看
          </div>
          <div id="mapId" style="width: 100%; height: 100%"></div>
        </div>
      </div>
      <div class="project_overview_periphery_right">
        <div class="project_overview_periphery_right_header">
          <div class="project_overview_periphery_right_header_left">
            <div class="project_overview_periphery_right_header_left_text">
              {{ storeList[0].spaceName }}
            </div>
          </div>
          <div class="project_overview_periphery_right_header_right">
            <div>
                <span style="color:rgba(78, 87, 170, 1);">人数：</span>
                <span style="color:rgba(255, 197, 49, 1)">{{ storeList[0].userCount }}</span>
            </div>
            <div style="margin-left:30px;">
                <span style="color:rgba(78, 87, 170, 1);">销售完成量：</span>
                <span style="color:rgba(255, 197, 49, 1)">{{ storeList[0].saleCount || 0}}</span>
            </div>
          </div>
        </div>
        <el-col :span="24">
          <el-row class="project_overview_periphery_right_main">
            <el-col class="project_overview_periphery_right_main_item" :span="6"
              >主动沟通占比</el-col
            >
            <el-col class="project_overview_periphery_right_main_item" :span="6"
              >话术匹配度</el-col
            >
            <el-col class="project_overview_periphery_right_main_item" :span="6"
              >通话时长(min)</el-col
            >
            <el-col class="project_overview_periphery_right_main_item" :span="6"
              >离岗次数</el-col
            >
          </el-row>
          <el-row class="project_overview_periphery_right_main_centent">
            <el-col
              :span="6"
              class="project_overview_periphery_right_main_centent_item"
              >{{ storeList[0].activeTalkProp + '%' || 0 }}</el-col
            >
            <el-col
              :span="6"
              class="project_overview_periphery_right_main_centent_item"
              >{{ storeList[0].verbalMatchingRate || 0 }}</el-col
            >
            <el-col
              :span="6"
              class="project_overview_periphery_right_main_centent_item"
              >{{ storeList[0].staffDuration || 0 }}</el-col
            >
            <el-col
              :span="6"
              class="project_overview_periphery_right_main_centent_item"
              >{{ storeList[0].odc || 0 }}</el-col
            >
          </el-row>

          <el-row class="project_overview_periphery_right_main">
            <el-col class="project_overview_periphery_right_main_item" :span="6"
              >静止次数</el-col
            >
            <el-col class="project_overview_periphery_right_main_item" :span="6"
              >静止时长(min)</el-col
            >
            <el-col class="project_overview_periphery_right_main_item" :span="6"
              >运动步数</el-col
            >
            <el-col class="project_overview_periphery_right_main_item" :span="6"
              >离岗时间(min)</el-col
            >
          </el-row>
          <el-row class="project_overview_periphery_right_main_centent">
            <el-col
              :span="6"
              class="project_overview_periphery_right_main_centent_item"
              >{{ storeList[0].ssc || 0 }}</el-col
            >
            <el-col
              :span="6"
              class="project_overview_periphery_right_main_centent_item"
              >{{ storeList[0].ssd || 0 }}</el-col
            >
            <el-col
              :span="6"
              class="project_overview_periphery_right_main_centent_item"
              >{{ storeList[0].step || 0 }}</el-col
            >
            <el-col
              :span="6"
              class="project_overview_periphery_right_main_centent_item"
              >{{ storeList[0].odd || 0 }}</el-col
            >
          </el-row>
        </el-col>

        <div v-if="storeList.length > 1" class="project_overview_periphery_right_header">
          <div class="project_overview_periphery_right_header_left">
            <!-- <div class="project_overview_periphery_right_header_left_color"></div> -->
            <div class="project_overview_periphery_right_header_left_text">
              {{ storeList[1].spaceName }}
            </div>
          </div>
          <div class="project_overview_periphery_right_header_right">
            <div>
                <span style="color:rgba(78, 87, 170, 1);">人数：</span>
                <span style="color:rgba(255, 197, 49, 1)">{{ storeList[1].userCount }}</span>
            </div>
            <div style="margin-left:30px;">
                <span style="color:rgba(78, 87, 170, 1);">销售完成量：</span>
                <span style="color:rgba(255, 197, 49, 1)">{{ storeList[1].saleCount || 0}}</span>
            </div>
          </div>
        </div>
        <el-col v-if="storeList.length > 1" :span="24">
          <el-row class="project_overview_periphery_right_main">
            <el-col class="project_overview_periphery_right_main_item" :span="6"
              >主动沟通占比</el-col
            >
            <el-col class="project_overview_periphery_right_main_item" :span="6"
              >话术匹配度</el-col
            >
            <el-col class="project_overview_periphery_right_main_item" :span="6"
              >通话时长(min)</el-col
            >
            <el-col class="project_overview_periphery_right_main_item" :span="6"
              >离岗次数</el-col
            >
          </el-row>
          <el-row class="project_overview_periphery_right_main_centent">
            <el-col
              :span="6"
              class="project_overview_periphery_right_main_centent_item"
              >{{ storeList[1].activeTalkProp + '%' || 0 }}</el-col
            >
            <el-col
              :span="6"
              class="project_overview_periphery_right_main_centent_item"
              >{{ storeList[1].verbalMatchingRate || 0 }}</el-col
            >
            <el-col
              :span="6"
              class="project_overview_periphery_right_main_centent_item"
              >{{ storeList[1].staffDuration || 0 }}</el-col
            >
            <el-col
              :span="6"
              class="project_overview_periphery_right_main_centent_item"
              >{{ storeList[1].odc || 0 }}</el-col
            >
          </el-row>

          <el-row class="project_overview_periphery_right_main">
            <el-col class="project_overview_periphery_right_main_item" :span="6"
              >静止次数</el-col
            >
            <el-col class="project_overview_periphery_right_main_item" :span="6"
              >静止时长(min)</el-col
            >
            <el-col class="project_overview_periphery_right_main_item" :span="6"
              >运动步数</el-col
            >
            <el-col class="project_overview_periphery_right_main_item" :span="6"
              >离岗时间(min)</el-col
            >
          </el-row>
          <el-row class="project_overview_periphery_right_main_centent">
            <el-col
              :span="6"
              class="project_overview_periphery_right_main_centent_item"
              >{{ storeList[1].ssc || 0 }}</el-col
            >
            <el-col
              :span="6"
              class="project_overview_periphery_right_main_centent_item"
              >{{ storeList[1].ssd || 0 }}</el-col
            >
            <el-col
              :span="6"
              class="project_overview_periphery_right_main_centent_item"
              >{{ storeList[1].step || 0 }}</el-col
            >
            <el-col
              :span="6"
              class="project_overview_periphery_right_main_centent_item"
              >{{ storeList[1].odd || 0 }}</el-col
            >
          </el-row>
        </el-col>
      </div>
    </div>


    <div class="store_data_analysis_detail_area_proportion_content">
      <div class="store_data_analysis_detail_area_proportion_content_header">
        <!-- <div class="project_overview_storeRanking_header_left"> -->
        <div class="project_overview_periphery_left_header_left">
          <div class="project_overview_periphery_left_header_left_color"></div>
          <div class="project_overview_periphery_left_header_left_text">
            门店人员
          </div>
        </div>
        <!-- </div> -->
      </div>
      <div class="store_data_analysis_detail_area_proportion">
        <el-col :span="24">
          <el-row class="store_data_analysis_detail_area_proportion_main">
            <el-col
              class="store_data_analysis_detail_area_proportion_main_item"
              :span="2"
              >人员名称</el-col
            >
            <el-col
              class="store_data_analysis_detail_area_proportion_main_item"
              :span="3"
              >主动沟通占比(%)</el-col
            >
            <el-col
              class="store_data_analysis_detail_area_proportion_main_item"
              :span="2"
              >话术匹配度(%)</el-col
            >
            <el-col
              class="store_data_analysis_detail_area_proportion_main_item"
              :span="2"
              >沟通时长(min)</el-col
            >
            <el-col
              class="store_data_analysis_detail_area_proportion_main_item"
              :span="2"
              >离岗次数</el-col
            >
            <el-col
              class="store_data_analysis_detail_area_proportion_main_item"
              :span="2"
              >离岗时间(min)</el-col
            >
            <el-col
              class="store_data_analysis_detail_area_proportion_main_item"
              :span="2"
              >设备静止次数</el-col
            >
            <el-col
              class="store_data_analysis_detail_area_proportion_main_item"
              :span="3"
              >设备静止时长(min)</el-col
            >
            <el-col
              class="store_data_analysis_detail_area_proportion_main_item"
              :span="2"
              >行动步数</el-col
            >
            <el-col
              class="store_data_analysis_detail_area_proportion_main_item"
              :span="2"
              >销售数量</el-col
            >
            <el-col
              class="store_data_analysis_detail_area_proportion_main_item"
              :span="2"
              >预警次数</el-col
            >
          </el-row>
          <el-row
            class="store_data_analysis_detail_area_proportion_centent"
            v-for="(item, index) in optionsData"
            :key="index"
          >
            <el-col
              :span="2"
              class="store_data_analysis_detail_area_proportion_main_centent_item"
              style="color: #4e57aa"
              >{{ item.nickName }}</el-col
            >
            <el-col
              :span="3"
              class="store_data_analysis_detail_area_proportion_main_centent_item"
              >{{ item.activeTalkProp }}</el-col
            >
            <el-col
              :span="2"
              class="store_data_analysis_detail_area_proportion_main_centent_item"
              >{{ item.verbalMatchingRate }}</el-col
            >
            <el-col
              :span="2"
              class="store_data_analysis_detail_area_proportion_main_centent_item"
              >{{ item.talkTime }}</el-col
            >
            <el-col
              :span="2"
              class="store_data_analysis_detail_area_proportion_main_centent_item"
              >{{ item.odc }}</el-col
            >
            <el-col
              :span="2"
              class="store_data_analysis_detail_area_proportion_main_centent_item"
              >{{ item.odd }}</el-col
            >
            <el-col
              :span="2"
              class="store_data_analysis_detail_area_proportion_main_centent_item"
              >{{ item.ssc }}</el-col
            >
            <el-col
              :span="3"
              class="store_data_analysis_detail_area_proportion_main_centent_item"
              >{{ item.ssd }}</el-col
            >
            <el-col
              :span="2"
              class="store_data_analysis_detail_area_proportion_main_centent_item"
              >{{ item.step }}</el-col
            >
            <el-col
              :span="2"
              class="store_data_analysis_detail_area_proportion_main_centent_item"
              >{{ item.saleCount }}</el-col
            >
            <el-col
              :span="2"
              class="store_data_analysis_detail_area_proportion_main_centent_item"
              >{{ item.warningCount }}</el-col
            >
          </el-row>
        </el-col>
      </div>
    </div>

    <div class="project_overview_analysisTrend">
      <div class="project_overview_storeRanking_header">
        <div class="project_overview_storeRanking_header_left">
          <div class="project_overview_periphery_left_header_left">
            <div
              class="project_overview_periphery_left_header_left_color"
            ></div>
            <div class="project_overview_periphery_left_header_left_text">
              概况分析趋势
            </div>
          </div>
        </div>
      </div>
      <div class="project_overview_analysisTrend_content">
        <div class="project_overview_analysisTrend_content_top">
          <div class="project_overview_analysisTrend_content_item">
            <div class="project_overview_analysisTrend_content_item_header">
              <div
                class="project_overview_analysisTrend_content_item_header_color"
              ></div>
              <div
                class="project_overview_analysisTrend_content_item_header_text"
              >
                行动步数趋势
              </div>
            </div>
            <div class="echarts-box">
              <Cu-Line-Xs
                :data="echartsTwoData"
                :animation="true"
                :dataMap="{
                  x: 'time',
                  y: 'count',
                }"
                :tooltip="{
                  show: false,
                  y: '',
                }"
                :lableShow="true"
                :yAxisShow="{
                  show: true,
                  color: '#EFF4FF',
                }"
                :gradient="{
                  top: '#bfbdef',
                  bottom: '#FFF',
                  linecolor: '#4E57AA',
                }"
                height="100%"
                width="100%"
              />
            </div>
          </div>
          <div class="project_overview_analysisTrend_content_item">
            <div class="project_overview_analysisTrend_content_item_header">
              <div
                class="project_overview_analysisTrend_content_item_header_color"
              ></div>
              <div
                class="project_overview_analysisTrend_content_item_header_text"
              >
                通话时长趋势
              </div>
            </div>
            <div class="echarts-box">
              <Cu-Line-Xs
                :data="echartSixData"
                :animation="true"
                :dataMap="{
                  x: 'time',
                  y: 'count',
                }"
                :tooltip="{
                  show: false,
                  y: '',
                }"
                :lableShow="true"
                :yAxisShow="{
                  show: true,
                  color: '#EFF4FF',
                }"
                :gradient="{
                  top: '#ffe3af',
                  bottom: '#FFF',
                  linecolor: '#FFC531',
                }"
                height="100%"
                width="100%"
              />
            </div>
          </div>
          <div class="project_overview_analysisTrend_content_item">
            <div class="project_overview_analysisTrend_content_item_header">
              <div
                class="project_overview_analysisTrend_content_item_header_color"
              ></div>
              <div
                class="project_overview_analysisTrend_content_item_header_text"
              >
                主动沟通占比趋势
              </div>
            </div>
            <div class="echarts-box">
              <Cu-Line-Xs
                :data="echartsFiveData"
                :animation="true"
                :dataMap="{
                  x: 'time',
                  y: 'count',
                }"
                :tooltip="{
                  show: false,
                  y: '',
                }"
                :lableShow="true"
                :yAxisShow="{
                  show: true,
                  color: '#EFF4FF',
                }"
                :gradient="{
                  top: '#b4ceff',
                  bottom: '#FFF',
                  linecolor: '#5885FF',
                }"
                height="100%"
                width="100%"
              />
            </div>
          </div>
        </div>
        <div class="project_overview_analysisTrend_content_top">
          <div class="project_overview_analysisTrend_content_item">
            <div class="project_overview_analysisTrend_content_item_header">
              <div
                class="project_overview_analysisTrend_content_item_header_color"
              ></div>
              <div
                class="project_overview_analysisTrend_content_item_header_text"
              >
                脱岗时长趋势
              </div>
            </div>
            <div class="echarts-box">
              <Cu-Line-Xs
                :data="echartsOneData"
                :animation="true"
                :dataMap="{
                  x: 'time',
                  y: 'count',
                }"
                :tooltip="{
                  show: false,
                  y: '',
                }"
                :lableShow="true"
                :yAxisShow="{
                  show: true,
                  color: '#EFF4FF',
                }"
                :gradient="{
                  top: '#a3f2d0',
                  bottom: '#FFF',
                  linecolor: '#23B899',
                }"
                height="100%"
                width="100%"
              />
            </div>
          </div>
          <div class="project_overview_analysisTrend_content_item">
            <div class="project_overview_analysisTrend_content_item_header">
              <div
                class="project_overview_analysisTrend_content_item_header_color"
              ></div>
              <div
                class="project_overview_analysisTrend_content_item_header_text"
              >
                设备静止时长趋势
              </div>
            </div>
            <div class="echarts-box">
              <Cu-Line-Xs
                :data="echartsThreeData"
                :animation="true"
                :dataMap="{
                  x: 'time',
                  y: 'count',
                }"
                :tooltip="{
                  show: false,
                  y: '',
                }"
                :lableShow="true"
                :yAxisShow="{
                  show: true,
                  color: '#EFF4FF',
                }"
                :gradient="{
                  top: '#cbadff',
                  bottom: '#FFF',
                  linecolor: '#6B48FF',
                }"
                height="100%"
                width="100%"
              />
            </div>
          </div>
          <div class="project_overview_analysisTrend_content_item">
            <div class="project_overview_analysisTrend_content_item_header">
              <div
                class="project_overview_analysisTrend_content_item_header_color"
              ></div>
              <div
                class="project_overview_analysisTrend_content_item_header_text"
              >
                话术匹配度
              </div>
            </div>
            <div class="echarts-box">
              <Cu-Line-Xs
                :data="echartsForeData"
                :animation="true"
                :dataMap="{
                  x: 'time',
                  y: 'count',
                }"
                :tooltip="{
                  show: false,
                  y: '',
                }"
                :lableShow="true"
                :yAxisShow="{
                  show: true,
                  color: '#EFF4FF',
                }"
                :gradient="{
                  top: '#b4edff',
                  bottom: '#FFF',
                  linecolor: '#58CFFF',
                }"
                height="100%"
                width="100%"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
function bgps_gps(bd_lng, bd_lat) {
  var X_PI = Math.PI * 3000.0 / 180.0;
  var x = bd_lng - 0.0065;
  var y = bd_lat - 0.006;
  var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * X_PI);
  var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * X_PI);
  var gg_lng = z * Math.cos(theta);
  var gg_lat = z * Math.sin(theta);
  return {lng: gg_lng, lat: gg_lat}
}
import CuLineXs from "@/views/components/Linexs";
import {
  getInfo,
  getInfoDetails,
  getStoreTendency,
  getSpaceAnal,
  getSpaceAnalVerbal,
  getStoresvs,
  getInfoUserAnal,
  getSpaceSpider,
  getProjectSpider
} from "@/api/dataAnalysis/project";
import { setTimeout } from 'timers';
export default {
  name: "storedataanalysisdetail",
  components: {
    CuLineXs,
  },
  data() {
    return {
      areaProps: {
        lazy: true,
        checkStrictly: true,
        lazyLoad: this.loadDom,
      },
      optionspaceName: '',
      areas: [],
      spaceName: "",
      placeMarker: null,
      showStore: false,
      markerList: [],
      zbtqb_data: [
        {
          a: "刘义君",
          b: "79%",
          c: "80%",
          d: "8h",
          e: "0",
          f: "0",
          g: "1",
          h: "0.5h",
          i: "12320",
          j: "307",
          k: "1",
        },
      ],
      echartsOneData: {
        name: "AAPL",
        max: 10,
        min: 0,
        data: [],
      },
      echartsTwoData: {
        name: "AAPL",
        max: 15000,
        min: 0,
        data: [],
      },
      echartsThreeData: {
        name: "AAPL",
        max: 20,
        min: 0,
        data: [],
      },
      echartsForeData: {
        name: "AAPL",
        max: 100,
        min: 0,
        data: [
          {
            time: "08-20",
            count: 0,
          },
          {
            time: "08-21",
            count: 0,
          },
          {
            time: "08-22",
            count: 0,
          },
          {
            time: "08-23",
            count: 0,
          },
          {
            time: "08-24",
            count: 0,
          },
          {
            time: "08-25",
            count: 0,
          },
          {
            time: "08-20",
            count: 0,
          },
        ],
      },
      echartsFiveData: {
        name: "AAPL",
        max: 100,
        min: 0,
        data: [],
      },
      echartSixData: {
        name: "AAPL",
        max: 5,
        min: 0,
        data: [
          {
            time: "08-20",
            count: 0,
          },
          {
            time: "08-21",
            count: 0,
          },
          {
            time: "08-22",
            count: 0,
          },
          {
            time: "08-23",
            count: 0,
          },
          {
            time: "08-24",
            count: 0,
          },
          {
            time: "08-25",
            count: 0,
          },
          {
            time: "08-20",
            count: 0,
          },
        ],
      },
      optionsDataspaces:[],
      storeList: [
        {
          spaceId:'',
          projectId:'',
          spaceName:'',
          userCount:'',
          saleCount:'',
          accordTalkCount:'',
          verbalMatchingRate:'',
          staffDuration:'',
          odc:'',
          ssc:'',
          ssd:'',
          odd:'',
        }
      ],
      takeoff: [],
      tendency:[],
      Mesh: [],
      optionsData: {},
      value: "",
      value1: "",
      timeSet: [],
      dayIndex: 1,
      indicatorList:[
        {
          name:'人均行动步数',
          num:'5789',
          src:require('@/assets/img/Icon-window.png')
        },
        {
          name:'人均离岗时长',
          num:'5789',
          src:require('@/assets/img/rjlgsc.png')
        },
        {
          name:'人均脱岗步数',
          num:'5789',
          src:require('@/assets/img/rjtgbs.png')
        },
        {
          name:'人均脱岗时长',
          num:'5789',
          src:require('@/assets/img/rjtgsc.png')
        },
        {
          name:'人均静止步数',
          num:'5789',
          src:require('@/assets/img/rjjzbs.png')
        },
        {
          name:'人均静止时长',
          num:'5789',
          src:require('@/assets/img/rjjzsc.png')
        }
      ],
      verbalList:[
        {
          name:'人均主动沟通占比',
          num:'5789',
          src:require('@/assets/img/rjzdgtzb.png')
        },
        {
          name:'人均沟通时长',
          num:'5789',
          src:require('@/assets/img/rjgtsc.png')
        },
        {
          name:'关键词命中次数',
          num:'5789',
          src:require('@/assets/img/gjcmzcs.png')
        },
        {
          name:'主动沟通次数',
          num:'5789',
          src:require('@/assets/img/zdgtcs.png')
        },
      ],
      projectAvg:[],
      spaceAvg:[],
      spaceSpiderList:[],
      projectspiderList:[],
      options: JSON.parse(localStorage.getItem('storeDetailsRow')),
      zlsj_l: [
        { text: "销售数量", num: "", src: require("@/assets/img/xs.png") },
        { text: "人均离岗次数", num: "", src: require("@/assets/img/tgcs.png") },
        { text: "在岗异常人数", num: "", src: require("@/assets/img/zgyc.png") },
        { text: "设备静止次数", num: "", src: require("@/assets/img/jzcs.png") },
        { text: "被动沟通次数", num: "", src: require("@/assets/img/mdgtzb.png") },
        { text: "话术匹配度", num: "", src: require("@/assets/img/hspp.png") },
      ],
      zlsj_r: [
        { text: "门店人数", num: "", src: require("@/assets/img/md.png") },
        { text: "人均沟通时长", num: "", src: require("@/assets/img/thsc.png") },
        { text: "人均行动步数", num: "", src: require("@/assets/img/bs.png") },
        { text: "设备静止时长", num: "", src: require("@/assets/img/jjgt.png") },
        { text: "主动沟通次数", num: "", src: require("@/assets/img/jzsc.png") },
        { text: "主动沟通占比", num: "", src: require("@/assets/img/gtzb.png") },
      ],
    };
  },
  created(){
    this.getDetails()
    this.getStatistics()
    this.getTendency()
    this.getIndicatorList()
  },
  methods: {
    getTendency(){
      getStoreTendency({
        projectId: this.options.storeProjectId, //this.options.storeProjectId,
        spaceId: this.options.spaceId //this.options.spaceId
      }).then(res=>{
        let takeoff = []
        let action = []
        let statics = []
        let talks = []
        let five = []
        if(res.code == 200){
          // this.tendency = 
          res.data.forEach(item=>{
            takeoff.push({
              time: item.sdate,
              count: item.oscaTime
            })
            action.push({
              time: item.sdate,
              count: item.step
            })
            statics.push({
              time: item.sdate,
              count: item.ssd
            })
            talks.push({
              time: item.sdate,
              count: item.activeTalkProp || 0
            })
            five.push({
              time: item.sdate,
              count: item.staffDuration
            })
          })
          this.echartsOneData.data = takeoff
          this.echartsTwoData.data = action
          this.echartSixData.data = five
          this.echartsThreeData.data = statics
          this.echartsFiveData.data = talks
        }
      })
    },
    getIndicatorList(){
      getSpaceAnal({
        projectId: this.options.storeProjectId,
        spaceId: this.options.spaceId
      }).then(res=>{
        this.indicatorList[0].num = res.data.userAvgStep
        this.indicatorList[1].num = res.data.userAvgOdd
        this.indicatorList[2].num = res.data.userAvgOscaCount
        this.indicatorList[3].num = res.data.userAvgOscaTime
        this.indicatorList[4].num = res.data.userAvgSsc
        this.indicatorList[5].num = res.data.userAvgSsd
      })
      getSpaceAnalVerbal({
        projectId: this.options.storeProjectId,
        spaceId: this.options.spaceId
      }).then(res=>{
        this.verbalList[0].num = res.data.userAvgTalkProp
        this.verbalList[1].num = res.data.userAvgStaff
        this.verbalList[2].num = res.data.verbalGetCount
        this.verbalList[3].num = res.data.activeTalkCount
      })
    },
    selectTime(val){
      if(val && val.length > 1){
        this.dayIndex = 3
        this.timeSet = val
        this.getStatistics()
      }
    },
    getStatistics(){
      let nowTime = ''
      let weekDay = ''
      let monthDay = ''
      let date1 = new Date();
      nowTime = date1.getFullYear() + "-" + (date1.getMonth() + 1) + "-" + date1.getDate();
      let date2 = new Date();
      date2.setDate(date2.getDate() - 7)
      weekDay = date2.getFullYear() + '-' + (date2.getMonth() + 1) + '-' + date2.getDate()
      let date3 = new Date();
      date3.setDate(date3.getDate() - 30)
      monthDay = date3.getFullYear() + '-' + (date3.getMonth() + 1) + '-' + date3.getDate()
      
      getSpaceSpider({
        projectId: this.options.storeProjectId,
        spaceId: this.options.spaceId,
        startDate: this.dayIndex == 0 ? nowTime : ( this.dayIndex == 1 ? weekDay : ( this.dayIndex == 3 ? this.timeSet[0] : '') ),
        endDate: this.dayIndex == 3 ? this.timeSet[1] : nowTime
      }).then(res=>{
        let data = res.data
        this.spaceAvg = [data.verbalGetCount, data.talkCount, data.step, data.osc, data.ssc]
        getProjectSpider({
          projectId: this.options.storeProjectId,
          startDate: this.dayIndex == 0 ? nowTime : ( this.dayIndex == 1 ? weekDay : ( this.dayIndex == 3 ? this.timeSet[0] : '') ),
          endDate: this.dayIndex == 3 ? this.timeSet[1] : nowTime
        }).then(res=>{
          let data = res.data
          this.projectAvg = [data.verbalGetCount, data.talkCount, data.step, data.osc, data.ssc]
          this.initChart();
        })
      })
      // getHistoryStatistics({
      //   projectId: this.options.storeProjectId,
      //   spaceId: this.options.spaceId,
      // }).then(res=>{
      //   // chart
      //   let data = res.data
      // })
    },
    getDetails(){
      getInfoDetails({
        projectId: this.options.storeProjectId, //this.options.storeProjectId,
        spaceId: this.options.spaceId //this.options.spaceId
      }).then(res=>{
        if(res.code == 200){
        this.optionsDataspaces = res.data
          this.zlsj_l[0].num = res.data.saleCount || '0'
          this.zlsj_l[1].num = res.data.oscAvg || '0'
          this.zlsj_l[2].num = res.data.warningUserCount || '0'
          this.zlsj_l[3].num = res.data.ssc || '0'
          this.zlsj_l[4].num = res.data.passiveTalkCount || '0'
          this.zlsj_l[5].num = res.data.verbalMatchingRate || '0'
          this.zlsj_r[0].num = res.data.spaceNum || '0'
          this.zlsj_r[1].num = res.data.staffDuration || '0'
          this.zlsj_r[2].num = res.data.stepAvg || '0'
          this.zlsj_r[3].num = res.data.ssd || '0'
          this.zlsj_r[4].num = res.data.activeTalkCount || '0'
          this.zlsj_r[5].num = res.data.activeTalkProp || '0'
        }
        getStoresvs({
          projectId: this.options.storeProjectId, //this.options.storeProjectId,
          spaceIds: this.options.spaceId //this.options.spaceId
        }).then(val=>{
          this.storeList = val.data
        })
        getInfoUserAnal({
          projectId: this.options.storeProjectId,
          spaceId: this.options.spaceId
        }).then(res=>{
            this.optionsData = res.data
          })
      })
    },
    toMap(){
      // optionsData.spaces
        //第一步定义routeUrl
        let routeUrl = this.$router.resolve({
            path: "/magnify"
        });
        window.open(routeUrl.href, "_blank");
    },
    querySearchAsync(queryString, cb) {
      // if (this.areas.length < 3) {
      //   this.$message("请选择区域后再进行本操作！");
      //   return;
      // }
      // if (this.areas.length) {
      //   console.log(this.showStore);
      //   this.showStore = true;
      // } else {
      //   console.log(this.showStore);
      //   this.showStore = false;
      // }
      let _this = this
      getInfo({
        province: this.province,
        city: this.city,
        county: this.county,
        spaceName: this.spaceName,
      }).then((res) => {
        // if (res.data.length) {
        //   res.data.map((item) => {
        //     item.label = item.spaceId;
        //     item.value = item.spacePath;
        //   });
        // }
        let results = queryString
          ? res.data.filter(_this.createFilter(queryString))
          : res.data;
        if (results.length) {
          results.map((item) => {
            item.label = item.spaceId;
            item.value = item.spaceName;
          });
        }
        // console.log(results);
        cb(results);
      });
    },
    createFilter(queryString) {
      return (data) => {
        // console.log(data);
        return (
          data.spaceNameGf.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    // 加载高德地图地图
    initMap() {
      let self = this;
      let aMapScript = document.createElement("script");
        console.log(self.optionsDataspaces)
      aMapScript.setAttribute(
        "src",
        "https://webapi.amap.com/maps?v=1.4.13&key=ec85d3648154874552835438ac6a02b2&plugin=AMap.PlaceSearch,AMap.AdvancedInfoWindow"
      );
      document.head.appendChild(aMapScript);
      aMapScript.onload = function () {
        let center = bgps_gps(self.optionsDataspaces.longitude,self.optionsDataspaces.latitude)
        let map = new AMap.Map("mapId", {
          center: [center.lng, center.lat], //地区位置
          resizeEnable: true,
          zoom: 15,
          isHotspot: false,
        });
        self.mapObject = map;
        var placeSearch = new AMap.PlaceSearch(); //构造地点查询类

        var infoWindow = new AMap.AdvancedInfoWindow({});
        map.on("hotspotover", function (result) {
          placeSearch.getDetails(result.id, function (status, result) {
            if (status === "complete" && result.info === "OK") {
              placeSearch_CallBack();
            }
          });
        });
        //回调函数
        function placeSearch_CallBack() {
          //infoWindow.open(map, result.lnglat);
          //var poiArr = data.poiList.pois;
          var location = [121.740318, 31.054126];

          infoWindow.setContent(createContent());
          infoWindow.open(map, location);
        }
        //添加点标记
        let markList = []
        self.optionsDataspaces.spaces.forEach(item=>{
          // bgps_gps(item.longitude, item.latitude)
          markList.push([bgps_gps(item.longitude, item.latitude).lng, bgps_gps(item.longitude, item.latitude).lat,item.spaceName,item.spaceId])
        })
        let markers = []
        for (var i = 0; i < markList.length; i += 1) {
            let marker;
            let icon = new AMap.Icon({
              image: "https://z3.ax1x.com/2021/05/20/goUkQI.png",
              size: new AMap.Size(48, 48),
            });
            // let clickIcon = new AMap.Icon({
            //     image: "",
            //     size: new AMap.Size(48, 48), //图标大小
            // });
            marker = new AMap.Marker({
              icon: icon,
              position: markList[i],
              offset: new AMap.Pixel(-12,-12),
              zIndex: 101,
              title:markList[i][2],
              map: map,
              spaceId: markList[i][3]
            });
            // map.add(marker);
            // marker逐一push到markers
            markers.push(marker)
            marker.on('click', resetMap);
        }
        let preIcon = new AMap.Icon({
          image: "https://z3.ax1x.com/2021/05/20/goUUkF.png",
          size: new AMap.Size(48, 48),
        });
        for (var i = 0; i < markers.length; i++) {
          //       let a = bgps_gps(markers[i].getPosition().R,markers[i].getPosition().Q)
          // if(markers[i].w.title == '成都欧尚超市高新店'){
          //   console.log(a,'<<<<')
          //   console.log(center,'<<<<')
          // }
          let a = markers[i].getPosition().R.toString().substring(0,markers[i].getPosition().R.toString().length -2 )
          let z = markers[i].getPosition().Q.toString().substring(0,markers[i].getPosition().Q.toString().length -2 )
          let b = center.lng.toString().substring(0,center.lng.toString().length-2)
          let c = center.lat.toString().substring(0,center.lat.toString().length-2)
          if(a == b && z == c){
            markers[i].setIcon(preIcon);
            markers[i].setLabel({//label默认蓝框白底左上角显示，样式className为：amap-marker-label
              offset: new AMap.Pixel(-60, -25),//修改label相对于maker的位置
              content: self.storeList[0].spaceName
            });
          }
        }
        //鼠标点击事件,设置地图中心点及放大显示级别
        function resetMap(e) {
            getStoresvs({
              projectId: self.options.storeProjectId, //this.options.storeProjectId,
              spaceIds: self.options.spaceId + ',' + e.target.w.spaceId//this.options.spaceId
            }).then(val=>{
              self.storeList = val.data
            })
            let preIcon = new AMap.Icon({
              image: "https://z3.ax1x.com/2021/05/20/goUUkF.png",
              size: new AMap.Size(48, 48),
            });
            let icon = new AMap.Icon({
              image: "https://z3.ax1x.com/2021/05/20/goUkQI.png",
              size: new AMap.Size(48, 48),
            });
            map.setZoomAndCenter(15, e.target.getPosition());
            for (var i = 0; i < markers.length; i++) {
                let a = markers[i].getPosition().R.toString().substring(0,markers[i].getPosition().R.toString().length -2 )
                let z = markers[i].getPosition().Q.toString().substring(0,markers[i].getPosition().Q.toString().length -2 )
                let b = center.lng.toString().substring(0,center.lng.toString().length-2)
                let c = center.lat.toString().substring(0,center.lat.toString().length-2)
                if(a == b && z == c){
                  
                }else{
                  markers[i].setIcon(icon);
                  if(markers[i].w.spaceId == e.target.w.spaceId){
                    markers[i].setIcon(preIcon);
                  }
                }
            }
            // e.target.setIcon(clickIcon);
        }
        function createContent() {
          //信息窗体内容
          var s = [];
          s.push(
            '<div class="info-title">aaaaaaa</div><div class="info-content">' +
              "地址：bbbbbbbb"
          );
          s.push("电话：999999999");
          s.push("类型：88888888");
          s.push("<div>");
          return s.join("<br>");
        }
      };
    },
    changeDayIndex(index) {
      this.dayIndex = index;
      this.getStatistics()
    },
    initChart() {
      let that = this
      var myChart = this.$echarts.init(document.getElementById("chart"));
      var legendData = [""];
      var indicator = [
        {
          text: "关键词命中次数",
          max: this.projectAvg[0] * 2 ,
        },
        {
          text: "主动沟通次数",
          max: this.projectAvg[1] * 2,
        },
        {
          text: "脱岗次数",
          max: this.projectAvg[2] * 2,
        },
        {
          text: "静止次数",
          max: this.projectAvg[3] * 2,
        },
        {
          text: "行动步数",
          max: this.projectAvg[4] * 2,
        }
      ];
      var dataArr = [
        {
          // that.Mesh
          value: that.Mesh,
          name: legendData[0],
          itemStyle: {
            normal: {
              lineStyle: {
                color: "#55d7f2",
              },
            },
          },
          areaStyle: {
            normal: {
              // 单项区域填充样式
              color: {
                type: "linear",
                x: 1, //右
                y: 0, //下
                x2: 1, //左
                y2: 1, //上
                colorStops: [
                  {
                    offset: 0,
                    color: "#c8ffff",
                  },
                  {
                    offset: 1,
                    color: "#c8ffff",
                  },
                ],
                globalCoord: false,
              },
              opacity: 0.5, // 区域透明度
            },
          },
        },
      ];
      var colorArr = ["#5885FF", "#FFC531"]; //颜色
      var option = {
        backgroundColor: "#FFFFFF",
        color: colorArr,
        legend: {
          left: "center",
          bottom: 0,
          icon: "circle",
          itemGap: 20,
          data: [ "门店人均", "项目人均"],
        },
        radar: {
          // shape: 'circle',
          name: {
            textStyle: {
              color: "#333333",
              fontSize: 12,
            },
          },
          indicator: indicator,
          splitArea: {
            // 坐标轴在 grid 区域中的分隔区域，默认不显示。
            show: true,
            areaStyle: {
              // 分隔区域的样式设置。
              color: ["rgba(255,255,255,0)", "rgba(255,255,255,0)"], // 分隔区域颜色。分隔区域会按数组中颜色的顺序依次循环设置颜色。默认是一个深浅的间隔色。
            },
          },
          axisLine: {
            //指向外圈文本的分隔线样式
            lineStyle: {
              color: "#e3e4ea",
              type: "dashed",
            },
          },
          splitLine: {
            lineStyle: {
              color: "#e3e4ea", // 分隔线颜色
              width: 1, // 分隔线线宽
            },
          },
        },
        series: [
          {
            type: "radar",
            symbolSize: 0,
            areaStyle: {
              normal: {
                shadowBlur: 13,
                shadowColor: "rgba(0,0,0,.2)",
                shadowOffsetX: 0,
                shadowOffsetY: 10,
                opacity: 1,
              },
            },
            data: [
              {
                value: this.spaceAvg,
                name: "门店人均",
                areaStyle: {
                  opacity: 0.2,
                  color: "#FFF0E0",
                },
              },
              {
                value: this.projectAvg,
                name: "项目人均",
                areaStyle: {
                  opacity: 0.2,
                  color: "#C7FDFD",
                },
              },
            ],
          },
        ],
      };
      myChart.setOption(option);
    },
  },
  mounted() {
    this.initChart();
    let that = this
    setTimeout(function(){
      that.initMap()
    },1000)
  },
};
</script>
<style scoped lang="less">
div {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.store_data_analysis_detail_content {
  width: 100%;
  height: auto;
  // overflow-y: auto;
  background: #f9f9fd;
  padding: 0vh 1.5vw;
}
.project_overview_header {
  width: 100%;
  height: 6vh;
  // margin: 4vh 0vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.project_overview_header_right {
  // width: 30%;
  height: 4vh;
  display: flex;
  // flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /deep/.el-breadcrumb {
    margin-right: 50px;
  }
}
.project_overview_header_right_times {
  font-size: 14px;
  color: #333333;
}

.store_data_analysis_detail_bigcontent {
  width: 100%;
  border-radius: 10px;
  background: #fff;
}

.store_data_analysis_detail_bigcontent_button {
  width: 100%;
  height: 8vh;
  padding: 1vh 1.5vw 2vh 1.5vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.store_data_analysis_detail_bigcontent_button_left {
  width: 15vw;
  height: 5vh;
  background: #4e57aa;
  box-shadow: 0 6px 10px 0 rgba(78, 87, 170, 0.3);
  border-radius: 20px;
  text-align: center;
  line-height: 5vh;
  color: #fff;
  cursor: pointer;
  visibility: hidden;
}

.project_overview_periphery {
  width: 100%;
  height: 64vh;
  margin-top: 2.5vh;
  border-radius: 10px;
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

    
.periphery_left_header_right_datetime {
  width: 32vw;
  display: flex;
  align-items: center;
  // height: 100%;
  // height: 40px;
}

.project_overview_overview {
  width: 100%;
  height: 50vh;
  // border-radius: 10px;
  // background: #FFF;
  margin-top:23px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.project_overview_overview_left {
  width: 50%;
  height: 100%;
  margin: 0vh 1.5vw;
}
.project_overview_overview_left_header {
  width: 100%;
  height: 3vh;
  margin: 3vh 0vw 5vh 0vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.project_overview_overview_left_header_color {
  width: 6px;
  height: 3vh;
  font-size: 18px;
  background: #4e57aa;
}
.project_overview_overview_left_header_text {
  margin-left: 0.7vw;
  font-size: 18px;
  line-height: 3vh;
}

.project_overview_overview_left_main {
  width: 100%;
  // padding-left: 8px;
  height: 39vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.project_overview_overview_left_main_left {
  width: 50%;
  height: 100%;
}
.project_overview_overview_left_main_left_item {
  width: 100%;
  height: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  background: #F9F9FD;
  border-radius: 8px;
}

// .project_overview_overview_left_main_left_item img {
//   height:23px;
// }
.overview_left_main_left_item_left {
  width: 58%;
  height: 5vh;
  font-size: 14px;
  line-height: 5vh;
  display: flex;
  align-items: center;
  padding-left:22px;
  color: #333333;
}
.overview_left_main_left_item_left img {
  margin-right:12px;
}
.overview_left_main_left_item_right {
  min-width: 42%;
  height: 5vh;
  line-height: 5vh;
  text-align: right;
  font-size: 18px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #4E57AA;
  padding-right:64px;
}

.project_overview_overview_right {
  width: 38%;
  height: 100%;
  margin: 0vh 1.5vw;
}

.store_data_analysis_detail_bigcontent_button_left_header {
  width: 100%;
  height: 5vh;
  display: flex;
  flex-direction: row;
}

.project_overview_overview_right_header {
  width: 100%;
  height: 5vh;
  margin: 3vh 0vw 4vh 0vw;
  display: flex;
  flex-direction: row;
}
.overview_right_header_left {
  width: 52%;
  height: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-right: 3%;
}
.overview_right_header_left_button {
  width: 4.3vw;
  height: 5vh;
  text-align: center;
  line-height: 5vh;
  border-radius: 6px;
  font-size: calc(14vh / 10.8);
  cursor: pointer;
}
.overview_right_header_left_button_checked {
  background: #4e57aa;
  box-shadow: 0 6px 10px 0 rgba(78, 87, 170, 0.3);
  color: #ffffff;
}
.overview_right_header_left_button_nochecked {
  color: #333333;
  border: 1px solid #cccccc;
  background: #ffffff;
}

.overview_right_header_right {
  width: 45%;
  height: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.project_overview_overview_right_main {
  width: 100%;
  height: 38vh;
}
#chart {
  width: 100%;
  height: 34vh;
}

.store_data_analysis_detail_area_proportion_content {
  width: 100%;
  // height: 64vh;
  margin-top: 2.5vh;
  border-radius: 10px;
  background: #fff;
  // display: flex;
  // flex-direction: row;
  // justify-content: space-between;
}

.store_data_analysis_detail_area_proportion_content_header {
  width: 100%;
  height: 11vh;
  padding: 3vh 1.5vw;
}

.store_data_analysis_detail_area_proportion {
  width: 100%;
  // height: 53vh;
  padding: 0 1.5vw;
  display:inline-block;
}

.store_data_analysis_detail_area_proportion_main {
  background: rgba(78, 87, 170, 0.05);
}

.store_data_analysis_detail_area_proportion_main_item {
  height: 6.5vh;
  line-height: 6.5vh;
  text-align: center;
  font-size: 14px;
  color: #333333;
}

.store_data_analysis_detail_area_proportion_centent {
  background: rgba(255, 255, 255, 0.2);
}

.store_data_analysis_detail_area_proportion_main_centent_item {
  height: 6.5vh;
  line-height: 6.5vh;
  text-align: center;
  font-size: 14px;
  color: #333333;
}

.project_overview_periphery_left {
    width: 50%;
    height: 100%;
    margin: 0vh 1.5vw;
}
.project_overview_periphery_left_header {
    width: 100%;
    height: 5vh;
    margin: 3vh 0vw 3vh 0vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.project_overview_periphery_left_mapcontent {
    width: 100%;
    height: 50vh;
    position: relative;
}
.toMapBtn {
    position: absolute;
    top:5px;
    right:5px;
    background:rgba(64, 158, 255, .5);
    border-radius: 4px;
    z-index:999;
    color:#fff;
    padding:5px 10px;
    cursor: pointer;
}
.project_overview_periphery_left_header_left {
  width: 25%;
  height: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.project_overview_periphery_left_header_left_color {
  width: 6px;
  height: 3vh;
  font-size: 18px;
  background: #4e57aa;
}
.project_overview_periphery_left_header_left_text {
  margin-left: 0.7vw;
  font-size: 18px;
  line-height: 3vh;
}

.project_overview_periphery_left_header_right {
  width: 75%;
  height: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.periphery_left_header_right_select {
  width: 36vw;
  height: 5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 1.2vw;
}

.project_overview_periphery_right {
  width: 45%;
  height: 100%;
  padding-top: 1vh;
  margin: 0 1.5vw;
}
.project_overview_periphery_right_header {
  width: 100%;
  height: 9vh;
  // margin: 3vh 0vw 0vh 0vw;
  padding: 2vh 0vw 2vh 0vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.project_overview_periphery_right_header_left {
  width: 30%;
  height: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.project_overview_periphery_right_header_left_text {
  margin-left: 0.7vw;
  font-size: 18px;
  line-height: 3vh;
  white-space: nowrap;
}

.project_overview_periphery_right_main {
  background: rgba(78, 87, 170, 0.1);
  border-radius: 6px;
}

.project_overview_periphery_right_main_item {
  height: 5vh;
  line-height: 5vh;
  text-align: center;
  font-size: 14px;
  color: #333333;
}

.project_overview_periphery_right_main_centent {
  background: rgba(255, 255, 255, 0.2);
  font-size: 14px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #333333;
}

.project_overview_periphery_right_main_centent_item {
  height: 5vh;
  line-height: 5vh;
  text-align: center;
  font-size: 14px;
  color: #333333;
}

.project_overview_storeRanking {
  width: 100%;
  height: 64vh;
  margin-top: 2.5vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.project_overview_storeRanking_header {
  width: 97%;
  height: 5vh;
  margin: 0vh 1.5% 3vh 1.5%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.project_overview_storeRanking_header_left {
  width: 65%;
  height: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.project_overview_storeRanking_header_left_right {
  width: 70%;
  height: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.project_overview_storeRanking_header_right {
  width: 4vw;
  height: 3.8vh;
  background: #4e57aa;
  box-shadow: 0 6px 10px 0 rgba(78, 87, 170, 0.3);
  border-radius: 6px;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  line-height: 4vh;
}

.project_overview_analysisTrend {
  width: 100%;
  height: auto;
  margin-top: 2.5vh;
  padding-bottom: 1.5vh;
}

.project_overview_analysisTrend_content {
  width: 100%;
  height: auto;
}

.project_overview_analysisTrend_content_top {
  width: 100%;
  height: 40vh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.project_overview_analysisTrend_content_item {
  background: #ffffff;
  border-radius: 12px;
  width: 32%;
  height: 38vh;
  position: relative;
}

.project_overview_analysisTrend_content_item_header {
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 4%;
  padding-bottom: 4%;
}
.project_overview_analysisTrend_content_item_header_color {
  width: 4px;
  height: 2vh;
  font-size: 14px;
  background: #4e57aa;
}
.project_overview_analysisTrend_content_item_header_text {
  margin-left: 0.4vw;
  font-size: 14px;
  line-height: 3vh;
}

.echarts-box {
  width: 100%;
  height: 73%;
  position: absolute;
  top: 20%;
  left: 0;
  overflow: hidden;
  .box {
    width: 100%;
    height: 100%;
  }
}

.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
  width: auto;
}
.el-range-editor--small.el-input__inner {
  // display: flex;
  // align-items: center;
  height: 4.8vh;
  padding-right: 0;
  // padding: calc(10vw / 19.2);
  /deep/.el-icon-date {
    width: 1.3vw;
    height: 4.8vh;
    line-height: 4.8vh;
    font-size: calc(14vw / 19.2);
  }
  /deep/.el-range-input {
    width: 43%;
    line-height: 4.8vh;
    vertical-align: middle;
    font-size: calc(14vw / 19.2);
  }
  /deep/.el-range-separator {
    // height: 4.8vh;
    // vertical-align: middle;
    // line-height: 40px;
    // line-height: none;
    width: 1vw;
    padding: 0 calc(2vw / 19.2);
    height: 4.8vh;
    line-height: 4.8vh;
    font-size: calc(13vw / 19.2);
  }
  /deep/.el-range__close-icon {
    width: 1.3vw;
    height: 4.8vh;
    line-height: 4.8vh;
    font-size: calc(13vw / 19.2);
  }
}

::-webkit-scrollbar {
  width: 3px;
}
::-webkit-scrollbar-track {
  border-radius: 8px;
  width: 8px;
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #bbb;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #eee;
}
.project_overview_periphery_right_header_right {
  display: flex;
  align-items: center;
}
.project_overview_periphery_right_header_right span{
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
}

.titleBox {
  width:100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.indicatorBox {
  width:100%;
  display:flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.indicatorItemBox {
  display: flex;
  align-items: center;
}

.indicatorItem {
  width:40%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom:12px;
  border-bottom:1px solid #EFF4FF;
}

.indicatorItem span {
  font-weight: 600;
  font-size: 16px;
  color: #333333;
  margin-left:6px;
  margin-right:5px;
}

.indicatorBtn {
  padding:7px 8.5px;
  background: rgba(78, 87, 170, 0.1);
  border-radius: 7px;
  font-weight: 600;
  font-size: 18px;
  color: #4E57AA;
}

.titleItem {
  width:500px;
  height:140px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.itemcontent {
  z-index:99;
  margin-left:30px;
}

.titlenum {
  font-family: Inter;
  font-weight: bold;
  font-size: 26px;
  color: #FFFFFF;
  margin:5px 0 0 0;
}

.itemcontent span{
  font-weight: 600;
  font-size: 18px;
  color: #FFFFFF;
}
.titlerightImg {
  z-index:99;
  margin-right:49px;
}
.itemBlue {
  position: absolute;
  top:0;
  left:0;
  z-index:0;
  width:100%;
  height:100%;
}
</style>
