<template>
  <div class="wordslibrary">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/wordsAnalyze' }"
          >话术分析</el-breadcrumb-item
        >
        <el-breadcrumb-item>标准话术库</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main">
      <div class="title">
        <div class="left">
          <i class="sep"></i>
          <h4>标准话术库</h4>
        </div>
      </div>
      <div class="form-content marginTop">
        <div class="form-item">
          <span class="selectName">项目：</span>
          <el-select
            v-model="formData.projectId"
            clearable
            placeholder="全部"
            filterable
          >
            <el-option
              v-for="item in projectMenu"
              :key="item.projectId"
              :label="item.projectName"
              :value="item.projectId"
            >
            </el-option>
          </el-select>
        </div>
        <div class="form-item">
          <span class="selectName">行业：</span>
          <el-select
            v-model="formData.industryId"
            clearable
            placeholder="全部"
            filterable
          >
            <el-option
              v-for="item in industryMenu"
              :key="item.industryId"
              :label="item.industryName"
              :value="item.industryId"
            >
            </el-option>
          </el-select>
        </div>
        <div class="form-item">
          <span class="selectName">品牌：</span>
          <el-select
            filterable
            v-model="formData.brandId"
            clearable
            placeholder="全部"
            @change="changeBrand"
          >
            <el-option
              v-for="item in brandMenu"
              :key="item.brandId"
              :label="item.brandName"
              :value="item.brandId"
            >
            </el-option>
          </el-select>
        </div>
        <div class="form-item">
          <span class="selectName">品类：</span>
          <el-select
            v-model="formData.categoryId"
            clearable
            filterable
            placeholder="全部"
            @change="changeCategory"
          >
            <el-option
              v-for="item in categoryMenu"
              :key="item.categoryId"
              :label="item.categoryName"
              :value="item.categoryId"
            >
            </el-option>
          </el-select>
        </div>
        <div class="form-item">
          <span class="selectName">商品：</span>
          <el-select
            v-model="formData.waresId"
            clearable
            placeholder="全部"
            filterable
          >
            <el-option
              v-for="item in waresMenu"
              :key="item.waresId"
              :label="item.waresName"
              :value="item.waresId"
            >
            </el-option>
          </el-select>
        </div>
        <div class="form-item">
          <span class="selectName">状态：</span>
          <el-select
            v-model="formData.status"
            clearable
            placeholder="全部"
            filterable
          >
            <el-option label="禁用" :value="0"></el-option>
            <el-option label="启用" :value="1"></el-option>
          </el-select>
        </div>
        <div class="form-item">
          <el-button class="searchBtn" @click="onInquire">查询</el-button>
          <el-button @click="onReset">重置</el-button>
        </div>
      </div>
      <div style="display: flex; justify-content: flex-end">
        <el-button class="searchBtn">导出</el-button>
      </div>
      <el-row :gutter="10">
        <TableChart
          :tableDatas="{
            data: wordsLibaryTable,
          }"
          :pageData="pageData"
          @changePage="currentChange"
          @changeSize="sizeChange"
        ></TableChart>
      </el-row>
    </div>
  </div>
</template>
<script>
import TableChart from "../table";
import { mapState, mapActions } from "vuex";
import {
  libraryList,
  toListPage,
  categoryList,
  waresList,
} from "@/api/wordsAnalyze/wordslibrary";
export default {
  computed: {
    // ...mapState("speechAnalysis", ["wordsLibaryTable"]),
  },
  components: {
    TableChart,
  },
  data() {
    return {
      projectMenu: [],
      industryMenu: [],
      brandMenu: [],
      categoryMenu: [],
      waresMenu: [],
      wordsLibaryTable: {
        size: "",
        loading: false,
        columns: [
          {
            col: "话术ID",
            prop: "scriptLibraryNumber",
            width: "",
          },
          {
            col: "话术内容",
            prop: "content",
            width: "",
          },
          {
            col: "项目",
            prop: "projectName",
            width: "",
          },
          {
            col: "行业",
            prop: "industryName",
            width: "",
          },
          {
            col: "品牌",
            prop: "brandName",
            width: "",
          },
          {
            col: "品类",
            prop: "categoryName",
            width: "",
          },
          {
            col: "商品",
            prop: "waresName",
            width: "",
          },
          {
            col: "创建时间",
            prop: "createDate",
            width: "",
          },
        ],
        data: [],
      },
      pageData: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      formData: {
        projectId: "",
        industryId: "",
        brandId: "",
        categoryId: "",
        waresId: "",
        status: "",
      },
      sales: {
        value: "",
        options: [
          {
            value: 0,
            label: "张雨晨",
          },
          {
            value: 1,
            label: "王楠",
          },
        ],
      },
      project: {
        value: "",
        options: [
          {
            value: 0,
            label: "语音项目",
          },
          {
            value: 1,
            label: "项目",
          },
        ],
      },
      profession: {
        value: "",
        options: [
          {
            value: 0,
            label: "直播",
          },
          {
            value: 1,
            label: "餐饮",
          },
        ],
      },
      area: {
        value: "",
        options: [
          {
            label: "热门城市",
            options: [
              {
                value: "Shanghai",
                label: "上海",
              },
              {
                value: "Beijing",
                label: "北京",
              },
            ],
          },
          {
            label: "城市名",
            options: [
              {
                value: "Chengdu",
                label: "成都",
              },
              {
                value: "Shenzhen",
                label: "深圳",
              },
              {
                value: "Guangzhou",
                label: "广州",
              },
              {
                value: "Dalian",
                label: "大连",
              },
            ],
          },
        ],
      },
      searchInput: "",
    };
  },
  created() {
    toListPage().then((res) => {
      this.projectMenu = res.data.projects;
      this.industryMenu = res.data.industries;
      this.brandMenu = res.data.brands;
    });
    this.getTableData();
  },
  methods: {
    getTableData() {
      this.wordsLibaryTable.loading = true;
      libraryList(
        this.formData,
        this.pageData.pageNum,
        this.pageData.pageSize
      ).then((res) => {
        this.wordsLibaryTable.loading = false;
        this.wordsLibaryTable.data = res.rows;
        this.pageData.total = res.total;
      });
    },
    onInquire() {
      this.pageData.pageNum = 1;
      this.getTableData();
    },
    onReset() {
      this.formData.projectId = "";
      this.formData.industryId = "";
      this.formData.brandId = "";
      this.formData.categoryId = "";
      this.formData.waresId = "";
      this.formData.status = "";
    },
    currentChange(val) {
      this.pageData.pageNum = val;
      this.getTableData();
    },
    sizeChange(val) {
      this.pageData.pageNum = 1;
      this.pageData.pageSize = val;
      this.getTableData();
    },
    changeBrand(val) {
      if (val) {
        categoryList(val).then((res) => {
          this.categoryMenu = res.data;
        });
      } else {
        this.categoryMenu = [];
        this.waresMenu = [];
        this.formData.categoryId = "";
        this.formData.waresId = "";
      }
    },
    changeCategory(val) {
      if (val) {
        waresList(this.formData.brandId, val).then((res) => {
          this.waresMenu = res.data;
        });
      } else {
        this.waresMenu = [];
        this.formData.waresId = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$baseColor: #4e57aa;
$white: #fff;
$fontColor: #333;
$fontSize: 1.2em;
.wordslibrary {
  margin: 2vh 1.5vw;
  .marginTop {
    margin-top: 2vh;
  }
  .main {
    background: #fff;
    padding: 2vh 1vw;
    margin-top: 2vh;
    border-radius: 2vh;
    height: 100%;
    min-height: 75vh;
    .form-content {
      display: flex;
      flex-wrap: wrap;
      .form-item {
        margin-right: 1.5625vw;
        margin-bottom: 1.7vh;
        .el-select {
          width: 10.9375vw;
        }
      }
    }
    // /deep/.el-select {
    //   width: 74%;
    // }
    .title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2.8vh;
      .left {
        display: flex;
        .sep {
          background: $baseColor;
          width: 5px;
          height: 1.5rem;
          display: inline-block;
          margin: 0 5px;
        }
        h4 {
          padding: 0;
          margin: 0;
        }
      }
    }
    .fr {
      float: left;
      width: auto;
      margin-left: 0;
    }
    .selectName {
      // width: 26%;
      font-size: 14px;
      display: inline-block;
      color: $fontColor;
      // text-align: left;
      // text-align-last: justify;
    }

    .searchBtn {
      color: $white;
      background-color: $baseColor;
    }
  }
}
</style>