<template>
  <div class="indusInfo">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/systemManage' }"
          >系统管理</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/systemManage/industry' }"
          >行业管理</el-breadcrumb-item
        >
        <el-breadcrumb-item v-if="$route.query.roleId">编辑</el-breadcrumb-item>
        <el-breadcrumb-item v-else>新增</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main">
      <div class="title">
        <div class="left">
          <i class="sep"></i>
          <h4>行业管理</h4>
        </div>
      </div>
      <div class="content">
        <el-form
          :model="formData"
          label-width="auto"
          class="basic_form"
          :rules="rules"
          ref="ruleForm"
        >
          <el-form-item label="行业名称：" prop="industryName">
            <el-input v-model="formData.industryName"></el-input>
            <!-- <el-select v-model="formData.industryName">
              <el-option value="选择一"></el-option>
              <el-option value="选择二"></el-option>
            </el-select> -->
          </el-form-item>
          <el-form-item label="描述：" prop="remark">
            <el-input
              type="textarea"
              v-model="formData.remark"
              resize="none"
            ></el-input>
          </el-form-item>
        </el-form>
        <el-button
          type="primary"
          icon="el-icon-refresh-right"
          class="subBtn"
          @click="onSubmitInfo"
          >提交</el-button
        >
        <el-button @click="onBack">返回列表</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  industryInsert,
  industryUpdate,
  industryDetail,
} from "@/api/sysManage/industry";

export default {
  data() {
    return {
      formData: {
        industryName: "",
        remark: "",
      },
      rules: {
        industryName: [
          { required: true, message: "请填写行业名称", trigger: "blur" },
        ],
        remark: [{ required: true, message: "请填写描述", trigger: "blur" }],
      },
    };
  },
  created() {
    if (this.$route.query.industryId) {
      industryDetail(this.$route.query.industryId).then((res) => {
        this.formData = res.data;
      });
    }
  },
  methods: {
    onSubmitInfo() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.$route.query.industryId) {
            let updateData = { ...this.formData };
            updateData.industryId = this.$route.query.industryId;
            industryUpdate(updateData).then((res) => {
              if (res.code === 200) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
                this.$router.push("/systemManage/industry");
              }
            });
          } else {
            industryInsert(this.formData).then((res) => {
              if (res.code === 200) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                });
                this.$router.push("/systemManage/industry");
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    onBack() {
      this.$router.push("/systemManage/industry");
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === "industry") {
      to.meta.keepAlive = true;
    }
    next();
  },
};
</script>

<style lang="scss" scoped>
.indusInfo {
  margin: 2vh 1.5vw;
  .marginTop {
    margin-top: 2vh;
  }
  .main {
    background: #fff;
    padding: 2vh 1vw;
    margin-top: 2vh;
    border-radius: 2vh;
    height: 100%;
    min-height: 75vh;
    .title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4.6vh;
      .left {
        display: flex;
        .sep {
          background: #4e57aa;
          width: 5px;
          height: 1.5rem;
          display: inline-block;
          margin: 0 5px;
        }
        h4 {
          padding: 0;
          margin: 0;
        }
      }
    }
    .content {
      margin-left: 2vw;
      .subBtn {
        background: #4e57aa;
        border-color: #4e57aa;
        margin-right: 1.09375vw;
      }
      .basic_form {
        margin-bottom: 10.3vh;
        /deep/.el-form-item {
          margin-bottom: 1.85vh;
          .el-form-item__label {
            color: #333;
          }
          .el-form-item__content {
            > .el-input {
              width: 20%;
            }
            .el-select {
              width: 20%;
            }
            .el-input__inner {
              font-size: 14px;
              color: #333;
            }
            .el-textarea {
              width: 60%;
              .el-textarea__inner {
                height: 20.6vh;
                font-size: 14px;
                color: #333333;
              }
            }
          }
        }
      }
    }
  }
}
</style>
