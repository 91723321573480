<template>
  <div class="supervision">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/dataTransfer' }"
          >数据传输检测</el-breadcrumb-item
        >
        <el-breadcrumb-item>数据传输情况</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main">
      <div class="title">
        <div class="left">
          <i class="sep"></i>
          <h4>数据传输情况</h4>
        </div>
      </div>
      <div class="form-content marginTop">
        <div class="form-item">
          <span class="selectName">姓名：</span>
          <el-input v-model="userName"></el-input>
        </div>
        <div class="select-date form-item">
          <el-date-picker
            v-model="date"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <div class="form-item fr">
          <el-button class="searchBtn" @click="onInquire">查询</el-button>
          <el-button @click="onReset">重置</el-button>
        </div>
      </div>
      <el-row>
        <el-button class="export" @click="handleExport">导出</el-button>
      </el-row>
      <el-row :gutter="10">
        <TableChart
          :tableDatas="{
            id: 'dataTest',
            data: dataTestTable,
          }"
          :pageData="pageData"
          @changePage="currentChange"
          @changeSize="sizeChange"
        ></TableChart>
      </el-row>
    </div>
  </div>
</template>
<script>
import TableChart from "../table";
// import { mapState, mapActions } from "vuex";
// import { getTjSession } from "@/api/inspection/promoter";
import { dataTestList, listExport } from "@/api/dataTransfer/dataTest";
export default {
  computed: {
    // ...mapState("supervision", ["supervisionTable"]),
  },
  components: {
    TableChart,
  },
  data() {
    return {
      // supervisionTable: [],
      dataTestTable: {
        size: "",
        loading: false,
        columns: [
          {
            col: "序号",
            prop: "rowNum",
            width: "",
          },
          {
            col: "员工名称",
            prop: "nickName",
            width: "",
          },
          {
            col: "设备号",
            prop: "sn",
            width: "",
          },
          {
            col: "项目名称",
            prop: "projectName",
            width: "",
          },
          {
            col: "门店名称",
            prop: "spaceName",
            width: "",
          },
          {
            col: "文件开始时间",
            prop: "fileStartTime",
            width: "",
          },
          {
            col: "文件上传时间",
            prop: "fileServerTime",
            width: "",
          },
          {
            col: "文件转义时间",
            prop: "fileTransFinishTime",
            width: "",
          },
          {
            col: "文字处理时间",
            prop: "fileDataTurnTime",
            width: "",
          },
          {
            col: "状态",
            prop: "statusName",
            width: "",
          },
        ],
        data: [],
      },
      pageData: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      date: [],
      userName: "",
    };
  },
  created() {
    this.getTableData();
  },
  methods: {
    getTableData() {
      this.dataTestTable.loading = true;
      let data = {
        nickName: this.userName,
        startTime: this.date[0],
        endTime: this.date[1],
      };
      dataTestList(data, this.pageData.pageNum, this.pageData.pageSize).then(
        (res) => {
          this.dataTestTable.loading = false;
          this.dataTestTable.data = res.rows;
          this.pageData.total = res.total;
        }
      );
    },
    onInquire() {
      this.pageData.pageNum = 1;
      this.getTableData();
    },
    onReset() {
      this.date = [];
      this.userName = "";
    },
    handleExport() {
      let data = {
        nickName: this.userName,
        startTime: this.date[0],
        endTime: this.date[1],
      };
      listExport(data).then((res) => {
        let blob = new Blob([res], { type: `text/plain;charset=utf-8` });
        let downloadElement = document.createElement("a");
        let href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = "数据传输情况.xls";
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      });
    },
    currentChange(val) {
      this.pageData.pageNum = val;
      this.getTableData();
    },
    sizeChange(val) {
      this.pageData.pageNum = 1;
      this.pageData.pageSize = val;
      this.getTableData();
    },
  },
};
</script>

<style lang="scss" scoped>
$baseColor: #4e57aa;
$white: #fff;
$fontColor: #333;
$fontSize: 1.2em;
.supervision {
  margin: 2vh 1.5vw;
  .marginTop {
    margin-top: 2vh;
  }
  .main {
    background: #fff;
    padding: 2vh 1vw;
    margin-top: 2vh;
    border-radius: 2vh;
    height: 100%;
    min-height: 75vh;
    .form-content {
      display: flex;
      flex-wrap: wrap;
      .form-item {
        margin-right: 1.5625vw;
        margin-bottom: 1.7vh;
        .el-select {
          width: 10.9375vw;
        }
        .el-input {
          width: 10.9375vw;
        }
      }
    }
    .export {
      float: right;
      color: #fff;
      background: #4e57aa;
    }
    // /deep/.el-select {
    //   width: 74%;
    // }
    /deep/.el-cascader {
      width: 74%;
    }
    .select-date {
      .el-input__inner {
        width: 13vw;
        padding-right: 0;
      }
    }
    .title {
      display: flex;
      justify-content: space-between;
      .left {
        display: flex;
        .sep {
          background: $baseColor;
          width: 5px;
          height: 1.5rem;
          display: inline-block;
          margin: 0 5px;
        }
        h4 {
          padding: 0;
          margin: 0;
        }
      }
    }
    .fr {
      float: right;
      width: auto;
      margin-left: 0;
    }
    .selectName {
      // width: 26%;
      font-size: 14px;
      display: inline-block;
      color: $fontColor;
      // text-align: left;
      // text-align-last: justify;
    }
    .indent2 {
      padding-right: 2em;
    }
    .parent {
      position: relative;
      .searchInput {
        position: absolute;
        right: 0;
        height: 100%;
        text-align: center;
        background: $baseColor;
        width: 2vw;
        right: 0px;
        top: 0;
        border-radius: 0 5px 5px 0;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .searchBtn {
      color: $white;
      background-color: $baseColor;
    }
  }
}
</style>