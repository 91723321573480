import request from "@/utils/request";

export function userList(data,pageNum,pageSize) {
  return request({
    url: "/jxzy/user/list",
    method: "post",
    data,
    params:{pageNum,pageSize}
  });
}

export function userDetail(userId) {
  return request({
    url: "/jxzy/user/detail",
    method: "post",
    data:{userId},
  });
}

export function toInsertPage() {
  return request({
    url: "/jxzy/user/toInsertPage",
    method: "post",
  });
}

export function insert(data) {
  return request({
    url: "/jxzy/user/insert",
    method: "post",
    data
  });
}

export function update(data) {
  return request({
    url: "/jxzy/user/update",
    method: "post",
    data
  });
}

export function toUDRPage(userId) {
  return request({
    url: "/jxzy/user/toUDRPage",
    method: "post",
    data:{userId}
  });
}

export function depts(customerId) {
  return request({
    url: "/jxzy/user/depts",
    method: "post",
    data:{customerId}
  });
}

export function projects(deptId) {
  return request({
    url: "/jxzy/user/projects",
    method: "post",
    data:{deptId}
  });
}

export function provinces(projectId) {
  return request({
    url: "/jxzy/user/provinces",
    method: "post",
    data:{projectId}
  });
}

export function citys(projectId,province) {
  return request({
    url: "/jxzy/user/citys",
    method: "post",
    data:{projectId,province}
  });
}

export function countys(projectId,city) {
  return request({
    url: "/jxzy/user/countys",
    method: "post",
    data:{projectId,city}
  });
}

export function spaces(projectId,county) {
  return request({
    url: "/jxzy/user/spaces",
    method: "post",
    data:{projectId,county}
  });
}

export function insertPsu(projectId,spaceId,userId) {
  return request({
    url: "/jxzy/psu/insert",
    method: "post",
    data:{projectId,spaceId,userId}
  });
}

export function deletePsu(projectId,spaceId,userId) {
  return request({
    url: "/jxzy/psu/delete",
    method: "post",
    data:{projectId,spaceId,userId}
  });
}