import request from '@/utils/request';

export function audioList(data, pageNum, pageSize) {
  return request({
    url: '/jxzy/audio/list',
    method: 'post',
    data,
    params: { pageNum, pageSize }
  });
}

export function audioDayList(audioId, pageNum, pageSize) {
  return request({
    url: '/jxzy/audioDay/list',
    method: 'post',
    data: { audioId },
    params: { pageNum, pageSize }
  });
}

export function audioDayDetail(audioDayId) {
  return request({
    url: '/jxzy/audioDay/detail',
    method: 'post',
    data: { audioDayId }
  });
}

export function audioProject() {
  return request({
    url: '/jxzy/audio/project',
    method: 'post'
  });
}

export function projectList() {
  return request({
    url: '/jxzy/project/list',
    method: 'post'
  });
}

export function audioSpace() {
  return request({
    url: '/jxzy/audio/space',
    method: 'post'
  });
}

export function audioDayTextList(audioLogId, startTime) {
  return request({
    url: '/jxzy/audioDay/textList',
    method: 'post',
    data: { audioLogId, startTime }
  });
}

// 导出指定某段音频文本
export function exportOneAudio(parentId, startTime) {
  return request({
    url: '/jxzy/audioDay/exportTexts',
    method: 'post',
    data: { parentId, startTime }
  });
}

// 导出全部音频文本
export function exportAllAudio(audioDayId, audioDate) {
  return request({
    url: '/jxzy/audioDay/exportAllTexts',
    method: 'post',
    data: { audioDayId, audioDate }
  });
}
// 导出指定某段音频文本excel格式
export function exportOneAudioExcel(parentId, startTime) {
  return request({
    url: '/jxzy/audioDay/exportTextExcels',
    method: 'post',
    responseType: 'blob',
    data: { parentId, startTime }
  });
}

// 导出全部音频文本excel格式
export function exportAllAudioExcel(audioDayId, audioDate) {
  return request({
    url: '/jxzy/audioDay/exportAllTextExcels',
    method: 'post',
    responseType: 'blob',
    data: { audioDayId, audioDate }
  });
}
export function getAudioList(audioDayId, userId, deviceNo, spaceId, pageNum, pageSize) {
  return request({
    url: '/jxzy/audioDay/logs',
    method: 'post',
    data: { audioDayId, userId, deviceNo, spaceId },
    params: { pageNum, pageSize }
  });
}

export function audioDayExportLogs(audioDayId) {
  return request({
    url: '/jxzy/audioDay/exportLogs',
    method: 'post',
    data: { audioDayId }
  });
}
// 查询当日音频详情（新）
export function getAudioDayDetail(audioDayId) {
  return request({
    url: '/jxzy/audioDay/detail',
    method: 'post',
    data: { audioDayId }
  });
}

// 查询音频列表选择信息
export function getAudioDaySpaces(audioDate, userId) {
  return request({
    url: '/jxzy/audioDay/spaces',
    method: 'post',
    data: { audioDate, userId }
  });
}

// 更新门店信息
export function getAudioDaySpacesDetail(audioDate, spaceId) {
  return request({
    url: '/jxzy/audioDay/spaceDetail',
    method: 'post',
    data: { audioDate, spaceId }
  });
}

// 下一天
export function getAudioDayDown(audioDate, audioId) {
  return request({
    url: '/jxzy/audioDay/down',
    method: 'post',
    data: { audioDate, audioId }
  });
}

// 上一天
export function getAudioDayUp(audioDate, audioId) {
  return request({
    url: '/jxzy/audioDay/up',
    method: 'post',
    data: { audioDate, audioId }
  });
}

// 话术完成
export function finishVerbalInfo(userId, audioDate, projectId) {
  return request({
    url: '/jxzy/audioDay/finishVerbalInfo',
    method: 'post',
    data: { userId, audioDate, projectId }
  });
}

// 门店话术完成
export function spaceFinishVerbalInfo(audioDayId, userId, audioDate, spaceId, projectId) {
  return request({
    url: '/jxzy/audioDay/spaceFinishVerbalInfo',
    method: 'post',
    data: { audioDayId, userId, audioDate, spaceId, projectId }
  });
}

// 关键字
export function verbalGetInfo(userId, audioDate) {
  return request({
    url: '/jxzy/audioDay/verbalGetInfo',
    method: 'post',
    data: { userId, audioDate }
  });
}

// 门店关键字
export function spaceVerbalGetInfo(audioDayId, userId, audioDate, spaceId) {
  return request({
    url: '/jxzy/audioDay/spaceVerbalGetInfo',
    method: 'post',
    data: { audioDayId, userId, audioDate, spaceId }
  });
}
