import request from "@/utils/request";

export function hotWordList(data,pageNum,pageSize) {
  return request({
    url: "/jxzy/hotWord/list",
    method: "post",
    data,
    params:{pageNum,pageSize}
  });
}

export function hotWord2ListPage() {
  return request({
    url: "/jxzy/hotWord/2ListPage",
    method: "post",
  });
}

export function hotWordDetail(hotWordId) {
  return request({
    url: "/jxzy/hotWord/detail",
    method: "post",
    data:{hotWordId}
  });
}

export function hotWordSave(data) {
  return request({
    url: "/jxzy/hotWord/save",
    method: "post",
    data,
  });
}

export function fileImport(data) {
  return request({
    url: "/jxzy/hotWord/import",
    method: "post",
    data,
  });
}

export function download() {
  return request({
    url: "/jxzy/hotWord/download",
    method: "post",
    responseType: "blob",
  });
}