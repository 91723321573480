<template>
  <div class="log">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/systemManage' }"
          >系统管理</el-breadcrumb-item
        >
        <el-breadcrumb-item>日志</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main">
      <div class="title">
        <div class="left">
          <i class="sep"></i>
          <h4>日志</h4>
        </div>
      </div>
      <div class="tabs">
        <div
          class="tab"
          :class="tabIndex ? '' : 'active'"
          @click="getOperationLog"
        >
          <img
            class="tab-img1"
            v-if="!tabIndex"
            src="../../../../assets/pc/操作-选中.png"
          />
          <img
            class="tab-img1"
            v-else
            src="../../../../assets/pc/操作-默认.png"
          />
          操作日志
        </div>
        <div class="tab" :class="tabIndex ? 'active' : ''" @click="getLoginLog">
          <img
            class="tab-img2"
            v-if="tabIndex"
            src="../../../../assets/pc/登录日志-选中.png"
          />
          <img
            class="tab-img2"
            v-else
            src="../../../../assets/pc/登录日志-默认.png"
          />
          登录日志
        </div>
      </div>
      <div class="select-date" v-show="!tabIndex">
        <el-date-picker
          v-model="operationDate"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="onSelectDate"
        >
        </el-date-picker>
      </div>
      <el-row :gutter="10" v-show="!tabIndex">
        <TableChart
          :tableDatas="{
            id: 'sysLog',
            data: operationTableData,
          }"
          :pageData="operationPageData"
          @changePage="operationCurrentChange"
          @changeSize="operationSizeChange"
        ></TableChart>
      </el-row>
      <el-row :gutter="10" v-show="tabIndex">
        <TableChart
          :tableDatas="{
            id: 'sysLog',
            data: loginTableData,
          }"
          :pageData="loginPageData"
          @changePage="loginCurrentChange"
          @changeSize="loginSizeChange"
        ></TableChart>
      </el-row>
    </div>
  </div>
</template>

<script>
import TableChart from "../table";
import { operLogList, logininforList } from "@/api/sysManage/log";
export default {
  components: {
    TableChart,
  },
  data() {
    return {
      tabIndex: 0,
      operationDate: [],
      operationTableData: {
        size: "",
        loading: false,
        columns: [
          {
            col: "应用名称",
            prop: "appName",
            width: "",
          },
          {
            col: "菜单名称",
            prop: "menuName",
            width: "",
          },
          {
            col: "登录用户名",
            prop: "userName",
            width: "",
          },
          {
            col: "人员名称",
            prop: "nickName",
            width: "",
          },
          {
            col: "登录IP",
            prop: "loginIp",
            width: "",
          },
          {
            col: "操作时间",
            prop: "operTime",
            width: "",
          },
          {
            col: "操作类型",
            prop: "operTypeName",
            width: "",
          },
          {
            col: "操作内容",
            prop: "operContent",
            width: "",
          },
          {
            col: "操作结果",
            prop: "operResultName",
            width: "",
          },
        ],
        data: [],
      },
      loginTableData: {
        size: "",
        loading: false,
        columns: [
          // {
          //   col: "编号",
          //   prop: "rowNum",
          //   width: "",
          // },
          {
            col: "用户名",
            prop: "userName",
            width: "",
          },
          {
            col: "人员名称",
            prop: "nickName",
            width: "",
          },
          {
            col: "请求IP",
            prop: "ipaddr",
            width: "",
          },
          {
            col: "操作状态",
            prop: "status",
            width: "",
          },
          {
            col: "提示信息",
            prop: "msg",
            width: "",
          },
          {
            col: "操作时间",
            prop: "accessTime",
            width: "",
          },
          {
            col: "终端信息",
            prop: "loginType",
            width: "",
          },
        ],
        data: [],
      },
      operationPageData: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      loginPageData: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  created() {
    let date = new Date();
    let s =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    this.operationDate = [s, s];
    this.getOperationTableData();
  },
  methods: {
    onSelectDate() {
      this.operationPageData.pageNum = 1;
      this.getOperationTableData();
    },
    getOperationLog() {
      this.tabIndex = 0;
      this.getOperationTableData();
    },
    getLoginLog() {
      this.tabIndex = 1;
      this.getLoginTableData();
    },
    getOperationTableData() {
      this.operationTableData.loading = true;
      operLogList(
        this.operationDate[0],
        this.operationDate[1],
        this.operationPageData.pageNum,
        this.operationPageData.pageSize
      ).then((res) => {
        this.operationTableData.loading = false;
        this.operationTableData.data = res.rows;
        // this.getSortRows(this.operationTableData.data);
        this.operationPageData.total = res.total;
      });
    },
    operationCurrentChange(val) {
      this.operationPageData.pageNum = val;
      this.getOperationTableData();
    },
    operationSizeChange(val) {
      this.operationPageData.pageNum = 1;
      this.operationPageData.pageSize = val;
      this.getOperationTableData();
    },

    getLoginTableData() {
      this.loginTableData.loading = true;
      logininforList(
        this.loginPageData.pageNum,
        this.loginPageData.pageSize
      ).then((res) => {
        this.loginTableData.loading = false;
        this.loginTableData.data = res.rows;
        // this.getSortRows(this.loginTableData.data);
        this.loginPageData.total = res.total;
      });
    },
    loginCurrentChange(val) {
      this.loginPageData.pageNum = val;
      this.getLoginTableData();
    },
    loginSizeChange(val) {
      this.loginPageData.pageNum = 1;
      this.loginPageData.pageSize = val;
      this.getLoginTableData();
    },
    // getSortRows(rows) {
    //   rows.sort(function (a, b) {
    //     return a.rowNum - b.rowNum;
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
$baseColor: #4e57aa;
$white: #fff;
$fontColor: #333;
$fontSize: 1.2em;
.log {
  margin: 2vh 1.5vw;
  .marginTop {
    margin-top: 2vh;
    margin-bottom: 2vh;
  }
  .main {
    background: #fff;
    padding: 2vh 1vw;
    margin-top: 2vh;
    border-radius: 2vh;
    height: 100%;
    min-height: 75vh;
    .title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 3.7vh;
      .left {
        display: flex;
        .sep {
          background: $baseColor;
          width: 5px;
          height: 1.5rem;
          display: inline-block;
          margin: 0 5px;
        }
        h4 {
          padding: 0;
          margin: 0;
        }
      }
    }
    .tabs {
      display: flex;
      width: 100%;
      height: 6.66vh;
      margin-bottom: 3vh;
      border-bottom: 1px solid rgba(143, 146, 161, 0.09);
      .tab {
        width: 15%;
        height: 100%;
        box-sizing: border-box;
        font-size: 18px;
        line-height: 6.66vh;
        text-align: center;
        color: #999999;
        cursor: pointer;
      }
      .active {
        color: #4e57aa;
        border-bottom: 2px solid #4e57aa;
      }
      .tab-img1 {
        width: 1.09375vw;
        height: 1.40625vw;
        margin-right: 0.78vw;
        vertical-align: middle;
      }
      .tab-img2 {
        width: 1.40625vw;
        height: 1.40625vw;
        margin-right: 0.78vw;
        vertical-align: middle;
      }
    }
    .select-date {
      overflow: hidden;
      .el-date-editor {
        float: right;
      }
    }
  }
}
</style>
