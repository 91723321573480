<template>
  <div class="speechAnalysis">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/wordsAnalyze' }"
          >话术分析</el-breadcrumb-item
        >
        <el-breadcrumb-item>员工音频记录</el-breadcrumb-item>
      </el-breadcrumb>
      <!-- <div class="items">
        <span class="marginRight2vw">上海布点项目20210201</span>
        <span>时间：2020/12/01-2021/06/01</span>
      </div> -->
    </div>
    <div class="main">
      <div class="title">
        <div class="left">
          <i class="sep"></i>
          <h4>员工音频记录</h4>
        </div>
      </div>
      <div class="marginTop inputBox">
        <div class="inputBoxCol marginRight1vw" style="width: 20%">
          <span class="selectName">项目：</span>
          <!-- <el-input class="input" v-model="formData.projectName"></el-input> -->
          <el-select v-model="formData.projectName" clearable filterable>
            <el-option
              v-for="item in projectMenu"
              :key="item.projectId"
              :value="item.projectName"
            >
            </el-option>
          </el-select>
        </div>
        <div class="inputBoxCol marginRight1vw" style="width: 20%">
          <span class="selectName">门店：</span>
          <!-- <el-input class="input" v-model="formData.spaceName"></el-input> -->
          <el-select v-model="formData.spaceName" clearable filterable>
            <el-option
              v-for="item in storeMenu"
              :key="item.spaceNo"
              :value="item.spaceName"
            >
            </el-option>
          </el-select>
        </div>
        <div class="inputBoxCol marginRight1vw" style="width: 20%">
          <span class="selectName">姓名：</span>
          <el-input
            class="input"
            placeholder="请输入"
            v-model="formData.userName"
          ></el-input>
        </div>
        <!-- <div class="inputBoxCol marginRight1vw" style="width: 20%">
          <el-date-picker
            v-model="date"
            type="daterange"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <div class="inputBoxCol parent" style="width: 20%">
          <el-input placeholder="请输入内容" v-model="searchInput"> </el-input>
          <i class="el-icon-search searchInput"></i>
        </div> -->
        <div>
          <el-button class="searchBtn" @click="onInquire">查询</el-button>
          <el-button @click="onReset">重置</el-button>
        </div>
      </div>
      <el-row :gutter="10">
        <TableChart
          :tableDatas="{
            id: 'audioListTable',
            data: audioListTable,
          }"
          :pageData="pageData"
          @audioShow="handlePopShow"
          @changePage="currentChange"
          @changeSize="sizeChange"
        ></TableChart>
        <!-- <el-table
          :data="audioListTable.data"
          :header-cell-style="rowClass"
          :height="Height"
          @row-click="clickRow"
        >
          <el-table-column
            v-for="(item, index) in audioListTable.columns"
            :key="index"
            :prop="item.prop"
            :label="item.col"
            :width="item.width"
          >
          </el-table-column>

          <el-table-column
            v-if="audioListTable.id == 'speechAnalysisTable'"
            fixed="right"
            label="操作"
            width="200"
          >
            <template slot-scope="scope">
              <el-button
                @click="handleClick(scope.row)"
                size="small"
                plain
                class="basicBtn"
                >查看</el-button
              >
              <el-button type="danger" plain size="small">删除</el-button>
            </template>
          </el-table-column>
          <el-table-column
            v-if="audioListTable.id == 'audioListTable'"
            fixed="right"
            label="操作"
            width="100"
          >
            <template slot-scope="scope">
              <el-button
                @click="handleClick(scope.row)"
                size="small"
                plain
                class="basicBtn"
                >查看</el-button
              >
            </template>
          </el-table-column>
        </el-table> -->
      </el-row>
      <!-- 分页器 -->
      <!-- <div class="block" style="margin-top: 20px">
        <el-pagination
          align="center"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 50, 100]"
          :page-size="pageSize"
          layout="sizes, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div> -->
    </div>
    <div class="popTable" v-show="popShow">
      <div class="popContent">
        <div class="title">
          <div class="left">
            <i class="sep"></i>
            <h4>人员音频列表</h4>
            <!-- <span>大康电信</span> -->
          </div>
          <div class="right" @click="popShow = false">
            <i class="el-icon-close"></i>
          </div>
        </div>
        <div class="inputBoxCol marginRight1vw popDate">
          <el-date-picker
            v-model="date"
            type="daterange"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
          <!-- <el-button class="searchBtn" @click="handleDownload" style="margin-left:50px;" :loading="exportloading">生成下载链接</el-button> -->
        </div>
        <TableChart
          :tableDatas="{
            id: 'popAudioTable',
            data: popTable,
          }"
          :pageData="popPageData"
          @handleLoad="handleDownload"
          @audioShowDis="handleDis"
          @changePage="popCurrentChange"
          @changeSize="popSizeChange"
        ></TableChart>
      </div>
    </div>
  </div>
</template>
<script>
import {
  audioList,
  audioDayList,
  projectList,
  audioSpace,
  audioDayExportLogs
} from "@/api/wordsAnalyze/audio";
import TableChart from "../table";

// import TableChart from "./table";
// import axios from 'axios'
// import { mapState, mapActions } from "vuex";
const ls = window.sessionStorage;
import $ from "jquery";

export default {
  computed: {},
  components: {
    TableChart,
  },
  data() {
    return {
      projectMenu: [],
      storeMenu: [],
      Height: 470, //表格高度
      currentPage: 1, // 当前页码
      total: 10, // 总条数
      pageSize: 10, // 每页的数据条数
      audioId: "",
      formData: {
        projectName: "",
        spaceName: "",
        userName: "",
      },
      exportloading:false,
      popShow: false,
      popTable: {
        size: "",
        loading: false,
        columns: [
          {
            col: "人员编号",
            prop: "userId",
            width: "",
          },
          {
            col: "人员名称",
            prop: "userName",
            width: "",
          },
          {
            col: "人员性别",
            prop: "sexName",
            width: "",
          },
          {
            col: "门店名称",
            prop: "spaceName",
            width: "",
          },

          {
            col: "录音时长",
            prop: "audioDuration",
            width: "",
          },
          {
            col: "录音日期",
            prop: "audioDate",
            width: "",
          },
          {
            col: "录音开始时间",
            prop: "audioStartTime",
            width: "",
          },
        ],
        data: [],
      },
      audioListTable: {
        size: "",
        loading: false,
        columns: [
          {
            col: "人员编号",
            prop: "userId",
            width: "",
          },
          {
            col: "人员名称",
            prop: "userName",
            width: "",
          },
          {
            col: "项目编号",
            prop: "projectNo",
            width: "",
          },
          {
            col: "项目名称",
            prop: "projectName",
            width: "",
          },

          {
            col: "门店编号",
            prop: "spaceNo",
            width: "",
          },
          {
            col: "门店名称",
            prop: "spaceName",
            width: "",
          },
          {
            col: "设备",
            prop: "sn",
            width: "",
          },
          {
            col: "最近上传时间",
            prop: "lastUpdateDate",
            width: "",
          },
        ],
        data: [],
      },
      pageData: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      popPageData: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      // audioListTable: {
      //   id: "audioListTable",
      //   size: "",
      //   loading: false,
      //   columns: [
      //     {
      //       col: "人员编号",
      //       prop: "staffNo",
      //       width: "",
      //     },
      //     {
      //       col: "人员名称",
      //       prop: "name",
      //       width: "",
      //     },
      //     {
      //       col: "人员性别",
      //       prop: "sex",
      //       width: "",
      //     },
      //     {
      //       col: "门店名称",
      //       prop: "mendmc",
      //       width: "",
      //     },
      //     {
      //       col: "录音时长",
      //       prop: "audioDuration",
      //       width: "",
      //     },
      //     {
      //       col: "录时日期",
      //       prop: "audioDate",
      //       width: "",
      //     },
      //     {
      //       col: "录音开始时间",
      //       prop: "audioStartTime",
      //       width: "",
      //     },
      //   ],
      //   data: [],
      // },

      badgeUserInfo: "",
      badgeToken: "",
      badgeUserId: "",

      date: "",
      store: {
        value: "",
        options: [
          {
            value: 0,
            label: "南京西路门店",
          },
          {
            value: 1,
            label: "徐汇路门店",
          },
        ],
      },
      project: {
        value: "",
        options: [
          {
            value: 0,
            label: "语音项目",
          },
          {
            value: 1,
            label: "项目",
          },
        ],
      },
      searchInput: "",
    };
  },
  created() {},
  mounted () {
    projectList().then((res) => {
      this.projectMenu = res.data;
    });
    audioSpace().then((res) => {
      this.storeMenu = res.data;
    });
  },
  methods: {
    getTableData() {
      this.audioListTable.loading = true;
      audioList(
        this.formData,
        this.pageData.pageNum,
        this.pageData.pageSize
      ).then((res) => {
        this.audioListTable.loading = false;
        this.audioListTable.data = res.rows;
        this.pageData.total = res.total;
      });
    },
    handleDownload(val){
      this.exportloading = true
      audioDayExportLogs(val).then(res=>{
        this.exportloading = false
        let blob = new Blob([res], { type: `text/plain;charset=utf-8` });
        let downloadElement = document.createElement("a");
        let href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = "音频下载链接列表.csv";
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      })
    },
    onInquire() {
      this.pageData.pageNum = 1;
      this.getTableData();
    },
    onReset() {
      this.formData.projectName = "";
      this.formData.spaceName = "";
      this.formData.userName = "";
    },
    currentChange(val) {
      this.pageData.pageNum = val;
      this.getTableData();
    },
    sizeChange(val) {
      this.pageData.pageNum = 1;
      this.pageData.pageSize = val;
      this.getTableData();
    },
    handlePopShow(audioId, show) {
      this.audioId = audioId;
      // this.getPopTableData();
      // this.popShow = show;
      audioDayList(
        audioId,
        this.popPageData.pageNum,
        this.popPageData.pageSize
      ).then((res) => {
        this.popTable.data = res.rows;
        this.popPageData.total = res.total;
        this.popShow = show;
      });
    },
    getPopTableData() {
      this.popTable.loading = true;
      audioDayList(
        this.audioId,
        this.popPageData.pageNum,
        this.popPageData.pageSize
      ).then((res) => {
        this.popTable.loading = false;
        this.popTable.data = res.rows;
        this.popPageData.total = res.total;
      });
    },
    popCurrentChange(val) {
      this.popPageData.pageNum = val;
      this.getPopTableData();
    },
    popSizeChange(val) {
      this.popPageData.pageNum = 1;
      this.popPageData.pageSize = val;
      this.getPopTableData();
    },
    //表头样式
    rowClass({ row, rowIndex }) {
      return "background:rgba(78,87,170,0.05);color:#000;fontSize:1.1em";
    },
    //点击查看
    handleClick(row) {
      console.log(row);
    },
    //点击行跳转
    clickRow(row, event, column) {
      this.getDetails(row.id, row.staffId, row.audioStartTime);
    },

    //每页条数改变时触发 选择一页显示多少行
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
      this.getList();
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },

    // 云知声登录鉴权接口方法
    getToken() {
      var _this = this;
      var data1 = {
        mobile: "15301777748",
        password: "9b60a0bb2d511e7fae929712d1584d6a",
        loginType: 0,
      };
      $.ajax({
        url: "https://sol-badge.uat.hivoice.cn/manager/user/login",
        type: "POST",
        dataType: "json",
        contentType: "application/json;charset=UTF-8",
        data: JSON.stringify(data1),
        success: function (res) {
          console.log(res);
          _this.badgeUserInfo = res.result.userInfo;
          _this.badgeToken = res.result.token.token;
          _this.badgeUserId = res.result.member.id;
          setTimeout(() => {
            _this.getList();
          }, 300);
        },
        error: function (err) {
          console.log(err);
        },
      });
    },
    handleDis(val,show){
      this.popShow = show
      this.$router.push(
        `/wordsAnalyze/audioRecord/audioDetail?audioDayId=${val.audioDayId}&audioDate=${val.audioDate}`
      );
    },
    // 获取列表
    getList() {
      var _this = this;
      $.ajax({
        url: "https://sol-badge.uat.hivoice.cn/manager/audio/audio_list",
        beforeSend: function (XMLHttpRequest) {
          XMLHttpRequest.setRequestHeader("badgeToken", _this.badgeToken);
          XMLHttpRequest.setRequestHeader("badgeUserId", _this.badgeUserId);
          XMLHttpRequest.setRequestHeader("badgeUserInfo", _this.badgeUserInfo);
        },
        contentType: "application/json;charset=UTF-8",
        dataType: "json",
        data: {
          spaceIdPath: "2004600.2008193",
          pageSize: _this.pageSize,
          pageNum: _this.currentPage,
        },
        type: "GET",
        success: function (res) {
          console.log(res);
          res.result.list.map((item) => {
            item.sex = item.sex == 1 ? "男" : item.sex == 0 ? "女" : "暂无";
            if (item.spaceNamePath) {
              item.mendmcList = item.spaceNamePath.split("/");
              item.mendmc = item.mendmcList[item.mendmcList.length - 1];
            }
            if (item.audioDuration) {
              item.audioDuration = item.audioDuration + "毫秒";
            }
          });
          _this.audioListTable.data = res.result.list;
          _this.total = res.result.total;
        },
        error: function (data) {
          console.log(data);
        },
      });
    },

    getDetails(Id, staffId, audioDate) {
      ls.removeItem("detailResult");
      var _this = this;
      $.ajax({
        url:
          "https://sol-badge.uat.hivoice.cn/manager/audio/record_detail_list",
        beforeSend: function (XMLHttpRequest) {
          XMLHttpRequest.setRequestHeader("badgeToken", _this.badgeToken);
          XMLHttpRequest.setRequestHeader("badgeUserId", _this.badgeUserId);
          XMLHttpRequest.setRequestHeader("badgeUserInfo", _this.badgeUserInfo);
        },
        contentType: "application/json;charset=UTF-8",
        dataType: "json",
        data: {
          Id: Id,
          staffId: staffId,
          audioDate: audioDate,
        },
        type: "GET",
        success: function (res) {
          console.log("detail", res);
          var detailResult = res.result;
          detailResult.dialogList.reverse();
          detailResult.dialogList.map((item) => {
            if (item.dialog) {
              item.dialog = JSON.parse(item.dialog);
            }
          });
          ls.setItem("detailResult", JSON.stringify(detailResult));
          const id = _this.audioListTable.id;
          switch (id) {
            case "supervisionTable":
              _this.$router.push("/inspection/promoterWarn/warnDetail");
              break;
            case "audioListTable":
              _this.$router.push("/wordsAnalyze/audioRecord/audioDetail");
              break;
          }
        },
        error: function (data) {
          console.log(data);
        },
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === "audioDetail") {
      next((vm) => {
        vm.getTableData();
      });
    } else {
      next((vm) => {
        // projectList().then((res) => {
        //   vm.projectMenu = res.data;
        // });
        // audioSpace().then((res) => {
        //   vm.storeMenu = res.data;
        // });
        vm.getTableData();
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== "audioDetail") {
      from.meta.keepAlive = false;
      next();
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
$baseColor: #4e57aa;
$white: #fff;
$fontColor: #333;
$fontSize: 1.2em;
.speechAnalysis {
  margin: 2vh 1vw;
  .breadcrumb {
    .items {
      font-size: 14px;
    }
  }
  .marginTop {
    margin-top: 2vh;
    margin-bottom: 2vh;
  }
  .title {
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;
      .sep {
        background: $baseColor;
        width: 5px;
        height: 1.5rem;
        display: inline-block;
        margin: 0 5px;
      }
      h4 {
        padding: 0;
        margin: 0;
      }
      span {
        margin-left: 1.1vw;
        font-size: 14px;
        font-weight: 600;
        color: #4e57aa;
        line-height: 1.5rem;
      }
    }
    .right {
      width: 3.24vh;
      height: 3.24vh;
      background: rgba(78, 87, 170, 0.1);
      border-radius: 8px;
      text-align: center;
      line-height: 3.24vh;
      font-size: 2.5vh;
      cursor: pointer;
    }
  }
  .main {
    background: #fff;
    padding: 2vh 1vw;
    margin-top: 2vh;
    border-radius: 2vh;
    height: 100%;
    min-height: 75vh;
    // .title {
    //   display: flex;
    //   justify-content: space-between;
    //   .left {
    //     display: flex;
    //     .sep {
    //       background: $baseColor;
    //       width: 5px;
    //       height: 1.5rem;
    //       display: inline-block;
    //       margin: 0 5px;
    //     }
    //     h4 {
    //       padding: 0;
    //       margin: 0;
    //     }
    //   }
    // }
    .inputBox {
      display: flex;
      position: relative;
      .fr {
        position: absolute;
        right: 0;
        // float: right;
        // margin-left: 10%;
        // order: 1;
        // align-self: flex-end;
      }
      .inputBoxCol {
        // flex:1;
        /deep/.el-select {
          width: 70%;
          // width: 9vw;
        }
        .input {
          width: 70%;
        }
        .el-date-editor {
          width:50%;
          // padding: 0 0.5vw;
          padding-left: 0.5vw;
          padding-right: 0.2vw;
        }
        // .el-date-picker {
        //   // width: 15vw;
        // }
      }
    }
    .selectName {
      // width: 30%;
      // width: 7vh;
      font-size: 14px;
      display: inline-block;
      color: $fontColor;
      // text-align: justify;
      // text-align-last: justify;
    }
    .parent {
      position: relative;
      height: 32px;
      // margin: 0 5vw 0 1vw;
      .searchInput {
        position: absolute;
        right: 0;
        height: 100%;
        text-align: center;
        background: $baseColor;
        width: 2vw;
        right: 0px;
        top: 0;
        border-radius: 0 5px 5px 0;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .searchBtn {
      color: $white;
      background-color: $baseColor;
      margin-right: 1.5vw;
    }
  }
  .popTable {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.29);
    z-index: 100;
    .popContent {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 82.3%;
      height: 80vh;
      padding: 3.3vh 1.4vw;
      background: #fff;
      border-radius: 25px;
      box-sizing: border-box;
      .popDate {
        margin: 3.3vh 0 2.8vh 0;
        /deep/.el-input__inner {
          /* width: 100%; */
        }
      }
      /deep/.el-table {
        height: 50vh !important;
      }
      /deep/.block {
        margin-top: 4.6vh !important;
      }
    }
  }
}

.table {
  margin-top: 2vh;
  .basicBtn {
    background: rgba(78, 87, 170, 0.1);
    color: #4e57aa;
  }
}
/deep/.el-table th > .cell {
  text-align: center;
}

/deep/.el-table .cell {
  text-align: center;
}
/deep/ {
  .el-table {
    tbody tr {
      &:hover {
        td {
          cursor: pointer;
        }
      }
    }
  }
}
</style>