import request from "@/utils/request";

export function dataTestList(data,pageNum,pageSize) {
  return request({
    url: "/jxzy-data/fileTask/page",
    method: "post",
    data,
    params:{pageNum,pageSize}
  });
}

export function listExport(data) {
  return request({
    url: "/jxzy/dataTrans/listExport",
    method: "post",
    responseType: "blob",
    data,
  });
}