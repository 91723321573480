<template>
  <div class="supervisRule">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/infoManage' }"
          >基础信息管理</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/infoManage/supervisRule' }"
          >督导规则列表</el-breadcrumb-item
        >
        <el-breadcrumb-item>督导规则设置</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main">
      <div class="title">
        <div class="left">
          <i class="sep"></i>
          <h4>督导规则列表</h4>
        </div>
      </div>
      <div class="des">督导员规则设置</div>
      <ul>
        <li>
          <div class="hint">督导异常</div>
          <div class="set">
            负责管辖内预警人数超
            <el-input v-model="formData.supervisorExceptionRole"></el-input>
            <!-- <el-select
              v-model="formData.supervisorExceptionRole"
              clearable
              placeholder="请选择"
            >
              <el-option value="10人"></el-option>
              <el-option value="20人"></el-option>
             </el-select> -->
            人以上，定义为督导异常预警
          </div>
        </li>
        <li>
          <span class="hint">督导超时</span>
          <div class="set">
            负责管辖内预警时长超
            <el-input v-model="formData.supervisorOvertimeRole"></el-input>
            <!-- <el-select
              v-model="formData.supervisorOvertimeRole"
              clearable
              placeholder="请选择"
            >
              <el-option value="10分钟"></el-option>
              <el-option value="20分钟"></el-option>
              </el-select> -->
            分钟以上，定义为督导超时预警
          </div>
        </li>
      </ul>
      <el-button type="primary" class="subBtn" @click="onSubmit"
        >提交</el-button
      >
    </div>
  </div>
</template>

<script>
import {
  supervisorRoleDetail,
  supervisorRoleUpdate,
} from "@/api/infoManage/rule";

export default {
  data() {
    return {
      formData: {
        supervisorExceptionRole: "",
        supervisorOvertimeRole: "",
      },
    };
  },

  created() {
    supervisorRoleDetail(this.$route.query.supervisorRoleId).then((res) => {
      this.formData = res.data;
    });
  },
  methods: {
    onSubmit() {
      if (
        this.formData.supervisorExceptionRole &&
        this.formData.supervisorOvertimeRole
      ) {
        let updateData = { ...this.formData };
        updateData.supervisorRoleId = this.$route.query.supervisorRoleId;
        supervisorRoleUpdate(updateData).then((res) => {
          if (res.code === 200) {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.$router.push("/infoManage/supervisRule");
          }
        });
      } else {
        this.$message({
          message: "请填写完所有信息后再提交",
          type: "warning",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.supervisRule {
  margin: 2vh 1.5vw;
  .marginTop {
    margin-top: 2vh;
    margin-bottom: 5vh;
  }

  .main {
    background: #fff;
    padding: 2vh 1vw;
    margin-top: 2vh;
    border-radius: 2vh;
    height: 100%;
    min-height: 75vh;
    .title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 3.7vh;
      .left {
        display: flex;
        .sep {
          background: #4e57aa;
          width: 5px;
          height: 1.5rem;
          display: inline-block;
          margin: 0 5px;
        }
        h4 {
          padding: 0;
          margin: 0;
        }
      }
    }
    .des {
      display: inline-block;
      padding: 0 0.73vw;
      height: 3vh;
      border-radius: 16px;
      border: 1px solid #cccccc;
      color: #333333;
      text-align: center;
      font-size: 14px;
      line-height: 3vh;
      margin-bottom: 3.241vh;
    }
    ul {
      margin: 0;
      padding: 0;
      margin-bottom: 6.5vh;
      margin-left: 0.4vw;
      li {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 3.7vh;
        margin-bottom: 3.15vh;
        .hint {
          //   vertical-align: middle;
          height: 3vh;
          padding: 0 0.9375vw;
          //   margin-top: 0.35vh;
          margin-right: 1.042vw;
          background: rgba(255, 86, 52, 0.1);
          color: #ff5634;
          text-align: center;
          line-height: 3vh;
          border-radius: 16px;
        }
        .set {
          color: #333333;
          /deep/.el-input {
            width: 11vw;
            height: 3.7vh;
            margin-left: 0.26vw;
            margin-right: 0.52vw;
            //   width: 74%;
          }
        }
      }
    }
    .subBtn {
      //   width: 3.65vw;
      //   height: 3.7vh;
      margin-left: 0.4vw;
      background: #4e57aa;
      color: #fff;
      border-color: #4e57aa;
    }
  }
}
</style>
