<template>
  <div class="supervision">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/dataAnalysis' }"
          >数据分析</el-breadcrumb-item
        >
        <el-breadcrumb-item>项目分析</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main">
      <div class="title">
        <div class="left">
          <i class="sep"></i>
          <h4>项目列表</h4>
        </div>
      </div>
      <div class="form-content marginTop">
        <div class="form-item">
          <span class="selectName">项目：</span>
          <el-select
            v-model="formData.projectId"
            clearable
            filterable
            placeholder="全部"
            @change="changeProject"
          >
            <el-option
              v-for="item in projectMenu"
              :key="item.projectId"
              :label="item.projectName"
              :value="item.projectId"
            >
            </el-option>
          </el-select>
        </div>
        <div class="form-item">
          <span class="selectName">状态：</span>
          <el-select
            v-model="formData.projectStatus"
            clearable
            placeholder="全部"
            filterable
          >
            <el-option label="进行中" value="进行中"></el-option>
            <el-option label="已结束" value="已结束"></el-option>
          </el-select>
        </div>
        <div class="form-item">
          <span class="selectName">起始时间：</span>
          <el-date-picker
              v-model="date"
              type="daterange"
              @change="handleDate"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <div class="form-item">
          <el-button class="searchBtn" @click="onInquire">查询</el-button>
          <el-button @click="onReset">重置</el-button>
        </div>
      </div>
      <el-row>
        <el-button class="searchBtn export" @click="handleExport"
          >导出</el-button
        >
      </el-row>
      <el-row :gutter="10">
        <TableChart
          :tableDatas="{
            id: 'project',
            data: projectTable,
          }"
          :pageData="pageData"
          @changePage="currentChange"
          @changeSize="sizeChange"
        ></TableChart>
      </el-row>
    </div>
  </div>
</template>
<script>
import TableChart from "../table";
import { mapState, mapActions } from "vuex";
// import { getTjSession } from "@/api/inspection/promoter";
// import { audioSpace } from "@/api/wordsAnalyze/audio";
import {
  projectList,
} from "@/api/dataAnalysis/staff";
import {
  getProjectAnal,
  listExport
} from "@/api/dataAnalysis/project";
export default {
  computed: {
    // ...mapState("supervision", ["supervisionTable"]),
  },
  components: {
    TableChart,
  },
  data() {
    return {
      // supervisionTable: [],
      areaMenu: [],
      provinceMenu: [],
      cityMenu: [],
      storeMenu: [],
      date:[],
      projectTable: {
        size: "",
        loading: false,
        columns: [
          {
            col: "项目编码",
            prop: "projectNo",
            width: "",
          },
          {
            col: "项目名称",
            prop: "projectName",
            width: "",
          },
          {
            col: "项目负责人",
            prop: "manageName",
            width: "",
          },
          {
            col: "项目状态",
            prop: "projectStatus",
            width: "",
          },
          {
            col: "项目开始时间",
            prop: "startDate",
            width: "",
          },
          {
            col: "项目结束时间",
            prop: "endDate",
            width: "",
          },
          {
            col: "促销员个数",
            prop: "userCount",
            width: "",
          },
          {
            col: "督导员个数",
            prop: "supervisorCount",
            width: "",
          }
        ],
        data: [],
      },
      pageData: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      formData: {
        projectId: "",
        projectStatus:'',
        startDate: "",
        endDate: ''
      },
      projectMenu: [],
      warnTypeMenu: [],
    };
  },
  created() {
    projectList().then((res) => {
      this.projectMenu = res.data;
    });
    // getSpaces().then((res) => {
    //   this.storeMenu = res.data;
    // });
    this.getTableData();
  },
  methods: {
    getTableData() {
      this.projectTable.loading = true;
      getProjectAnal(
        this.formData,
        this.pageData.pageNum,
        this.pageData.pageSize
      ).then((res) => {
        this.projectTable.loading = false;
        this.projectTable.data = res.rows;
        this.pageData.total = res.total;
      });
    },
    onInquire() {
      this.pageData.pageNum = 1;
      this.getTableData();
    },
    onReset() {
      this.formData.projectId = "";
      this.formData.projectStatus = "";
    },
    currentChange(val) {
      this.pageData.pageNum = val;
      this.getTableData();
    },
    sizeChange(val) {
      this.pageData.pageNum = 1;
      this.pageData.pageSize = val;
      this.getTableData();
    },
    changeProject(val) {
      if (val) {
        this.formData.spaceId = "";
      }
      getSpaces(val).then((res) => {
        this.storeMenu = res.data;
      });
    },
    handleDate(val){
      this.formData.startDate = val[0]
      this.formData.endDate = val[1]
    },
    handleExport() {
      listExport(this.formData).then((res) => {
        let blob = new Blob([res], { type: `text/plain;charset=utf-8` });
        let downloadElement = document.createElement("a");
        let href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = "项目数据分析列表.xls";
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === "staffToday") {
      next((vm) => {
        vm.getTableData();
      });
    } else {
      next();
    }
  },
  beforeRouteLeave(to, from, next) {
    from.meta.keepAlive = false;
    next();
  },
};
</script>

<style lang="scss" scoped>
$baseColor: #4e57aa;
$white: #fff;
$fontColor: #333;
$fontSize: 1.2em;
.supervision {
  margin: 2vh 1.5vw;
  .marginTop {
    margin-top: 2vh;
  }
  .main {
    background: #fff;
    padding: 2vh 1vw;
    margin-top: 2vh;
    border-radius: 2vh;
    height: 100%;
    min-height: 75vh;
    .form-content {
      display: flex;
      flex-wrap: wrap;
      .form-item {
        margin-right: 1.5625vw;
        margin-bottom: 1.7vh;
        .el-select {
          width: 10.9375vw;
        }
        .el-input {
          width: 10.9375vw;
        }
        .el-cascader {
          width: 10.9375vw;
        }
      }
    }
    .export {
      float: right;
    }
    // /deep/.el-select {
    //   width: 74%;
    // }
    // /deep/.el-cascader {
    //   width: 74%;
    // }
    .title {
      display: flex;
      justify-content: space-between;
      .left {
        display: flex;
        .sep {
          background: $baseColor;
          width: 5px;
          height: 1.5rem;
          display: inline-block;
          margin: 0 5px;
        }
        h4 {
          padding: 0;
          margin: 0;
        }
      }
    }
    .fr {
      float: right;
      width: auto;
      margin-left: 0;
    }
    .selectName {
      // width: 26%;
      font-size: 14px;
      display: inline-block;
      color: $fontColor;
      // text-align: left;
      // text-align-last: justify;
    }
    .indent2 {
      padding-right: 2em;
    }
    .parent {
      position: relative;
      .searchInput {
        position: absolute;
        right: 0;
        height: 100%;
        text-align: center;
        background: $baseColor;
        width: 2vw;
        right: 0px;
        top: 0;
        border-radius: 0 5px 5px 0;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .searchBtn {
      color: $white;
      background-color: $baseColor;
    }
  }
}
</style>