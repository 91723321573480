<template>
  <div class="warnDetail">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/dataAnalysis' }">数据分析</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/dataAnalysis/staff' }">
          人员数据分析列表
        </el-breadcrumb-item>
        <el-breadcrumb-item>人员详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main">
      <div class="info-content">
        <div class="head">
          <div class="headline">人员详情</div>
          <div class="right">
            <el-button :class="dayIndex == 1 ? 'active-btn' : 'btn'" @click="changeDayIndex(1)"
              >昨日</el-button
            >
            <el-button :class="dayIndex == 2 ? 'active-btn' : 'btn'" @click="changeDayIndex(2)"
              >近7天</el-button
            >
            <el-button :class="dayIndex == 3 ? 'active-btn' : 'btn'" @click="changeDayIndex(3)"
              >近一个月</el-button
            >
            <div class="select-date">
              <el-date-picker
                v-model="date"
                type="daterange"
                size="small"
                range-separator="~"
                value-format="yyyy-MM-dd"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="selectTime"
              >
              </el-date-picker>
            </div>
          </div>
        </div>
        <div class="details marginTop">
          <div class="detailsBack">
            <img src="@/assets/img/Background.png" alt="" />
          </div>
          <div class="left">
            <div class="block">
              <el-avatar icon="el-icon-user-solid"></el-avatar>
            </div>
            <div class="info">
              <h5>{{ staffData.userName }}-{{ staffData.spaceName }}</h5>
              <p>{{ staffData.roleName }}</p>
              <p>设备号：{{ staffData.deviceNo }}</p>
              <p>编号：{{ staffData.userId }}</p>
            </div>
          </div>
          <div class="right">
            <div class="rightItem">
              <img src="@/assets/img/yjzsc1.png" alt="" />
              <div class="textindex" style="margin-left:10px;">
                <p>沟通时长</p>
                <span>{{ staffData.allWarningTime }}</span>
              </div>
            </div>
            <div class="rightItem">
              <img src="@/assets/img/yjzcs1.png" alt="" />
              <div class="textindex" style="margin-left:10px;">
                <p>关键词命中次数</p>
                <span>{{ staffData.allWarningCount }}</span>
              </div>
            </div>
            <div class="jumpItem" @click="toVideo">
              查看音频记录
            </div>
          </div>
        </div>
      </div>
      <div class="condition">
        <div class="chartDiv">
          <div class="left">
            <!-- <div class="subtitle">工作情况指数</div> -->
            <div class="note-content">
              <div class="project_overview_overview_left">
                <p style="margin-bottom:20px;">行为指标</p>
                <div class="indicatorBox">
                  <div
                    class="indicatorItem"
                    v-for="(item, index) in indicatorList"
                    :key="index"
                    :style="{ marginTop: index > 1 ? '20px' : '' }"
                  >
                    <div class="indicatorItemBox">
                      <img :src="item.src" alt="" />
                      <span>{{ item.name }}</span>
                      <img src="@/assets/img/Frame.png" alt="" />
                    </div>
                    <div :title="item.num" class="indicatorBtn">
                      {{ item.num }}
                    </div>
                  </div>
                </div>
                <p style="margin:30px 0;">话术指标</p>
                <div class="indicatorBox">
                  <div
                    class="indicatorItem"
                    v-for="(item, index) in verbalList"
                    :key="index"
                    :style="{ marginTop: index > 1 ? '20px' : '' }"
                  >
                    <div class="indicatorItemBox">
                      <img :src="item.src" alt="" />
                      <span>{{ item.name }}</span>
                      <img src="@/assets/img/Frame.png" alt="" />
                    </div>
                    <div :title="item.num" class="indicatorBtn">
                      {{ item.num || 0 }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="subtitle">工作情况指数</div>
            <div class="radar">
              <Radar
                id="staffToday"
                :spaceData="spaceData"
                :projectData="projectData"
                :userData="userData"
              ></Radar>
            </div>
          </div>
        </div>
      </div>
      <div class="chart-content">
        <div class="left">
          <div class="subtitle">电子围栏工作情况</div>
          <div class="work-info">
            <ring-chart :ringData="ringData"></ring-chart>
          </div>
        </div>
        <div class="right">
          <div class="head">
            <div class="subtitle">热门关键词</div>
            <div class="des">
              总匹配次数：<span class="num">{{ matchNum }}</span
              ><span class="unit">次</span>
            </div>
          </div>
          <div class="keyword">
            <!-- <bubble-chart :bubbleData="bubbleData"></bubble-chart> -->
            <div class="hotListBox">
              <ul>
                <li v-for="(item, index) in hotData" :key="index">
                  <div class="hot_left">
                    <div :style="{ background: index + 1 < 4 ? '#4E57AA' : '' }">
                      {{ index + 1 }}
                    </div>
                    <p>{{ item.content }}</p>
                  </div>
                  <span
                    :style="{
                      color:
                        index == 0
                          ? '#FF5533'
                          : index == 1
                          ? '#FF9837'
                          : index == 2
                          ? '#FC9883'
                          : ''
                    }"
                    >{{ item.getCount }}</span
                  >
                </li>
              </ul>
              <ul style="margin-left:50px;">
                <li v-for="(item, index) in hotData2" :key="index">
                  <div class="hot_left">
                    <div>{{ index + 7 }}</div>
                    <p>{{ item.content }}</p>
                  </div>
                  <span>{{ item.getCount }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Tool from '@/utils/tool';
// import { mapState, mapActions } from "vuex";
import Radar from '../radar';
import ringChart from './ringChart';
import bubbleChart from './bubbleChart';
import {
  userAnalDetail,
  spaceAvg,
  projectAvg,
  userAvg,
  scriptLibraryGet
} from '@/api/dataAnalysis/staff';

export default {
  name: 'warnDetail',
  computed: {},
  components: {
    Radar,
    ringChart,
    bubbleChart
  },
  created() {
    if (this.dayIndex == 2) {
      let date2 = new Date();
      date2.setDate(date2.getDate() - 7);
      let weekDay = date2.getFullYear() + '-' + (date2.getMonth() + 1) + '-' + date2.getDate();
      localStorage.setItem(
        'staffRow',
        JSON.stringify({
          userId: this.staffRow.userId,
          startDate: weekDay,
          endDate:
            new Date().getFullYear() +
            '-' +
            (new Date().getMonth() + 1) +
            '-' +
            (new Date().getDate() - 1)
        })
      );
      this.getList();
    }
    this.getList();
    scriptLibraryGet(this.staffRow.userId).then(res => {
      res.data.map((item, index) => {
        if (index < 6) {
          this.hotData.push(item);
        } else if (index > 6 && index < 13) {
          this.hotData2.push(item);
        }
      });
      this.bubbleData = res.data;
      this.matchNum = res.data.reduce((total, item) => {
        return total + item.getCount;
      }, 0);
    });
  },
  data() {
    return {
      matchNum: 0,
      ringData: [],
      bubbleData: [],
      spaceData: {},
      projectData: {},
      userData: {},
      staffData: {},
      indicatorList: [
        {
          name: '在排时长（h）',
          num: '5789',
          src: require('@/assets/img/Icon-window.png')
        },
        {
          name: '在岗时长（h）',
          num: '5789',
          src: require('@/assets/img/rjlgsc.png')
        },
        {
          name: '行动步数',
          num: '5789',
          src: require('@/assets/img/rjtgbs.png')
        },
        {
          name: '离岗时长（h）',
          num: '5789',
          src: require('@/assets/img/rjtgsc.png')
        },
        {
          name: '脱岗次数',
          num: '5789',
          src: require('@/assets/img/rjjzbs.png')
        },
        {
          name: '脱岗时长（h）',
          num: '5789',
          src: require('@/assets/img/rjjzsc.png')
        },
        {
          name: '静止次数',
          num: '5789',
          src: require('@/assets/img/rjjzsc.png')
        },
        {
          name: '静止时长（h）',
          num: '5789',
          src: require('@/assets/img/rjjzsc.png')
        }
      ],
      verbalList: [
        {
          name: '主动沟通占比',
          num: '5789',
          src: require('@/assets/img/rjzdgtzb.png')
        },
        {
          name: '沟通时长（h）',
          num: '5789',
          src: require('@/assets/img/rjgtsc.png')
        },
        {
          name: '关键词命中次数',
          num: '5789',
          src: require('@/assets/img/gjcmzcs.png')
        },
        {
          name: '主动沟通次数',
          num: '5789',
          src: require('@/assets/img/zdgtcs.png')
        }
      ],
      date: '',
      hotData: [],
      hotData2: [],
      dayIndex: 2,
      timeSet: [],
      staffRow: JSON.parse(localStorage.getItem('staffRow'))
    };
  },
  methods: {
    getList() {
      let staffRow = JSON.parse(localStorage.getItem('staffRow'));
      userAnalDetail(staffRow).then(res => {
        this.indicatorList[0].num = res.data.fenceDate;
        this.indicatorList[1].num = res.data.workDate;
        this.indicatorList[2].num = res.data.step;
        this.indicatorList[3].num = res.data.odd;
        this.indicatorList[4].num = res.data.osc;
        this.indicatorList[5].num = res.data.oscaTime;
        this.indicatorList[6].num = res.data.ssc;
        this.indicatorList[7].num = res.data.ssd;
        this.verbalList[0].num = res.data.activeTalkProp;
        this.verbalList[1].num = res.data.staffDuration;
        this.verbalList[2].num = res.data.verbalTrickGet;
        this.verbalList[3].num = res.data.activeTalkCount;
        this.staffData = res.data;
        this.ringData = res.data.fences.map(item => {
          return {
            name: item.fenceName,
            value: Number(item.workTime)
          };
        });
        spaceAvg(res.data.projectId, res.data.spaceId, res.data.startDate, res.data.endDate).then(
          res => {
            this.spaceData = res.data;
          }
        );
        projectAvg(res.data.projectId, res.data.startDate, res.data.endDate).then(res => {
          this.projectData = res.data;
        });
        userAvg(res.data.startDate, res.data.endDate, res.data.userId).then(res => {
          this.userData = res.data;
        });
      });
    },
    toVideo() {
      this.$router.push('/wordsAnalyze/audioRecord');
    },
    changeDayIndex(index) {
      this.dayIndex = index;
      let nowTime = '';
      let weekDay = '';
      let monthDay = '';
      let date1 = new Date();
      nowTime = date1.getFullYear() + '-' + (date1.getMonth() + 1) + '-' + date1.getDate();
      let date2 = new Date();
      date2.setDate(date2.getDate() - 7);
      weekDay = date2.getFullYear() + '-' + (date2.getMonth() + 1) + '-' + date2.getDate();
      let date3 = new Date();
      date3.setDate(date3.getDate() - 30);
      monthDay = date3.getFullYear() + '-' + (date3.getMonth() + 1) + '-' + date3.getDate();
      let params = this.staffRow;
      if (index == 1) {
        params.startDate =
          date1.getFullYear() + '-' + (date1.getMonth() + 1) + '-' + (date1.getDate() - 1);
        params.endDate =
          date1.getFullYear() + '-' + (date1.getMonth() + 1) + '-' + (date1.getDate() - 1);
        localStorage.setItem('staffRow', JSON.stringify(params));
      } else if (index == 2) {
        params.startDate = weekDay;
        params.endDate = nowTime;
        localStorage.setItem('staffRow', JSON.stringify(params));
      } else if (index == 3) {
        params.startDate = monthDay;
        params.endDate = nowTime;
        localStorage.setItem('staffRow', JSON.stringify(params));
      }
      this.date = '';
      this.getList();
    },
    selectTime(val) {
      let params = JSON.parse(localStorage.getItem('staffRow'));
      if (val && val.length > 1) {
        this.dayIndex = 4;
        params.startDate = val[0];
        params.endDate = val[1];
      } else {
        params.startDate =
          new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate();
        params.endDate =
          new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate();
        this.dayIndex = 1;
      }
      localStorage.setItem('staffRow', JSON.stringify(params));
      this.getList();
    },
    getImgUrl(url) {
      return require('@/assets/pc/' + url + '.png');
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'staff') {
      to.meta.keepAlive = true;
    }
    next();
  }
};
</script>

<style scoped>
.hotListBox {
  margin-top: 26px;
  display: flex;
}
.hotListBox ul {
  padding: 0;
  margin: 0;
}
.hotListBox li {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.hot_left {
  display: flex;
  align-items: center;
}
.hot_left div {
  width: 20px;
  height: 20px;
  background: rgba(78, 87, 170, 0.4);
  border-radius: 1px;
  font-size: 14px;
  font-family: Helvetica;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}
.hotListBox li p {
  font-size: 16px;
  font-family: Helvetica;
  color: #2a2828;
  margin-right: 50px;
  white-space: nowrap;
}
.hotListBox li span {
  font-size: 18px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #4e57aa;
}
</style>

<style lang="scss" scoped>
@import '../../../../assets/common.scss';
$baseColor: #4e57aa;
$white: #fff;
$fontColor: #333;
$fontSize: 1.2em;

p {
  margin: 0;
  padding: 0;
}
.warnDetail {
  padding: 2vh 1.5vw;
  background: #f9f9fd;

  .breadcrumb {
    .items {
      font-size: 14px;
    }
  }
  .main {
    // background: #fff;
    // padding: 2vh 1vw;
    margin-top: 2vh;
    // border-radius: 2vh;
    height: 100%;
    min-height: 75vh;
    .info-content {
      background: #fff;
      border-radius: 16px;
      padding: 3vh 1.04vw 2vh 1.04vw;
      margin-bottom: 1.505vh;
      .head {
        display: flex;
        justify-content: space-between;
      }
      .right {
        display: flex;
        .active-btn {
          color: #fff;
          background: #4e57aa;
          border-radius: 6px;
        }
        .btn {
          border-radius: 6px;
        }
      }
      .select-date {
        margin-left: 10px;
        .el-date-editor {
          width: 12vw;
        }
      }
    }
    .condition {
      background: #fff;
      border-radius: 16px;
      padding: 2.3vh 1.04vw 3.4vh 1.04vw;
      margin-bottom: 1.505vh;
    }
    .headline {
      position: relative;
      height: 28px;
      padding-left: 16px;
      font-size: 20px;
      font-weight: 600;
      color: #333333;
      line-height: 28px;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 6px;
        height: 26px;
        background: #4e57aa;
      }
    }
    .subtitle {
      position: relative;
      height: 22px;
      padding-left: 10px;
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      line-height: 22px;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 4px;
        height: 16px;
        background: #4e57aa;
      }
    }
    .title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 3.24vh;
      .left {
        display: flex;
        .sep {
          background: $baseColor;
          width: 5px;
          height: 1.5rem;
          display: inline-block;
          margin: 0 5px;
        }
        h4 {
          padding: 0;
          margin: 0;
          font-size: 20px;
        }
        h5 {
          padding: 0;
          margin: 0;
          font-size: 16px;
        }
      }
    }

    .rightItem {
      display: flex;
      align-items: center;
    }

    .jumpItem {
      width: 150px;
      height: 48px;
      background: #ffffff;
      border-radius: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 16px;
      color: #4e57aa;
      cursor: pointer;
    }

    .detailsBack img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
    .textindex {
      > p {
        white-space: nowrap;
      }
    }
    .textindex span {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      color: #ffffff;
    }

    .details {
      position: relative;
      background: $baseColor;
      border-radius: 25px;
      // height: 13vh;
      display: flex;
      align-items: center;
      // padding: 5vh 3vw;
      padding: 3.5vh 0;
      color: #fff;
      .left {
        z-index: 99;
        position: relative;
        display: flex;
        align-items: center;
        // width: 75%;
        width: 43%;
        // border-right: 1px solid rgba(255, 255, 255, 0.5);
        .block {
          //   position: absolute;
          //   top: 0;
          //   bottom: 0;
          //   // margin: auto;
          margin: 0 2.084vw 0 1.5625vw;
          width: 5.2vw;
          height: 5.2vw;
          > span {
            width: 100%;
            height: 100%;
            line-height: 5.2vw;
          }
        }
        .info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          h5 {
            margin: 0;
            font-size: 16px;
            color: #fff;
          }
          p {
            margin-top: 1.3vh;
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
      .right {
        z-index: 99;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // width: 36%;
        height: 8.9vh;
        padding-left: 80px;
        border-left: 1px solid rgba(229, 229, 255, 0.3);
        box-sizing: border-box;
        p {
          margin-right: 5.468vw;
          font-size: 16px;
          font-weight: 600;
          color: #ffffff;
          span {
            color: #ffc531;
          }
        }
      }
    }
    .chartDiv {
      display: flex;
      .left {
        flex: 5;
        margin-right: 50px;
        .note-content {
          display: flex;
          flex-wrap: wrap;
        }
        .notes {
          background: #f9f9fd;
          border-radius: 8px;
          display: flex;
          width: 20.84vw;
          margin-left: 1.5625vw;
          margin-bottom: 2.8vh;
          padding: 1vh 1vw 1vh 1vw;
          //   float: left;
          //   transition: 0.3s;
          img {
            margin-right: 8px;
          }
          // box-shadow: 0 2px 12px rgb(0 0 0 / 10%);
          .flex7 {
            flex: 7;
          }
          .num {
            color: #4e57aa;
            // font-size: 1.2rem;
            font-size: 18px;
          }
        }
      }
      .right {
        flex: 3;
        .radar {
          height: 50vh;
          margin-top: -2vh;
          .chart {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .chart-content {
      display: flex;
      padding-bottom: 4vh;
      > div {
        width: 100%;
        background: #fff;
        border-radius: 12px;
        padding: 1.85vh 1.042vw;
        box-sizing: border-box;
      }
      .left {
        display: flex;
        flex-direction: column;
        margin-right: 1.04vw;
        .work-info {
          flex: 1;
          width: 80%;
          //   margin: 0 auto;
          //   margin-top: 5%;
          .ring-chart {
            width: 100%;
            height: 100%;
          }
        }
      }
      .right {
        .head {
          display: flex;
          justify-content: space-between;
          .des {
            font-size: 14px;
            color: #333333;
            .num {
              color: #4e57aa;
              font-size: 18px;
            }
            .unit {
              color: #4e57aa;
              font-size: 14px;
            }
          }
        }
        .keyword {
          width: 100%;
        }
      }
    }
  }
}

.titleBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.indicatorBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.indicatorItemBox {
  display: flex;
  align-items: center;
}

.indicatorItem {
  width: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  border-bottom: 1px solid #eff4ff;
}

.indicatorItem span {
  font-weight: 600;
  font-size: 16px;
  color: #333333;
  margin-left: 6px;
  margin-right: 5px;
}

.indicatorBtn {
  padding: 7px 8.5px;
  background: rgba(78, 87, 170, 0.1);
  border-radius: 7px;
  font-weight: 600;
  font-size: 18px;
  color: #4e57aa;
}

.titleItem {
  width: 500px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.itemcontent {
  z-index: 99;
  margin-left: 30px;
}

.titlenum {
  font-family: Inter;
  font-weight: bold;
  font-size: 26px;
  color: #ffffff;
  margin: 5px 0 0 0;
}

.itemcontent span {
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
}
.titlerightImg {
  z-index: 99;
  margin-right: 49px;
}
.itemBlue {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
}
</style>
