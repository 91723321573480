<template>
  <div class="speechAnalysis">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/systemManage' }"
          >系统管理</el-breadcrumb-item
        >
        <el-breadcrumb-item>账户管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main">
      <div class="title">
        <div class="left">
          <i class="sep"></i>
          <h4>账户管理</h4>
        </div>
      </div>
      <div class="form-content marginTop">
        <div class="form-item">
          <span class="selectName indent2">用户名：</span>
          <el-input
            class="input"
            v-model="formData.userName"
            placeholder="请输入"
          ></el-input>
        </div>
        <div class="form-item">
          <span class="selectName">姓名：</span>
          <el-input
            class="input"
            v-model="formData.nickName"
            placeholder="请输入"
          ></el-input>
          <!-- <el-select v-model="formData.nickName" clearable>
            <el-option
              v-for="item in project.options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select> -->
        </div>
        <div class="form-item">
          <span class="selectName">用户角色：</span>
          <!-- <el-input v-model="input" placeholder="请输入内容"></el-input> -->
          <el-select v-model="formData.roleId" clearable filterable>
            <el-option
              v-for="item in roleMenu"
              :key="item.roleId"
              :label="item.roleName"
              :value="item.roleId"
            >
            </el-option>
          </el-select>
        </div>
        <div class="form-item">
          <span class="selectName">用户状态：</span>
          <el-select v-model="formData.status" clearable filterable>
            <el-option label="正常" value="0"></el-option>
            <el-option label="停用" value="1"></el-option>
          </el-select>
        </div>

        <!-- <el-col :span="6" class="parent">
          <el-input placeholder="请输入" v-model="searchInput"> </el-input>
          <i class="el-icon-search searchInput"></i>
        </el-col> -->
        <div class="form-item">
          <el-button class="searchBtn" @click="onInquire">查询</el-button>
          <el-button @click="onReset">重置</el-button>
        </div>
      </div>
      <div style="display: flex; justify-content: flex-end; margin-top: 2vh">
        <el-button icon="el-icon-plus" @click="add">新建</el-button>
        <el-button
          style="background: #4e57aa; border-color: #4e57aa; color: #fff"
          >导出</el-button
        >
        <!-- <el-button
          type="primary"
          icon="el-icon-plus"
          style="background: #4e57aa; border-color: #4e57aa"
          @click="add"
          >新建</el-button
        >
        <el-button>批量开启</el-button>
        <el-button>批量关闭</el-button> -->
      </div>

      <el-row :gutter="10">
        <TableChart
          :tableDatas="{
            id: 'jurisdiction',
            data: jurisdiction,
          }"
          :pageData="pageData"
          @changePage="currentChange"
          @changeSize="sizeChange"
        ></TableChart>
      </el-row>
    </div>
  </div>
</template>
<script>
import TableChart from "../table";
import { mapState, mapActions } from "vuex";
import { userList, toInsertPage } from "@/api/sysManage/account";

export default {
  computed: {
    // ...mapState("speechAnalysis", ["jurisdiction"]),
  },
  components: {
    TableChart,
  },
  data() {
    return {
      roleMenu: [],
      jurisdiction: {
        size: "",
        loading: false,
        columns: [
          {
            col: "用户名",
            prop: "userName",
            width: "",
          },
          {
            col: "姓名",
            prop: "nickName",
            width: "",
          },
          {
            col: "用户状态",
            prop: "statusName",
            width: "",
          },
          {
            col: "手机号",
            prop: "phone",
            width: "",
          },
          {
            col: "最后登录时间",
            prop: "loginDate",
            width: "",
          },
          {
            col: "角色",
            prop: "roleName",
            width: "",
          },
          {
            col: "客户名称",
            prop: "customerName",
            width: "",
          },
        ],

        data: [],
      },
      pageData: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      formData: {
        userName: "",
        nickName: "",
        roleId: "",
        status: "",
      },
      date: "",
      sales: {
        value: "",
        options: [
          {
            value: 0,
            label: "张雨晨",
          },
          {
            value: 1,
            label: "王楠",
          },
        ],
      },
      project: {
        value: "",
        options: [
          {
            value: 0,
            label: "语音项目",
          },
          {
            value: 1,
            label: "项目",
          },
        ],
      },
      profession: {
        value: "",
        options: [
          {
            value: 0,
            label: "直播",
          },
          {
            value: 1,
            label: "餐饮",
          },
        ],
      },
      area: {
        value: "",
        options: [
          {
            label: "热门城市",
            options: [
              {
                value: "Shanghai",
                label: "上海",
              },
              {
                value: "Beijing",
                label: "北京",
              },
            ],
          },
          {
            label: "城市名",
            options: [
              {
                value: "Chengdu",
                label: "成都",
              },
              {
                value: "Shenzhen",
                label: "深圳",
              },
              {
                value: "Guangzhou",
                label: "广州",
              },
              {
                value: "Dalian",
                label: "大连",
              },
            ],
          },
        ],
      },
      searchInput: "",
    };
  },
  created() {
    // if (this.$route.query.customerId) {
    //   userList(
    //     { customerId: this.$route.query.customerId },
    //     this.pageData.pageNum,
    //     this.pageData.pageSize
    //   ).then((res) => {
    //     this.jurisdiction.data = res.rows;
    //     this.pageData.total = res.total;
    //   });
    // } else {
    //   this.getTableData();
    // }
  },
  methods: {
    getTableData() {
      this.jurisdiction.loading = true;
      let data = { ...this.formData };
      if (this.$route.query.customerId) {
        data.customerId = this.$route.query.customerId;
      }
      userList(data, this.pageData.pageNum, this.pageData.pageSize).then(
        (res) => {
          this.jurisdiction.loading = false;
          this.jurisdiction.data = res.rows;
          this.pageData.total = res.total;
        }
      );
    },
    onInquire() {
      this.pageData.pageNum = 1;
      this.getTableData();
    },
    onReset() {
      this.formData.userName = "";
      this.formData.nickName = "";
      this.formData.roleId = "";
      this.formData.status = "";
    },
    currentChange(val) {
      this.pageData.pageNum = val;
      this.getTableData();
    },
    sizeChange(val) {
      this.pageData.pageNum = 1;
      this.pageData.pageSize = val;
      this.getTableData();
    },
    add() {
      this.$router.push("/systemManage/account/accountInfo");
    },
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === "accountInfo") {
      next((vm) => {
        vm.getTableData();
      });
    } else {
      next((vm) => {
        toInsertPage().then((res) => {
          vm.roleMenu = res.data.roles;
        });
        vm.getTableData();
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== "accountInfo") {
      from.meta.keepAlive = false;
      next();
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
$baseColor: #4e57aa;
$white: #fff;
$fontColor: #333;
$fontSize: 1.2em;
.speechAnalysis {
  margin: 2vh 1.5vw;
  .marginTop {
    margin-top: 2vh;
  }
  .main {
    background: #fff;
    padding: 2vh 1vw;
    margin-top: 2vh;
    border-radius: 2vh;
    height: 100%;
    min-height: 75vh;
    .form-content {
      display: flex;
      flex-wrap: wrap;
      .form-item {
        margin-right: 1.5625vw;
        margin-bottom: 1.7vh;
        .el-select {
          width: 10.9375vw;
        }
        .el-input {
          width: 10.9375vw;
        }
        .el-cascader {
          width: 10.9375vw;
        }
      }
    }
    // /deep/.input {
    //   width: 74%;
    // }
    // /deep/.el-select {
    //   width: 74%;
    // }
    .title {
      display: flex;
      justify-content: space-between;
      .left {
        display: flex;
        .sep {
          background: $baseColor;
          width: 5px;
          height: 1.5rem;
          display: inline-block;
          margin: 0 5px;
        }
        h4 {
          padding: 0;
          margin: 0;
        }
      }
    }
    .fr {
      float: right;
      width: auto;
      margin-left: 0;
    }
    .selectName {
      // width: 10vh;
      font-size: 14px;
      display: inline-block;
      color: $fontColor;
      // text-align: justify;
      // text-align-last: justify;
    }
    .indent2 {
      padding-right: 1em;
    }
    .parent {
      position: relative;
      .searchInput {
        position: absolute;
        right: 0;
        height: 100%;
        text-align: center;
        background: $baseColor;
        width: 2vw;
        right: 0px;
        top: 0;
        border-radius: 0 5px 5px 0;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .searchBtn {
      color: $white;
      background-color: $baseColor;
      border-color: $baseColor;
    }
  }
}
</style>