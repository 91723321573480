<template>
  <div class="project_exam_detail_content">
    <div class="project_exam_detail_content_header">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/quickTest' }"
          >快考分析</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/quickTest/testCase' }"
          >快考记录列表</el-breadcrumb-item
        >
        <el-breadcrumb-item>快考详情</el-breadcrumb-item>
      </el-breadcrumb>
      <!-- <div class="project_exam_detail_content_header_right">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item>雀巢咖啡活动</el-breadcrumb-item>
                    <el-breadcrumb-item>项目</el-breadcrumb-item>
                </el-breadcrumb>
                <div class="project_exam_detail_content_header_right_times" v-text="'时间：2020/12/01-2021/03/01'"></div>
            </div> -->
    </div>
    <div class="exam_basic_info">
      <!-- <div class="exam_small_content_header">
        <div class="exam_small_content_header_color"></div>
        <div class="exam_small_content_header_text">考试基本信息</div>
      </div> -->
      <div class="exam_basic_info_top">
        <h3>考试基本信息</h3>
        <p>考试项目：{{ infoData.projectName }}</p>
        <ul>
          <li class="yellow">试卷名称：{{ infoData.paperName }}</li>
          <li>试卷总分：{{ infoData.totalScore }}分</li>
          <li>试卷总时长：{{ infoData.totalTime }}min</li>
          <li>考试截止时间：{{ infoData.endTime }}</li>
        </ul>
        <!-- <div class="exam_basic_info_top_head">
          <span>考试项目：</span><span>{{ resultData.projectName }}</span>
        </div>
        <div class="exam_basic_info_top_foot">
          <div class="exam_basic_info_top_foot_item">
            <span>试卷名称：</span><span>{{ resultData.paperName }}</span>
          </div>
          <div class="exam_basic_info_top_foot_item">
            <span>试卷总分：</span><span>{{ resultData.totalScore }}</span>
          </div>
          <div class="exam_basic_info_top_foot_item">
            <span>试卷总时长：</span><span>{{ resultData.examTime }}min</span>
          </div>
          <div class="exam_basic_info_top_foot_item">
            <span>考试截止时间：</span><span>{{ resultData.endTime }}</span>
          </div>
        </div> -->
      </div>
      <div class="exam_basic_info_bottom">
        <ul>
          <li>应考人数：{{ infoData.examShouldCnt }}人</li>
          <li class="orange">未考人数：{{ infoData.examMissCnt }}人</li>
          <li>实考人数：{{ infoData.examActualCnt }}人</li>
        </ul>
        <ul>
          <li>合格人数：{{ infoData.examPassCnt }}人</li>
          <li class="orange">不合格人数：{{ infoData.examNopassCnt }}人</li>
          <li>平均得分：{{ infoData.avgScore }}分</li>
        </ul>
        <!-- <div class="exam_basic_info_bottom_foot" style="margin-top: 2vh">
          <div class="exam_basic_info_bottom_foot_item">
            <span>应考人数：</span
            ><span
              >{{
                resultData.examShouldCount ? resultData.examShouldCount : 0
              }}人</span
            >
          </div>
          <div class="exam_basic_info_bottom_foot_item">
            <span>未考人数：</span><span>无</span>
          </div>
          <div class="exam_basic_info_bottom_foot_item">
            <span>实考人数：</span
            ><span
              >{{
                resultData.examActualCount ? resultData.examActualCount : 0
              }}人</span
            >
          </div>
        </div>
        <div class="exam_basic_info_bottom_foot">
          <div class="exam_basic_info_bottom_foot_item">
            <span>合格人数：</span
            ><span
              >{{
                resultData.examPassCount ? resultData.examPassCount : 0
              }}人</span
            >
          </div>
          <div class="exam_basic_info_bottom_foot_item">
            <span>不合格人数：</span><span>无</span>
          </div>
          <div class="exam_basic_info_bottom_foot_item">
            <span>平均得分：</span
            ><span>{{ resultData.avgScore ? resultData.avgScore : 0 }}分</span>
          </div>
        </div> -->
      </div>
    </div>
    <div class="exam_small_content">
      <div class="exam_small_content_header">
        <div class="exam_small_content_header_color"></div>
        <div class="exam_small_content_header_text">考试结果列表</div>
      </div>
      <div class="exam_small_content_select">
        <div class="exam_small_content_select_left">
          <div>是否合格：</div>
          <el-select v-model="isPass" placeholder="请选择" clearable filterable>
            <el-option label="合格" :value="1"></el-option>
            <el-option label="不合格" :value="0"></el-option>
          </el-select>
          <!-- <div class="search">
            <el-input placeholder="请输入" v-model="input"> </el-input>
            <i class="el-icon-search searchInput"></i>
          </div> -->
          <!-- <el-input placeholder="请输入内容" v-model="input" clearable>
          </el-input>

          <el-button type="primary" icon="el-icon-search"></el-button> -->
        </div>
        <div class="exam_small_content_select_right">
          <el-button type="primary" @click="onInquire">查询</el-button>
          <el-button @click="onReset">重置</el-button>
        </div>
      </div>
      <el-row>
        <el-button class="export">导出</el-button>
      </el-row>
      <div class="exam_small_content_main_box">
        <TableChart
          :tableDatas="{
            id: 'quickDetailTable',
            data: testDetailTable,
          }"
          :pageData="pageData"
          @changePage="currentChange"
          @changeSize="sizeChange"
        ></TableChart>
        <!-- <el-col :span="24">
          <el-row class="exam_small_content_main">
            <el-col class="exam_small_content_main_item" :span="4"
              >人员编号</el-col
            >
            <el-col class="exam_small_content_main_item" :span="2"
              >人员名称</el-col
            >
            <el-col class="exam_small_content_main_item" :span="2">职位</el-col>
            <el-col class="exam_small_content_main_item" :span="2">分数</el-col>
            <el-col class="exam_small_content_main_item" :span="3"
              >是否合格</el-col
            >
            <el-col class="exam_small_content_main_item" :span="4"
              >参加考试时间</el-col
            >
            <el-col class="exam_small_content_main_item" :span="4"
              >结束考试时间</el-col
            >
            <el-col class="exam_small_content_main_item" :span="3"
              >所属门店</el-col
            >
          </el-row>
          <el-row
            class="exam_small_content_centent"
            v-for="(item, index) in StaffInfo"
            :key="index"
          >
            <el-col :span="4" class="exam_small_content_main_centent_item">{{
              item.staffNo ? item.staffNo : "无"
            }}</el-col>
            <el-col :span="2" class="exam_small_content_main_centent_item">{{
              item.name ? item.name : "无"
            }}</el-col>
            <el-col :span="2" class="exam_small_content_main_centent_item">{{
              item.position ? item.position : "无"
            }}</el-col>
            <el-col :span="2" class="exam_small_content_main_centent_item">{{
              item.score ? item.score : "无"
            }}</el-col>
            <el-col :span="3" class="exam_small_content_main_centent_item">{{
              item.isPass == 1 ? "合格" : "不合格"
            }}</el-col>
            <el-col :span="4" class="exam_small_content_main_centent_item">{{
              item.startTime ? item.startTime : "无"
            }}</el-col>
            <el-col :span="4" class="exam_small_content_main_centent_item">{{
              item.endTime ? item.endTime : "无"
            }}</el-col>
            <el-col :span="3" class="exam_small_content_main_centent_item">{{
              item.spaceName ? item.spaceName : "无"
            }}</el-col>
          </el-row>
        </el-col> -->
      </div>
    </div>
  </div>
</template>

<script>
import { examInfoDetail, examUserList } from "@/api/quickTest/test";

import TableChart from "../table";

const ls = window.sessionStorage;
export default {
  name: "projectexamdetail",
  components: {},
  data() {
    return {
      isPass: null,
      infoData: {},
      input: "",
      testDetailTable: {
        size: "",
        loading: false,
        columns: [
          {
            col: "人员编号",
            prop: "userId",
            width: "",
          },
          {
            col: "人员名称",
            prop: "userName",
            width: "",
          },
          {
            col: "职位",
            prop: "position",
            width: "",
          },
          {
            col: "分数",
            prop: "score",
            width: "",
          },

          {
            col: "是否合格",
            prop: "isPassName",
            width: "",
          },
          {
            col: "参加考试时间",
            prop: "startTime",
            width: "",
          },
          {
            col: "结束考试时间",
            prop: "endTime",
            width: "",
          },
          {
            col: "所属门店",
            prop: "spaceName",
            width: "",
          },
        ],
        data: [],
      },
      pageData: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      formData: {
        warningType: "",
        projectId: "",
      },
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      StaffInfo: [],
      resultData: {},
    };
  },
  components: {
    TableChart,
  },
  created() {
    examInfoDetail(this.$route.query.examInfoId).then((res) => {
      this.infoData = res.data;
    });
    this.getTableData();
  },
  methods: {
    getTableData() {
      this.testDetailTable.loading = true;
      examUserList(
        this.$route.query.examInfoId,
        this.isPass,
        this.pageData.pageNum,
        this.pageData.pageSize
      ).then((res) => {
        this.testDetailTable.loading = false;
        this.testDetailTable.data = res.rows;
        this.pageData.total = res.total;
      });
    },
    onInquire() {
      this.pageData.pageNum = 1;
      this.getTableData();
    },
    onReset() {
      this.isPass = null;
      // this.formData.projectId = "";
      // this.formData.warningType = "";
    },
    currentChange(val) {
      this.pageData.pageNum = val;
      this.getTableData();
    },
    sizeChange(val) {
      this.pageData.pageNum = 1;
      this.pageData.pageSize = val;
      this.getTableData();
    },
    getItemData() {
      this.resultData = JSON.parse(ls.getItem("itemData"));
      this.StaffInfo = this.resultData.staffInfo;
    },
  },
  // mounted() {
  //   this.getItemData();
  // },
  beforeRouteLeave(to, from, next) {
    if (to.name === "testCase") {
      to.meta.keepAlive = true;
    }
    next();
  },
};
</script>
<style scoped lang="less">
div {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.project_exam_detail_content {
  width: 100%;
  height: auto;
  // overflow-y: auto;
  background: #f9f9fd;
  padding: 0vh 1.5vw;
}
.project_exam_detail_content_header {
  width: 100%;
  height: 6vh;
  // margin: 4vh 0vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.project_exam_detail_content_header_right {
  width: 30%;
  height: 4vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.project_exam_detail_content_header_right_times {
  font-size: 14px;
  color: #333333;
}

.exam_small_content {
  width: 100%;
  // height: 80vh;
  padding-bottom: 10vh;
  border-radius: 10px;
  background: #fff;
}

.exam_small_content_header {
  width: 25%;
  // height: 11vh;
  padding: 2vh 0vw 2vh 1.5vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.exam_small_content_header_color {
  width: 6px;
  height: 3vh;
  font-size: 18px;
  background: #4e57aa;
}

.exam_small_content_header_text {
  margin-left: 0.7vw;
  font-size: 18px;
  line-height: 3vh;
}

.exam_small_content_select {
  width: 100%;
  height: 5vh;
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  align-items: center;
}

.exam_small_content_select_left {
  // width: 50%;
  height: 5vh;
  margin-left: 1.5vw;
  display: flex;
  flex-direction: row;
  // justify-content: space-around;
  align-items: center;
  .search {
    margin-left: 1.8vw;
    position: relative;
    .searchInput {
      position: absolute;
      right: 0;
      height: 100%;
      text-align: center;
      background: #4e57aa;
      width: 2vw;
      right: 0px;
      top: 0;
      border-radius: 0 5px 5px 0;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.exam_small_content_select_right {
  width: 12%;
  height: 5vh;
  margin-left: 2vw;
  margin-right: 1.5vw;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.el-input {
  width: 12vw;
}

.el-button--primary {
  background-color: #4e57aa;
  border-color: #4e57aa;
  color: #fff;
}

.el-button--primary:hover {
  background-color: #4e57aa;
  border-color: #4e57aa;
  color: #fff;
}

.export {
  float: right;
  margin-right: 1.5vw;
  color: #fff;
  background-color: #4e57aa;
}

.exam_small_content_main_box {
  width: 100%;
  // height: 58vh;
  padding: 0 1.5vw;
  // padding-top: 2vh;
}

.exam_small_content_main {
  background: rgba(78, 87, 170, 0.05);
}

.exam_small_content_main_item {
  height: 6.5vh;
  line-height: 6.5vh;
  text-align: center;
  font-size: 14px;
  color: #333333;
  font-weight: 600;
}

.exam_small_content_centent {
  background: rgba(255, 255, 255, 0.2);
  cursor: pointer;
}

.exam_small_content_centent:hover {
  background: rgba(187, 187, 187, 0.2);
  cursor: pointer;
}

.exam_small_content_main_centent_item {
  height: 6.5vh;
  line-height: 6.5vh;
  text-align: center;
  font-size: 14px;
  color: #333333;
}

.exam_basic_info {
  width: 100%;
  background: #4e57aa;
  border-radius: 29px;
  margin-bottom: 3vh;
  padding-top: 2.8vh;
  padding-bottom: 3vh;
  padding-left: 2.08vw;
  padding-right: 2.08vw;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}

.exam_basic_info_top {
  // width: 94%;
  // height: auto;
  // margin: 0 3% 0 3%;
  padding-bottom: 2.7vh;
  border-bottom: 1px solid rgba(237, 237, 237, 0.09);
  h3 {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 2.7vh;
  }
  p {
    margin: 0;
    margin-bottom: 1.85vh;
  }
  ul {
    display: flex;
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      width: 20%;
      &.yellow {
        color: #ffd942;
      }
      &:last-child {
        flex: 1;
      }
    }
  }
}

.exam_basic_info_top_head {
  width: 100%;
  height: 5.5vh;
  line-height: 5.5vh;
  font-size: 14px;
}

.exam_basic_info_top_foot {
  width: 100%;
  height: 5.5vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2vh;
}

.exam_basic_info_top_foot_item {
  font-size: 14px;
  margin-right: 4vw;
}

.exam_basic_info_bottom {
  padding-top: 2.5vh;
  // width: 94%;
  // height: auto;
  // margin: 0 3% 0 3%;
  ul {
    display: flex;
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      width: 20%;
      margin-bottom: 1.85vh;
      &.orange {
        color: #fe9431;
      }
    }
  }
}

.exam_basic_info_bottom_foot {
  width: 100%;
  height: 5.5vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.exam_basic_info_bottom_foot_item {
  font-size: 14px;
  margin-right: 4vw;
}

.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
  width: auto;
}
.el-range-editor--small.el-input__inner {
  height: 4.8vh;
}

::-webkit-scrollbar {
  width: 3px;
}
::-webkit-scrollbar-track {
  border-radius: 8px;
  width: 8px;
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #bbb;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #eee;
}
</style>
