import request from "@/utils/request";

export function industryList(data,pageNum,pageSize) {
  return request({
    url: "/jxzy/industry/list",
    method: "post",
    data,
    params:{pageNum,pageSize}
  });
}

export function industryInsert(data) {
  return request({
    url: "/jxzy/industry/insert",
    method: "post",
    data,
  });
}

export function industryUpdate(data) {
  return request({
    url: "/jxzy/industry/update",
    method: "post",
    data,
  });
}

export function industryDetail(industryId) {
  return request({
    url: "/jxzy/industry/detail",
    method: "post",
    data:{industryId},
  });
}