import request from "@/utils/request";

export function allInfo(data,pageNum,pageSize) {
  return request({
    url: "/jxzy/customer/allInfo",
    method: "post",
    data,
    params:{pageNum,pageSize}
  });
}

export function insertInfo(data) {
  return request({
    url: "/jxzy/customer/insertInfo",
    method: "post",
    data,
  });
}

export function getListInfo() {
  return request({
    url: "/jxzy/customer/getListInfo",
    method: "post",
  });
}

export function customerInfoXq(customerId) {
  return request({
    url: "/jxzy/customer/customerInfoXq",
    method: "post",
    data:{customerId},
  });
}

export function updateCustomerInfo(data) {
  return request({
    url: "/jxzy/customer/updateCustomerInfo",
    method: "post",
    data,
  });
}

export function toInsertPage() {
  return request({
    url: "/jxzy/customer/toInsertPage",
    method: "post",
  });
}
