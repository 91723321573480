<template>
  <div class="speechAnalysis">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/systemManage' }"
          >系统管理</el-breadcrumb-item
        >
        <el-breadcrumb-item>角色管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main">
      <div class="title">
        <div class="left">
          <i class="sep"></i>
          <h4>角色管理</h4>
        </div>
      </div>
      <el-row :gutter="20" class="marginTop">
        <el-col :span="6">
          <span class="selectName">角色名称：</span>
          <el-input
            class="input"
            placeholder="请输入"
            v-model="formData.roleName"
          >
          </el-input>
        </el-col>
        <el-col :span="6" class="status">
          <span class="selectName">客户名称：</span>
          <el-select v-model="formData.customerName" clearable filterable>
            <el-option
              v-for="item in customerMenu"
              :key="item.customerId"
              :label="item.customerName"
              :value="item.customerId"
            >
            </el-option>
            <!-- <el-option value="1"></el-option>
            <el-option value="2"></el-option> -->
          </el-select>
        </el-col>
        <el-col :span="3" :offset="3" class="fr">
          <el-button class="searchBtn" @click="onInquire">查询</el-button>
          <el-button @click="onReset">重置</el-button>
          <!-- <el-button class="searchBtn" @click="onAdd">新增角色</el-button> -->
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <TableChart
          :tableDatas="{
            id: 'character',
            data: character,
          }"
          :pageData="pageData"
          @changePage="currentChange"
          @changeSize="sizeChange"
        ></TableChart>
      </el-row>
    </div>
  </div>
</template>
<script>
import TableChart from "../table";
import { mapState, mapActions } from "vuex";
import { roleList, toInsertPage } from "@/api/sysManage/role";

export default {
  computed: {
    // ...mapState("speechAnalysis", ["character"]),
  },
  components: {
    TableChart,
  },
  data() {
    return {
      customerMenu: [],
      formData: {
        roleName: "",
        customerName: "",
      },
      character: {
        size: "",
        loading: false,
        columns: [
          {
            col: "客户名称",
            prop: "customerName",
            width: "",
          },
          {
            col: "角色名称",
            prop: "roleName",
            width: "",
          },
          {
            col: "角色类型",
            prop: "roleTypeName",
            width: "",
          },
          {
            col: "描述",
            prop: "remark",
            width: "",
          },
          {
            col: "创建时间",
            prop: "createDate",
            width: "",
          },
        ],
        data: [],
      },
      date: "",
      searchInput: "",
      pageData: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  created() {},
  methods: {
    getTableData() {
      this.character.loading = true;
      roleList(
        this.formData,
        this.pageData.pageNum,
        this.pageData.pageSize
      ).then((res) => {
        this.character.loading = false;
        this.character.data = res.rows;
        this.pageData.total = res.total;
      });
    },
    onInquire() {
      this.pageData.pageNum = 1;
      this.getTableData();
    },
    onReset() {
      this.formData.customerName = "";
      this.formData.roleName = "";
    },
    currentChange(val) {
      this.pageData.pageNum = val;
      this.getTableData();
    },
    sizeChange(val) {
      this.pageData.pageNum = 1;
      this.pageData.pageSize = val;
      this.getTableData();
    },
    onAdd() {
      this.$router.push("/systemManage/role/roleInfo");
    },
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === "roleInfo") {
      next((vm) => {
        vm.getTableData();
      });
    } else {
      next((vm) => {
        toInsertPage().then((res) => {
          vm.customerMenu = res.data.customerOptions;
        });
        if (vm.$route.query.customerId) {
          vm.formData.customerName = Number(vm.$route.query.customerId);
        }
        vm.getTableData();
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== "roleInfo") {
      from.meta.keepAlive = false;
      next();
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
$baseColor: #4e57aa;
$white: #fff;
$fontColor: #333;
$fontSize: 1.2em;
.speechAnalysis {
  margin: 2vh 1.5vw;
  .marginTop {
    margin-top: 2vh;
    margin-bottom: 5vh;
  }
  .main {
    background: #fff;
    padding: 2vh 1vw;
    margin-top: 2vh;
    border-radius: 2vh;
    height: 100%;
    min-height: 75vh;
    /deep/.input {
      width: 60%;
    }
    /deep/.el-select {
      width: 74%;
    }
    .title {
      display: flex;
      justify-content: space-between;
      .left {
        display: flex;
        .sep {
          background: $baseColor;
          width: 5px;
          height: 1.5rem;
          display: inline-block;
          margin: 0 5px;
        }
        h4 {
          padding: 0;
          margin: 0;
        }
      }
    }
    .status {
      // .selectName {
      //   width: 35%;
      // }
      /deep/.el-select {
        width: 65%;
      }
    }
    .fr {
      float: right;
      width: auto;
      margin-left: 0;
    }
    .selectName {
      // width: 10vh;
      font-size: 14px;
      display: inline-block;
      color: $fontColor;
      // text-align: justify;
      // text-align-last: justify;
    }
    .parent {
      position: relative;
      .searchInput {
        position: absolute;
        right: 0;
        height: 100%;
        text-align: center;
        background: $baseColor;
        width: 2vw;
        right: 0px;
        top: 0;
        border-radius: 0 5px 5px 0;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .searchBtn {
      color: $white;
      background-color: $baseColor;
      border-color: $baseColor;
    }
  }
}
</style>